import { EditPatientFeature } from 'features/patient/PatientFeature';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { useNavigate } from 'react-router-dom';
import { TITLE as parentTitle } from '../PatientPage';
import { TITLE } from './constants';

const EditPatientPage = () => {
  const navigate = useNavigate();
  const { patientId } = useProtectedParams('patientId');

  const parent = `../patients/${patientId}`;

  return (
    <Page pageType="standard" title={TITLE} parentRelativePath={parent} parentTitle={parentTitle}>
      <EditPatientFeature patientId={patientId} onSuccess={() => navigate(parent)} />
    </Page>
  );
};

export default EditPatientPage;
