import {
  useMediaQuery,
  useTheme
} from '@mui/material';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridOverlay,
  GridRowModel
} from '@mui/x-data-grid';
import get from 'lodash/get';
import {
  isNil,
  prop,
  times
} from 'ramda';
import { useDebounce } from 'use-debounce';
import { getRandomInt } from 'utils';

interface DataGridWithLoadingProps {
  loading: boolean;
  loadingNew: boolean;
  filters: { fields: string[], term: string | null }[]
  pageSize: number;
  idProp: string;
}

interface MobileConfig {
  isVisible: boolean;
  width: number;
}
export interface ResponsiveGridColDef extends GridColDef {
  mobileConfig?: MobileConfig;
}

export interface ResponsiveDataGridProps extends DataGridProps {
  columns: ResponsiveGridColDef[];
}

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <Box sx={{ pt: 5 }}>No Rows</Box>
  </GridOverlay>
);

const DataGridWithLoading = ({
  loadingNew,
  filters,
  loading,
  idProp,
  ...props
}: ResponsiveDataGridProps & DataGridWithLoadingProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isSearchingNew = filters && filters.length > 0 && loadingNew;
  let { rows, columns } = props;
  const { pageSize } = props;

  if (isSearchingNew) {
    filters.forEach((filterItem) => {
      if (filterItem.term) {
        const bulkFilter = (
          newRows: GridRowModel[],
          field: string,
        ) => ([
          ...newRows,
          ...rows.filter((row) => get(row, field).toLocaleLowerCase().includes(filterItem.term)),
        ]);
        rows = filterItem.fields.reduce(bulkFilter, []);
      }
    });
  }

  const hasLocalResultsForNewSearch = isSearchingNew && rows.length;
  const [loadingNewDebounced] = useDebounce(loadingNew && !hasLocalResultsForNewSearch, 300);

  columns = columns.map((col: ResponsiveGridColDef) => {
    const processedColumn = {
      ...col,
      renderCell: (params: any) => {
        if (loadingNewDebounced) {
          return <Skeleton animation="wave" height={20} width={getRandomInt(80, 150)} />;
        }
        if (isNil(col.renderCell)) {
          return params.formattedValue;
        }
        return col.renderCell(params);
      },
    };
    if (isSmall && col.mobileConfig) {
      processedColumn.width = col.mobileConfig.width;
      processedColumn.flex = undefined;
      processedColumn.hide = !col.mobileConfig.isVisible;
    }
    return processedColumn;
  });

  if (loadingNewDebounced) {
    rows = [...rows, ...times<any>((entityId) => ({ [idProp]: `${entityId}`, name: '' }), pageSize - rows.length)];
  }

  const updateProps = {
    ...props,
    rows,
    columns,
    getRowId: prop<string>(idProp),
  };

  return (
    <Box position="relative">
      <DataGrid
        {...updateProps}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
      />
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? '300ms' : '0ms',
        }}
        unmountOnExit
      >
        <Box position="absolute" top={52} left={0} width="100%"><LinearProgress /></Box>
      </Fade>
    </Box>
  );
};

export default DataGridWithLoading;
