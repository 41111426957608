import { RoleDto } from './api';
import { GetKey, makeGetQuery } from './useApi';

const ROLES_KEY = 'roles' as GetKey;

// eslint-disable-next-line import/prefer-default-export
export const useTeamRoleListQuery = makeGetQuery<RoleDto[], string>(
  ROLES_KEY,
  (api, teamId) => () => api.teams.getRoles(teamId),
);
