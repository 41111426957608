import { ZonedDateTime } from '@js-joda/core';
import {
  CalendarEventDto,
  CalendarEventForm,
  FileResponse,
  UpdateCalendarEventInfoCommand,
  UpdateCalendarEventTimeAndRepeatInfoCommand,
  UpdateCalendarEventUserCommand,
  UpdateTimeBlockCommand
} from './api';
import {
  GetKey,
  ListKey,
  makeCreateMutation,
  makeDeleteMutation,
  makeGetQuery,
  makeListQuery,
  makeUpdateMutation
} from './useApi';

const CALENDAR_EVENTS_KEY = 'calendar_events' as ListKey;
export const CALENDAR_EVENT_KEY = 'calendar_event' as GetKey;

export interface CalendarEventsQueryParams {
  practiceId: string,
  userId?: string,
  startDate: ZonedDateTime,
  endDate: ZonedDateTime;
}

export const useCalendarEventsQuery = makeListQuery<CalendarEventsQueryParams, CalendarEventDto[]>(
  CALENDAR_EVENTS_KEY,
  (api, params) => () => api.calendarEvents.list(
    params.startDate.toJSON(),
    params.endDate.toJSON(),
    params.practiceId,
    params.userId,
  ),
);

export interface CalendarEventQueryParams {
  practiceId: string,
  calendarEventId: string,
}

export const useCalendarEventQuery = makeGetQuery<CalendarEventDto, CalendarEventQueryParams>(
  CALENDAR_EVENT_KEY,
  (api, params) => () => api.calendarEvents.get(params.practiceId, params.calendarEventId),
);

export interface CreateCalendarEventMutation {
  practiceId: string;
  userId: string;
  form: CalendarEventForm;
}

export const useCreateCalendarEventMutation = makeCreateMutation<string, CreateCalendarEventMutation>(
  CALENDAR_EVENTS_KEY,
  (api) => (mutation) => api.calendarEvents.create(mutation.practiceId, mutation.form),
  () => 'A new calendar event has been created',
);

// Below are all parts of editing a calendar event
export interface UpdateTimeBlockMutation {
  practiceId: string;
  calendarEventId: string;
  form: UpdateTimeBlockCommand;
}

export const useUpdateTimeBlockMutation = makeCreateMutation<FileResponse, UpdateTimeBlockMutation>(
  CALENDAR_EVENTS_KEY,
  (api) => (mutation) => api.calendarEvents.updateTimeBlock(mutation.practiceId, mutation.calendarEventId, mutation.form),
  () => 'Calendar event updated successfully',
);
export interface UpdateCalendarEventTimeMutation {
  practiceId: string;
  calendarEventId: string;
  form: UpdateCalendarEventTimeAndRepeatInfoCommand;
}

export const useUpdateCalendarEventTime = makeUpdateMutation<FileResponse, UpdateCalendarEventTimeMutation>(
  CALENDAR_EVENT_KEY,
  CALENDAR_EVENTS_KEY,
  (api) => (mutation) => api.calendarEvents.updateCalendarEventTime(mutation.practiceId, mutation.calendarEventId, mutation.form),
  () => 'Calendar event updated successfully',
);
export interface UpdateCalendarEventUserMutation {
  practiceId: string;
  calendarEventId: string;
  form: UpdateCalendarEventUserCommand;
}

export const useUpdateCalendarEventUser = makeUpdateMutation<FileResponse, UpdateCalendarEventUserMutation>(
  CALENDAR_EVENT_KEY,
  CALENDAR_EVENTS_KEY,
  (api) => (mutation) => api.calendarEvents.updateCalendarEventUser(mutation.practiceId, mutation.calendarEventId, mutation.form),
  () => 'Vet updated successfully',
);

export interface UpdateCalendarEventInfoMutation {
  practiceId: string;
  calendarEventId: string;
  form: UpdateCalendarEventInfoCommand;
}

export const useUpdateCalendarEventInfo = makeUpdateMutation<FileResponse, UpdateCalendarEventInfoMutation>(
  CALENDAR_EVENT_KEY,
  CALENDAR_EVENTS_KEY,
  (api) => (mutation) => api.calendarEvents.updateCalendarEventInfo(mutation.practiceId, mutation.calendarEventId, mutation.form),
  () => 'Calendar event updated successfully',
);

export interface DeleteTimeBlockMutation {
  practiceId: string;
  calendarEventId: string;
  timeBlockId: string;
}

export const useDeleteTimeBlock = makeDeleteMutation<void, DeleteTimeBlockMutation>(
  CALENDAR_EVENT_KEY,
  CALENDAR_EVENTS_KEY,
  (api) => (mutation) => api.calendarEvents.deleteTimeBlock(mutation.practiceId, mutation.calendarEventId, mutation.timeBlockId),
  () => 'Individual calendar event has been successfully deleted',
  (mutation) => mutation.calendarEventId,
  [CALENDAR_EVENT_KEY],
);

export interface DeleteCalendarEventMutation {
  practiceId: string;
  calendarEventId: string;
}

export const useDeleteCalendarEvent = makeDeleteMutation<void, DeleteCalendarEventMutation>(
  CALENDAR_EVENT_KEY,
  CALENDAR_EVENTS_KEY,
  (api) => (mutation) => api.calendarEvents.delete(mutation.practiceId, mutation.calendarEventId),
  () => 'Calendar event series has been successfully deleted',
  (mutation) => mutation.calendarEventId,
);
