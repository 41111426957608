/* global google */

import { Instant } from '@js-joda/core';
import { MarkerIconTypes, MarkerProps } from 'components/GoogleMap/Marker';
import MapDashboardPage from 'features/MapDashboard';
import { ValidatedLocationsState } from 'features/ValidatedLocations/validatedLocation.context';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import {
  APPOINTMENT_KEY,
  GeoPointDto,
  useAppointmentQuery,
  useLocationQuery,
  ValidatedLocationDto
} from 'providers/api';
import { useValidatedLocationsQuery, ValidatedLocationsQueryParams } from 'providers/api/useValidatedLocations';
import React from 'react';
import { TITLE as parentTitle } from '../CalendarPage';

const selectMarkerType = (
  validatedLocationId: string,
  selectedLocationId: string | undefined,
  highlightedLocationId: string | undefined,
): MarkerIconTypes => {
  if (validatedLocationId === selectedLocationId) return 'location';
  if (validatedLocationId === highlightedLocationId) return 'vetOutdated';
  return 'vet';
};

const AppointmentLocationPage = () => {
  const { teamId, appointmentId } = useProtectedParams('teamId', 'appointmentId');
  const [highlightedLocationId, setHighlightedLocationId] = React.useState<string>();
  const [selectedLocationId, setSelectedLocationId] = React.useState<string>();
  const [selectedGeoPoint, setSelectedGeoPoint] = React.useState<GeoPointDto>();
  const [showSelectedGeoPoint, setShowSelectedGeoPoint] = React.useState(false);

  const [pageInfo, setPageInfo] = React.useState<ValidatedLocationsQueryParams>({
    clientId: '',
    teamId,
  });

  const { data: appointment } = useAppointmentQuery(
    appointmentId,
    { onSuccess: (loadedAppointment) => setPageInfo((currentPageInfo) => ({ ...currentPageInfo, clientId: loadedAppointment.clientId })) },
  );

  const { data: location } = useLocationQuery({ entityId: appointmentId, entityType: APPOINTMENT_KEY }, {
    enabled: !!appointment,
  });

  const { data: validatedLocations } = useValidatedLocationsQuery(
    pageInfo,
    { enabled: pageInfo.clientId !== '' },
  );

  const validatedLocationsWithoutMarkedForRemoval = validatedLocations?.filter(
    (currentLocation) => !currentLocation.markedForRemoval,
  );

  // placed in the validated location array so temp locations appear on map and sidebar
  const tempLocationAsValidatedLocation: ValidatedLocationDto[] = appointment && location && !location.validatedLocationId
    ? [{
      description: `Temporary Location: ${location?.description}`,
      geoPoint: location.geoPoint,
      entityId: location.entityId,
      clientId: appointment.clientId,
      modifiedBy: 'unknown',
      dateModified: Instant.from(location.dateModified),
      markedForRemoval: false,
      tags: [],
    }]
    : [];

  const completeValidatedLocationList = validatedLocationsWithoutMarkedForRemoval
    ? [
      ...tempLocationAsValidatedLocation,
      ...validatedLocationsWithoutMarkedForRemoval,
    ]
    : tempLocationAsValidatedLocation;

  const validatedLocationMarkerData: MarkerProps[] = React.useMemo(
    () => (completeValidatedLocationList
      ? completeValidatedLocationList.map((validatedLocation) => (
        {
          key: validatedLocation.entityId,
          position: {
            lat: validatedLocation.geoPoint.latitude,
            lng: validatedLocation.geoPoint.longitude,
          },
          selected: true,
          iconType: selectMarkerType(validatedLocation.entityId, selectedLocationId, highlightedLocationId),
          info: validatedLocation.description,
          onClick: () => { },
          onMouseOver: () => setHighlightedLocationId(validatedLocation.entityId),
          onMouseOut: () => setHighlightedLocationId(undefined),
        }
      ))
      : []
    ),
    [completeValidatedLocationList, validatedLocations, highlightedLocationId, selectedLocationId, location],
  );

  const validatedLocationState: ValidatedLocationsState = {
    highlightedValidatedLocationId: highlightedLocationId,
    selectedValidatedLocationId: selectedLocationId,
    validatedLocations: completeValidatedLocationList,
    confirmedValidatedLocationId: location?.validatedLocationId ?? location?.entityId,
    selectedGeoPoint,
    setHighlightedValidatedLocationId: setHighlightedLocationId,
    setShowSelectedGeoPoint,
    setSelectedValidatedLocationId: setSelectedLocationId,
  };

  const tempMarkerData = (geoPoint: GeoPointDto | undefined, showSelectedLocation: boolean): MarkerProps[] => (
    geoPoint && showSelectedLocation
      ? [{
        position: { lat: geoPoint.latitude, lng: geoPoint.longitude },
        selected: true,
        iconType: 'vetOutdated',
      }]
      : []
  );

  const markerData = [...validatedLocationMarkerData, ...tempMarkerData(selectedGeoPoint, showSelectedGeoPoint)];

  const handleLocationSelect = (latLng: google.maps.LatLngLiteral) => setSelectedGeoPoint({ latitude: latLng.lat, longitude: latLng.lng });

  return (
    <Page pageType="full" title="Appointment Location" parentTitle={parentTitle} parentRelativePath="../">
      <MapDashboardPage
        markerData={markerData}
        title="Locations"
        actions={[]}
        contextData={validatedLocationState}
        onMapClick={handleLocationSelect}
        search
      />
    </Page>
  );
};

export default AppointmentLocationPage;
