import {
  FileResponse, PagedListOfAppointmentDto, PagedListOfMessageDto, TeamDto, UpdateUserCommand
} from './api';
import {
  GetKey,
  ListKey,
  makeListQuery,
  makeUpdateMutation,
  PageParams
} from './useApi';
import { USER_KEY } from './useUsers';

const MESSAGES_KEY = 'messages' as ListKey;
const MESSAGE_KEY = 'message' as GetKey;

const ME_KEY = 'user-practices' as ListKey;
const MY_KEY = 'user-practice' as GetKey;

export const useMyTeamsQuery = makeListQuery<null, TeamDto[]>(
  ME_KEY,
  (api) => () => api.me.getTeams(),
);

export const useArchiveMyPreviousMessagesMutation = makeUpdateMutation<void, string>(
  MESSAGE_KEY,
  MESSAGES_KEY,
  (api, teamId) => () => api.me.archiveMyPreviousMessages(teamId),
  () => 'Messages have been archived',
);

export const useMyAppointmentsQuery = makeListQuery<string, PagedListOfAppointmentDto>(
  ME_KEY,
  (api, teamId) => () => api.me.getMyAppointments(1, 100, teamId),
);

export interface MessagesQueryParams extends PageParams {
  teamId: string;
}

export const useMyMessageListQuery = makeListQuery<MessagesQueryParams, PagedListOfMessageDto>(
  MESSAGES_KEY,
  (api, params) => () => api.me.myMessages(params.page, params.pageSize, params.teamId),
);

export const useUpdateProfile = makeUpdateMutation<FileResponse, UpdateUserCommand>(
  MY_KEY,
  ME_KEY,
  (api) => (command) => api.me.updateUser(command),
  () => 'Account settings updated',
  [USER_KEY],
);
