import PatientsFeature from 'features/patient/PatientsFeature';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { useNavigate } from 'react-router-dom';
import { TITLE as sectionTitle } from './constants';

const PatientsPage = () => {
  const title = sectionTitle;
  const navigate = useNavigate();
  const { teamId } = useProtectedParams('teamId');

  const handleNavigateToPatient = (patientId: string) => navigate(patientId);

  return (
    <Page pageType="full" title={title}>
      <PatientsFeature onPatientSelect={handleNavigateToPatient} clientId={teamId} />
    </Page>
  );
};

export default PatientsPage;
