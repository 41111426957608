import {
  FormikConfig, FormikHandlers,
  FormikHelpers,
  useFormik
} from 'formik';
import camelCase from 'lodash/camelCase';
import {
  dissocPath,
  hasPath,
  is,
  lensPath, path,
  set
} from 'ramda';
import React, { useEffect } from 'react';
import { UseMutationResult } from 'react-query';
import { isNilOrEmpty, removeNilOrEmptyKeysFromObject } from 'utils';
import { mixed, ObjectSchema } from 'yup';
import { Form } from './context';

const fieldFromEvent = (event: React.ChangeEvent<any>) => {
  const target = event.target
    ? (event as React.ChangeEvent<any>).target
    : (event as React.ChangeEvent<any>).currentTarget;
  const {
    name,
    id,
  } = target;

  return name ?? id;
};

// Buzz: Taken from formik source code so than we can wrap the handle change method

// React currently throws a warning when using useLayoutEffect on the server.
// To get around it, we can conditionally useEffect on the server (no-op) and
// useLayoutEffect in the browser.
// @see https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
const useIsomorphicLayoutEffect = typeof window !== 'undefined'
  && typeof window.document !== 'undefined'
  && typeof window.document.createElement !== 'undefined'
  ? React.useLayoutEffect
  : React.useEffect;

const useEventCallback = <T extends (...args: any[]) => any>(fn: T): T => {
  const ref: any = React.useRef(fn);

  // we copy a ref to the callback scoped to the current state/props on each render
  useIsomorphicLayoutEffect(() => {
    ref.current = fn;
  });

  return React.useCallback(
    // eslint-disable-next-line no-void
    (...args: any[]) => ref.current.apply(void 0, args),
    [],
  ) as T;
};

interface useDefaultFormProps<T> {
  mutation: UseMutationResult<any, any, any, any>;
  formikConfig: FormikConfig<T>;
  pathToServerEntityModel?: string;
}

const useDefaultForm = <TData>({ mutation, formikConfig, pathToServerEntityModel }: useDefaultFormProps<TData>): Form<TData> => {
  const [serverErrors, setServerErrors] = React.useState<object>();

  const currentSchema: ObjectSchema<any> | undefined = formikConfig.validationSchema;

  const schemaWithServerError = currentSchema?.shape({
    _server: mixed().test(
      'server-error',
      'Server error',
      () => isNilOrEmpty(serverErrors),
    ),
  });

  const formikConfigWithDefaults: FormikConfig<TData> = {
    ...formikConfig,
    validationSchema: schemaWithServerError,
  };

  const formik = useFormik<TData>(formikConfigWithDefaults);

  const { setSubmitting, resetForm, values } = formik;

  useEffect(() => {
    if (!mutation.isLoading) setSubmitting(false);
  }, [mutation.isLoading]);

  useEffect(() => {
    if (mutation.isSuccess) resetForm({ values });
  }, [mutation.isSuccess]);

  const processServerErrors = () => {
    if (mutation.error && mutation.error.status === 400 && mutation.error.result.errors) {
      const errors = Object.keys(mutation.error.result.errors)
        .reduce((accumulator: any, error: string) => {
          const formPath = pathToServerEntityModel ? `${pathToServerEntityModel}.${error}` : error;
          const targetPath = formPath.split('.').map(camelCase);
          const errorPathLens = lensPath(targetPath);
          // Space before capital and after acronym
          const errorString = mutation.error.result.errors[error][0].replace(/([A-Z]+)/g, ' $1').replace(/(?:[A-Z])(?=[a-z])/g, ' $&').trim();
          return set(errorPathLens, errorString, accumulator);
        }, {});

      setServerErrors(errors);
    }
  };

  const removePathFromErrors = (fieldArrayPath: string[]) => {
    setServerErrors((currentServerErrors: any) => {
      if (hasPath(fieldArrayPath, currentServerErrors)) {
        return dissocPath(fieldArrayPath, currentServerErrors);
      }

      if (!currentServerErrors) {
        return undefined;
      }

      return removeNilOrEmptyKeysFromObject(currentServerErrors);
    });
  };

  React.useEffect(() => {
    processServerErrors();
  }, [mutation.error]);

  const getErrorHelpText = (stringPath: string) => {
    const arrayPath = stringPath.split('.');
    const isTouched = path(arrayPath, formik.touched);

    if (!isTouched) {
      return '';
    }

    const client = path(arrayPath, formik.errors) as string;
    const server = path(arrayPath, serverErrors) as string;

    return client ?? server;
  };

  const hasError = (stringPath: string) => {
    const arrayPath = stringPath.split('.');
    const isTouched = path(arrayPath, formik.touched) as boolean;
    const hasClientError = Boolean(path(arrayPath, formik.errors));
    const hasServerError = Boolean(path(arrayPath, serverErrors));

    return isTouched && (hasClientError || hasServerError);
  };

  const handleChangeCallback = (eventOrPath: string | React.ChangeEvent<any>): void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void) => {
    const fieldPath = is(String, eventOrPath) ? eventOrPath as string : fieldFromEvent(eventOrPath as React.ChangeEvent<any>);
    const fieldArrayPath = fieldPath.split('.');

    removePathFromErrors(fieldArrayPath);

    return formik.handleChange(eventOrPath);
  };

  const handleChange = useEventCallback<FormikHandlers['handleChange']>(handleChangeCallback);

  const setFieldValue: FormikHelpers<any>['setFieldValue'] = (...args) => {
    const [field] = args;
    const fieldArrayPath = field.split('.');

    removePathFromErrors(fieldArrayPath);

    formik.setFieldValue(...args);
  };

  return {
    formik: {
      ...formik,
      handleChange,
      setFieldValue,
    },
    helpers: {
      hasError,
      getErrorHelpText,
    },
  };
};

export default useDefaultForm;
