import { useContext } from 'react';
import MenuStateContext from './menuStateContext';

const useMenuState = () => {
  const context = useContext(MenuStateContext);
  if (context === null) {
    throw new Error('No menuState context available');
  }
  return context;
};

export default useMenuState;
