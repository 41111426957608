import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { UserDto } from 'providers/api';
import { ArrivetUser } from 'providers/authorisation/context';
import React from 'react';

interface UsersTableMenuProps {
  user: UserDto;
  currentUser: ArrivetUser;
  onEdit: (userId: string) => void;
  onDelete: (user: UserDto) => void;
}

const UsersTableMenu = ({ user, currentUser, onEdit, onDelete }: UsersTableMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit(user.userId);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete(user);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" aria-controls={`client-actions-menu-${user.userId}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`client-actions-menu-${user.userId}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Edit Roles</Typography>
        </MenuItem>
        <MenuItem onClick={handleDelete} disabled={currentUser.id === user.userId}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <Typography variant="inherit" color="error">Delete</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UsersTableMenu;
