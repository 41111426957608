import { LocationDto, UpdateLocationCommand } from './api';
import {
  GetKey,
  ListKey,
  makeGeneralUpdateMutation,
  makeGetQuery,
  makeListQuery,
  makeUpdateMutation
} from './useApi';

const LOCATIONS_KEY = 'locations' as ListKey;
const LOCATION_KEY = 'location' as GetKey;

export const useUpdateLocationMutation = makeUpdateMutation<string, UpdateLocationCommand>(
  LOCATION_KEY,
  LOCATIONS_KEY,
  (api, appointmentId) => (location) => api.locations.update(location, appointmentId),
  () => 'Location successfully updated',
  // locations do not follow the same pattern as other updates end points. The entity Id could belong to
  // any model such as Appointment.cs or User.cs. The usual invalidation does not apply. Instead, we
  // invalidate all the individual locations.
  [LOCATION_KEY],
);

export interface LocationQueryParams {
  entityId: string;
  entityType: string;
}

export const useLocationQuery = makeGetQuery<LocationDto | undefined, LocationQueryParams>(
  LOCATION_KEY,
  (api, params) => async () => {
    const location = await api.locations.get(params.entityId, params.entityType)
      .then((result) => result)
      .catch(() => undefined);
    return location;
  },
);

export interface LocationsGetParams {
  entityIds: string[];
  entityType: string;
}

export const useLocationsQuery = makeListQuery<LocationsGetParams, LocationDto[]>(
  LOCATIONS_KEY,
  (api, params) => async () => {
    const promiseQueue = Promise.allSettled(params.entityIds.map((entityId) => api.locations.get(entityId, params.entityType)));
    const promiseResults = await promiseQueue;
    return promiseResults.filter((result) => result.status === 'fulfilled').map((result) => (result as PromiseFulfilledResult<LocationDto>).value);
  },
);

export interface UserLocationQueryParams {
  appointmentId: string;
  userId: string;
}

export const useUserLocationQuery = makeGetQuery<LocationDto, UserLocationQueryParams>(
  LOCATION_KEY,
  (api, params) => () => api.locations.getUserLocation(params.appointmentId, params.userId),
);

export const useUsersLocationQuery = makeListQuery<UserLocationQueryParams[], LocationDto[]>(
  LOCATIONS_KEY,
  (api, users) => async () => {
    const promiseQueue = Promise.allSettled(users.map((user) => api.locations.getUserLocation(user.appointmentId, user.userId)));
    const promiseResults = await promiseQueue;
    return promiseResults.filter((result) => result.status === 'fulfilled').map((result) => (result as PromiseFulfilledResult<LocationDto>).value);
  },
);

export const useUpdatePracticeLocationMutation = makeGeneralUpdateMutation<string, UpdateLocationCommand>(
  (api) => (mutation) => api.locations.updatePracticeLocation(mutation),
  () => 'Location successfully updated',
  (mutation) => [
    LOCATIONS_KEY,
    [LOCATION_KEY, { entityId: mutation.entityKey, entityType: mutation.entityType }],
  ],
);
export interface UpdatePublicLocationMutation {
  cmd: UpdateLocationCommand;
  accessTokenHash: string
}

// TODO: use new makeGeneralUpdateMutation when it is available in develop
export const useUpdatePublicAppointmentLocationMutation = makeUpdateMutation<string, UpdatePublicLocationMutation>(
  LOCATION_KEY,
  LOCATIONS_KEY,
  (api, appointmentId) => (mutation) => api.locations.publicAppointmentLocationUpdate(mutation.cmd, appointmentId, mutation.accessTokenHash),
  () => 'Location successfully updated',
  // locations do not follow the same pattern as other updates end points. The entity Id could belong to
  // any model such as Appointment.cs or User.cs. The usual invalidation does not apply. Instead, we
  // invalidate all the individual locations.
  [LOCATION_KEY],
);

export interface PublicGetLocationQuery {
  appointmentId: string;
  accessTokenHash: string
}

export const usePublicAppointmentLocationGetQuery = makeGetQuery<LocationDto | undefined, PublicGetLocationQuery>(
  LOCATION_KEY,
  (api, params) => async () => {
    const location = await api.locations.publicAppointmentLocationGet(params.appointmentId, params.accessTokenHash)
      .then((result) => result)
      .catch(() => undefined);
    return location;
  },
);
