import { GridComponentProps } from '@mui/x-data-grid';
import DataGridWithLoading, { ResponsiveGridColDef } from 'components/DataGrid';
import { PAGINATION_DEFAULT_PAGE_SIZE, PAGINATION_PAGE_SIZES } from 'config';
import { ClientDto } from 'providers/api';
import ClientsTableMenu from './ClientsTableMenu';

interface ClientsTableProps {
  data: ClientDto[] | undefined;
  page: number;
  pageSize: number;
  totalItems: number | undefined;
  loading: boolean;
  loadingNew: boolean;
  clientNameSearchTerm: string | null;
  clientPostCodeSearchTerm: string | null;
  onPageChange: GridComponentProps['onPageChange'];
  onPageSizeChange: GridComponentProps['onPageSizeChange'];
  onSelect: (clientId: string) => void,
  onEdit: (userId: string) => void;
  onInvite: (userId: string) => void;
  onDelete: (user: ClientDto) => void;
}

const ClientsTable = ({
  data,
  loadingNew,
  loading,
  page,
  pageSize = PAGINATION_DEFAULT_PAGE_SIZE,
  totalItems = 0,
  clientNameSearchTerm,
  clientPostCodeSearchTerm,
  onPageChange,
  onPageSizeChange,
  onSelect,
  onEdit,
  onInvite,
  onDelete,
}: ClientsTableProps) => {
  const pageIndex = data ? (page - 1) : 0;
  const rows = data ?? [];

  const columns: ResponsiveGridColDef[] = [
    {
      field: 'name',
      headerName: 'Client Name',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 1,
    },
    {
      field: 'mainContact',
      headerName: 'Contact Name',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: ({ row }) => `${row.mainContact.firstName} ${row.mainContact.lastName}`,
    },
    {
      field: 'mainContact.contactNumber',
      headerName: 'Number',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 0.6,
      renderCell: ({ row }) => row.mainContact.contactNumber,
    },
    {
      field: 'mainContact.site.addressLine1',
      headerName: 'Address',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: ({ row }) => row.site.addressLine1,
    },
    {
      field: 'site.postCode',
      headerName: 'Postcode',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 0.4,
      renderCell: ({ row }) => row.site.postCode,
    },
    {
      field: 'mainContact.email',
      headerName: 'Contact Email',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 1.25,
      renderCell: ({ row }) => row.mainContact.email,
    },
    {
      field: '',
      sortable: false,
      filterable: false,
      resizable: false,
      width: 80,
      renderCell: ({ row }) => (<ClientsTableMenu client={row as any} onEdit={onEdit} onInvite={onInvite} onDelete={onDelete} />),
    },
  ];

  return (
    <DataGridWithLoading
      loading={loading}
      loadingNew={loadingNew}
      filters={[
        {
          fields: ['name'],
          term: clientNameSearchTerm,
        },
        {
          fields: ['site.postCode'],
          term: clientPostCodeSearchTerm,
        },
      ]}
      idProp="entityId"
      disableSelectionOnClick
      disableColumnMenu
      autoHeight
      rows={rows}
      columns={columns}
      pagination
      paginationMode="server"
      page={pageIndex}
      pageSize={pageSize}
      rowCount={totalItems}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onRowClick={({ row }) => onSelect(row.entityId)}
      rowsPerPageOptions={PAGINATION_PAGE_SIZES}
    />
  );
};

export default ClientsTable;
