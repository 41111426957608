import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Loading from 'components/Loading';
import TitleBox from 'components/TitleBox';
import ValidatedLocationAssociatedUser from 'features/ValidatedLocations/ValidatedLocationAssocisatedUser';
import ValidatedLocationInfoForm from 'features/ValidatedLocations/ValidatedLocationInfoForm';
import ValidatedLocationPositionForm from 'features/ValidatedLocations/ValidatedLocationPositionForm';
import useProtectedParams from 'hooks/useProtectedParams';
import { useListOfTeamUsersQuery } from 'providers/api';
import {
  useDeleteValidatedLocationMutation,
  useUpdateValidatedLocationDescriptionMutation,
  useUpdateValidatedLocationPositionMutation,
  useValidatedLocationQuery
} from 'providers/api/useValidatedLocations';
import { useConfirmation } from 'providers/confirm';
import { useNavigate } from 'react-router-dom';

const ValidatedLocationPanel = () => {
  const confirm = useConfirmation();
  const { teamId, locationId, clientId } = useProtectedParams('teamId', 'locationId', 'clientId');
  const navigate = useNavigate();

  const { data: location, refetch: refetchValidatedLocation } = useValidatedLocationQuery({ entityId: locationId, clientId });

  const updateValidatedLocationDetails = useUpdateValidatedLocationDescriptionMutation(locationId, { onSuccess: () => refetchValidatedLocation() });
  const updateValidatedLocationPosition = useUpdateValidatedLocationPositionMutation(locationId, { onSuccess: () => refetchValidatedLocation() });
  const deleteValidatedLocation = useDeleteValidatedLocationMutation();

  const associatedUserIds = [
    ...(location && location.modifiedBy ? [location.modifiedBy] : []),
    ...(location && location.validatingClientUser ? [location.validatingClientUser] : []),
    ...(location && location.validatingVetUser ? [location.validatingVetUser] : []),
  ];

  const { data: associatedUsers } = useListOfTeamUsersQuery({
    teamId,
    userIds: associatedUserIds,
  });

  if (!location) return <Loading />;

  const isValidatedLocation = !!location.validatingClientUser || !!location.validatingVetUser;

  const handleDeleteValidatedLocation = () => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want to delete this validated location?',
    }).then(() => deleteValidatedLocation.mutate({ practiceId: teamId, validatedLocationId: locationId }))
      .then(() => navigate('../'));
  };

  // Receptionist can only read or delete validated locations (readOnly)
  return (
    <>
      <ValidatedLocationInfoForm
        readOnly
        mutation={updateValidatedLocationDetails}
        validatedLocation={location}
      />
      <ValidatedLocationPositionForm
        readOnly
        location={location}
        mutation={updateValidatedLocationPosition}
      />

      {isValidatedLocation && (
        <TitleBox title="Validated by" small>
          <ValidatedLocationAssociatedUser users={associatedUsers ?? []} validatedLocation={location} />
        </TitleBox>
      )}
      <Tooltip
        title="The validated location needs to be marked for deletion by the client before it can be deleted"
        disableHoverListener={location.markedForRemoval}
      >
        <span>
          <Button
            fullWidth
            variant="outlined"
            color="error"
            onClick={handleDeleteValidatedLocation}
            disabled={!location.markedForRemoval}
          >
            Delete Location
          </Button>
        </span>
      </Tooltip>
    </>
  );
};
export default ValidatedLocationPanel;
