import { MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AppointmentType } from 'providers/api';
import { enumValues, splitCaps } from 'utils';
import { useAppointmentForm } from './context';

const AppointmentTypeField = () => {
  const {
    form: { formik, helpers },
  } = useAppointmentForm();

  const { values, handleChange, handleBlur } = formik;

  return (
    <TextField
      fullWidth
      id="appointmentType"
      name="appointmentType"
      label="Appointment Type"
      value={values.appointmentType ?? ''}
      onChange={handleChange}
      onBlur={handleBlur}
      error={helpers.hasError('appointmentType')}
      helperText={helpers.getErrorHelpText('appointmentType')}
      select
    >
      <MenuItem value="">
        None
      </MenuItem>
      {enumValues(AppointmentType).map((option) => (
        <MenuItem key={option} value={option}>
          {splitCaps(AppointmentType[option as any])}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default AppointmentTypeField;
