import { HOUR_IN_MILLISECONDS } from 'config';
import { useClientQuery } from 'providers/api';

const useClientPageTitle = (clientId: string, sectionTitle: string) => {
  const { data: client } = useClientQuery(clientId, { staleTime: HOUR_IN_MILLISECONDS });

  return client ? `${client.name} - ${sectionTitle}` : sectionTitle;
};

export default useClientPageTitle;
