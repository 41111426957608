import { Duration, ZonedDateTime } from '@js-joda/core';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { SECOND_IN_MILLISECONDS } from 'config';
import AppointmentStatusChip from 'features/AppointmentStatusChip';
import VetStatusChip from 'features/VetStatusChip';
import useProtectedParams from 'hooks/useProtectedParams';
import {
  AppointmentDto,
  AppointmentStatus,
  EtaDto,
  useArrivalTimesQuery
} from 'providers/api';
import useApi from 'providers/api/useApi';
import { useSignalR } from 'providers/signalR';
import { indexBy } from 'ramda';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DEFAULT_TIME_CONFIG,
  displayTemporal,
  getDurationHoursMinsSeconds,
  isNilOrEmpty
} from 'utils';
import { DashboardState, useDashboardState } from './DashboardPage';
import IncrementalDisplay from './IncrementalDisplay';
import useDashboardData from './useDashboardData';
import useVetLocations from './useVetLocations';

const ScheduledVetsPanel = () => {
  const { teamId } = useProtectedParams('teamId');
  const navigate = useNavigate();
  const signalR = useSignalR();
  const api = useApi();
  const { dashboardState } = useDashboardState();

  const {
    indexes: {
      vetLocationsIndexedById,
      appointmentsIndexedByVetId,
    },
  } = useDashboardData(teamId);

  const realtimeVetLocationsIndexedById = useVetLocations(vetLocationsIndexedById, appointmentsIndexedByVetId);

  const [impendingAppointmentsIndexedByVetId, setImpendingAppointmentsIndexedByVetId] = React.useState<Record<string, AppointmentDto>>({});
  const [impendingAppointmentEtasIndexByVetId, setImpendingAppointmentEtasIndexByVetId] = React.useState<Record<string, EtaDto>>({});

  useArrivalTimesQuery(
    Object.values(impendingAppointmentsIndexedByVetId).map((appointment) => appointment.entityId),
    {
      enabled: !isNilOrEmpty(impendingAppointmentsIndexedByVetId) && isNilOrEmpty(impendingAppointmentEtasIndexByVetId),
      onSuccess: (etas) => setImpendingAppointmentEtasIndexByVetId(indexBy((eta) => eta.userId, etas)),
    },
  );

  const updateImpendingAppointment = (appointment: AppointmentDto) => {
    setImpendingAppointmentsIndexedByVetId((existingImpendingAppointmentsIndexedByVetId) => ({
      ...existingImpendingAppointmentsIndexedByVetId,
      [appointment.entityId]: appointment,
    }));
  };

  const updateImpendingAppointmentEta = (eta: EtaDto) => setImpendingAppointmentEtasIndexByVetId((existingEtas) => ({ ...existingEtas, [eta.userId]: eta }));
  const handleVetSelected = (vetId: string) => navigate(`${vetId}/info`);

  React.useEffect(() => {
    const etaUpdatedCallbackId = signalR.actions.addCallback('Vet ETA updated', (payload) => {
      const appointmentId = payload.split('|')[0];
      api.appointments.get(appointmentId).then((result) => updateImpendingAppointment(result));
      api.arrivalTimes.get(appointmentId).then((result) => updateImpendingAppointmentEta(result));
    });
    return () => signalR.actions.removeCallback('Vet ETA updated', etaUpdatedCallbackId);
  }, []);

  React.useEffect(
    () => {
      dashboardState.appointments && setImpendingAppointmentsIndexedByVetId(
        indexBy(
          (a) => a.vet.entityId,
          Object.values(dashboardState.appointments)
            .filter((appointment) => appointment.appointmentStatus === AppointmentStatus.InTransit || appointment.appointmentStatus === AppointmentStatus.InProgress),
        ),
      );
    },
    [(dashboardState as DashboardState).appointments],
  );

  return (
    <Box height="calc(100vh - 200px)" overflow="auto">
      <Typography variant="h5" sx={{ textAlign: 'center' }}>Active Vets</Typography>
      {dashboardState && Object.entries(dashboardState.scheduledVets).map(
        ([vetId, vet]) => (
          <Card key={vetId} sx={{ background: (theme) => theme.palette.grey[200], marginBottom: 2 }}>
            <CardContent sx={{ paddingBottom: 0.5 }}>
              <Stack spacing={1}>
                <Stack direction="row">
                  <Box>
                    <Typography variant="subtitle2" component="div" flexGrow={1}>
                      {`${vet.forename} ${vet.surname}`}
                    </Typography>
                    {
                      realtimeVetLocationsIndexedById[vetId]
                      && (
                        <>
                          <Typography variant="caption">
                            Location last confirmed:
                          </Typography>
                          <br />
                          <Typography variant="caption">
                            <IncrementalDisplay
                              callback={() => (Duration.between(realtimeVetLocationsIndexedById[vetId].dateModified, ZonedDateTime.now())
                              .toHours() < 48
                              ? getDurationHoursMinsSeconds(realtimeVetLocationsIndexedById[vetId].dateModified)
                              : 'No recent update')}
                              delay={SECOND_IN_MILLISECONDS}
                            />
                          </Typography>
                        </>
                      )
                    }
                  </Box>
                  {dashboardState.vetIdsArrayForAppointmentsWithoutLocations.includes(vetId) && (
                    <Tooltip title="Error: missing appointment locations">
                      <WrongLocationIcon />
                    </Tooltip>
                  )}
                </Stack>
                <Stack direction="row">
                  {impendingAppointmentsIndexedByVetId[vetId] && (
                    <Stack direction="row" spacing={1} pt={1}>
                      <VetStatusChip vetStatus={impendingAppointmentsIndexedByVetId[vetId]?.etaStatus} />
                      <AppointmentStatusChip appointmentStatus={impendingAppointmentsIndexedByVetId[vetId]?.appointmentStatus} />
                    </Stack>
                  )}
                </Stack>

                <Divider />
                {
                  impendingAppointmentsIndexedByVetId[vetId] ? (
                    <>
                      <Typography variant="caption">
                        Current Appointment
                      </Typography>
                      {
                        impendingAppointmentEtasIndexByVetId[vetId] && impendingAppointmentEtasIndexByVetId[vetId].estimatedArrivalTime
                        && (
                          <Typography variant="caption">
                            {`ETA: ${displayTemporal(impendingAppointmentEtasIndexByVetId[vetId].estimatedArrivalTime!, DEFAULT_TIME_CONFIG)}`}
                          </Typography>
                        )
                      }
                      <Typography variant="caption">
                        {`${impendingAppointmentsIndexedByVetId[vetId].patient.name}:
                        ${displayTemporal(impendingAppointmentsIndexedByVetId[vetId].startTime, DEFAULT_TIME_CONFIG)}-
                        ${displayTemporal(impendingAppointmentsIndexedByVetId[vetId].endTime, DEFAULT_TIME_CONFIG)}`}
                      </Typography>
                    </>
                  ) : <Typography variant="caption">No Current Appointment</Typography>
                }

              </Stack>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => handleVetSelected(vetId)}>View Schedule</Button>
            </CardActions>
          </Card>
        ),
      )}
    </Box>
  );
};

export default ScheduledVetsPanel;
