import TextField from '@mui/material/TextField';
import { useAccountForm } from './context';

const AccountContactNumberField = () => {
  const {
    form: { formik, helpers },
  } = useAccountForm();

  const { values, handleChange, handleBlur } = formik;

  return (
    <TextField
      required
      autoFocus
      fullWidth
      id="contactNumber"
      name="contactNumber"
      label="Contact Number"
      value={values.contactNumber}
      onChange={handleChange}
      onBlur={handleBlur}
      error={helpers.hasError('contactNumber')}
      helperText={helpers.getErrorHelpText('contactNumber')}
    />
  );
};

export default AccountContactNumberField;
