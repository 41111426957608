import { CLIENT_ROLES } from 'config';
import { EditUserFeature } from 'features/user';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import useClientPageTitle from '../../../useClientPageTitle';
import { TITLE as parentTitle } from '../ClientUsersPage';
import { TITLE as pageTitle } from './constants';

const EditClientUserPage = () => {
  const { clientId, userId } = useProtectedParams('clientId', 'userId');
  const title = useClientPageTitle(clientId, pageTitle);

  return (
    <Page title={title} pageType="large" parentTitle={parentTitle} parentRelativePath="../users">
      <EditUserFeature teamId={clientId} userId={userId} availableRoles={CLIENT_ROLES} />
    </Page>
  );
};

export default EditClientUserPage;
