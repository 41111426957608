/* global google */
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { GoogleMap, Marker } from 'components/GoogleMap';
import { INITIAL_ZOOM_FOR_UK, LONDON_CENTER } from 'config';
import React, { useState } from 'react';

interface GeolocationPositionError {
  readonly code: number;
  readonly message: string;
  readonly PERMISSION_DENIED: number;
  readonly POSITION_UNAVAILABLE: number;
  readonly TIMEOUT: number;
}

interface ShareLocationProps {
  appointmentLocation?: google.maps.LatLngLiteral
  currentClientLocation?: google.maps.LatLngLiteral;
  onSaveLocation: () => void;
  setCurrentClientLocation: (latLng: google.maps.LatLngLiteral) => void
}

// INFO: This component needs to be updated with respect to the changes to practice map location and reused where required.
const ShareLocation = ({ currentClientLocation, onSaveLocation, setCurrentClientLocation, appointmentLocation }: ShareLocationProps) => {
  const [navigatorFeedback, setNavigatorFeedback] = React.useState<string>();
  const [mapIsDirty, setMapIsDirty] = useState(false);
  const [navigatorFeedbackIsLoading, setNavigatorFeedbackIsLoading] = useState(false);

  const showNavigatorFeedback = (result: GeolocationPositionError) => {
    setNavigatorFeedbackIsLoading(false);
    switch (result.code) {
      case result.PERMISSION_DENIED:
        setNavigatorFeedback('User denied the request for Geolocation.');
        break;
      case result.POSITION_UNAVAILABLE:
        setNavigatorFeedback('Location information is unavailable.');
        break;
      case result.TIMEOUT:
        setNavigatorFeedback('The request to get user location timed out.');
        break;
      default:
        setNavigatorFeedback('An unknown error occurred.');
        break;
    }
  };

  const requestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentClientLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setNavigatorFeedbackIsLoading(false);
        },
        showNavigatorFeedback,
      );
      setMapIsDirty(true);
    } else {
      setNavigatorFeedback('Geolocation is not supported by this browser.');
      setNavigatorFeedbackIsLoading(false);
    }
  };

  const handleOnClick = async () => {
    await setNavigatorFeedbackIsLoading(true);
    await requestLocation();
  };

  const currentLocationIsLondon = () => {
    if (appointmentLocation?.lat === LONDON_CENTER.lat
      && appointmentLocation?.lat === LONDON_CENTER.lat) {
      return true;
    }
    return false;
  };

  return (
    <Stack spacing={2}>
      <Alert severity="info">
        Mark your location on the map by either using the button below to find your current location automatically,
        or manually zoom and click your location.
        Then press the &quot;Save Location&quot; button to confirm your location.
      </Alert>
      <Box height={400}>
        <GoogleMap
          disableDefaultUI
          zoomControl
          onClick={(event) => {
            event.latLng && setCurrentClientLocation({ lat: event.latLng.lat(), lng: event.latLng.lng() });
            setMapIsDirty(true);
          }}
          draggableCursor="crosshair"
          zoom={
            currentLocationIsLondon()
              ? INITIAL_ZOOM_FOR_UK
              : 19
          }
          center={currentClientLocation ?? appointmentLocation}
        >
          {!currentLocationIsLondon() && appointmentLocation
            && (
              <Marker
                position={{ lat: appointmentLocation.lat, lng: appointmentLocation.lng }}
                selected
                label="Arranged appointment location"
              />
            )}
          {!currentLocationIsLondon() && currentClientLocation
            && (
              <Marker
                position={{ lat: currentClientLocation.lat, lng: currentClientLocation.lng }}
                selected
                iconType="vet"
                label="Your precise location"
              />
            )}
        </GoogleMap>
      </Box>
      <LoadingButton
        fullWidth
        color="secondary"
        variant="contained"
        onClick={handleOnClick}
        loading={navigatorFeedbackIsLoading}
      >
        Use GPS To Set My Current Location
      </LoadingButton>
      <Tooltip title={
        !currentClientLocation && !mapIsDirty
          ? 'Click a location on the map or click the "Use GPS" button above to set your location before saving'
          : ''
      }
      >
        <div>
          <Button
            fullWidth
            variant="contained"
            onClick={onSaveLocation}
            disabled={!currentClientLocation && !mapIsDirty}
          >
            Save Location
          </Button>
        </div>
      </Tooltip>
      {navigatorFeedback && <Alert severity="info">{navigatorFeedback}</Alert>}
    </Stack>
  );
};

export default ShareLocation;
