import { useContext } from 'react';
import TopContentContext, { ITopContentContext } from './TopContentContext';

const useTopContent = (): ITopContentContext => {
  const context = useContext(TopContentContext);

  if (context === null) {
    throw new Error('No top content context available');
  }

  return context;
};

export default useTopContent;
