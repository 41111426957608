import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { FormikActions, FormStack } from 'components/Form';
import TitleBox from 'components/TitleBox';
import { InvitePracticeCommand } from 'providers/api';
import { useDefaultForm } from 'providers/form';
import { UseMutationResult } from 'react-query';
import {
  object,
  SchemaOf,
  string
} from 'yup';

const NewPracticeInviteSchema: SchemaOf<InvitePracticeCommand> = object().shape({
  email: string()
    .email('Email is invalid')
    .required('Email is required'),
  practiceName: string()
    .required('Practice name is required'),
});

interface PracticeInviteFormProps {
  mutation: UseMutationResult<void, unknown, InvitePracticeCommand>;
}
const PracticeInviteForm = ({ mutation }: PracticeInviteFormProps) => {
  const {
    formik,
    helpers,
  } = useDefaultForm<InvitePracticeCommand>({
    mutation,
    formikConfig: {
      initialValues: {
        email: '',
        practiceName: '',
      },
      onSubmit: (form, { setSubmitting }) => {
        setSubmitting(true);
        mutation.mutate(form, {
          onSettled: () => {
            setSubmitting(false);
          },
        });
      },
      validationSchema: NewPracticeInviteSchema,
    },
  });

  const {
    values,
    isValid,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  const inviteAction: [string, JSX.Element] = ['invite', (
    <Button variant="contained" color="primary" type="submit" disabled={isSubmitting || !isValid}>
      Invite
    </Button>
  )];

  return (
    <form onSubmit={handleSubmit}>
      <TitleBox title="Invite Details">
        <FormStack>
          <TextField
            required
            autoFocus
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('email')}
            helperText={helpers.getErrorHelpText('email')}
          />

          <TextField
            required
            fullWidth
            id="practiceName"
            name="practiceName"
            label="Practice Name"
            value={values.practiceName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('practiceName')}
            helperText={helpers.getErrorHelpText('practiceName')}
          />
        </FormStack>
      </TitleBox>

      <Box mt={2}>
        <FormikActions
          formik={formik}
          mutation={mutation}
          submitText="Invite"
          right={['reset', inviteAction]}
        />
      </Box>
    </form>
  );
};

export default PracticeInviteForm;
