import { Instant } from '@js-joda/core';
import {
  AppointmentDetailsDto,
  AppointmentDto,
  AppointmentForm,
  RedactedAppointmentDto,
  SetAppointmentsAsDraftCommand,
  SetAppointmentTimeAndVetCommand,
  SetAppointmentTimeCommand,
  UpdateAppointmentForm,
  UpdateMultipleAppointmentTimesCommand
} from './api';
import {
  GetKey,
  ListKey,
  makeCreateMutation,
  makeGeneralUpdateMutation,
  makeGetQuery,
  makeListQuery,
  makeUpdateMutation
} from './useApi';

const APPOINTMENTS_KEY = 'appointments' as ListKey;
export const APPOINTMENT_KEY = 'appointment' as GetKey;
export const APPOINTMENTS_COUNT_KEY = 'appointment_count' as ListKey;
const APPOINTMENT_VET_NOTES_KEY = 'appointment_vet_notes' as GetKey;

export interface AppointmentsQueryParams {
  practiceId?: string,
  clientId?: string,
  patientId?: string | null,
  vetId?: string | null,
  startDateUtc: Instant,
  endDateUtc: Instant;
  appointmentType?: number | null;
  appointmentPriority?: number | null;
  appointmentStatus?: number | null;
}

export const useAppointmentsQuery = makeListQuery<AppointmentsQueryParams, AppointmentDto[]>(
  APPOINTMENTS_KEY,
  (api, params) => () => api.appointments.list(
    params.startDateUtc.toString(),
    params.endDateUtc.toString(),
    params.practiceId,
    params.clientId,
    params.vetId,
    params.patientId,
    params.appointmentType,
    params.appointmentPriority,
    params.appointmentStatus,
  ),
);

export const useAppointmentsCountQuery = makeListQuery<AppointmentsQueryParams, number>(
  APPOINTMENTS_COUNT_KEY,
  (api, params) => () => api.appointments.count(
    params.startDateUtc.toString(),
    params.endDateUtc.toString(),
    params.practiceId,
    params.clientId,
    params.vetId,
    params.patientId,
    params.appointmentType,
    params.appointmentPriority,
    params.appointmentStatus,
  ),
);

export const useAppointmentQuery = makeGetQuery<AppointmentDetailsDto, string>(
  APPOINTMENT_KEY,
  (api, appointmentId) => () => api.appointments.getAppointmentWithUserDetails(appointmentId),
);

export const useAppointmentVetNotesQuery = makeGetQuery<string, string>(
  APPOINTMENT_VET_NOTES_KEY,
  (api, appointmentId) => () => api.appointments.getVetNotes(appointmentId),
);

export interface CreateAppointmentMutation {
  practiceId: string;
  form: AppointmentForm;
}

export const useCreateAppointmentMutation = makeCreateMutation<string, CreateAppointmentMutation>(
  APPOINTMENTS_KEY,
  (api) => (mutation) => api.appointments.create(mutation.practiceId, mutation.form),
  () => 'A new appointment has been created',
);

export interface UpdateAppointmentMutation {
  practiceId: string;
  form: UpdateAppointmentForm;
}

export const useUpdateAppointmentDetailsMutation = makeUpdateMutation<void, CreateAppointmentMutation>(
  APPOINTMENT_KEY,
  APPOINTMENTS_KEY,
  (api, appointmentId) => (mutation) => api.appointments.update(appointmentId, mutation.form, mutation.practiceId),
  () => 'Appointment updated',
);

export const useUpdateAppointmentDatetimeMutation = makeUpdateMutation<void, SetAppointmentTimeCommand>(
  APPOINTMENT_KEY,
  APPOINTMENTS_KEY,
  (api, appointmentId) => (mutation) => api.appointments.setAppointmentTime(appointmentId, mutation),
  () => 'Appointment date and time updated',
);

export const useUpdateMultipleAppointmentTimesMutation = makeUpdateMutation<void, UpdateMultipleAppointmentTimesCommand>(
  APPOINTMENT_KEY,
  APPOINTMENTS_KEY,
  (api, practiceId) => (mutation) => api.appointments.updateMutipleAppointmentTimes(mutation, practiceId),
  () => 'All appointment times updated',
);

export const useUpdateAppointmentTimeAndVetMutation = makeUpdateMutation<void, SetAppointmentTimeAndVetCommand>(
  APPOINTMENT_KEY,
  APPOINTMENTS_KEY,
  (api, appointmentId) => (mutation) => api.appointments.setTimeAndVet(appointmentId, mutation),
  () => 'Appointment date and time updated',
);

export const useUpdateAppointmentPrimaryContactMutation = makeUpdateMutation<void, string>(
  APPOINTMENT_KEY,
  APPOINTMENTS_KEY,
  (api, appointmentId) => (userId) => api.appointments.setPrimaryContact(appointmentId, userId),
  () => 'Appointment primary contact updated',
);

export const useUpdateAppointmentAddAttendeeMutation = makeUpdateMutation<void, string>(
  APPOINTMENT_KEY,
  APPOINTMENTS_KEY,
  (api, appointmentId) => (userId) => api.appointments.addAttendee(appointmentId, userId),
  () => 'Attendee added to appointment',
);

export const useUpdateAppointmentRemoveAttendeeMutation = makeUpdateMutation<void, string>(
  APPOINTMENT_KEY,
  APPOINTMENTS_KEY,
  (api, appointmentId) => (userId) => api.appointments.removeAttendee(appointmentId, userId),
  () => 'Attendee removed from appointment',
);

interface SetAppointmentsMutation {
  teamId: string
  command: SetAppointmentsAsDraftCommand
}

export const useSetAppointmentsAsDraftMutation = makeGeneralUpdateMutation<void, SetAppointmentsMutation>(
  (api) => (mutation) => api.appointments.setAppointmentsAsDraft(mutation.teamId, mutation.command),
  () => 'Moved to draft successfully',
  (mutation) => [
    ...mutation.command.appointmentIds.map((id) => [APPOINTMENT_KEY, id]),
    APPOINTMENTS_KEY,
  ],
);

export const useSetAppointmentsAsUnconfirmedMutation = makeGeneralUpdateMutation<void, SetAppointmentsMutation>(
  (api) => (mutation) => api.appointments.setAppointmentsAsUnconfirmed(mutation.teamId, mutation.command),
  () => 'Published successfully',
  (mutation) => [
    ...mutation.command.appointmentIds.map((id) => [APPOINTMENT_KEY, id]),
    APPOINTMENTS_KEY,
  ],
);

export const useConfirmAppointmentMutation = makeUpdateMutation<void, string>(
  APPOINTMENT_KEY,
  APPOINTMENTS_KEY,
  (api, appointmentId) => () => api.appointments.confirmAppointment(appointmentId),
  () => 'Appointment confirmed',
);

export const useCancelAppointmentMutation = makeUpdateMutation<void, string>(
  APPOINTMENT_KEY,
  APPOINTMENTS_KEY,
  (api, appointmentId) => () => api.appointments.cancelAppointment(appointmentId),
  () => 'Appointment cancelled',
);

interface PublicAppointmentQueryParams {
  appointmentId: string;
  accessTokenHash: string;
}

export const usePublicAppointmentQuery = makeGetQuery<RedactedAppointmentDto, PublicAppointmentQueryParams>(
  APPOINTMENT_KEY,
  (api, params) => () => api.appointments.publicGet(params.appointmentId, params.accessTokenHash),
);

interface PublicConfirmAppointmentMutation {
  accessTokenHash: string;
}

export const usePublicConfirmAppointmentMutation = makeUpdateMutation<void, PublicConfirmAppointmentMutation>(
  APPOINTMENT_KEY,
  APPOINTMENTS_KEY,
  (api, appointmentId) => (mutation) => api.appointments.publicConfirmAppointment(appointmentId, mutation.accessTokenHash),
  () => 'Appointment confirmed',
);
