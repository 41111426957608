import Box from '@mui/material/Box';
import React, { ReactNode } from 'react';
import { useMutationObserverRef } from 'rooks';
import { px } from 'utils';
import { DRAWER_WIDTH, DRAWER_WIDTH_CLOSED_SM, TOP_BAR_HEIGHT } from '../utils';
import TopContentContext, { ITopContentContext } from './TopContentContext';

interface TopContentProviderProps {
  children: (height: number) => ReactNode;
  mainMenuIsOpen: boolean;
  notificationsDrawerIsOpen: boolean;
}

const TopContentProvider = ({ children, mainMenuIsOpen, notificationsDrawerIsOpen }: TopContentProviderProps) => {
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = React.useState<number>(0);

  const onContainerUpdate = () => {
    setContainerHeight(container?.offsetHeight ?? 0);
  };

  const [containerRefInternal] = useMutationObserverRef(onContainerUpdate);

  const containerRef = React.useCallback((node: HTMLDivElement) => {
    containerRefInternal(node);
    setContainer(node);
  }, []);

  const value = React.useMemo<ITopContentContext>(() => ({
    container,
    containerHeight,
  }), [container, containerHeight]);

  // May have performance issues
  React.useEffect(() => {
    setContainerHeight(container?.offsetHeight ?? 0);
  });

  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          position: 'fixed',
          backgroundColor: (theme) => theme.palette.primary.dark,
          top: { xs: TOP_BAR_HEIGHT - 8, sm: TOP_BAR_HEIGHT },
          left: 0,
          zIndex: (theme) => theme.zIndex.appBar - 1,
          transition: (theme) => theme.transitions.create(['width', 'margin', 'padding'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...(mainMenuIsOpen && {
            marginLeft: px(DRAWER_WIDTH),
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            transition: (theme) => theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
          ...(notificationsDrawerIsOpen && {
            paddingRight: px(400),
          }),
          ...(!mainMenuIsOpen && {
            marginLeft: px(DRAWER_WIDTH_CLOSED_SM),
            width: `calc(100% - ${DRAWER_WIDTH_CLOSED_SM}px)`,
          }),
        }}
      />
      <TopContentContext.Provider value={value}>
        {children(value.containerHeight)}
      </TopContentContext.Provider>
    </>
  );
};

export default TopContentProvider;
