import DateFnsAdapter from '@date-io/date-fns';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormikActions, FormStack } from 'components/Form';
import TitleBox from 'components/TitleBox';
import { enGB } from 'date-fns/locale';
import { FormikConfig } from 'formik';
import { PatientDto, UpdatePatientForm } from 'providers/api';
import useDefaultForm from 'providers/form/useDefaultForm';
import { UseMutationResult } from 'react-query';
import {
  bool,
  date,
  number,
  object,
  SchemaOf,
  string
} from 'yup';

const updatePatientFormSchema: SchemaOf<UpdatePatientForm> = object().shape({
  name: string().required('Patient name is required'),
  breed: string().required('Patient breed is required'),
  species: string().required('Patient classification is required'),
  colour: string().required('Patient coat is required'),
  dob: date().required('Patient date of birth is required'),
  gender: number().required('Patient gender is required'),
  microchipNumber: string(),
  deceased: bool(),
});

interface PatientFormProps {
  patient?: PatientDto;
  mutation: UseMutationResult<any, any, any, any>;
  onSubmit: FormikConfig<UpdatePatientForm>['onSubmit'];
}
const PatientForm = ({ patient, mutation, onSubmit }: PatientFormProps) => {
  const {
    formik,
    helpers,
  } = useDefaultForm<UpdatePatientForm>({
    mutation,
    formikConfig: {
      initialValues: {
        name: patient?.name ?? '',
        breed: patient?.breed ?? '',
        species: patient?.species ?? '',
        colour: patient?.colour ?? '',
        dob: patient?.dob ?? new Date(),
        gender: patient?.gender ?? 0,
        microchipNumber: patient?.microchipNumber ?? '',
      },
      onSubmit: (...args) => {
        const [, { setSubmitting }] = args;
        setSubmitting(true);
        onSubmit(...args);
      },
      validationSchema: updatePatientFormSchema,
    },
  });

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  const handleDateOfBirthChange = (dob: Date | null) => dob && setFieldValue('dob', dob);

  return (
    <form onSubmit={handleSubmit}>
      <TitleBox title="Patient Details">
        <FormStack>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Patient Name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('name')}
            helperText={helpers.getErrorHelpText('name')}
          />
          <TextField
            fullWidth
            id="breed"
            name="breed"
            label="Patient Breed"
            value={values.breed}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('breed')}
            helperText={helpers.getErrorHelpText('breed')}
          />
          <TextField
            fullWidth
            id="species"
            name="species"
            label="Patient Species"
            value={values.species}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('species')}
            helperText={helpers.getErrorHelpText('species')}
          />
          <TextField
            fullWidth
            id="colour"
            name="colour"
            label="Patient Colour"
            value={values.colour}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('colour')}
            helperText={helpers.getErrorHelpText('colour')}
          />
          {/* TODO: Change date adapter. While there is a JS Joda adapter, it appears to be broken for MUI Time Picker.
          When Temporal is passed in, there's an infinite loop.
          https://dev.azure.com/buzzinteractiveteam/Aegis/_workitems/edit/8833/
          */}
          <LocalizationProvider adapterLocale={enGB} dateAdapter={DateFnsAdapter}>
            <DatePicker
              label="Date of Birth"
              value={values.dob}
              onChange={handleDateOfBirthChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <TextField
            id="gender"
            name="gender"
            select
            label="Patient Gender"
            value={values.gender}
            onChange={handleChange}
            error={helpers.hasError('gender')}
            helperText={helpers.getErrorHelpText('gender')}
          >
            <MenuItem value={0}>
              Male
            </MenuItem>
            <MenuItem value={10}>
              Female
            </MenuItem>
          </TextField>
          <TextField
            fullWidth
            id="microchipNumber"
            name="microchipNumber"
            label="Patient Microchip"
            value={values.microchipNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('microchipNumber')}
            helperText={helpers.getErrorHelpText('microchipNumber')}
          />
        </FormStack>
      </TitleBox>

      <Box mt={2}>
        <FormikActions
          formik={formik}
          mutation={mutation}
          submitText={patient ? 'Save' : 'Create'}
          right={['reset', 'submit']}
        />
      </Box>
    </form>
  );
};

export default PatientForm;
