import Alert from '@mui/material/Alert';
import Page from 'modules/Page';
import { TITLE } from './constants';

const VetAccessPage = () => (
  <Page pageType="standard" title={TITLE}>
    <Alert severity="info">As a vet, you do not have access to the Arrivet hub. Contact the administrator for further support.</Alert>
  </Page>
);

export default VetAccessPage;
