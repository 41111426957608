import BillingAddressFields from './BillingAddressFields';
import BillingContactFields from './BillingContactFields';
import { usePracticeForm } from './context';
import PracticeCompanyNameField from './PracticeCompanyNameField';
import PracticeCompanyRegField from './PracticeCompanyRegField';
import PracticeCompanyVatField from './PracticeCompanyVatField';
import PracticeContactFields from './PracticeContactFields';
import PracticeForm from './PracticeForm';
import PracticeMainContactNumberField from './PracticeMainContactNumberField';
import PracticeNameField from './PracticeNameField';
import SiteAddressFields from './SiteAddressFields';

export default PracticeForm;

export {
  usePracticeForm,
  BillingAddressFields,
  BillingContactFields,
  PracticeCompanyNameField,
  PracticeCompanyRegField,
  PracticeCompanyVatField,
  PracticeContactFields,
  PracticeMainContactNumberField,
  PracticeNameField,
  SiteAddressFields,
};
