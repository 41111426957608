import { List, Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { ResponsiveStyleValue } from '@mui/system/styleFunctionSx/styleFunctionSx';
import React from 'react';
import { enumKeys } from 'utils';

interface EnumCheckboxListProps<T> {
  title?: string;
  checkboxEnum: Record<string, any>;
  selection: any[];
  direction: ResponsiveStyleValue<'row' | 'row-reverse' | 'column' | 'column-reverse'> | undefined
  handleCheckboxClick: (selection: T) => void;
  removeKey?: string
  disabled?: number[]
}
const EnumCheckboxList = <T, >({ title, checkboxEnum, selection, direction, handleCheckboxClick, removeKey, disabled }: EnumCheckboxListProps<T>) => {
  const filterArray = () => {
    if (removeKey === undefined) return enumKeys(checkboxEnum);
    const array = enumKeys(checkboxEnum);
    const filteredArray = array.filter((key) => key !== removeKey);
    return filteredArray;
  };

  const handleClick = (enumKey: any) => {
    if (disabled && disabled.some((disabledValue) => disabledValue === enumKey)) {
      return;
    }
    handleCheckboxClick(enumKey);
  };

  return (
    <List dense>
      {title && (
        <ListSubheader component="div" id="nested-list-subheader">
          {title}
        </ListSubheader>
      )}
      <Stack
        direction={direction}
        sx={
          {
            flexWrap: 'wrap',
            width: direction === 'column' ? '150px' : undefined,
          }
        }
        justifyContent="center"
        alignItems="center"
      >
        {
          filterArray()
            .map((enumKey) => (
              <ListItem
                sx={{ padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100', flex: 1 }}
                key={enumKey}
                disablePadding
              >
                <ListItemButton
                  sx={{ padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  onClick={() => handleClick(checkboxEnum[enumKey])}
                  dense
                >
                  <ListItemText id={enumKey} primary={enumKey} sx={{ flex: 1, display: 'flex', textAlign: 'center' }} />
                  <ListItemIcon sx={{ flex: 1, justifyContent: 'flexStart' }}>
                    <Checkbox
                      edge="start"
                      checked={selection.includes(checkboxEnum[enumKey])}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': enumKey }}
                      disabled={disabled && disabled.some((disabledValue) => disabledValue === checkboxEnum[enumKey])}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))
        }
      </Stack>
    </List>
  );
};
export default EnumCheckboxList;
