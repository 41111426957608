import LockClockSharpIcon from '@mui/icons-material/LockClockSharp';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Loading from 'components/Loading';
import { HOUR_IN_MILLISECONDS } from 'config';
import AppointmentInfoSections from 'features/AppointmentUpdateForm/AppointmentInfoSections';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import {
  AppointmentStatus,
  useAppointmentQuery,
  useClientQuery,
  useConfirmAppointmentMutation
} from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const AppointmentPage = () => {
  const { appointmentId } = useProtectedParams('appointmentId');
  const confirm = useConfirmation();
  const navigate = useNavigate();

  const { data: appointment, isLoading: appointmentIsLoading } = useAppointmentQuery(appointmentId);
  const { data: client, isLoading: clientIsLoading } = useClientQuery(appointment?.clientId ?? '', {
    enabled: !!appointment,
    staleTime: HOUR_IN_MILLISECONDS,
  });

  const confirmAppointmentMutation = useConfirmAppointmentMutation(appointmentId, {
    onSuccess: () => navigate('../..'),
  });

  const handleConfirmAppointment = (appointmentToConfirm: string) => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want to confirm this appointment?',
    }).then(() => confirmAppointmentMutation.mutate(appointmentToConfirm));
  };

  if (!appointment || appointmentIsLoading || !client || clientIsLoading) {
    return <Loading />;
  }

  return (
    <Page pageType="standard" title="Appointment Details">
      {client && <AppointmentInfoSections appointment={appointment} client={client} />}
      <Stack spacing={2}>
        { appointment.appointmentStatus === AppointmentStatus.Unconfirmed && (
          <Button
            fullWidth
            variant="contained"
            onClick={() => handleConfirmAppointment(appointmentId)}
          >
            Confirm Appointment Details
          </Button>
        )}
        <Tooltip title="Only available when the vet is on their way to the patient" placement="top">
          <Button
            fullWidth
            variant="contained"
            onClick={() => navigate('../share')}
            disabled={appointment.appointmentStatus !== AppointmentStatus.InTransit}
            startIcon={appointment.appointmentStatus === AppointmentStatus.InTransit ? <ShareLocationIcon /> : <LockClockSharpIcon />}
          >
            Share Location
          </Button>
        </Tooltip>
      </Stack>
    </Page>
  );
};

export default AppointmentPage;
