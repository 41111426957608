import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import MailIcon from '@mui/icons-material/Mail';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { ClientDto } from 'providers/api';
import React from 'react';

interface ClientsTableMenuProps {
  client: ClientDto;
  onEdit: (clientId: string) => void;
  onInvite: (clientId: string) => void;
  onDelete: (client: ClientDto) => void;
}

const ClientsTableMenu = ({ client, onEdit, onInvite, onDelete }: ClientsTableMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit(client.entityId);
    setAnchorEl(null);
  };

  const handleInvite = () => {
    onInvite(client.entityId);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete(client);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" aria-controls={`client-actions-menu-${client.entityId}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`client-actions-menu-${client.entityId}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Edit</Typography>
        </MenuItem>
        <MenuItem onClick={handleInvite}>
          <ListItemIcon>
            <MailIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Invite User</Typography>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteForeverIcon color="error" fontSize="small" />
          </ListItemIcon>
          <Typography color="error" variant="inherit">Delete</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ClientsTableMenu;
