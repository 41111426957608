import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useAppointmentForm } from './context';

const ContactDetailsFields = () => {
  const {
    form: { formik, helpers },
  } = useAppointmentForm();

  const { values, handleChange, handleBlur, setFieldValue } = formik;

  const handleAddTemporaryContact = () => setFieldValue('contactDetails', {});
  const handleRemoveTemporaryContact = () => setFieldValue('contactDetails', undefined);

  if (!values.contactDetails) {
    return (
      <Button
        variant="contained"
        fullWidth
        onClick={() => handleAddTemporaryContact()}
      >
        Add Temporary Contact
      </Button>
    );
  }

  return (
    <Stack spacing={2}>
      <TextField
        fullWidth
        id="contactDetails.firstName"
        name="contactDetails.firstName"
        label="First Name"
        value={values.contactDetails.firstName ?? ''}
        onChange={handleChange}
        onBlur={handleBlur}
        error={helpers.hasError('contactDetails.firstName')}
        helperText={helpers.getErrorHelpText('contactDetails.firstName')}
      />

      <TextField
        fullWidth
        id="contactDetails.lastName"
        name="contactDetails.lastName"
        label="Last Name"
        value={values.contactDetails.lastName ?? ''}
        onChange={handleChange}
        onBlur={handleBlur}
        error={helpers.hasError('contactDetails.lastName')}
        helperText={helpers.getErrorHelpText('contactDetails.lastName')}
      />

      <TextField
        fullWidth
        id="contactDetails.email"
        name="contactDetails.email"
        label="Email"
        value={values.contactDetails.email ?? ''}
        onChange={handleChange}
        onBlur={handleBlur}
        error={helpers.hasError('contactDetails.email')}
        helperText={helpers.getErrorHelpText('contactDetails.email')}
      />

      <TextField
        fullWidth
        id="contactDetails.contactNumber"
        name="contactDetails.contactNumber"
        label="Contact Number"
        value={values.contactDetails.contactNumber ?? ''}
        onChange={handleChange}
        onBlur={handleBlur}
        error={helpers.hasError('contactDetails.contactNumber')}
        helperText={helpers.getErrorHelpText('contactDetails.contactNumber')}
      />

      <Button
        variant="contained"
        fullWidth
        onClick={() => handleRemoveTemporaryContact()}
      >
        <RemoveIcon />
        Remove Temporary Contact
      </Button>
    </Stack>
  );
};

export default ContactDetailsFields;
