import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface NotificationListItemProps {
  title: string;
  primaryDescription: string;
  actionPath?: string;
}

const NotificationListItem = ({ title, primaryDescription, actionPath }: NotificationListItemProps) => {
  const navigate = useNavigate();
  return (
    <ListItem
      alignItems="flex-start"
      sx={{ margin: 1, bgcolor: 'background.paper', width: 'auto' }}
      component={Paper}
      secondaryAction={actionPath && (
        <IconButton edge="end" aria-label="delete" onClick={() => navigate(actionPath)}>
          <NavigateNextIcon />
        </IconButton>
      )}
    >
      <ListItemText
        primary={<Typography variant="subtitle2">{title}</Typography>}
        secondary={(
          <Typography
            sx={{ display: 'inline' }}
            component="span"
            variant="body2"
            color="text.primary"
          >
            {primaryDescription}
          </Typography>
        )}
      />
    </ListItem>
  );
};

export default NotificationListItem;
