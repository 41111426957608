import Autocomplete, { autocompleteClasses, AutocompleteCloseReason, AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import Button, { ButtonProps } from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import React, { ReactNode } from 'react';

const PopperComponent = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${
        theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
      }`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  minWidth: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${
    theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
  }`,
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === 'light'
          ? 'rgba(3, 102, 214, 0.3)'
          : 'rgb(12, 45, 107)'
      }`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
}));

interface MenuAutoCompleteProps<T> {
  label: string;
  data: T[];
  selectedItems?: T[] | null;
  noOptionsText?: string;
  buttonProps?: ButtonProps;
  getTitle: (option: T) => string;
  onChange: (client: any[] | null) => void;
  renderOption: ((props: React.HTMLAttributes<HTMLLIElement>, option: T, state: AutocompleteRenderOptionState) => React.ReactNode) | undefined;
  onSearch?: (searchTerm: string) => void;
  children?: ReactNode;
}

const MenuAutoComplete = <T extends any>({
  children,
  label = 'Select an item',
  data,
  noOptionsText = 'No data available',
  selectedItems,
  buttonProps = {},
  getTitle,
  onChange,
  renderOption,
  onSearch,
}: MenuAutoCompleteProps<T>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [pendingValue, setPendingValue] = React.useState<T[]>(selectedItems || []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setPendingValue(selectedItems || []);
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = (event: any, reason: AutocompleteCloseReason) => {
    if (reason === 'toggleInput') {
      return;
    }
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <>
      {/* Trigger */}
      <Button
        {...buttonProps}
        onClick={handleClick}
      >
        {children}
      </Button>

      {/* Pop Up */}
      <StyledPopper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        sx={{ top: 10 }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={() => handleClose}>
          <div>
            <div>{label}</div>
            <Autocomplete
              open
              multiple
              onClose={handleClose}
              value={pendingValue}
              onChange={(event, newValue) => {
                setPendingValue(newValue as any);
                onChange(newValue);
              }}
              disableCloseOnSelect
              disablePortal
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText={noOptionsText}
              renderOption={renderOption}
              options={data ?? []}
              getOptionLabel={(option) => getTitle(option)}
              onInputChange={(event, newInputValue) => {
                onSearch && onSearch(newInputValue || '');
              }}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder="Filter labels"
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

export default MenuAutoComplete;
