import Loading from 'components/Loading';
import { useCreateUserInviteMutation, useTeamRoleListQuery } from 'providers/api';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserInviteForm from '../UserInviteForm';

interface NewUserFeatureProps {
  teamId: string;
  availableRoles: string[];
}

const NewUserFeature = ({ teamId, availableRoles }: NewUserFeatureProps) => {
  const navigate = useNavigate();
  const createUserMutation = useCreateUserInviteMutation({
    onSuccess: () => {
      navigate('../invites');
    },
  });

  const { data: roles } = useTeamRoleListQuery(teamId);

  if (!roles) {
    return <Loading />;
  }

  return (
    <UserInviteForm teamId={teamId} roles={roles.filter((role) => availableRoles.includes(role.name))} mutation={createUserMutation} />
  );
};

export default NewUserFeature;
