import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { iconDark } from 'assets';
import {
  Image, ImageBackdrop,
  ImageButton, ImageSrc
} from 'components/ImageButton';
import { PatientDto } from 'providers/api';

interface PatientsListProps {
  patients: PatientDto[];
  onNavigateToPatient: (patientId: string) => void;
}

const PatientsList = ({ patients, onNavigateToPatient }: PatientsListProps) => (
  <Grid container spacing={3}>
    {patients.map((patient) => (
      <Grid key={patient.entityId} item xs={12} md={6} lg={4} xl={3}>
        <ImageButton
          onClick={() => onNavigateToPatient(patient.entityId)}
          focusRipple
          sx={{
            width: '100%',
            pt: '100%',
            flexShrink: 0,
          }}
        >
          <ImageSrc style={{ backgroundImage: `url(${patient.image?.sizes?.thumb ?? iconDark})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{
                position: 'relative',
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              }}
            >
              {patient.name}
            </Typography>
          </Image>
        </ImageButton>
      </Grid>
    ))}
  </Grid>
);
export default PatientsList;
