import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TitleBox from 'components/TitleBox';
import TitleDisplayStack from 'components/TitleDisplayStack';
import React from 'react';
import { isNilOrEmpty } from 'utils';

interface AppointmentVeterinaryNotesSectionProps {
  vetNotes?: string;
}

const AppointmentVeterinaryNotesSection = ({ vetNotes }: AppointmentVeterinaryNotesSectionProps) => (
  <TitleBox title="Appointment Actions">
    <Box>
      <Divider sx={{ my: 3 }} />
      <TitleDisplayStack
        title="Veterinary notes"
        display={<Typography variant="body2">{isNilOrEmpty(vetNotes) ? 'No notes available' : vetNotes}</Typography>}
      />
    </Box>
  </TitleBox>
);

export default AppointmentVeterinaryNotesSection;
