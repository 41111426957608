import Loading from 'components/Loading';
import { HOUR_IN_MILLISECONDS } from 'config';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { useClientQuery, useUpdateClientMutation } from 'providers/api';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ClientForm from '../ClientForm';
import { TITLE as parentTitle } from '../ClientsPage';
import { TITLE } from './constants';

const EditClientPage = () => {
  const { clientId, teamId } = useProtectedParams('clientId', 'teamId');
  const navigate = useNavigate();

  const updateClientMutation = useUpdateClientMutation(clientId ?? '', {
    onSuccess: () => navigate('../'),
  });

  const { data: client, isStale } = useClientQuery(clientId, { staleTime: HOUR_IN_MILLISECONDS });

  if (!client || isStale) {
    return <Loading />;
  }

  return (
    <Page pageType="large" title={TITLE} parentRelativePath="../.." parentTitle={parentTitle}>
      <ClientForm practiceId={teamId} client={client} mutation={updateClientMutation} />
    </Page>
  );
};

export default EditClientPage;
