import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react';

interface ImageUploadButtonProps {
  label: string;
  Icon?: JSX.Element;
  fullWidth?: boolean;
  uploading?: boolean;
  onUpload: (image: File) => void;
}

const ImageUploadButton = ({ label, Icon, fullWidth, uploading, onUpload }: ImageUploadButtonProps) => {
  const handleFileChange = (fileList: FileList | null) => {
    if (fileList && fileList.length > 0) {
      onUpload(fileList[0]);
    }
  };

  return (
    <LoadingButton
      loading={uploading ?? false}
      variant="contained"
      color="secondary"
      fullWidth={fullWidth}
      size="small"
      startIcon={Icon}
      component="label"
    >
      {label}
      <input
        id="data-upload"
        name="file"
        type="file"
        accept="image/*,.pdf"
        onChange={(event) => handleFileChange(event.target.files)}
        hidden
      />
    </LoadingButton>
  );
};

export default ImageUploadButton;
