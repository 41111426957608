import { useContext } from 'react';
import AuthorisationContext, { IAuthorisationContext } from './context';

export default function useAuthorisation(): IAuthorisationContext {
  const context = useContext(AuthorisationContext);
  if (context === null) {
    throw new Error('No page context available');
  }

  return context;
}
