import { nativeJs, ZonedDateTime } from '@js-joda/core';
import { HOUR_IN_MILLISECONDS } from 'config';
import {
  CalendarEventDto, CalendarEventType, TimeBlockDto, useCalendarEventsQuery
} from 'providers/api';
import { useEffect, useState } from 'react';

interface UseValidateVetAvailableProps {
  teamId: string;
  vetId: string;
  startTime: Date | null;
  endTime: Date | null;
}

export enum VetAvailability {
  UnavailableOnLeaveOrOutsideWorkingHours = -1,
  NoData = 0,
  Available = 1,
}

const appointmentInsideTimeBlock = (appointmentStart: ZonedDateTime, appointmentEnd: ZonedDateTime, timeBlocks: TimeBlockDto[], allowOverlap: boolean): boolean => {
  const appointmentStartInsideTimeBlock = timeBlocks.some((timeBlock) => (
    appointmentStart.isAfter(timeBlock.start.minusMinutes(1)) // plus/minus ensures no error thrown at very end of time (e.g. scheudled until end of working day)
    && appointmentStart.isBefore(timeBlock.end.plusMinutes(1))
  ));
  const appointmentEndInsideTimeBlock = timeBlocks.some((timeBlock) => (
    appointmentEnd.isAfter(timeBlock.start)
    && appointmentEnd.isBefore(timeBlock.end.plusMinutes(1))
  ));
  if (allowOverlap) return (appointmentStartInsideTimeBlock || appointmentEndInsideTimeBlock); // excludes appointment start or end encroaching onto leave
  return (appointmentStartInsideTimeBlock && appointmentEndInsideTimeBlock); // ensures start and end fit within the working hours shift
};

const calendarEventArrayHasClash = (appointmentStart: ZonedDateTime, appointmentEnd: ZonedDateTime, calendarEvent: CalendarEventDto[], allowOverlap: boolean) => {
  const calendarEventArrayClashesWithAppointment = calendarEvent.some((calEvent) => (
    appointmentInsideTimeBlock(appointmentStart, appointmentEnd, calEvent.timeBlocks, allowOverlap)
  ));
  return calendarEventArrayClashesWithAppointment;
};

const useValidateVetAvailable = ({ teamId, vetId, startTime, endTime }: UseValidateVetAvailableProps) => {
  const [vetAvailability, setVetAvailability] = useState<VetAvailability>();

  const availabilityStartTime = startTime
    ? ZonedDateTime.from(nativeJs(startTime)).withHour(0).withMinute(0)
    : ZonedDateTime.now().withHour(0).withMinute(0);

  const {
    data: calendarEvents,
  } = useCalendarEventsQuery(
    {
      practiceId: teamId,
      startDate: availabilityStartTime,
      endDate: availabilityStartTime.plusDays(1),
      userId: vetId,
    },
    {
      staleTime: HOUR_IN_MILLISECONDS,
      enabled: !!startTime && !!endTime && !!vetId,
    },
  );

  useEffect(() => {
    if (vetId === null || startTime === null || endTime === null) return;
    if (calendarEvents) {
      const appointmentStartTime = ZonedDateTime.from(nativeJs(startTime));
      const appointmentEndTime = ZonedDateTime.from(nativeJs(endTime));

      const calEventsWorkingHoursArr = calendarEvents.filter((availabilityEvent) => (
        availabilityEvent.type === CalendarEventType.WorkingHours
      ));
      const calEventsOnLeaveArr = calendarEvents.filter((availabilityEvent) => (
        availabilityEvent.type !== CalendarEventType.WorkingHours
      ));
      if (calEventsWorkingHoursArr.length === 0
        && calEventsOnLeaveArr.length === 0) {
        (
          setVetAvailability(VetAvailability.NoData)
        );
      }
      if (calEventsOnLeaveArr.length !== 0 && calEventsWorkingHoursArr.length === 0) {
        const onLeaveConflict = calendarEventArrayHasClash(appointmentStartTime, appointmentEndTime, calEventsOnLeaveArr, true);
        setVetAvailability(onLeaveConflict
          ? VetAvailability.UnavailableOnLeaveOrOutsideWorkingHours
          : VetAvailability.Available);
        return;
      }
      if (calEventsWorkingHoursArr.length !== 0) {
        const appointmentFallsWithinWorkingHours = calendarEventArrayHasClash(appointmentStartTime, appointmentEndTime, calEventsWorkingHoursArr, false);

        const workingHoursConflict = !appointmentFallsWithinWorkingHours;
        const onLeaveConflict = calendarEventArrayHasClash(appointmentStartTime, appointmentEndTime, calEventsOnLeaveArr, true);

        setVetAvailability(workingHoursConflict || onLeaveConflict
          ? VetAvailability.UnavailableOnLeaveOrOutsideWorkingHours
          : VetAvailability.Available);
      }
    }
  }, [teamId, startTime, endTime, vetId, calendarEvents]);

  return { vetAvailability };
};

export default useValidateVetAvailable;
