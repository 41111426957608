import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LogoOpen from 'modules/Layout/logo-open.svg';
import { px } from 'utils';
import { TOP_BAR_GUEST_HEIGHT } from './utils';

interface LayoutProps {
  title: string;
  children: JSX.Element;
}

const Layout = ({ title, children }: LayoutProps) => (
  <>
    <AppBar position="relative">
      <Toolbar sx={{ height: TOP_BAR_GUEST_HEIGHT, justifyContent: 'center', width: '100%' }}>
        <Box
          component="img"
          sx={{
            flex: 1,
            height: {
              xs: px(TOP_BAR_GUEST_HEIGHT / 2),
              sm: px(TOP_BAR_GUEST_HEIGHT / 2),
            },
          }}
          src={LogoOpen}
          alt="Arrivet Logo"
        />
      </Toolbar>
    </AppBar>

    <main>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        paddingBottom: '75px', // allows for snackbar space below button in mobile view
      }}
      >
        <Stack maxWidth={750} width="100%" p={2}>
          <Typography variant="h6" color="inherit" sx={{ textAlign: 'center', mb: 2 }}>
            {title}
          </Typography>
          {children}
        </Stack>
      </Box>
    </main>
  </>
);

export default Layout;
