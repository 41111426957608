import React, { ReactNode } from 'react';
import MenuStateContext, { IMenuStateContext } from './menuStateContext';

interface menuStateProviderProps {
  mainMenuIsOpen: boolean;
  notificationsDrawerIsOpen: boolean;
  children: ReactNode;
}

const menuStateProvider = ({ mainMenuIsOpen, notificationsDrawerIsOpen, children }: menuStateProviderProps) => {
  const value: IMenuStateContext = React.useMemo(() => ({
    state: { mainMenuIsOpen, notificationsDrawerIsOpen },
  }), [mainMenuIsOpen, notificationsDrawerIsOpen]);

  return (
    <MenuStateContext.Provider value={value}>
      {children}
    </MenuStateContext.Provider>
  );
};

export default menuStateProvider;
