import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { TopContent } from 'modules/Layout';
import { useAppRoutes } from 'providers/appRoutes';
import {
  Link,
  matchRoutes,
  Outlet,
  useLocation
} from 'react-router-dom';

export type TabRoutes = Record<string, string>;

interface TabRouterOutletProps {
  routes: TabRoutes,
  routeId: string;
}

const TabRouterOutlet = ({ routes, routeId }: TabRouterOutletProps) => {
  const appRoutes = useAppRoutes();
  const location = useLocation();

  const matchedRoutes = matchRoutes([appRoutes], location);
  const baseRoute = matchedRoutes && matchedRoutes.find((route) => (route.route as any).tabRouteId === routeId);
  if (!baseRoute) throw new Error('To implement the TabRouter you must include a tabRouteId in the routes file');

  // baseRoute pathname sometimes has a '/' at the end
  const absoluteBasePath = baseRoute && baseRoute.pathname.endsWith('/')
    ? baseRoute.pathname.slice(0, -1)
    : baseRoute.pathname;

  const subPath = location.pathname.replace(absoluteBasePath, '');

  // might have issues if the URL includes one of the other route strings further down the path
  const currentTab = subPath
    ? Object.values(routes).find((value) => value && subPath.includes(value)) ?? ''
    : '';

  const processedRoutes: Record<string, string> = Object.entries(routes).reduce(
    (newRoutes, [currentRouteKey, currentRouteValue]) => ({
      ...newRoutes,
      [currentRouteKey]: absoluteBasePath + currentRouteValue,
    }),
    {},
  );

  return (
    <>
      <TopContent>
        <Tabs
          sx={{ backgroundColor: (theme) => theme.palette.grey[100] }}
          value={absoluteBasePath + currentTab}
          aria-label="Tabbed area"
          variant="fullWidth"
        >
          {
            Object.entries(processedRoutes).map(([label, value]) => (
              <Tab
                key={`${label}-tab`}
                component={Link}
                to={value}
                value={value}
                label={label}
                id={`${label}-tab`}
              />
            ))
          }
        </Tabs>
      </TopContent>
      <Outlet />
    </>
  );
};

export default TabRouterOutlet;
