import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { HOUR_IN_MILLISECONDS } from 'config';
import { UserDto, useUsersQuery } from 'providers/api';
import React from 'react';
import { useAppointmentForm } from './context';

const PrimaryContactField = () => {
  const { form: { formik, helpers } } = useAppointmentForm();
  const { handleBlur, setFieldValue, values } = formik;

  // TODO: review the process for feeding large data sets into autocomplete
  // SEE: https://dev.azure.com/buzzinteractiveteam/Aegis/_sprints/taskboard/Aegis%20Team/Aegis/Sprint%203?workitem=6596
  const { data: clientUsers } = useUsersQuery({ page: 1, pageSize: 1000, teamId: values.clientId ?? '' }, {
    enabled: !!values.clientId,
    staleTime: HOUR_IN_MILLISECONDS,
  });

  const selectedPrimaryContact = clientUsers?.items.find((user) => user.userId === values.primaryContactId) ?? null;

  if (!values.clientId) return <Alert severity="info">You must select a client before selecting a primary contact</Alert>;

  return (
    <Autocomplete
      fullWidth
      options={clientUsers?.items || []}
      getOptionLabel={(option: UserDto) => `${option.forename} ${option.surname}`}
      value={selectedPrimaryContact}
      onChange={(event, newUser) => {
        setFieldValue('primaryContactId', newUser?.userId ?? '');
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          id="primaryContactId"
          name="primaryContactId"
          label="Primary Contact"
          variant="outlined"
          onBlur={handleBlur}
          error={helpers.hasError('primaryContactId')}
          helperText={helpers.getErrorHelpText('primaryContactId')}
        />
      )}
    />
  );
};

export default PrimaryContactField;
