import { isNilOrEmpty } from './logic';

// eslint-disable-next-line import/prefer-default-export
export const removeNilOrEmptyKeysFromObject = (obj: any) => {
  const keys: string[] = Object.keys(obj);
  return keys.reduce((accumulator: any, value: any): any => {
    if (isNilOrEmpty(obj[value])) {
      return accumulator;
    }
    return { ...accumulator, [value]: typeof obj[value] === 'object' ? removeNilOrEmptyKeysFromObject(obj[value]) : obj[value] };
  }, {});
};
