import LocationForm from 'features/location/LocationForm';
import { useValidatedLocationsState } from 'features/ValidatedLocations';
import useProtectedParams from 'hooks/useProtectedParams';
import { APPOINTMENT_KEY, useLocationQuery, useUpdateLocationMutation } from 'providers/api';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const AppointmentTemporaryLocationPanel = () => {
  const navigate = useNavigate();
  const { appointmentId } = useProtectedParams('appointmentId');
  const { contextData: { setShowSelectedGeoPoint, selectedGeoPoint, setSelectedValidatedLocationId } } = useValidatedLocationsState();

  const attemptToLoadLocationFromServer = React.useRef<boolean>(true);

  const updateLocationMutation = useUpdateLocationMutation(
    appointmentId,
    { onSuccess: () => navigate('..') },
  );
  const { data: location } = useLocationQuery({ entityId: appointmentId, entityType: APPOINTMENT_KEY }, {
    enabled: attemptToLoadLocationFromServer.current,
  });

  React.useEffect(() => {
    setShowSelectedGeoPoint && setShowSelectedGeoPoint(true);
    setSelectedValidatedLocationId && setSelectedValidatedLocationId(undefined);
    return () => setShowSelectedGeoPoint && setShowSelectedGeoPoint(false);
  }, []);

  return (
    <LocationForm
      entityKey={appointmentId}
      entityType={APPOINTMENT_KEY}
      mutation={updateLocationMutation}
      geoPoint={selectedGeoPoint}
      location={location}
      onCancel={() => navigate('..')}
    />
  );
};

export default AppointmentTemporaryLocationPanel;
