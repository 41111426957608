import DashboardIcon from '@mui/icons-material/Dashboard';
import { ArrivetRouteObject, RouteConfiguration } from 'modules/interfaces';
import AppointmentPage from './AppointmentPage';
import DashboardPage from './DashboardPage/DashboardPage';
import ScheduledVetsPanel from './DashboardPage/ScheduledVetsPanel';
import SelectedVetPanel from './DashboardPage/SelectedVetPanel';

export const defaultRoute = 'dashboard';

const routes: (config: RouteConfiguration) => ArrivetRouteObject[] = () => [{
  path: 'dashboard',
  mainNavigation: {
    title: 'Dashboard',
    group: '',
    iconComponent: <DashboardIcon />,
  },
  children: [
    {
      element: <DashboardPage />,
      children: [
        {
          index: true,
          element: <ScheduledVetsPanel />,
        },
        {
          path: ':vetId/info',
          element: <SelectedVetPanel />,
        },
      ],
    },
    {
      path: 'appointment/:appointmentId',
      element: <AppointmentPage />,
    },
  ],
}];

export default routes;
