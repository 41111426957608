/* global google */
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { GoogleMap, Marker } from 'components/GoogleMap';
import { INITIAL_ZOOM_FOR_UK, LONDON_CENTER } from 'config';
import React, { useState } from 'react';

interface GeolocationPositionError {
  readonly code: number;
  readonly message: string;
  readonly PERMISSION_DENIED: number;
  readonly POSITION_UNAVAILABLE: number;
  readonly TIMEOUT: number;
}

interface PracticeLocationProps {
  practiceLocation?: google.maps.LatLngLiteral
  onSaveLocation: () => void;
  setPracticeLocation: (latLng: google.maps.LatLngLiteral) => void
}

const PracticeLocationMap = ({ practiceLocation, onSaveLocation, setPracticeLocation }: PracticeLocationProps) => {
  const [currentPositionFeedback, setCurrentPositionFeedback] = React.useState<string>();
  const [mapIsDirty, setMapIsDirty] = useState(false);
  const [currentPositionIsLoading, setCurrentPositionIsLoading] = useState(false);

  const showCurrentPositionFeedback = (result: GeolocationPositionError) => {
    switch (result.code) {
      case result.PERMISSION_DENIED:
        setCurrentPositionFeedback('User denied the request for Geolocation.');
        break;
      case result.POSITION_UNAVAILABLE:
        setCurrentPositionFeedback('Location information is unavailable.');
        break;
      case result.TIMEOUT:
        setCurrentPositionFeedback('The request to get user location timed out.');
        break;
      default:
        setCurrentPositionFeedback('An unknown error occurred.');
        break;
    }
  };

  const handleRequestUserLocation = () => {
    if (navigator.geolocation) {
      setCurrentPositionIsLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setPracticeLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setCurrentPositionIsLoading(false);
        },
        (error) => {
          setCurrentPositionIsLoading(false);
          showCurrentPositionFeedback(error);
        },
      );
      setMapIsDirty(true);
    } else {
      setCurrentPositionFeedback('Geolocation is not supported by this browser.');
    }
  };

  return (
    <Stack spacing={2}>
      <Alert severity="info">
        Mark your practice location on the map by either using the button below to find your current location automatically if you are at your practice,
        or manually zoom and click your location.
        Then press the &quot;Save Location&quot; button to confirm your practice location.
      </Alert>
      <Box height={400}>
        <GoogleMap
          disableDefaultUI
          zoomControl
          onClick={(event) => {
            event.latLng && setPracticeLocation({ lat: event.latLng.lat(), lng: event.latLng.lng() });
            setMapIsDirty(true);
          }}
          draggableCursor="crosshair"
          zoom={
            practiceLocation
              ? 19
              : INITIAL_ZOOM_FOR_UK
          }
          center={practiceLocation ?? LONDON_CENTER}
        >
          {practiceLocation
            && (
              <Marker
                position={{ lat: practiceLocation.lat, lng: practiceLocation.lng }}
                selected
                label="Practice location"
              />
            )}
        </GoogleMap>
      </Box>
      <LoadingButton
        fullWidth
        color="secondary"
        variant="contained"
        onClick={handleRequestUserLocation}
        loading={currentPositionIsLoading}
      >
        Use my current location
      </LoadingButton>
      <Tooltip title={
        !practiceLocation && !mapIsDirty
          ? 'Select a practice location on the map or click the "Use my current location" button above to set your location before saving'
          : ''
      }
      >
        <div>
          <Button
            fullWidth
            variant="contained"
            onClick={onSaveLocation}
            disabled={!practiceLocation && !mapIsDirty}
          >
            Save Practice Location
          </Button>
        </div>
      </Tooltip>
      {currentPositionFeedback && <Alert severity="info">{currentPositionFeedback}</Alert>}
    </Stack>
  );
};

export default PracticeLocationMap;
