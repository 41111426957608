import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FormikActions, FormStack } from 'components/Form';
import TitleBox from 'components/TitleBox';
import { FormikConfig } from 'formik';
import { CreateValidatedLocationRequestDto, GeoPointDto } from 'providers/api';
import useDefaultForm from 'providers/form/useDefaultForm';
import React from 'react';
import { UseMutationResult } from 'react-query';
import {
  array,
  number,
  object,
  SchemaOf,
  string
} from 'yup';
import TagSelectField from '../TagSelectField';

const updateValidatedLocationFormSchema: SchemaOf<CreateValidatedLocationRequestDto> = object().shape({
  clientId: string().required('A client id is required'),
  geoPoint: object().shape({
    latitude: number().required('Latitude is required'),
    longitude: number().required('Longitude is required'),
  }),
  description: string().required('A description is required'),
  clientUserId: string(),
  vetId: string(),
  tags: array().of(string().required()),
});

interface ValidatedLocationFormProps {
  clientId: string;
  clientUserId?: string;
  vetId?: string;
  selectedLocation?: GeoPointDto;
  mutation: UseMutationResult<any, any, any, any>;
  onSubmit: FormikConfig<CreateValidatedLocationRequestDto>['onSubmit'];
}
const ValidatedLocationForm = ({ clientId, clientUserId, vetId, selectedLocation, mutation, onSubmit }: ValidatedLocationFormProps) => {
  const {
    formik,
    helpers,
  } = useDefaultForm<CreateValidatedLocationRequestDto>({
    mutation,
    formikConfig: {
      initialValues: {
        clientId,
        clientUserId,
        vetId,
        geoPoint: { latitude: 0, longitude: 0 },
        description: '',
        tags: [],
      },
      onSubmit: (...args) => {
        const [, { setSubmitting }] = args;
        setSubmitting(true);
        onSubmit(...args);
      },
      validationSchema: updateValidatedLocationFormSchema,
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleAddTag = (tag: string) => setFieldValue('tags', [...values.tags, tag]);
  const handleRemoveTag = (tag: string) => setFieldValue('tags', values.tags.filter((currentTag) => tag !== currentTag));

  React.useEffect(() => {
    selectedLocation && setFieldValue('geoPoint', selectedLocation);
  }, [selectedLocation]);

  return (
    <form onSubmit={handleSubmit}>
      <TitleBox title="Location Details">
        <FormStack>
          <TextField
            fullWidth
            id="description"
            name="description"
            label="Description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('description')}
            helperText={helpers.getErrorHelpText('description')}
            size="small"
            multiline
            rows={3}
          />
          <TagSelectField currentTags={values.tags} onAdd={handleAddTag} onRemove={handleRemoveTag} />
          <Typography variant="caption">Click a point on the map to update the location fields.</Typography>
          <TextField
            fullWidth
            type="number"
            id="latitude"
            name="latitude"
            label="Latitude"
            value={values.geoPoint.latitude ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('geoPoint.latitude')}
            helperText={helpers.getErrorHelpText('geoPoint.latitude')}
            disabled
          />
          <TextField
            fullWidth
            type="number"
            id="longitude"
            name="longitude"
            label="Longitude"
            value={values.geoPoint.longitude ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('geoPoint.longitude')}
            helperText={helpers.getErrorHelpText('geoPoint.longitude')}
            disabled
          />
        </FormStack>
      </TitleBox>

      <Box mt={2}>
        <FormikActions
          formik={formik}
          mutation={mutation}
          submitText="Create"
          right={['reset', 'submit']}
        />
      </Box>
    </form>
  );
};

export default ValidatedLocationForm;
