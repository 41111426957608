import { DateClickArg, EventResizeDoneArg } from '@fullcalendar/interaction';
import {
  CustomContentGenerator, DayHeaderContentArg, EventClickArg, EventDropArg, EventSourceInput
} from '@fullcalendar/react';
import { ResourceLabelContentArg } from '@fullcalendar/resource-common';
import { FULL_CALENDARY_KEY } from 'config';
import { EventInputWithType } from 'features/AppointmentCalendar/interfaces';
import useMenuState from 'modules/Layout/MenuStateProvider';
import { AppointmentStatus, UserDto } from 'providers/api';
import React from 'react';
import { truncate } from 'utils';
import FullCalendar, {
  dayGridPlugin,
  interactionPlugin,
  resourceTimeGridPlugin,
  resourceTimelinePlugin,
  scrollGridPlugin,
  timeGridPlugin
} from './imports';

export const CALENDAR_EVENT_STATUS_KEY: Record<AppointmentStatus, string> = {
  [AppointmentStatus.Draft]: '#bdbdbd',
  [AppointmentStatus.Unconfirmed]: '#f50057',
  [AppointmentStatus.Confirmed]: '#f5a590',
  [AppointmentStatus.InTransit]: '#44a184',
  [AppointmentStatus.InProgress]: '#2e5f65',
  [AppointmentStatus.Completed]: '#e3e0d8',
  [AppointmentStatus.Cancelled]: '#bdbdbd',
};

const NO_HEADER = {
  start: '',
  center: '',
  end: '',
};

const STANDARD_HEADER = {
  left: 'prev,next today',
  center: 'title',
  right: 'dayView,weekView,monthView',
};

interface AppointmentCalendarProps {
  appointmentCalendarEvents: EventInputWithType[]
  timeBlockEvents?: EventInputWithType[];
  readOnly?: boolean;
  viewType: 'timeGridDay' | 'timeGridWeek' | 'resourceTimeGrid' | 'resourceTimeline';
  controls?: boolean;
  vets?: UserDto[]
  dateClick?: (date: Date, vetId: string) => void;
  onDateChange?: (startDate: Date, endDate: Date) => void,
  eventClick?: (appointmentId: string) => void,
  onAppointmentEdit?: (arg: EventDropArg | EventResizeDoneArg) => void,
  initialDate?: Date;
  resourceAreaHeaderContent?: string,
  initialView?: string,
  renderResourceLabelContent?: CustomContentGenerator<ResourceLabelContentArg>;
  renderDateLabelContent?: CustomContentGenerator<DayHeaderContentArg>;
}

const AppointmentCalendar = (
  {
    viewType,
    appointmentCalendarEvents,
    timeBlockEvents,
    readOnly,
    controls = true,
    vets,
    dateClick,
    onDateChange,
    eventClick,
    onAppointmentEdit,
    initialDate,
    initialView,
    resourceAreaHeaderContent,
    renderResourceLabelContent,
    renderDateLabelContent,
  }: AppointmentCalendarProps,
) => {
  const { state: { mainMenuIsOpen, notificationsDrawerIsOpen } } = useMenuState();
  const calendarRef = React.createRef<FullCalendar>();

  React.useEffect(() => {
    setTimeout(() => calendarRef.current && calendarRef.current.getApi().updateSize(), 300);
  }, [mainMenuIsOpen, notificationsDrawerIsOpen]);

  const combinedEvents: EventSourceInput = [
    ...(timeBlockEvents || []),
    ...appointmentCalendarEvents,
  ];

  return (
    <FullCalendar
      initialDate={initialDate}
      schedulerLicenseKey={FULL_CALENDARY_KEY}
      locale="en-gb"
      slotLabelFormat={{
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: false,
      }}
      ref={calendarRef}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin, scrollGridPlugin, resourceTimelinePlugin]}
      headerToolbar={controls ? STANDARD_HEADER : NO_HEADER}
      views={{
        dayView: {
          type: viewType,
          duration: { days: 1 },
          buttonText: 'Day',
        },
        weekView: {
          type: viewType,
          duration: { weeks: 1 },
          buttonText: 'Week',
        },
        monthView: {
          type: viewType,
          duration: { months: 1 },
          buttonText: 'Month',
        },
      }}
      initialView={initialView ?? viewType}
      weekends
      events={combinedEvents}
      height="100%"
      dayMinWidth={200}
      duration={{ days: 1 }}
      nowIndicator
      dateClick={readOnly ? undefined : (event: DateClickArg) => dateClick && dateClick(event.date, event.resource?.id ?? '')}
      eventClick={(event: EventClickArg) => (
        event.event.extendedProps.type === 'availability'
          ? undefined
          : eventClick && eventClick(event.event.id)
      )}
      datesSet={(dateConstraints) => onDateChange && onDateChange(dateConstraints.start, dateConstraints.end)}
      resources={
        vets
          ? vets.map((vet) => ({
            id: vet.userId,
            ...(vet.forename === undefined && vet.surname === undefined
              ? { title: truncate(vet.email, 23) }
              : { title: `${vet.forename ?? ''} ${vet.surname ?? ''}` }
            ),
          }))
          : undefined
      }
      eventDrop={onAppointmentEdit}
      eventResize={onAppointmentEdit}
      resourceAreaHeaderContent={resourceAreaHeaderContent}
      resourceLabelContent={renderResourceLabelContent}
      dayHeaderContent={renderDateLabelContent}
      allDaySlot={false}
    />
  );
};

export default AppointmentCalendar;
