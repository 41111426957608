import Alert from '@mui/material/Alert';
import Page from 'modules/Page';
import { TITLE } from './constants';

const PracticeDisabledPage = () => (
  <Page pageType="standard" title={TITLE}>
    <Alert severity="info">This practice has been disabled. Contact the administrator for further support.</Alert>
  </Page>
);

export default PracticeDisabledPage;
