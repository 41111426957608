import { useReactOidc } from '@axa-fr/react-oidc-context';
import Loading from 'components/Loading';
import useProtectedParams from 'hooks/useProtectedParams';
import AccountInfoSection from 'modules/account/settings/AccountInfoSection';
import Page from 'modules/Page';
import { useUserQuery } from 'providers/api';
import React from 'react';

const AccountSettings = () => {
  const { teamId } = useProtectedParams('teamId');
  const { oidcUser } = useReactOidc();
  const userId = oidcUser.profile.sub;
  const { data: user, isLoading } = useUserQuery({ teamId, userId });

  if (!user || isLoading) {
    return <Loading />;
  }

  return (
    <Page pageType="standard" title="Account Settings">
      <AccountInfoSection user={user} />
    </Page>
);
};

export default AccountSettings;
