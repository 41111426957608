import {
  AuthenticationProvider,
  InMemoryWebStorage,
  oidcLog,
  withOidcSecure
} from '@axa-fr/react-oidc-context';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import App from 'App';
import Loading from 'components/Loading';
import { OIDC_CONFIGURATION } from 'config';
import 'index.css';
import PublicConfirmAppointmentPage from 'modules/public/PublicConfirmAppointmentPage';
import SharePublicLocationPage from 'modules/public/SharePublicLocationPage';
import { SnackbarProvider } from 'notistack';
import {
  ApiProvider,
  withQueryClientDefaults
} from 'providers/api';
import { AuthorisationProvider } from 'providers/authorisation';
import { ConfirmationProvider } from 'providers/confirm';
import { PageProvider } from 'providers/page';
import { SignalRProvider } from 'providers/signalR';
import { TeamProvider } from 'providers/team';
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import theme from 'theme/theme';
import reportWebVitals from './reportWebVitals';

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => (
  <Box
    sx={{
      backgroundColor: theme.palette.background.default,
      width: '100vh',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Alert
      sx={{
        width: '50%',
        minWidth: 400,
        maxHeight: 700,
      }}
      severity="error"
    >
      <Typography variant="subtitle2">Something went wrong:</Typography>
      <pre>{error.message}</pre>
      <Button onClick={resetErrorBoundary}>Retry</Button>
    </Alert>
  </Box>
);

const withAuthorisation = (WrappedComponent: React.ComponentType) => () => <AuthorisationProvider><WrappedComponent /></AuthorisationProvider>;
const withTeam = (WrappedComponent: React.ComponentType) => () => <TeamProvider><WrappedComponent /></TeamProvider>;
const withSignalR = (WrappedComponent: React.ComponentType) => () => <SignalRProvider><WrappedComponent /></SignalRProvider>;

const AppWithAuthAndApi = withOidcSecure(withQueryClientDefaults(withAuthorisation(withTeam(withSignalR(App)))));

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <PageProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthenticationProvider
            configuration={OIDC_CONFIGURATION}
            loggerLevel={oidcLog.DEBUG}
            isEnabled
            UserStore={InMemoryWebStorage}
            notAuthenticated={Loading}
            notAuthorized={Loading}
            authenticating={Loading}
            callbackComponentOverride={Loading}
          >
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <ApiProvider>
                <ConfirmationProvider>
                  <Router basename="app">
                    <Routes>
                      <Route path="confirm-appointment/:appointmentId/:accessTokenHash" element={<PublicConfirmAppointmentPage />} />
                      <Route path="share-location/:appointmentId/:accessTokenHash" element={<SharePublicLocationPage />} />
                      <Route path="*" element={<AppWithAuthAndApi />} />
                    </Routes>
                  </Router>
                </ConfirmationProvider>
              </ApiProvider>
            </SnackbarProvider>
          </AuthenticationProvider>
        </ThemeProvider>
      </PageProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
