import { ArrivetRouteObject, RouteConfiguration } from 'modules/interfaces';
import PatientPage from './PatientPage';
import PatientsPage from './PatientsPage';
import { HorseIcon } from './svgs';

export const defaultRoute = 'patients';

const routes: (config: RouteConfiguration) => ArrivetRouteObject[] = () => [{
  path: 'patients',
  mainNavigation: {
    title: 'Patients',
    group: '',
    iconComponent: <HorseIcon />,
  },
  children: [
    {
      index: true,
      element: <PatientsPage />,
    },
    {
      path: ':patientId',
      element: <PatientPage />,
    },
  ],
}];

export default routes;
