import PracticeLocationFeature from 'features/PracticeLocation/PracticeLocationFeature';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import React from 'react';
import { TITLE as parentTitle } from '../PracticesPage';
import { TITLE } from './constants';

const PracticeLocationPage = () => {
  const { practiceId } = useProtectedParams('practiceId');

  return (
    <Page pageType="standard" title={TITLE} parentRelativePath="../../" parentTitle={parentTitle}>
      <PracticeLocationFeature practiceId={practiceId} />
    </Page>
  );
};

export default PracticeLocationPage;
