import { ChronoUnit, Instant } from '@js-joda/core';
import { HOUR_IN_MILLISECONDS } from 'config';
import {
  AppointmentDto,
  APPOINTMENT_KEY,
  EntitySummaryDto,
  LocationDto, useAppointmentsQuery,
  useListOfTeamUsersQuery, useLocationsQuery,
  UserDto, useUsersLocationQuery
} from 'providers/api';
import { indexBy, isEmpty } from 'ramda';
import React from 'react';

const useDashboardData = (teamId: string) => {
  const instantNowRef = React.useRef(Instant.now());

  const {
    data: appointments,
  } = useAppointmentsQuery(
    {
      practiceId: teamId,
      startDateUtc: instantNowRef.current,
      endDateUtc: instantNowRef.current.plus(1, ChronoUnit.DAYS),
    },
  );

  const appointmentsIndexedById: Record<string, AppointmentDto> = React.useMemo(
    () => (appointments && appointments.length > 0
      ? indexBy((a) => a.entityId, appointments)
      : {}),
    [appointments],
  );

  const appointmentsIndexedByVetId: Record<string, AppointmentDto> = React.useMemo(
    () => (appointments && appointments.length > 0
      ? indexBy((a) => a.vet.entityId, appointments)
      : {}),
    [appointments],
  );

  const entitySummariesForActiveVetsIndexedById: Record<string, EntitySummaryDto> = React.useMemo(
    () => (appointments && appointments.length && !isEmpty(appointmentsIndexedById)
      ? indexBy((v) => v.entityId, appointments?.map((appointment) => appointment.vet))
      : {}),
    [appointments],
  );

  const { data: appointmentLocations } = useLocationsQuery(
    { entityType: APPOINTMENT_KEY, entityIds: Object.keys(appointmentsIndexedById) },
    { staleTime: HOUR_IN_MILLISECONDS, enabled: !isEmpty(appointmentsIndexedById) },
  );

  const appointmentLocationsIndexedById: Record<string, LocationDto> = React.useMemo(
    () => (appointmentLocations && appointmentLocations.length > 0
      ? indexBy((a) => a.entityId.split('|')[0], appointmentLocations)
      : {}),
    [appointmentLocations],
  );

  const { data: vetLocations } = useUsersLocationQuery(
    appointments
      ? appointments.map((appointment) => ({ appointmentId: appointment.entityId, userId: appointment.vet.entityId }))
      : [],
    { staleTime: 0, cacheTime: 0, enabled: !isEmpty(appointmentsIndexedById) },
  );

  const vetLocationsIndexedById: Record<string, LocationDto> = React.useMemo(
    () => (vetLocations && vetLocations.length > 0
      ? indexBy((a) => a.entityId.split('|')[0], vetLocations)
      : {}),
    [vetLocations],
  );

  const { data: scheduledVets } = useListOfTeamUsersQuery(
    { teamId: teamId ?? '', userIds: Object.keys(entitySummariesForActiveVetsIndexedById) },
    { staleTime: HOUR_IN_MILLISECONDS, enabled: !isEmpty(entitySummariesForActiveVetsIndexedById) },
  );

  const scheduledVetsIndexedById: Record<string, UserDto> = React.useMemo(
    () => (scheduledVets && scheduledVets.length > 0
      ? indexBy((v) => v.userId, scheduledVets)
      : {}),
    [scheduledVets],
  );

  const vetIdsArrayForAppointmentsWithoutLocations = React.useMemo(
    () => (appointments
      ? appointments.filter((appointment) => appointmentLocationsIndexedById[appointment.entityId] === undefined)
        .map((appointmentWithNoLocation) => appointmentWithNoLocation.vet.entityId)
      : []),
    [appointments, appointmentLocationsIndexedById],
  );

  return {
    indexes: {
      appointmentsIndexedById,
      appointmentsIndexedByVetId,
      appointmentLocationsIndexedById,
      scheduledVetsIndexedById,
      vetLocationsIndexedById,
    },
    arrays: {
      appointments,
      vetIdsArrayForAppointmentsWithoutLocations,
    },
  };
};

export default useDashboardData;
