import { GeoPointDto, ValidatedLocationDto } from 'providers/api';
import { useOutletContext } from 'react-router-dom';

export type ValidatedLocationsState = {
  validatedLocations: ValidatedLocationDto[],
  selectedGeoPoint?: GeoPointDto,
  selectedValidatedLocationId?: string,
  confirmedValidatedLocationId?: string;
  highlightedValidatedLocationId?: string;
  setSelectedValidatedLocationId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setHighlightedValidatedLocationId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setShowSelectedGeoPoint?: React.Dispatch<React.SetStateAction<boolean>>,
};

type ContextType = { contextData: ValidatedLocationsState };
export const useValidatedLocationsState = () => useOutletContext<ContextType>();
