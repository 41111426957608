import { ArrivetRouteObject, RouteConfiguration } from 'modules/interfaces';
import { Navigate } from 'react-router-dom';
import practicesRoutes, { defaultRoute as practicesDefaultRoute } from './practices';
import toolsRoutes from './tools';

export const defaultRoute = 'admin';

const routes: (config: RouteConfiguration) => ArrivetRouteObject[] = (config) => {
  const practices = practicesRoutes(config);
  const tools = toolsRoutes(config);
  if (!config.authorisation.functions.isAdmin()) return [];

  return [
    {
      path: 'admin',
      children: [
        {
          index: true,
          element: <Navigate to={practicesDefaultRoute} />,
        },
        ...practices,
        ...tools,
      ],
    },
  ];
};

export default routes;
