import Loading from 'components/Loading';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { usePracticeQuery } from 'providers/api';
import React from 'react';
import PracticeInfoSection from '../PracticeInfoSection';
import { TITLE } from './constants';

const SettingsPage = () => {
  const { teamId } = useProtectedParams('teamId');
  const { data: practice, isLoading } = usePracticeQuery(teamId);

  if (!practice || isLoading) {
    return <Loading />;
  }
  return (
    <Page pageType="standard" title={TITLE}>
      <PracticeInfoSection practice={practice} />
    </Page>
  );
};

export default SettingsPage;
