import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ImageIcon from '@mui/icons-material/Image';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import MenuAutoComplete from 'components/MenuAutoComplete';
import { HOUR_IN_MILLISECONDS } from 'config';
import { UserDto, useUsersQuery } from 'providers/api';
import React from 'react';
import { useAppointmentForm } from './context';

const ClientUserField = () => {
  const { form: { formik } } = useAppointmentForm();

  const { setFieldValue, values } = formik;

  const [searchTerm, setSearchTerm] = React.useState<string>('');

  // TODO: review the process for feeding large data sets into autocomplete
  // SEE: https://dev.azure.com/buzzinteractiveteam/Aegis/_sprints/taskboard/Aegis%20Team/Aegis/Sprint%203?workitem=6596
  const { data: clientUsers } = useUsersQuery({ page: 1, pageSize: 1000, teamId: values.clientId ?? '', searchTerm }, {
    enabled: !!values.clientId,
    staleTime: HOUR_IN_MILLISECONDS,
  });

  const handleUserSelectUpdate = (newAttendeesList: UserDto[] | null) => {
    setFieldValue('attendeeIds', newAttendeesList && newAttendeesList.map((attendee: UserDto) => attendee.userId));
  };

  const handleRemoveAttendee = (userId: string) => {
    setFieldValue('attendeeIds', values.attendeeIds.filter((attendeeId) => attendeeId !== userId));
  };

  const handleSearch = (value: string) => setSearchTerm(value);

  // used to marry up incomplete user data with full team object
  const checkSelected = (arr: UserDto[], id: string) => arr.some((item: UserDto) => id === item.userId);
  const selectedList = (fullList: UserDto[], smallList: UserDto[]) => fullList.filter((item: UserDto) => checkSelected(smallList, item.userId));

  const selectedAttendees = clientUsers?.items.filter((clientUser) => values.attendeeIds.includes(clientUser.userId)) ?? null;
  if (!values.clientId) return <Alert severity="info">You must select a client before selecting a patient</Alert>;

  return (
    <Stack>
      <List sx={{ width: '100%' }}>
        {selectedAttendees && selectedAttendees.length === 0 && (
          <ListItem>
            <ListItemText primary="No attendees selected. For this form to validate you must either select an attendee or add a temporary contact." />
          </ListItem>
        )}
        {selectedAttendees && selectedAttendees.map((attendee) => (
          <ListItem
            key={attendee.userId}
            secondaryAction={(
              <IconButton edge="end" aria-label="remove attendee" onClick={() => handleRemoveAttendee(attendee.userId)}>
                <CloseIcon />
              </IconButton>
            )}
          >
            <ListItemAvatar>
              <Avatar>
                {/* TODO: Switch icon depending on the type of client user */}
                <ImageIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${attendee.forename} ${attendee.surname}`} secondary={attendee.email} />
          </ListItem>
        ))}
      </List>

      <MenuAutoComplete
        label="Select attendees"
        data={clientUsers ? clientUsers.items : []}
        selectedItems={selectedList(clientUsers?.items ?? [], selectedAttendees ?? [])}
        getTitle={(user) => `${user.forename} ${user.surname}`}
        noOptionsText="No users assigned to the selected client"
        onChange={handleUserSelectUpdate}
        onSearch={handleSearch}
        buttonProps={{
          color: 'primary',
          fullWidth: true,
          variant: 'contained',
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Box
              component={DoneIcon}
              sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
              style={{
                visibility: selected ? 'visible' : 'hidden',
              }}
            />
            <Box
              component="span"
              sx={{
                width: 14,
                height: 14,
                flexShrink: 0,
                borderRadius: '3px',
                mr: 1,
                mt: '2px',
              }}
            />
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              {`${option.forename} ${option.surname}`}
              <br />
              <span>{option.email}</span>
            </Box>
            <Box
              component={CloseIcon}
              sx={{ opacity: 0.6, width: 18, height: 18 }}
              style={{
                visibility: selected ? 'visible' : 'hidden',
              }}
            />
          </li>
        )}
      >
        <Stack direction="row">
          <AddIcon />
          Add Select Attendees
        </Stack>
      </MenuAutoComplete>
    </Stack>
  );
};

export default ClientUserField;
