import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { FormStack } from 'components/Form';
import { FormikErrors } from 'formik';
import { UpdatePracticeCommand } from 'providers/api';
import { dissocPath } from 'ramda';
import { usePracticeForm } from './context';

const BillingContactFields = () => {
  const {
    form: { formik, helpers },
  } = usePracticeForm();

  const { values, handleChange, handleBlur, setFieldValue, setErrors, errors } = formik;

  const handleAddBillingContact = () => {
    setFieldValue('billing.billingContact', {
      firstName: '',
      lastName: '',
      contactNumber: '',
      email: '',
    });
  };

  const handleRemoveBillingContact = () => {
    setFieldValue('billing.billingContact', undefined);
    const updatedErrors = dissocPath<FormikErrors<UpdatePracticeCommand>>(['billing', 'billingContact'], errors);
    setErrors(updatedErrors);
  };

  return !values.billing.billingContact
    ? (
      <Box my={2}>
        <Button variant="contained" color="secondary" onClick={handleAddBillingContact} startIcon={<AddIcon />}>Billing Contact</Button>
      </Box>
    )
    : (
      <>
        <FormStack>
          <FormStack direction={{ xs: 'column', md: 'row' }}>
            <TextField
              fullWidth
              id="billing.billingContact.firstName"
              name="billing.billingContact.firstName"
              label="First Name"
              value={values.billing.billingContact.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('billing.billingContact.firstName')}
              helperText={helpers.getErrorHelpText('billing.billingContact.firstName')}
            />
            <TextField
              fullWidth
              id="billing.billingContact.lastName"
              name="billing.billingContact.lastName"
              label="Last Name"
              value={values.billing.billingContact.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('billing.billingContact.lastName')}
              helperText={helpers.getErrorHelpText('billing.billingContact.lastName')}
            />
          </FormStack>
          <TextField
            fullWidth
            id="billing.billingContact.contactNumber"
            name="billing.billingContact.contactNumber"
            label="Contact Number"
            value={values.billing.billingContact.contactNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('billing.billingContact.contactNumber')}
            helperText={helpers.getErrorHelpText('billing.billingContact.contactNumber')}
          />
          <TextField
            fullWidth
            id="billing.billingContact.email"
            name="billing.billingContact.email"
            label="Email"
            value={values.billing.billingContact.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('billing.billingContact.email')}
            helperText={helpers.getErrorHelpText('billing.billingContact.email')}
          />
        </FormStack>
        <Box my={2}>
          <Button variant="contained" color="inherit" onClick={handleRemoveBillingContact}>Remove Billing Contact</Button>
        </Box>
      </>
    );
};

export default BillingContactFields;
