/* global google */
import React from 'react';
import MapProps from './MapProps';
import MapSearch from './MapSearch';
import useDeepCompareEffectForMap from './useDeepCompareEffectForMap';
import useDirectionRenderer, { RouteRequest } from './useDirectionRenderer';

interface MapCustomProps {
  search?: boolean;
  routes?: RouteRequest[];
}

const Map = ({
  search = false,
  routes,
  onClick,
  onIdle,
  children,
  style,
  ...options
}: MapProps & MapCustomProps) => {
  const [initialBoundFitComplete, setInitialBoundFitComplete] = React.useState(false);
  const initRoutes = useDirectionRenderer();

  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    if (ref.current && !map) {
      const bounds = new google.maps.LatLngBounds();
      React.Children.forEach(children, (child) => {
        if (React.isValidElement<google.maps.MarkerOptions>(child)) {
          bounds.extend(child.props.position!);
        }
      });
      const newMap = new google.maps.Map(ref.current, options);

      if (React.Children.count(children) > 0) {
        newMap.fitBounds(bounds);
      }

      // Only set the map when it is idle as under some circumstance the initial fitBounds doesn't load in time
      const listener = google.maps.event.addListener(newMap, 'idle', () => {
        google.maps.event.removeListener(listener);
        setMap(newMap);
      });
    }
  }, [ref, map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMap(() => {
    if (map) {
      map.setOptions(options);
      routes && initRoutes(map, routes);
    }
  }, [map, routes, options]);

  React.useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) => google.maps.event.clearListeners(map, eventName));

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }

      if (!initialBoundFitComplete) {
        if (map.getZoom()! > 15) {
          map.setZoom(15);
        }
        setInitialBoundFitComplete(true);
      }
    }
  }, [map, onClick, onIdle, initialBoundFitComplete]);

  return (
    <>
      {map && search && <MapSearch map={map} />}
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement<google.maps.MarkerOptions>(child)) {
          // set the map prop on the child component
          const Marker = React.cloneElement(child, { map });

          return Marker;
        }

        return null;
      })}
    </>
  );
};

export default Map;
