import { FormikConfig } from 'formik';
import { UpdatePatientForm, useCreatePatientMutation } from 'providers/api';
import PatientForm from './PatientForm';

interface NewPatientFeatureProps {
  practiceId: string,
  clientId: string,
  onSuccess: () => void;
}

const NewPatientFeature = ({ practiceId, clientId, onSuccess }: NewPatientFeatureProps) => {
  const createPatientMutation = useCreatePatientMutation({
    onSuccess: () => {
      onSuccess();
    },
  });

  const onSubmit: FormikConfig<UpdatePatientForm>['onSubmit'] = (patientForm, { setSubmitting }) => {
    createPatientMutation.mutate({ practiceId, command: { clientId, patient: patientForm } }, {
      onSettled: () => {
        setSubmitting(false);
      },
    });
  };

  return (
    <PatientForm onSubmit={onSubmit} mutation={createPatientMutation} />
  );
};

export default NewPatientFeature;
