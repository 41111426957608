/* global google */
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { debounce } from 'lodash';
import React from 'react';

interface MapSearchProps {
  map: google.maps.Map;
}

const MapSearch = ({ map }: MapSearchProps) => {
  const placesService = React.useRef<google.maps.places.PlacesService>();
  const [value, setValue] = React.useState<google.maps.places.PlaceResult | null>(null);
  const [inputValue, setInputValue] = React.useState<string>();
  const [options, setOptions] = React.useState<google.maps.places.PlaceResult[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    placesService.current = new google.maps.places.PlacesService(map);
  });

  const locationSearch = (request: google.maps.places.TextSearchRequest) => {
    if (!inputValue || !placesService.current) return;
    setLoading(true);
    placesService.current && placesService.current.textSearch(
      request,
      (
        results: google.maps.places.PlaceResult[] | null,
        status: google.maps.places.PlacesServiceStatus,
      ) => {
        setLoading(false);
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          setOptions(results);
        }
      },
    );
  };

  React.useEffect(() => {
    if (value) {
      map.setCenter({ lat: value.geometry?.location?.lat() ?? 0, lng: value.geometry?.location?.lng() ?? 0 });
      map.setZoom(19);
    }
  }, [value]);

  React.useEffect(() => {
    locationSearch({ query: inputValue });
  }, [inputValue]);

  const debouncedLocationSearch = React.useRef(
    debounce((nextValue) => setInputValue(nextValue)),
  ).current;

  const handleAutocompleteInputChange = (event: any, newValue: string) => debouncedLocationSearch(newValue);

  return (
    <Box
      component={Paper}
      position="absolute"
      left={30}
      bottom={50}
      zIndex={1}
      padding={2}
      sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
    >
      <Autocomplete
        id="map-search"
        sx={{ width: 400 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, newValue) => option.name === newValue.name}
        getOptionLabel={(option) => option.name ?? ''}
        options={options}
        loading={loading}
        onChange={(event: any, newValue: google.maps.places.PlaceResult | null) => setValue(newValue)}
        value={value}
        inputValue={inputValue}
        onInputChange={handleAutocompleteInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search for place"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default MapSearch;
