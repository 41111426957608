import { TEMPORARY_ACCESS_TOKEN_EXPIRED_CODE, TIMEOUT_ERROR_CODE } from 'config';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from 'react-query';
import { Pager } from './api';
import ApiContext, { IApiContext } from './context';

export interface PageParams {
  page: number,
  pageSize: number
}

export interface PagedListOfT<T> {
  items: T[];
  pager: Pager;
}

enum GetKeyBrand {
  _ = ''
}

export type GetKey = GetKeyBrand & string;

enum ListKeyBrand {
  _ = ''
}

export type ListKey = string & ListKeyBrand;

const retry = (failureCount: number, error: any) => (
  error
  && error.status
  && error.status === TIMEOUT_ERROR_CODE
  && error.status !== TEMPORARY_ACCESS_TOKEN_EXPIRED_CODE
  && failureCount < 2
);

const useApi = (): IApiContext => {
  const context = useContext(ApiContext);
  if (context === null) {
    throw new Error('No api context available');
  }
  return context;
};

export const makeListQuery = <P, Data>(
  key: ListKey,
  queryFnFactory: (api: IApiContext, params: P) => QueryFunction<Data>) => (
    params: P,
    options?: Omit<UseQueryOptions<Data, unknown, Data, (string | P)[]>, 'queryKey' | 'queryFn'>,
  ) => {
    const api = useApi();
    return useQuery(
      [key as string, params],
      queryFnFactory(api, params),
      { retry, keepPreviousData: true, ...options },
    );
  };

export const makeInfiniteListQuery = <P, Data>(
  key: ListKey,
  queryFnFactory: (api: IApiContext, params?: P) => QueryFunction<PagedListOfT<Data>>,
) => (params?: P, options?: Omit<UseInfiniteQueryOptions<PagedListOfT<Data>>, 'queryKey' | 'queryFn'>) => {
  const api = useApi();

  return useInfiniteQuery<PagedListOfT<Data>>(
    key as string,
    queryFnFactory(api, params),
    {
      getPreviousPageParam: (firstPage) => {
        if (firstPage.pager.page !== 1) {
          return {
            page: firstPage.pager.page - 1,
            pageSize: firstPage.pager.pageSize,
          };
        }

        return false;
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.pager.page < lastPage.pager.pageCount) {
          return {
            page: lastPage.pager.page + 1,
            pageSize: lastPage.pager.pageSize,
          };
        }

        return false;
      },
      retry,
      ...options,
    },
  );
};

export const makeGetQuery = <TGet, P>(
  key: GetKey,
  queryFnFactory: (
    api: IApiContext,
    params: P,
  ) => QueryFunction<TGet>) => (params: P, options?: Omit<UseQueryOptions<TGet, unknown, TGet, (string | P)[]>, 'queryKey' | 'queryFn'>) => {
    const api = useApi();
    return useQuery(
      [key, params],
      queryFnFactory(api, params),
      {
        ...options as any,
        retry,
      },
    );
  };

export const makeCreateMutation = <TData, TModel>(
  listKey: ListKey | null,
  mutationFnFactory: (api: IApiContext) => MutationFunction<TData, TModel>,
  successMessageFn: (model: TModel) => string,
  invalidateKeys: string[] = []) => (options?: UseMutationOptions<TData, unknown, TModel>) => {
    const api = useApi();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<TData, unknown, TModel>(
      mutationFnFactory(api),
      {
        ...options,
        onSuccess: (...args) => {
          if (options?.onSuccess) {
            options.onSuccess(...args);
          }

          enqueueSnackbar(successMessageFn(args[1]));

          listKey && queryClient.invalidateQueries(listKey, { exact: false });
          invalidateKeys.forEach((key) => queryClient.invalidateQueries(key, { exact: false }));
        },
        retry,
      },
    );
  };

export const makeUpdateMutation = <TData, TModel>(
  getKey: GetKey,
  listKey: ListKey | null,
  mutationFnFactory: (api: IApiContext, entityId: string) => MutationFunction<TData, TModel>,
  successMessageFn: (model: TModel) => string,
  invalidateKeys: string[] = []) => (entityId: string, options?: UseMutationOptions<TData, unknown, TModel>) => {
    const api = useApi();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<TData, unknown, TModel>(
      mutationFnFactory(api, entityId),
      {
        ...options,
        onSuccess: (...args) => {
          if (options?.onSuccess) {
            options.onSuccess(...args);
          }

          enqueueSnackbar(successMessageFn(args[1]));

          listKey && queryClient.invalidateQueries(listKey, { exact: false });
          queryClient.invalidateQueries([getKey, entityId]);
          invalidateKeys.forEach((key) => queryClient.invalidateQueries(key, { exact: false }));
        },
        retry,
      },
    );
  };

export const makeGeneralUpdateMutation = <TData, TModel>(
  mutationFnFactory: (api: IApiContext) => MutationFunction<TData, TModel>,
  successMessageFn: (model: TModel) => string,
  createInvalidateKeys: (model: TModel) => QueryKey[],
) => (options?: UseMutationOptions<TData, unknown, TModel>) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation<TData, unknown, TModel>(
    mutationFnFactory(api),
    {
      ...options,
      onSuccess: (...args) => {
        if (options?.onSuccess) {
          options.onSuccess(...args);
        }

        enqueueSnackbar(successMessageFn(args[1]));

        const invalidateKeys = createInvalidateKeys(args[1]);
        invalidateKeys.forEach((key) => queryClient.invalidateQueries(key));
      },
      retry,
    },
  );
};

export const makeDeleteMutation = <TData, TModel>(
  getKey: GetKey | null,
  listKey: ListKey | null,
  mutationFnFactory: (api: IApiContext) => MutationFunction<TData, TModel>,
  successMessageFn: (model: TModel) => string,
  invalidationId: (model: TModel) => string,
  invalidateKeys: string[] = []) => (options?: UseMutationOptions<TData, unknown, TModel>) => {
    const api = useApi();
    const queryClient = useQueryClient();

    const { enqueueSnackbar } = useSnackbar();

    return useMutation<TData, unknown, TModel>(
      mutationFnFactory(api),
      {
        ...options,
        onSuccess: (...args) => {
          if (options?.onSuccess) {
            options.onSuccess(...args);
          }

          enqueueSnackbar(successMessageFn(args[1]));

          listKey && queryClient.invalidateQueries(listKey, { exact: false });
          getKey && queryClient.invalidateQueries([getKey, invalidationId(args[1])]);
          invalidateKeys.forEach((key) => queryClient.invalidateQueries(key, { exact: false }));
        },
        retry,
      },
    );
  };

export default useApi;
