import { useReactOidc } from '@axa-fr/react-oidc-context';
import * as signalR from '@microsoft/signalr';
import { dissoc } from 'ramda';
import React, { ReactNode } from 'react';
import short from 'short-uuid';
import SignalRContext, {
  ISignalRContext,
  MessageType,
  MessageTypeCallbackList,
  MessageTypeCallbackRecord
} from './signalR.context';

export interface SignalRProviderProps {
  children: ReactNode;
}

const SignalRProvider = ({ children }: SignalRProviderProps) => {
  const { oidcUser } = useReactOidc();
  const connectionRef = React.useRef<signalR.HubConnection>();
  const connection = connectionRef.current;

  if (!connectionRef.current) {
    connectionRef.current = new signalR.HubConnectionBuilder()
      .withUrl('/applicationshub', { accessTokenFactory: () => oidcUser.access_token })
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();
  }

  const isConnected = (): boolean => (connection ? connection.state === signalR.HubConnectionState.Connected : false);

  const [messageTypeCallbackRecord, setMessageCallbackRecord] = React.useState<MessageTypeCallbackRecord>({
    signal: {},
    'Vet ETA updated': {},
    'Location Updated': {},
  });

  // INFO: Stale closure - ref required for trigger callbackRecord to access messageTypeCallbackRecord
  // SEE: https://stackoverflow.com/questions/62806541/how-to-solve-the-react-hook-closure-issue
  const callbackRecord = React.useRef<MessageTypeCallbackRecord>();
  callbackRecord.current = messageTypeCallbackRecord;

  const triggerCallbacks = (messageType: MessageType, args: any[]) => {
    callbackRecord.current && Object.values(callbackRecord.current[messageType]).forEach((callback) => callback(args));
  };

  const start = async () => {
    if (!connection || isConnected()) return;
    try {
      await connection.start();
      Object.keys(messageTypeCallbackRecord)
        .forEach((messageType) => connection.on(messageType, (args: any[]) => triggerCallbacks(messageType as MessageType, args)));
    } catch (err) {
      // NOOP
    }
  };

  const addCallback = (messageType: MessageType, callback: (args: any[]) => void): string => {
    const newCallbackId = short.generate();

    setMessageCallbackRecord((currentMessageCallbackRecord) => {
      const newCallbacksList: MessageTypeCallbackList = { ...currentMessageCallbackRecord[messageType], [newCallbackId]: callback };
      return ({
        ...currentMessageCallbackRecord,
        [messageType]: newCallbacksList,
      });
    });

    return newCallbackId;
  };

  const removeCallback = (messageType: MessageType, callbackId: string) => {
    setMessageCallbackRecord((currentMessageCallbackRecord) => ({
      ...currentMessageCallbackRecord,
      [messageType]: dissoc(callbackId, currentMessageCallbackRecord[messageType]),
    }));
  };

  React.useEffect(() => () => {
    if (connection) connection.stop();
  }, []);

  start();

  const value: ISignalRContext = React.useMemo(() => ({
    actions: {
      addCallback,
      removeCallback,
      isConnected,
    },
  }), [messageTypeCallbackRecord]);

  return (
    <SignalRContext.Provider value={value}>
      {children}
    </SignalRContext.Provider>
  );
};

export default SignalRProvider;
