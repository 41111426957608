import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { FormikActions } from 'components/Form';
import TitleBox from 'components/TitleBox';
import TitleDisplayStack from 'components/TitleDisplayStack';
import ClientAppointmentForm, {
  AppointmentDateTimeField,
  AppointmentPriorityField,
  AppointmentSummaryField,
  AppointmentTypeField,
  ClientField,
  ClientUserField,
  ContactDetailsFields,
  PatientField,
  PrimaryContactField,
  useAppointmentForm,
  VetField
} from 'features/ClientAppointmentForm';
import { AppointmentForm, CreateAppointmentMutation } from 'providers/api';
import { UseMutationResult } from 'react-query';

const NewAppointmentFormDetails = () => {
  const { form: { formik }, mutation } = useAppointmentForm();

  return (
    <>
      <TitleBox title="New Appointment">
        <TitleDisplayStack
          title="Date and Times"
          display={<AppointmentDateTimeField />}
        />
        <Divider sx={{ my: 3 }} />
        <TitleDisplayStack
          title="Client"
          display={<ClientField />}
        />
        <Divider sx={{ my: 3 }} />
        <TitleDisplayStack
          title="Patient"
          display={<PatientField />}
        />
        <Divider sx={{ my: 3 }} />
        <TitleDisplayStack
          title="Primary Contact"
          display={<PrimaryContactField />}
        />
        <Divider sx={{ my: 3 }} />
        <TitleDisplayStack
          title="Attendees"
          display={<ClientUserField />}
        />
        <Divider sx={{ my: 3 }} />
        <TitleDisplayStack
          title="Temporary Contact Details"
          display={<ContactDetailsFields />}
        />
        <Divider sx={{ my: 3 }} />
        <TitleDisplayStack
          title="Vet"
          display={<VetField />}
        />
        <Divider sx={{ my: 3 }} />
        <TitleDisplayStack
          title="Appointment Details"
          display={(
            <Stack spacing={2}>
              <AppointmentTypeField />
              <AppointmentPriorityField />
            </Stack>
          )}
        />
        <Divider sx={{ my: 3 }} />
        <TitleDisplayStack
          title="Summary"
          display={<AppointmentSummaryField />}
        />
      </TitleBox>
      <Box mt={2}>
        <FormikActions
          formik={formik}
          mutation={mutation}
          submitText="Save Draft Appointment"
          right={['reset', 'submit']}
        />
      </Box>
    </>
  );
};

interface NewAppointmentFormProps {
  mutation: UseMutationResult<string, unknown, CreateAppointmentMutation>;
  initialStartDate?: Date;
  initialPatient?: string;
  initialVet?: string;
  initialClient?: string;
  onValidationSuccess: (values: AppointmentForm) => void;
}

const NewAppointmentForm = ({ mutation, initialStartDate, initialPatient, initialClient, initialVet, onValidationSuccess }: NewAppointmentFormProps) => (
  <ClientAppointmentForm
    mutation={mutation}
    initialStartDate={initialStartDate}
    initialPatient={initialPatient}
    initialClient={initialClient}
    initialVet={initialVet}
    onValidationSuccess={onValidationSuccess}
  >
    <NewAppointmentFormDetails />
  </ClientAppointmentForm>
);

export default NewAppointmentForm;
