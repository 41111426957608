import { useReactOidc } from '@axa-fr/react-oidc-context';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import MuiAppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuthorisation } from 'providers/authorisation';
import { TempName } from 'providers/authorisation/context';
import { usePage } from 'providers/page';
import { useTeam } from 'providers/team';
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { px } from 'utils';
import { NotificationsDrawerContext, NOTIFICATIONS_DRAWER_WIDTH } from './Notifications';
import {
  DRAWER_WIDTH,
  DRAWER_WIDTH_CLOSED,
  DRAWER_WIDTH_CLOSED_SM
} from './utils';

interface AccountMenuProps {
  logout: () => void;
  name: string;
  isLarge: boolean;
  userId: string;
  teamId?: string;
  userTempName: TempName | undefined;

}
const AccountMenu = ({ logout, isLarge, name, teamId, userTempName }: AccountMenuProps) => {
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [accountMenuOpen, setAccountMenuOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleAccountMenuToggle = () => {
    setAccountMenuOpen((prevOpen) => !prevOpen);
  };

  const handleAccountMenuClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setAccountMenuOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setAccountMenuOpen(false);
    }
  };

  // return focus to the button when we transitioned from !accountMenuOpen -> accountMenuOpen
  const prevAccountMenuOpen = React.useRef(accountMenuOpen);
  React.useEffect(() => {
    if (prevAccountMenuOpen.current === true && accountMenuOpen === false) {
      anchorRef.current!.focus();
    }

    prevAccountMenuOpen.current = accountMenuOpen;
  }, [accountMenuOpen]);

  const navigateToAccountSettings = () => navigate(`/${teamId}/account`);

  return (
    <>
      {
        (isLarge) ? (
          <Button
            ref={anchorRef}
            variant="outlined"
            aria-label="account of current user"
            aria-controls={accountMenuOpen ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleAccountMenuToggle}
            color="inherit"
          >
            {userTempName ? `${userTempName.forename} ${userTempName.surname}` : name }
          </Button>
        ) : (
          <IconButton
            ref={anchorRef}
            aria-label="account of current user"
            aria-controls={accountMenuOpen ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleAccountMenuToggle}
            color="inherit"
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        )
      }

      <Popper open={accountMenuOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleAccountMenuClose}>
                <MenuList autoFocusItem={accountMenuOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem component={Link} href="/ChangePassword" color="textPrimary">
                    Change Password
                  </MenuItem>
                  <MenuItem onClick={navigateToAccountSettings}>
                    Account Settings
                  </MenuItem>
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

interface BackNavButtonProps {
  path: string;
  text: string | null;
  isLarge: boolean;
}

const BackNavButton = ({ path, text, isLarge }: BackNavButtonProps) => (
  (isLarge && text) ? (
    <Button
      aria-label="back navigation"
      color="inherit"
      component={RouterLink}
      to={path}
      startIcon={<ArrowBackIcon />}
    >
      {`BACK TO ${text}`}
    </Button>
  ) : (
    <IconButton
      color="inherit"
      aria-label="back navigation"
      component={RouterLink}
      to={path}
      size="large"
    >
      <ArrowBackIcon />
    </IconButton>
  )
);

type TopBarProps = {
  mainMenuIsOpen: boolean;
  notificationsDrawerIsOpen: boolean;
  errorTotal: number;
  eventTotal: number;
  onOpenNotifications: (context: NotificationsDrawerContext) => void;
};

const TopBar = ({ mainMenuIsOpen, notificationsDrawerIsOpen, errorTotal, eventTotal, onOpenNotifications }: TopBarProps) => {
  const { state: { activeTeam }, actions: { clearTeamsStoredInLocalStorage } } = useTeam();
  const { isEnabled, logout } = useReactOidc();
  const { state: { user, loading: authoriseLoading, userTempName } } = useAuthorisation();
  const { state: pageState } = usePage();

  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('sm'));

  const topBarShouldDisplay = !authoriseLoading && (user || !isEnabled) && !(!isLarge && mainMenuIsOpen);

  const handleLogout = () => {
    clearTeamsStoredInLocalStorage();
    logout();
  };

  const notificationsDrawerOffset = notificationsDrawerIsOpen ? NOTIFICATIONS_DRAWER_WIDTH : 0;

  return (
    <MuiAppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${DRAWER_WIDTH_CLOSED_SM}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(mainMenuIsOpen && isLarge && {
          width: `calc(100% - ${px(DRAWER_WIDTH)})`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
        ...(!(mainMenuIsOpen && isLarge) && {
          [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`,
          },
        }),
      }}
    >

      <Toolbar>
        {topBarShouldDisplay && (
          <Box display="flex" alignItems="center" width="100%">
            <Box flex="1">
              {pageState.parentPath && (
                <BackNavButton path={pageState.parentPath!} text={pageState.parentTitle} isLarge={isLarge} />
              )}
            </Box>

            <Box><Typography variant="h6">{pageState.title}</Typography></Box>

            <Box
              display="flex"
              flexDirection="row-reverse"
              flex="1"
              sx={{
                marginRight: px(notificationsDrawerOffset),
                transition: theme.transitions.create(['width', 'margin'], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
              }}
            >
              <IconButton size="large" onClick={() => onOpenNotifications('events')}>
                <Badge
                  badgeContent={eventTotal}
                  color="secondary"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>

              <IconButton size="large" onClick={() => onOpenNotifications('errors')} sx={{ marginLeft: 1 }}>
                <Badge
                  badgeContent={errorTotal}
                  color="secondary"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <WrongLocationIcon />
                </Badge>
              </IconButton>
              <AccountMenu logout={handleLogout} isLarge={isLarge} name={user.name} userTempName={userTempName} userId={user.id} teamId={activeTeam?.teamId} />
            </Box>
          </Box>
        )}
      </Toolbar>
    </MuiAppBar>
  );
};

export default TopBar;
