import TextField from '@mui/material/TextField';
import { usePracticeForm } from './context';

const PracticeCompanyRegField = () => {
  const {
    form: { formik, helpers },
  } = usePracticeForm();

  const { values, handleChange, handleBlur } = formik;

  return (
    <TextField
      fullWidth
      id="practice.companyRegNumber"
      name="practice.companyRegNumber"
      label="REG Number"
      value={values.practice.companyRegNumber ?? ''}
      onChange={handleChange}
      onBlur={handleBlur}
      error={helpers.hasError('practice.companyRegNumber')}
      helperText={helpers.getErrorHelpText('practice.companyRegNumber')}
    />
  );
};

export default PracticeCompanyRegField;
