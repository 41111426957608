import Page from 'modules/Page';
import { TITLE } from './constants';

const PageNotFoundPage = () => (
  <Page pageType="standard" title={TITLE}>
    404 Page Not Found
  </Page>
);

export default PageNotFoundPage;
