import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { styled, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AppointmentDto, MessageDto } from 'providers/api';
import { useTeam } from 'providers/team';
import React from 'react';
import { DEFAULT_SHORT_DATE_TIME_CONFIG, displayTemporal, px } from 'utils';
import NotificationListItem from './NotificationListItem';

export const NOTIFICATIONS_DRAWER_WIDTH = 420;
export type NotificationsDrawerContext = 'events' | 'errors';
export type NotificationsDrawerDataObject = {
  title: string,
  notifications: JSX.Element[],
}
export type NotificationsData = Record<NotificationsDrawerContext, NotificationsDrawerDataObject>;

const TITLES: Record<NotificationsDrawerContext, string> = {
  events: 'Recent Updates',
  errors: 'Appointment Errors',
};

const DrawerHeader = styled('div')(
  ({ theme }) => ({
    background: theme.palette.primary.main,
    // match app bar height
    ...theme.mixins.toolbar,
  }),
);

const openedMixin = (theme: Theme) => ({
  width: px(NOTIFICATIONS_DRAWER_WIDTH + 1),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme) => ({
  width: 0,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const Drawer = styled(MuiDrawer)(
  ({ theme, open }) => ({
    ...(open && openedMixin(theme)),
    ...(!open && closedMixin(theme)),
    flexShrink: 0,
    zIndex: theme.zIndex.drawer - 1,
    '& .MuiDrawer-paper': {
      overflowX: 'hidden',
      background: theme.palette.sideMenu.main,
      border: 0,
      boxShadow: theme.shadows[2],
      width: NOTIFICATIONS_DRAWER_WIDTH,
    },
  }),
);

interface NotificationsProp {
  events: MessageDto[],
  errors: AppointmentDto[],
  closeMenu: () => void;
  onArchiveAllMessage: () => void;
  state?: NotificationsDrawerContext;
}

const Notifications = ({ events, errors, closeMenu, onArchiveAllMessage, state }: NotificationsProp) => {
  const { state: { activeTeam } } = useTeam();

  return (
    <Drawer anchor="right" variant="persistent" open={!!state} onClose={closeMenu} hideBackdrop>
      <DrawerHeader sx={{ position: 'fixed', zIndex: (theme) => theme.zIndex.drawer + 1, width: NOTIFICATIONS_DRAWER_WIDTH, height: 64, paddingRight: 1 }}>
        <Stack direction="row" display="flex" alignItems="center" height="100%">
          <IconButton size="large" sx={{ marginLeft: 1 }} onClick={() => closeMenu()}>
            <ChevronRightIcon />
          </IconButton>
          <Box
            sx={{
              flex: 2,
              textAlign: 'center',
            }}
          >
            {state && <Typography variant="h6" sx={{ color: (theme) => theme.palette.background.paper }}>{TITLES[state]}</Typography>}
          </Box>
          {
            state === 'events'
            ? (
              <IconButton size="large" sx={{ marginLeft: 1 }} onClick={() => onArchiveAllMessage()}>
                <ClearAllIcon />
              </IconButton>
            ) : <Box width={51} height={51} />
          }
        </Stack>
      </DrawerHeader>
      <DrawerHeader />
      <Divider />

      {/* // TODO: Message title should come from the backend */}
      <List sx={{ width: '100%', maxWidth: NOTIFICATIONS_DRAWER_WIDTH, margin: 0, padding: 0 }}>
        {
          state === 'errors' && errors.map((item) => (
            <NotificationListItem
              key={item.entityId}
              title="Urgent unconfirmed appointment"
              primaryDescription={`${item.patient.name}: ${item.appointmentSummary}`}
              actionPath={`${activeTeam?.teamId ?? ''}/calendar/${item.entityId}/details`}
            />
          ))
        }

        {
          state === 'events' && events.map((item) => (
            <NotificationListItem
              key={item.entityId}
              title="ETA Update"
              primaryDescription={`${displayTemporal(item.sent, DEFAULT_SHORT_DATE_TIME_CONFIG)}: ${item.body}`}
            />
          ))
        }
      </List>
      <Divider />
    </Drawer>
  );
};

export default Notifications;
