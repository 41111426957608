import { EventResizeDoneArg } from '@fullcalendar/interaction';
import { DayHeaderContentArg, EventDropArg } from '@fullcalendar/react';
import { ResourceLabelContentArg } from '@fullcalendar/resource-common';
import {
  Duration,
  Instant, LocalDate, LocalTime, nativeJs, ZonedDateTime, ZoneId
} from '@js-joda/core';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Tooltip } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { HOUR_IN_MILLISECONDS } from 'config';
import AppointmentCalendar from 'features/AppointmentCalendar';
import { mapAppointmentToEvents, mapTimeBlockToEvents } from 'features/AppointmentCalendar/eventHelpers';
import { EventInputWithType } from 'features/AppointmentCalendar/interfaces';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { useNewAppointmentNavigate } from 'modules/practice/calendar/NewAppointmentPage/useNewAppointment';
import usePublishAppointments from 'modules/practice/calendar/usePublishAppointments';
import {
  AppointmentStatus,
  useAppointmentsQuery,
  useCalendarEventsQuery,
  useUpdateAppointmentTimeAndVetMutation,
  useVetsQuery
} from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_MONTHVIEW_CALENDAR_CONFIG, displayTemporal } from 'utils';

export type AppointmentEditDetails = {
  appointmentId: string;
  startTime: Date,
  endTime: Date,
  vetId?: string;
};

const isOneDayAhead = (date: Date) => ZonedDateTime.from(nativeJs(date)).isAfter(ZonedDateTime.now().withHour(0).withMinute(0));

type AppointmentResourceLabelProps = {
  content: ResourceLabelContentArg,
  onPublish: (appointmentsToPublish: string[]) => void,
  appointments: EventInputWithType[],
  isDayView: boolean,
}

const AppointmentResourceLabel = ({
  content,
  onPublish,
  appointments,
  isDayView,
}: AppointmentResourceLabelProps) => {
  const navigate = useNavigate();
  const vetSpecificAppointments = appointments.filter((appointment) => appointment.resourceId === content.resource.id);
  const draftAppointmentsObjectArray = vetSpecificAppointments.filter((appointment) => appointment.extendedProps.status === AppointmentStatus.Draft
    && appointment.id !== undefined);
  const draftAppointmentIdsArray = draftAppointmentsObjectArray.map((draftAppointment) => draftAppointment.id!) ?? [];

  return (
    isDayView
      ? (
        <Stack display="flex" flexDirection="row">
          <Box mr={3}>
            <Typography variant="h6">{content.resource.title}</Typography>
          </Box>
          <Tooltip title="Publish all draft appointments for this day">
            <IconButton
              aria-label="Publish all draft appointments for this day "
              size="small"
              onClick={() => onPublish(draftAppointmentIdsArray)}
            >
              <EventAvailableIcon />
            </IconButton>
          </Tooltip>

          {content && content.date && isOneDayAhead(content.date)
            && (
              <Tooltip title="Optimise the vet schedule">
                <IconButton
                  aria-label="Optimise the vet schedule"
                  onClick={() => navigate(`scheduler/${content.resource.id}/${content.date!.getDate()}/${content.date!.getMonth()}/${content.date!.getFullYear()}`)}
                  size="small"
                >
                  <EditLocationAltIcon />
                </IconButton>
              </Tooltip>
            )}
        </Stack>
      )
      : (
        <Stack display="flex" flexDirection="row">
          <Box mr={3}>
            <Typography variant="h6">{content.resource.title}</Typography>
          </Box>
          <Tooltip title="Publish all draft appointments for this period">
            <IconButton
              aria-label="Publish all draft appointments"
              size="small"
              onClick={() => onPublish(draftAppointmentIdsArray)}
            >
              <EventAvailableIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )
  );
};

type AppointmentDateLabelProps = {
  content: DayHeaderContentArg,
  isDayView: boolean,
}

const AppointmentDateLabel = ({ content, isDayView }: AppointmentDateLabelProps) => {
  const navigate = useNavigate();
  return !isDayView
    ? (
      <Stack
        sx={{ height: '34px' }} // Height 34px is necessary to match height of FullCalendar Header row, otherwise results alignment issues.
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <Box>
          <Typography variant="body2">{`${displayTemporal(content.date, DEFAULT_MONTHVIEW_CALENDAR_CONFIG)}`}</Typography>
        </Box>
        {isOneDayAhead(content.date) && (
          <Box sx={{ ml: 2 }}>
            <Tooltip title="Optimise the vet schedule">
              <IconButton
                aria-label="Optimise the vet schedule"
                onClick={() => navigate(`scheduler/${content.resource.id}/${content.date!.getDate()}/${content.date!.getMonth()}/${content.date!.getFullYear()}`)}
                size="small"
              >
                <EditLocationAltIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Stack>
    )
    : null;
};

const CalendarPage = () => {
  const navigate = useNavigate();
  const confirm = useConfirmation();
  const newAppointmentNavigate = useNewAppointmentNavigate();
  const [isDayView, setIsDayView] = React.useState(true);
  const { teamId } = useProtectedParams('teamId');
  const viewType = 'resourceTimeGrid';
  const { publishAppointments } = usePublishAppointments(teamId);
  const [calendarConstraints, setCalendarConstraints] = React.useState({
    startDate: ZonedDateTime.of(LocalDate.now(), LocalTime.MIDNIGHT, ZoneId.systemDefault()),
    endDate: ZonedDateTime.of(LocalDate.now(), LocalTime.MIDNIGHT, ZoneId.systemDefault()).plusDays(1),
  });
  const [currentAppointmentUpdateDetails, setCurrentAppointmentUpdateDetails] = React.useState<AppointmentEditDetails>();
  const updateAppointmentDatetimeMutation = useUpdateAppointmentTimeAndVetMutation(currentAppointmentUpdateDetails?.appointmentId ?? '');

  const { data: practiceVets } = useVetsQuery({ page: 1, pageSize: 100, teamId });

  const {
    data: appointments,
  } = useAppointmentsQuery(
    {
      practiceId: teamId,
      startDateUtc: Instant.from(calendarConstraints.startDate),
      endDateUtc: Instant.from(calendarConstraints.endDate),
    },
    {
      staleTime: HOUR_IN_MILLISECONDS,
      // lift the loading overlay and stop filtering out the last edited appointment
      // Appointment event card glitches from old to new location if we allow it to show while loading appointments
      onSuccess: () => setCurrentAppointmentUpdateDetails(undefined),
    },
  );

  const appointmentCalendarEvents = appointments && appointments.length > 0
    ? mapAppointmentToEvents(appointments, false)
    : [];

  const {
    data: calendarEvents,
  } = useCalendarEventsQuery(
    {
      practiceId: teamId,
      startDate: calendarConstraints.startDate,
      endDate: calendarConstraints.endDate,
    },
    {
      staleTime: HOUR_IN_MILLISECONDS,
    },
  );

  const timeBlockEvents: EventInputWithType[] = calendarEvents
    ? mapTimeBlockToEvents(calendarEvents, true, isDayView, viewType)
    : [];

  // placeholder new event for when the mutation is still going through
  const addPlaceholderEvent = (appointmentId: string) => {
    if (appointmentCalendarEvents && currentAppointmentUpdateDetails) {
      return appointmentCalendarEvents.map((event) => ((event.id === appointmentId)
        ? {
          ...event,
          startTime: currentAppointmentUpdateDetails.startTime,
          endTime: currentAppointmentUpdateDetails.endTime,
          vet: {
            entityId: currentAppointmentUpdateDetails.vetId ?? '',
            name: '',
          },
        }
        : event));
    }
    return [];
  };

  const appointmentCalendarEventsWithPlaceholder: EventInputWithType[] = !currentAppointmentUpdateDetails
    ? appointmentCalendarEvents
    : addPlaceholderEvent(currentAppointmentUpdateDetails.appointmentId);

  const handleDateClick = (date: Date, vetId: string) => {
    newAppointmentNavigate('new', { date, vetId });
  };

  const handleAppointmentClick = (appointmentId: string) => navigate(appointmentId);

  const handleCalendarConstraintsChange = (startDate: Date, endDate: Date) => {
    const startDateZonedDateTime = ZonedDateTime.from(nativeJs(startDate));
    const endDateZonedDateTime = ZonedDateTime.from(nativeJs(endDate));

    const constraintDuration = Duration.between(startDateZonedDateTime, endDateZonedDateTime);
    setIsDayView(constraintDuration.toDays() === 1);

    setCalendarConstraints({
      startDate: startDateZonedDateTime,
      endDate: endDateZonedDateTime,
    });
  };

  const handleUpdateAppointmentDatetimeSuccess = (event: any) => {
    const currentAppointment = appointments?.find((appointment) => appointment.entityId === event.event.id);
    if (!currentAppointment) return;

    if (event.event.start && event.event.end) {
      const payload = {
        appointmentId: currentAppointment.entityId,
        startTime: event.event.start,
        endTime: event.event.end,
        vetId: event.newResource ? event.newResource.id : undefined,
      };

      setCurrentAppointmentUpdateDetails(payload);
    }
  };

  const handleConfirmEditAppointment = (event: EventDropArg | EventResizeDoneArg) => {
    confirm({
      catchOnCancel: true,
      variant: 'danger',
      description: 'Are you sure you want to update the appointment?',
    })
      .then(() => handleUpdateAppointmentDatetimeSuccess(event))
      .catch(() => event.revert());
  };

  React.useEffect(() => {
    if (currentAppointmentUpdateDetails) {
      updateAppointmentDatetimeMutation.mutate(
        {
          startTime: ZonedDateTime.from(nativeJs(currentAppointmentUpdateDetails.startTime)),
          endTime: ZonedDateTime.from(nativeJs(currentAppointmentUpdateDetails.endTime)),
          vetId: currentAppointmentUpdateDetails.vetId,
        },
      );
    }
  }, [currentAppointmentUpdateDetails]);

  return (
    <Page pageType="full" title="Calendar">
      <Paper sx={{ p: 2, mb: 2, height: '100%' }}>
        <AppointmentCalendar
          viewType={viewType}
          appointmentCalendarEvents={appointmentCalendarEventsWithPlaceholder}
          timeBlockEvents={timeBlockEvents}
          dateClick={handleDateClick}
          eventClick={(targetAppointment) => handleAppointmentClick(targetAppointment)}
          onDateChange={handleCalendarConstraintsChange}
          onAppointmentEdit={handleConfirmEditAppointment}
          vets={practiceVets?.items}
          renderResourceLabelContent={(resourceLabelContent) => (
            <AppointmentResourceLabel
              content={resourceLabelContent}
              onPublish={(ids: string[]) => publishAppointments(ids)}
              isDayView={isDayView}
              appointments={appointmentCalendarEventsWithPlaceholder}
            />
          )}
          renderDateLabelContent={(dateLabelContent) => (
            <AppointmentDateLabel
              content={dateLabelContent}
              isDayView={isDayView}
            />
          )}
        />
      </Paper>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!!currentAppointmentUpdateDetails}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>

  );
};
export default CalendarPage;
