import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { GoogleMap, Marker } from 'components/GoogleMap';
import Loading from 'components/Loading';
import TitleBox from 'components/TitleBox';
import TitleDisplayStack from 'components/TitleDisplayStack';
import { INITIAL_ZOOM_FOR_UK, LONDON_CENTER } from 'config';
import AppointmentStatusChip from 'features/AppointmentStatusChip';
import VetStatusChip from 'features/VetStatusChip';
import {
  AppointmentPriority,
  AppointmentStatus, AppointmentType, usePublicAppointmentLocationGetQuery, usePublicAppointmentQuery, usePublicConfirmAppointmentMutation, withQueryClientDefaults
} from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import { useParams } from 'react-router-dom';
import { DEFAULT_SHORT_DATE_TIME_CONFIG, displayTemporal, splitCaps } from 'utils';
import Layout from '../Layout';

const PublicConfirmAppointmentPage = () => {
  const { appointmentId, accessTokenHash } = useParams();

  const confirm = useConfirmation();

  const { data: publicAppointment, isLoading: publicAppointmentIsLoading } = usePublicAppointmentQuery({
    appointmentId: appointmentId ?? '',
    accessTokenHash: accessTokenHash ?? '', // test by creating an appointment and checking server log - hashkey is generated here for testing.
  });

  const { data: publicAppointmentLocation, isLoading: publicAppointmentLocationIsLoading } = usePublicAppointmentLocationGetQuery({
    appointmentId: appointmentId ?? '',
    accessTokenHash: accessTokenHash ?? '',
  });

  const publicConfirmAppointmentMutation = usePublicConfirmAppointmentMutation(appointmentId ?? '');

  const handlePublicConfirmAppointment = () => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want to confirm this appointment?',
    }).then(() => publicConfirmAppointmentMutation.mutate({ accessTokenHash: accessTokenHash ?? '' }));
  };

  if (publicAppointmentIsLoading) {
    return <Loading />;
  }

  if (!publicAppointment) {
    return (
      <Layout title="Confirm Your Appointment">
        <Alert severity="error"> This link has expired. Please contact the practice. </Alert>
      </Layout>
    );
  }

  return (
    <Layout title="Confirm Your Appointment">
      <Box width="100%">
        <Stack spacing={1} width="100%">
          {publicAppointmentLocation
            && (
              <Box height={300} sx={{ pb: 2 }}>
                <GoogleMap
                  disableDefaultUI
                  zoomControl
                  draggableCursor="default"
                  zoom={publicAppointmentLocation ? 18 : INITIAL_ZOOM_FOR_UK}
                  center={{
                    lat: publicAppointmentLocation?.geoPoint.latitude ?? LONDON_CENTER.lat,
                    lng: publicAppointmentLocation?.geoPoint.longitude ?? LONDON_CENTER.lng,
                  }}
                >

                  <Marker
                    position={{
                      lat: publicAppointmentLocation.geoPoint.latitude,
                      lng: publicAppointmentLocation.geoPoint.longitude,
                    }}
                    selected
                  />
                </GoogleMap>
              </Box>
            )}
          {
            !publicAppointmentLocationIsLoading && !publicAppointmentLocation
            && (
              <Box sx={{ pb: 2, pt: 1 }}>
                <Alert severity="error">
                  No location has been provided by the practice. Please contact the practice to arrange a location before confirming your appointment.
                </Alert>
              </Box>
            )
          }
          <Box>
            <TitleBox title="Schedule">
              <TitleDisplayStack
                title="Appointment status"
                display={(
                  <Stack direction="row" spacing={1}>
                    <AppointmentStatusChip appointmentStatus={publicAppointment.appointmentStatus} />
                    <VetStatusChip vetStatus={publicAppointment.etaStatus} />
                  </Stack>
                )}
              />
              <Divider sx={{ my: 1 }} />
              <TitleDisplayStack
                title="Date and times"
                display={
                  (
                    <Typography variant="body2">
                      {`${displayTemporal(publicAppointment.startTime, DEFAULT_SHORT_DATE_TIME_CONFIG)} -
                        ${displayTemporal(publicAppointment.endTime, DEFAULT_SHORT_DATE_TIME_CONFIG)}`}
                    </Typography>
                  )
                }
              />
              <Divider sx={{ my: 1 }} />
              <TitleDisplayStack
                title="Appointment Details"
                display={
                  (
                    <Typography variant="body2">
                      {publicAppointment
                        && `${splitCaps(AppointmentType[publicAppointment.appointmentType])},
                    ${splitCaps(AppointmentPriority[publicAppointment.appointmentPriority])}`}
                    </Typography>
                  )
                }
              />
            </TitleBox>
          </Box>

          <Box>
            <TitleBox title="Contact Details and Patient">
              <TitleDisplayStack
                title="Contact"
                display={(
                  <Stack>
                    <Typography display="inline" variant="body2">
                      {`${publicAppointment.contactDetails?.firstName} ${publicAppointment.contactDetails?.lastName}`}
                    </Typography>
                    <Typography display="inline" variant="body2">
                      {publicAppointment.contactDetails?.email}
                    </Typography>
                    <Typography display="inline" variant="body2">
                      {publicAppointment.contactDetails?.contactNumber}
                    </Typography>
                  </Stack>
                )}
              />
              <Divider sx={{ my: 1 }} />
              <TitleDisplayStack
                title="Patient"
                display={<Typography variant="body2">{publicAppointment.patient.name}</Typography>}
              />
              <Divider sx={{ my: 1 }} />
              <TitleDisplayStack
                title="Vet"
                display={
                  <Typography variant="body2">{publicAppointment.vet.name}</Typography>
                }
              />
            </TitleBox>
          </Box>

          <Box>
            {publicAppointment.appointmentStatus === AppointmentStatus.Confirmed
              ? (
                <Button
                  fullWidth
                  variant="contained"
                  disabled
                >
                  Appointment Confirmed
                </Button>
              )
              : (
                <Tooltip title={
                  !publicAppointmentLocation
                    ? 'Please arrange a location with the practice before confirming your appointment'
                    : ''
                }
                >
                  <Box>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => handlePublicConfirmAppointment()}
                      disabled={!publicAppointmentLocation}
                    >
                      Confirm Appointment Details
                    </Button>
                  </Box>
                </Tooltip>
              )}
          </Box>
        </Stack>
      </Box>
    </Layout>
  );
};

export default withQueryClientDefaults(PublicConfirmAppointmentPage);
