import React from 'react';
// TODO: Move to components and potentially use animation frame
interface IncrementalDisplayProps {
  callback: any,
  delay: any,
}

const IncrementalDisplay = ({
  callback,
  delay,
}: IncrementalDisplayProps) => {
  const [timer, setTimer] = React.useState<ReturnType<typeof setInterval>>();
  const [displayValue, setDisplayValue] = React.useState('');

  const intervalCallback = () => setDisplayValue(callback());

  React.useEffect(() => {
    setTimer(
      setInterval(intervalCallback, delay),
    );
    return () => timer && clearInterval(timer);
  }, []);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{displayValue}</>;
};

export default IncrementalDisplay;
