import { useContext } from 'react';
import TeamContext, { ITeamContext } from './context';

export default function useTeam(): ITeamContext {
  const context = useContext(TeamContext);
  if (context === null) {
    throw new Error('No team context available');
  }
  return context;
}
