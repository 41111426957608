import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import EditableSection from 'components/EditableSection';
import { FormStack } from 'components/Form';
import { ValidatedLocationDto } from 'providers/api';
import { UpdateValidatedLocationDescriptionMutation } from 'providers/api/useValidatedLocations';
import useDefaultForm from 'providers/form/useDefaultForm';
import { UseMutationResult } from 'react-query';
import { object, SchemaOf, string } from 'yup';

const validatedLocationSchema: SchemaOf<UpdateValidatedLocationDescriptionMutation> = object().shape({
  validatedLocationId: string().required('A validated location Id is required'),
  teamId: string().required('A team Id is required'),
  request: object().shape({
    description: string().required('A description is required.'),
  }),
});

interface ValidatedLocationInfoFormProps {
  validatedLocation: ValidatedLocationDto;
  readOnly?: boolean;
  mutation: UseMutationResult<string, unknown, UpdateValidatedLocationDescriptionMutation, unknown>;
}
const ValidatedLocationInfoForm = ({ validatedLocation, readOnly = false, mutation }: ValidatedLocationInfoFormProps) => {
  const {
    formik,
    helpers,
  } = useDefaultForm<UpdateValidatedLocationDescriptionMutation>({
    mutation,
    formikConfig: {
      initialValues: {
        validatedLocationId: validatedLocation.entityId,
        teamId: validatedLocation.clientId,
        request: {
          description: validatedLocation.description,
        },
      },
      onSubmit: (form, { setSubmitting }) => {
        setSubmitting(true);

        mutation.mutate(form, {
          onSettled: () => {
            setSubmitting(false);
          },
        });
      },
      validationSchema: validatedLocationSchema,
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  return (
    <EditableSection
      title={readOnly ? 'Details' : 'Edit details'}
      mutation={mutation as any}
      formik={formik as any}
      small
      enabled={!readOnly}
    >
      {(editMode) => (
        <Box>
          {
            editMode
              ? (
                <form onSubmit={handleSubmit}>
                  <FormStack>
                    <TextField
                      fullWidth
                      id="request.description"
                      name="request.description"
                      label="Description"
                      value={values.request.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={helpers.hasError('request.description')}
                      helperText={helpers.getErrorHelpText('request.description')}
                      size="small"
                      multiline
                      rows={3}
                    />
                  </FormStack>
                </form>
              )
              : (
                <Box>
                  <Typography variant="body2">{validatedLocation.description}</Typography>
                </Box>
              )
          }
        </Box>
      )}
    </EditableSection>
  );
};

export default ValidatedLocationInfoForm;
