import { useDeletePatientMutation, usePatientQuery } from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const usePatientData = (patientId: string) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const { data: patient } = usePatientQuery(patientId, {
    enabled: !isDeleting,
  });

  const deleteMutation = useDeletePatientMutation();

  const navigate = useNavigate();
  const confirm = useConfirmation();

  const handleDelete = () => {
    patient && confirm({
      variant: 'danger',
      description: `Are you sure you want to delete ${patient.name}?`,
    })
      .then(() => {
        setIsDeleting(true);
        deleteMutation.mutate(patient, {
          onSuccess: () => {
            navigate('../');
            setIsDeleting(false);
          },
        });
      });
  };
  return { patient, handleDelete };
};

export default usePatientData;
