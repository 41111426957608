/* global google */

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { MarkerIconTypes, MarkerProps } from 'components/GoogleMap/Marker';
import MapDashboardPage from 'features/MapDashboard';
import { ValidatedLocationsState } from 'features/ValidatedLocations/validatedLocation.context';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { GeoPointDto } from 'providers/api';
import { useValidatedLocationsQuery } from 'providers/api/useValidatedLocations';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TITLE as parentTitle } from '../ClientsPage';
import { TITLE as sectionTitle } from './constants';

const selectMarkerType = (
  validatedLocationId: string,
  selectedLocationId: string | undefined,
  highlightedLocationId: string | undefined,
): MarkerIconTypes => {
  if (validatedLocationId === selectedLocationId) return 'location';
  if (validatedLocationId === highlightedLocationId) return 'vetOutdated';
  return 'vet';
};

const ClientLocationsPage = () => {
  const navigate = useNavigate();
  const { teamId, clientId } = useProtectedParams('teamId', 'clientId');
  const { locationId } = useParams();

  const [highlightedLocationId, setHighlightedLocationId] = React.useState<string>();
  const [selectedLocationId, setSelectedLocationId] = React.useState<string>();
  const [selectedGeoPoint, setSelectedGeoPoint] = React.useState<GeoPointDto>();
  const [showSelectedGeoPoint, setShowSelectedGeoPoint] = React.useState(false);
  const { data: validatedLocations } = useValidatedLocationsQuery({ teamId, clientId });

  const validatedLocationMarkerData: MarkerProps[] = React.useMemo(
    () => (validatedLocations
      ? validatedLocations.map((validatedLocation) => (
        {
          key: validatedLocation.entityId,
          position: {
            lat: validatedLocation.geoPoint.latitude,
            lng: validatedLocation.geoPoint.longitude,
          },
          selected: true,
          iconType: selectMarkerType(validatedLocation.entityId, selectedLocationId, highlightedLocationId),
          info: validatedLocation.description,
          onClick: () => navigate(validatedLocation.entityId),
          onMouseOver: () => setHighlightedLocationId(validatedLocation.entityId),
          onMouseOut: () => setHighlightedLocationId(undefined),
        }
      ))
      : []
    ),
    [locationId, validatedLocations, highlightedLocationId, selectedLocationId],
  );

  const tempMarkerData = (geoPoint: GeoPointDto | undefined, showSelectedLocation: boolean): MarkerProps[] => (
    geoPoint && showSelectedLocation
      ? [{
        position: { lat: geoPoint.latitude, lng: geoPoint.longitude },
        selected: true,
        iconType: 'vetOutdated',
      }]
      : []
  );

  const handleLocationSelect = (latLng: google.maps.LatLngLiteral) => setSelectedGeoPoint({ latitude: latLng.lat, longitude: latLng.lng });

  const locationPanelActions = (): [string, JSX.Element][] => [
    [
      'Close',
      <IconButton onClick={() => navigate('./')}><CloseIcon /></IconButton>,
    ],
  ];

  const validatedLocationState: ValidatedLocationsState = {
    highlightedValidatedLocationId: highlightedLocationId,
    selectedValidatedLocationId: selectedLocationId,
    validatedLocations: validatedLocations ?? [],
    setHighlightedValidatedLocationId: setHighlightedLocationId,
    setShowSelectedGeoPoint,
    setSelectedValidatedLocationId: setSelectedLocationId,
  };

  return (
    <Page pageType="full" title={sectionTitle} parentRelativePath="../../.." parentTitle={parentTitle}>
      <MapDashboardPage
        markerData={[...validatedLocationMarkerData, ...tempMarkerData(selectedGeoPoint, showSelectedGeoPoint)]}
        title={locationId ? 'Location' : 'Client Locations'}
        actions={locationId ? locationPanelActions() : undefined}
        contextData={validatedLocationState}
        onMapClick={handleLocationSelect}
      />
    </Page>
  );
};

export default ClientLocationsPage;
