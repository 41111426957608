// INFO: Full calendar requires specific import ordering
// automatic import ordering causes the plugins to be defined before Full Calendar
import FullCalendar from './importCalendar';
import {
  dayGridPlugin,
  interactionPlugin,
  resourceTimeGridPlugin,
  resourceTimelinePlugin,
  scrollGridPlugin,
  timeGridPlugin
} from './importsPlugins';

export default FullCalendar;

export {
  dayGridPlugin,
  interactionPlugin,
  resourceTimeGridPlugin,
  scrollGridPlugin,
  timeGridPlugin,
  resourceTimelinePlugin,
};
