import {
  compose,
  concat,
  converge,
  head,
  join,
  map,
  replace,
  split,
  tail,
  toUpper
} from 'ramda';
import short from 'short-uuid';

export const capitalize = converge(
  concat,
  [
    compose(
      toUpper,
      head,
    ),
    tail,
  ],
);

export const toTitleCase = compose(
  join(' '),
  map(capitalize),
  split(' '),
);

export const resolveTemplate = (template: string, variables: { [key: string]: string | number }) => Object.keys(variables)
  .reduce((accResolvedTemplate, variableKey) => replace(
    `${variableKey}`,
    `${variables[variableKey]}`,
    accResolvedTemplate,
  ), template);

export const splitCaps = (currentString: string) => currentString.replace(/([A-Z])/g, ' $1').trim();

export const isNumeric = (str: string) => !Number.isNaN(str) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  && !Number.isNaN(Number(str)); // ...and ensure strings of whitespace fail

export const px = (number: number) => `${number}px`;

export const initials = (value: string) => value.split(' ').map((word) => word[0]).join('');

// alphabet matches the chars used in CompactGuid.cs
export const compactGuid = short('0123456789abcdefghjkmnpqrstvwxyz');

export const isValidCompactGuid = (testGuid: string) => {
  try {
    compactGuid.toUUID(testGuid);
  } catch {
    return false;
  }

  return true;
};

export const truncate = (string: string, characterLengthIncString: number) => {
  if (string.length > characterLengthIncString) {
     if (characterLengthIncString <= 3) {
        return `${string.slice(0, characterLengthIncString - 3)}...`;
     }

        return `${string.slice(0, characterLengthIncString)}...`;
  }

     return string;
};
