import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import { IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TitleBox from 'components/TitleBox';
import TitleDisplayStack from 'components/TitleDisplayStack';
import { AppointmentDetailsDto, EntitySummaryDto, UserDto } from 'providers/api';
import React from 'react';
import ClientUserAutocomplete from './ClientUserAutocomplete';

interface AppointmentAttendeesEditableSectionProps {
  appointment: AppointmentDetailsDto;
  onAddAttendee: (attendeeId: string) => void;
  onRemoveAttendee: (attendee: EntitySummaryDto) => void;
}

const AppointmentAttendeesEditableSection = ({ appointment, onAddAttendee, onRemoveAttendee }: AppointmentAttendeesEditableSectionProps) => {
  const [selectedUser, setSelectedUser] = React.useState<UserDto | null>(null);
  const attendeeIds = appointment.attendees.map((attendee) => attendee.entityId);

  const handleAddAttendee = () => {
    if (selectedUser) {
      onAddAttendee(selectedUser.userId);
      setSelectedUser(null);
    }
  };

  return (
    <TitleBox
      title="Appointment Attendees"
      actions={(
        <Stack direction="row">
          <ClientUserAutocomplete
            clientId={appointment.clientId}
            onUserSelect={setSelectedUser}
            selectedUser={selectedUser}
            unavailableUsers={attendeeIds}
          />
          <IconButton onClick={() => handleAddAttendee()}>
            <AddIcon />
          </IconButton>
        </Stack>
      )}
    >
      <Box>
        <List sx={{ width: '100%' }}>
          {(!appointment.attendeeDetails || appointment.attendeeDetails.length === 0) && (
            <ListItem>
              <ListItemText primary="No additional attendees." />
            </ListItem>
          )}
          {appointment && appointment.attendees.map((attendee, index) => (
            <ListItem
              key={attendee.entityId}
              secondaryAction={(
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onRemoveAttendee(attendee)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            >
              <ListItemAvatar>
                <Avatar>
                  {/* TODO: Switch icon depending on the type of client user */}
                  <ImageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={
                appointment.attendeeDetails && appointment.attendeeDetails[index]
                  ? `${appointment.attendeeDetails[index].forename} ${appointment.attendeeDetails[index].surname} - ${appointment.attendeeDetails[index].email}`
                  : attendee.name
              }
              />
            </ListItem>
          ))}
        </List>

        {appointment.contactDetails && (
          <>
            <Divider sx={{ my: 3 }} />
            <TitleDisplayStack
              title="Temporary Contact Details"
              display={(
                <Stack>
                  <Typography variant="body2">{`${appointment?.contactDetails.firstName} ${appointment?.contactDetails.lastName}`}</Typography>
                  <Typography variant="body2">{appointment?.contactDetails.contactNumber}</Typography>
                  <Typography variant="body2">{appointment?.contactDetails.email}</Typography>
                </Stack>
              )}
            />
            <Divider sx={{ my: 3 }} />
          </>
        )}
      </Box>
    </TitleBox>
  );
};

export default AppointmentAttendeesEditableSection;
