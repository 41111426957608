import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Address from 'components/Address';
import { AUTOCOMPLETE_PAGE_SIZE, HOUR_IN_MILLISECONDS } from 'config';
import useProtectedParams from 'hooks/useProtectedParams';
import { ClientDto, useClientsQuery } from 'providers/api';
import React from 'react';
import { useDebounce } from 'use-debounce';
import { useAppointmentForm } from './context';

const ClientField = () => {
  const { teamId } = useProtectedParams('teamId');
  const { form: { formik, helpers } } = useAppointmentForm();
  const { handleBlur, setFieldValue, values } = formik;
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchTermDebounced] = useDebounce(searchTerm, 500);

  const { data: clients } = useClientsQuery({
    page: 1,
    pageSize: AUTOCOMPLETE_PAGE_SIZE,
    teamId,
    clientName: searchTermDebounced,
  }, {
    enabled: true,
    staleTime: HOUR_IN_MILLISECONDS,
  });

  const selectedClient = (clients?.items ?? []).find((client: ClientDto) => client.entityId === values.clientId) ?? null;

  return (
    <Autocomplete
      fullWidth
      options={clients?.items || []}
      getOptionLabel={(option: ClientDto) => option.name}
      value={selectedClient}
      onChange={(event, newClient) => {
        setFieldValue('clientId', newClient?.entityId ?? '');
      }}
      inputValue={searchTerm}
      onInputChange={(event, newInputValue) => {
        setSearchTerm(newInputValue);
      }}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.entityId}>
          <Typography>
            {`${option.name}:`}
          </Typography>
          <Box mr={1} display="inline" />
          <Address address={option.site} type="inline" typographyVariant="body2" />
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          id="clientId"
          name="clientId"
          label="Client"
          variant="outlined"
          onBlur={handleBlur}
          error={helpers.hasError('clientId')}
          helperText={helpers.getErrorHelpText('clientId')}
        />
      )}
    />
  );
};

export default ClientField;
