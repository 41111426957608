import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';

interface ResponsiveNavButtonProps {
  path: string;
  label: string;
  Icon: React.ReactNode;
}

const ResponsiveNavButton = ({ path, label, Icon }: ResponsiveNavButtonProps) => (
  <>
    <Hidden smDown>
      <Button
        aria-label={`navigation button for ${label}`}
        variant="contained"
        color="primary"
        size="large"
        startIcon={Icon}
        component={Link}
        to={path}
      >
        {label}
      </Button>
    </Hidden>
    <Hidden smUp>
      <IconButton
        aria-label={`navigation button for ${label}`}
        color="primary"
        component={Link}
        to={path}
      >
        { Icon }
      </IconButton>
    </Hidden>
  </>
);

export default ResponsiveNavButton;
