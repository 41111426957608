import { Wrapper, WrapperProps } from '@googlemaps/react-wrapper';
import { GOOGLE_MAP_API_KEY } from 'config';
import Map from './Map';
import MapProps from './MapProps';
import { RouteRequest } from './useDirectionRenderer';

interface GoogleMapProps extends MapProps {
  search?: boolean;
  routes?: RouteRequest[];
  render?: WrapperProps['render'];
}

const GoogleMap = ({
  search = false,
  routes,
  render,
  ...props
}: GoogleMapProps) => (
  <Wrapper apiKey={GOOGLE_MAP_API_KEY} render={render} libraries={['places']}>
    <Map style={{ height: '100%', width: '100%', position: 'relative' }} {...props} mapTypeControl search={search} routes={routes} />
  </Wrapper>
);

export default GoogleMap;
