import { validation as addressFormValidation } from 'components/AddressFormFields';
import { FormikConfig } from 'formik';
import { PracticeDto, UpdatePracticeCommand, useUpdatePracticeMutation } from 'providers/api';
import { object, SchemaOf, string } from 'yup';
import { PracticeFormProvider } from './context';

const PracticeSchema: SchemaOf<UpdatePracticeCommand> = object().shape({
  practice: object().shape({
    practiceName: string().required('Practice Name is required'),
    registeredCompanyName: string().notRequired(),
    companyRegNumber: string().notRequired(),
    companyVatNumber: string().notRequired(),
    mainContactNumber: string().notRequired(),
  }),
  site: object().shape({
    siteAddress: addressFormValidation.notRequired().default(undefined),
  }),
  contact: object().shape({
    practiceContact: object().shape({
      firstName: string().required('First Name is required'),
      lastName: string().required('Last Name is required'),
      email: string().email('Email is not valid').required('Email is required'),
      contactNumber: string().required('Contact number is not valid'),
    }).notRequired().default(undefined),
  }),
  billing: object().shape({
    billingAddress: addressFormValidation.notRequired().default(undefined),
    billingContact: object().shape({
      firstName: string().required('First Name is required'),
      lastName: string().required('Last Name is required'),
      email: string().email('Email is not valid').required('Email is required'),
      contactNumber: string().required('Contact number is not valid'),
    }).notRequired().default(undefined),
  }),
});

interface PracticeFormProps {
  practice: PracticeDto;
  onSuccess?: () => void;
  children: React.ReactNode;
}

const PracticeForm = ({ practice, children, onSuccess }: PracticeFormProps) => {
  const updatePracticeMutation = useUpdatePracticeMutation(practice.entityId);

  const formikConfig: FormikConfig<UpdatePracticeCommand> = {
    initialValues: {
      practice: {
        practiceName: practice.practiceName,
        registeredCompanyName: practice.registeredCompanyName,
        companyRegNumber: practice.companyRegNumber,
        companyVatNumber: practice.companyVatNumber,
        mainContactNumber: practice.mainContactNumber,
      },
      site: {
        siteAddress: practice.siteAddress,
      },
      contact: {
        practiceContact: practice.mainContact,
      },
      billing: {
        billingContact: practice.billingContact,
        billingAddress: practice.billingAddress,
      },
    },
    onSubmit: (practiceCommand, { setSubmitting }) => {
      setSubmitting(true);
      updatePracticeMutation.mutate(practiceCommand, {
        onSettled: () => {
          setSubmitting(false);
          onSuccess && onSuccess();
        },
      });
    },
    validationSchema: PracticeSchema,
  };

  return (
    <PracticeFormProvider mutation={updatePracticeMutation} formikConfig={formikConfig}>
      {children}
    </PracticeFormProvider>
  );
};

export default PracticeForm;
