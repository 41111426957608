import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { HOUR_IN_MILLISECONDS } from 'config';
import useProtectedParams from 'hooks/useProtectedParams';
import { PatientDto, usePatientsQuery } from 'providers/api';
import React from 'react';
import { useAppointmentForm } from './context';

const PatientField = () => {
  const { teamId } = useProtectedParams('teamId');
  const { form: { formik, helpers } } = useAppointmentForm();
  const { values, handleBlur, setFieldValue } = formik;

  // TODO: review the process for feeding large data sets into autocomplete
  // SEE: https://dev.azure.com/buzzinteractiveteam/Aegis/_sprints/taskboard/Aegis%20Team/Aegis/Sprint%203?workitem=6596
  const { data: patients } = usePatientsQuery({ page: 1, pageSize: 1000, practiceId: teamId, clientId: values.clientId ?? '' }, {
    enabled: !!values.clientId,
    staleTime: HOUR_IN_MILLISECONDS,
  });

  const selectedPatient = patients?.items.find((patient) => patient.entityId === values.patientId) ?? null;

  React.useEffect(() => {
    setFieldValue('patientId', selectedPatient ? selectedPatient.entityId : '');
  }, [selectedPatient]);

  if (!values.clientId) return <Alert severity="info">You must select a client before selecting a patient</Alert>;

  return (
    <Autocomplete
      fullWidth
      options={patients?.items || []}
      getOptionLabel={(option: PatientDto) => option.name}
      value={selectedPatient}
      onChange={(event, newPatient) => {
        setFieldValue('patientId', newPatient ? newPatient.entityId : '');
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          id="patientId"
          name="patientId"
          label="Patient"
          variant="outlined"
          onBlur={handleBlur}
          error={helpers.hasError('patientId')}
          helperText={helpers.getErrorHelpText('patientId')}
        />
      )}
    />
  );
};

export default PatientField;
