import Typography from '@mui/material/Typography';
import { UserDto, ValidatedLocationDto } from 'providers/api';

interface ValidatedLocationAssociatedUserProps {
  validatedLocation: ValidatedLocationDto;
  users: UserDto[];
}

const ValidatedLocationAssociatedUser = ({ validatedLocation, users }: ValidatedLocationAssociatedUserProps) => {
  const validatingUserId = validatedLocation.validatingClientUser ?? validatedLocation.validatingVetUser;
  const validatingUser = users.find((user) => user.userId === validatingUserId);

  return (
    <Typography variant="body2">
      {validatingUser
        ? `${validatingUser.forename} ${validatingUser.surname}`
        : 'Unable to find user'}
    </Typography>
  );
};

export default ValidatedLocationAssociatedUser;
