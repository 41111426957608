import React from 'react';
import { IFormContext } from './context';

// eslint-disable-next-line import/prefer-default-export
export const createUseForm = <TForm, TValues>(Context: React.Context<IFormContext<TForm, TValues> | null>) => () => {
  const context = React.useContext(Context);
  if (context === null) {
    throw new Error('No form context available');
  }
  return context;
};
