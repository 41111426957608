import { AUTH_TO_CREATE_APPOINTMENTS } from 'config';
import { useAuthorisation } from 'providers/authorisation';
import { useTeam } from 'providers/team';
import { Navigate } from 'react-router-dom';
import accountRoutes from './account';
import adminRoutes from './admin';
import clientRoutes from './client';
import { ArrivetRouteObject, RouteConfiguration } from './interfaces';
import Layout, { TopLevelMenuItem } from './Layout';
import practiceRoutes from './practice';
import TeamRouterOutlet from './TeamRouterOutlet';
import utilsRoutes from './utils';

const getMainMenuItemsFromRoute = (routes: ArrivetRouteObject[], path: string = ''): TopLevelMenuItem[] => routes
  .flatMap((route) => {
    const newPath = route.path ? `${path}/${route.path}` : path;
    const menu: TopLevelMenuItem[] = route.mainNavigation ? [
      {
        group: route.mainNavigation.group,
        display: route.mainNavigation.title,
        path: newPath,
        icon: route.mainNavigation.iconComponent,
      },
    ] : [];

    const childrenRoutes = route.children ? getMainMenuItemsFromRoute(route.children, newPath) : [];

    return [...menu, ...childrenRoutes];
  });

const useModuleRoutes: () => ArrivetRouteObject = () => {
  const { state: { activeTeam } } = useTeam();
  const authorisation = useAuthorisation();
  const config: RouteConfiguration = { authorisation };

  const practice = practiceRoutes(config);
  const admin = adminRoutes(config);
  const client = clientRoutes(config);
  const utils = utilsRoutes(config);
  const account = accountRoutes(config);

  const getRoutesFromActiveTeam = () => {
    // Global routes
    if (!activeTeam!.isEnabled) return { routes: [utils.disabled], default: `${activeTeam!.teamId}` };

    // Practice routes
    if (activeTeam!.teamType === 'Practice') {
      if (!authorisation.actions.userHasPermissions(activeTeam?.teamId ?? '', AUTH_TO_CREATE_APPOINTMENTS)) {
        return { routes: [utils.vetAccess], default: `${activeTeam!.teamId}` };
      }
      return { routes: [...practice, ...account], default: activeTeam!.teamId };
    }

    // Client routes
    if (activeTeam!.teamType === 'Client') {
      return { routes: [...client, ...account], default: activeTeam!.teamId };
    }

    throw new Error('Failed to locate routes for this team type');
  };

  const routesFromTeam = activeTeam ? getRoutesFromActiveTeam() : { routes: [], default: '' };

  const teamRoutes = {
    path: ':teamId',
    element: <TeamRouterOutlet />,
    children: [
      ...routesFromTeam.routes,
    ],
  };

  const appRoutes = activeTeam ? [teamRoutes, ...admin] : [...admin];

  const menu = getMainMenuItemsFromRoute(appRoutes);
  return ({
    element: <Layout menu={menu} />,
    children: [
      {
        index: true,
        element: <Navigate to={authorisation.functions.isAdmin() && !activeTeam ? admin[0].path! : routesFromTeam.default} />,
      },
      ...appRoutes,
      utils[404],
    ],
  });
};

export default useModuleRoutes;
