import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { useCreateClientMutation } from 'providers/api';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ClientForm from '../ClientForm';
import { TITLE as parentTitle } from '../ClientsPage';
import { TITLE } from './constants';

const NewClientPage = () => {
  const { teamId } = useProtectedParams('teamId');

  const navigate = useNavigate();
  const createClientMutation = useCreateClientMutation({
    onSuccess: () => {
      navigate('../');
    },
  });
  return (
    <Page pageType="standard" title={TITLE} parentRelativePath="../.." parentTitle={parentTitle}>
      <ClientForm practiceId={teamId} mutation={createClientMutation} />
    </Page>
  );
};

export default NewClientPage;
