import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Address from 'components/Address';
import EditableSection from 'components/EditableSection';
import { FormStack } from 'components/Form';
import TitleBox from 'components/TitleBox';
import TitleDisplayStack from 'components/TitleDisplayStack';
import PracticeForm, {
  BillingAddressFields,
  BillingContactFields,
  PracticeCompanyNameField,
  PracticeCompanyRegField,
  PracticeCompanyVatField,
  PracticeContactFields,
  PracticeMainContactNumberField,
  PracticeNameField,
  SiteAddressFields,
  usePracticeForm
} from 'features/PracticeForm';
import { AddressDto, PracticeDto } from 'providers/api';
import { useNavigate } from 'react-router-dom';

interface SiteAddressDisplayProps {
  address?: AddressDto;
}
const AddressDisplay = ({ address }: SiteAddressDisplayProps) => {
  if (!address) {
    return <Typography variant="body2">-</Typography>;
  }

  return <Address address={address} type="inline" typographyVariant="body2" />;
};

interface PracticeEditableSectionProps {
  practice: PracticeDto;
}
const PracticeEditableSection = ({ practice }: PracticeEditableSectionProps) => {
  const { form, mutation } = usePracticeForm();
  const navigate = useNavigate();

  return (
    <Stack spacing={1}>
      <EditableSection title="Practice Information" mutation={mutation} formik={form.formik}>
        {(editMode) => (
          <Box py={3}>
            <TitleDisplayStack
              title="Name"
              display={editMode ? <PracticeNameField /> : <Typography variant="body2">{practice.practiceName}</Typography>}
            />
            <Divider sx={{ my: 3 }} />
            <TitleDisplayStack
              title="Address"
              display={editMode
                ? <SiteAddressFields />
                : <AddressDisplay address={practice.siteAddress} />}
            />
            <Divider sx={{ my: 3 }} />
            <TitleDisplayStack
              title="Office Number"
              display={editMode ? <PracticeMainContactNumberField /> : <Typography variant="body2">{practice.mainContactNumber ?? '-'}</Typography>}
            />
            <Divider sx={{ my: 3 }} />
            <TitleDisplayStack
              title="Primary Contact"
              display={editMode
                ? (
                  <PracticeContactFields />
                )
                : (
                  <Box>
                    {practice.mainContact && (<Typography variant="body2">{`${practice.mainContact.firstName} ${practice.mainContact.lastName}`}</Typography>)}
                    {practice.mainContact && (<Typography variant="body2">{practice.mainContact.contactNumber}</Typography>)}
                    {practice.mainContact && (<Typography variant="body2">{practice.mainContact.email}</Typography>)}
                    {!practice.mainContact && (<Typography variant="body2">-</Typography>)}
                  </Box>
                )}
            />
            <Divider sx={{ my: 3 }} />
            <TitleDisplayStack
              title="Company Details"
              display={editMode
                ? (
                  <FormStack>
                    <PracticeCompanyNameField />
                    <PracticeCompanyVatField />
                    <PracticeCompanyRegField />
                  </FormStack>
                )
                : (
                  <Box>
                    {practice.registeredCompanyName && <Typography variant="body2">{practice.registeredCompanyName}</Typography>}
                    {practice.companyVatNumber && <Typography variant="body2">{`VAT: ${practice.companyVatNumber ?? ''}`}</Typography>}
                    {practice.companyRegNumber && <Typography variant="body2">{`REG: ${practice.companyRegNumber ?? ''}`}</Typography>}
                    {(!practice.registeredCompanyName && !practice.companyVatNumber && !practice.companyRegNumber) && <Typography variant="body2">-</Typography>}
                  </Box>
                )}
            />
            <Divider sx={{ my: 3 }} />
            <TitleDisplayStack
              title="Billing Contact"
              display={editMode
                ? (
                  <BillingContactFields />
                )
                : (
                  <Box>
                    {practice.billingContact && (<Typography variant="body2">{`${practice.billingContact.firstName} ${practice.billingContact.lastName}`}</Typography>)}
                    {practice.billingContact && (<Typography variant="body2">{practice.billingContact.contactNumber}</Typography>)}
                    {practice.billingContact && (<Typography variant="body2">{practice.billingContact.email}</Typography>)}
                    {!practice.billingContact && (<Typography variant="body2">-</Typography>)}
                  </Box>
                )}
            />
            <Divider sx={{ my: 3 }} />
            <TitleDisplayStack title="Billing Address" display={editMode ? <BillingAddressFields /> : <AddressDisplay address={practice.billingAddress} />} />
          </Box>
        )}
      </EditableSection>

      <TitleBox title="Dashboard Map Settings">
        <Tooltip title="Choose the location you would like the map to centre on in your dashboard">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate('./practice-location')}
            startIcon={<AddIcon />}
          >
            Select Practice Location On The Map
          </Button>
        </Tooltip>
      </TitleBox>
    </Stack>
  );
};

interface PracticeInfoSectionProps {
  practice: PracticeDto;
}

const PracticeInfoSection = ({ practice }: PracticeInfoSectionProps) => (
  <PracticeForm practice={practice}>
    <PracticeEditableSection practice={practice} />
  </PracticeForm>
);

export default PracticeInfoSection;
