import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { styled } from '@mui/material/styles';
import { iconDark } from 'assets';
import ImageUploadButton from 'components/ImageUploadButton';
import { Gender, PatientDto } from 'providers/api';
import { DEFAULT_SHORT_DATE_CONFIG, displayTemporal, isNilOrEmpty } from 'utils';

const ReverseListItemText = styled(ListItemText)({
  display: 'flex',
  flexDirection: 'column-reverse',
});

interface PatientDetailItemProps {
  title: string;
  value?: string;
}

const PatientDetailItem = ({ title, value }: PatientDetailItemProps) => (
  <ListItem disablePadding>
    <ReverseListItemText
      primary={value}
      secondary={title}
      secondaryTypographyProps={{ variant: 'overline', sx: { lineHeight: 1 } }}
    />
  </ListItem>
);

interface PatientInfoPanelProps {
  patient: PatientDto;
  uploading: boolean;
  onUpload: (image: File) => void;
  onCreateAppointment?: () => void;
  onEdit?: () => void;
  handleDelete: (() => void)
}

const PatientInfoPanel = ({ patient, uploading, onCreateAppointment, onUpload, onEdit, handleDelete }: PatientInfoPanelProps) => {
  const detailsData: [string, string][] = [
    ['Breed', patient.breed],
    ['Classification', patient.species],
    ['Coat', patient.colour],
    ['Date of Birth', displayTemporal(patient.dob, DEFAULT_SHORT_DATE_CONFIG)],
    ['Gender', Gender[patient.gender]],
    ['Microchip', isNilOrEmpty(patient.microchipNumber)
      ? 'Unknown'
      : patient.microchipNumber ?? 'Unknown',
    ],
  ];

  return (
    <Card>
      <CardHeader
        sx={{ bgcolor: (theme) => theme.palette.grey[200] }}
        title={patient.name}
        action={onEdit && (
          <>
            <IconButton aria-label="edit" onClick={() => onEdit()}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => handleDelete()}>
              <DeleteIcon />
            </IconButton>
          </>
        )}
      />
      <Box display="flex">
        <Box sx={{ flexGrow: 1, position: 'relative' }}>
          <Box sx={{ position: 'absolute', left: '50%', transform: 'translate(-50%, 0)', bottom: 20 }}>
            <ImageUploadButton
              label="Upload New Photo"
              Icon={<AddPhotoAlternateIcon />}
              uploading={uploading}
              onUpload={onUpload}
            />
          </Box>
          <CardMedia
            component="img"
            sx={{ height: '100%' }}
            src={patient.image?.sizes?.large ?? iconDark}
            alt={`image of ${patient.name}`}
          />
        </Box>
        <CardContent sx={{
          display: 'flex',
          alignContent: 'space-between',
          flexWrap: 'wrap',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: 400,
          minHeight: 500,
          backgroundColor: (theme) => theme.palette.grey[50],
        }}
        >
          <List
            disablePadding
            dense
            subheader={(
              <ListSubheader disableSticky disableGutters>
                Patient Details
              </ListSubheader>
            )}
          >
            {detailsData.map(([title, value]) => <PatientDetailItem key={title} title={title} value={value} />)}
          </List>
          {onCreateAppointment && (
            <Box sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
           }}
            >
              <Button variant="contained" startIcon={<CalendarTodayIcon />} onClick={() => onCreateAppointment()}>Appointment</Button>
            </Box>
          )}
        </CardContent>
      </Box>
    </Card>
  );
};

export default PatientInfoPanel;
