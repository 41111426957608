import Box from '@mui/material/Box';
import { FormikActions, FormStack } from 'components/Form';
import Loading from 'components/Loading';
import TitleBox from 'components/TitleBox';
import { HOUR_IN_MILLISECONDS } from 'config';
import PracticeForm, {
  BillingAddressFields,
  BillingContactFields,
  PracticeCompanyNameField,
  PracticeCompanyRegField,
  PracticeCompanyVatField,
  PracticeContactFields,
  PracticeMainContactNumberField,
  PracticeNameField, SiteAddressFields, usePracticeForm
} from 'features/PracticeForm';
import Page from 'modules/Page';
import { usePracticeQuery } from 'providers/api';
import { useNavigate, useParams } from 'react-router-dom';
import { TITLE as parentTitle } from '../PracticesPage';

const PracticeFormControls = () => {
  const { form: { formik }, mutation } = usePracticeForm();

  return (
    <>
      <TitleBox title="Practice Name">
        <Box mt={2}><PracticeNameField /></Box>
      </TitleBox>

      <TitleBox title="Address">
        <Box mt={2}><SiteAddressFields /></Box>
      </TitleBox>

      <TitleBox title="Office Number">
        <Box mt={2}><PracticeMainContactNumberField /></Box>
      </TitleBox>

      <TitleBox title="Primary Contact">
        <Box mt={2}><PracticeContactFields /></Box>
      </TitleBox>

      <TitleBox title="Company Details">
        <Box mt={2}>
          <FormStack>
            <PracticeCompanyNameField />
            <PracticeCompanyVatField />
            <PracticeCompanyRegField />
          </FormStack>
        </Box>
      </TitleBox>

      <TitleBox title="Billing Contact">
        <Box mt={2}><BillingContactFields /></Box>
      </TitleBox>

      <TitleBox title="Billing Address">
        <Box mt={2}><BillingAddressFields /></Box>
      </TitleBox>

      <FormikActions
        formik={formik}
        mutation={mutation}
        submitText="Save"
        right={['reset', 'submit']}
      />
    </>
  );
};

const EditPracticePage = () => {
  const params = useParams<'practiceId'>();
  const navigate = useNavigate();

  if (!params.practiceId) {
    throw new Error('No practice id found');
  }

  const { data: practice } = usePracticeQuery(params.practiceId, { staleTime: HOUR_IN_MILLISECONDS });

  if (!practice) {
    return <Loading />;
  }

  const handleSuccess = () => {
    navigate('../');
  };

  return (
    <Page title={`Edit ${practice.practiceName}`} parentTitle={parentTitle} parentRelativePath="../">
      <PracticeForm practice={practice} onSuccess={handleSuccess}>
        <PracticeFormControls />
      </PracticeForm>
    </Page>
  );
};

export default EditPracticePage;
