import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Loading from 'components/Loading';
import {
  ValidatedLocationInfoForm,
  ValidatedLocationPositionForm
} from 'features/ValidatedLocations';
import ValidatedLocationTagsForm from 'features/ValidatedLocations/ValidatedLocationTagsForm';
import useProtectedParams from 'hooks/useProtectedParams';
import {
  useClientValidateLocationMutation,
  useMarkForRemovalValidatedLocationMutation,
  useUpdateValidatedLocationDescriptionMutation,
  useUpdateValidatedLocationPositionMutation,
  useUpdateValidatedLocationTagsMutation,
  useValidatedLocationQuery,
  useVetValidateLocationMutation
} from 'providers/api/useValidatedLocations';
import { useAuthorisation } from 'providers/authorisation';
import { useConfirmation } from 'providers/confirm';
import { useTeam } from 'providers/team';
import { TeamType } from 'providers/team/provider';
import { useNavigate } from 'react-router-dom';

const ValidatedLocationPanel = () => {
  const confirm = useConfirmation();
  const { teamId, locationId } = useProtectedParams('teamId', 'locationId');
  const { state: { user } } = useAuthorisation();
  const { state: { activeTeam } } = useTeam();
  const navigate = useNavigate();

  const { data: location, refetch: refetchValidatedLocation } = useValidatedLocationQuery({ entityId: locationId, clientId: teamId });

  const updateValidatedLocationDetails = useUpdateValidatedLocationDescriptionMutation(locationId, { onSuccess: () => refetchValidatedLocation() });
  const updateValidatedLocationTags = useUpdateValidatedLocationTagsMutation(locationId, { onSuccess: () => refetchValidatedLocation() });
  const updateValidatedLocationPosition = useUpdateValidatedLocationPositionMutation(locationId, { onSuccess: () => refetchValidatedLocation() });
  const flagValidatedLocationForDeletion = useMarkForRemovalValidatedLocationMutation(locationId);
  const validateLocation = activeTeam?.teamType as TeamType === 'Client'
    ? useClientValidateLocationMutation(locationId, { onSuccess: () => refetchValidatedLocation() })
    : useVetValidateLocationMutation(locationId, { onSuccess: () => refetchValidatedLocation() });

  if (!location) return <Loading />;

  const isValidatedLocation = !!location.validatingClientUser || !!location.validatingVetUser;

  const handleValidateLocation = () => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want to validate this location',
    }).then(() => validateLocation.mutate({ teamId, userId: user.id }));
  };

  const handleFlagForDeletion = () => {
    confirm({
      variant: 'danger',
      description: 'This action cannot be undone. Are you sure you want to flag this validated location for deletion?',
    }).then(() => flagValidatedLocationForDeletion.mutate(teamId))
      .then(() => navigate('../'));
  };

  return (
    <>
      <ValidatedLocationInfoForm
        mutation={updateValidatedLocationDetails}
        validatedLocation={location}
      />
      <ValidatedLocationTagsForm
        mutation={updateValidatedLocationTags}
        validatedLocation={location}
      />
      <ValidatedLocationPositionForm
        readOnly={isValidatedLocation}
        location={location}
        mutation={updateValidatedLocationPosition}
      />

      <Stack spacing={1}>
        {!isValidatedLocation && (
          <Button
            fullWidth
            variant="outlined"
            onClick={() => handleValidateLocation()}
          >
            Validate Location
          </Button>
        )}
        <Tooltip
          title="This validated location has already been flagged for deletion"
          disableHoverListener={!location.markedForRemoval}
        >
          <span>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => handleFlagForDeletion()}
              disabled={location.markedForRemoval}
            >
              Flag for Deletion
            </Button>
          </span>
        </Tooltip>
      </Stack>
    </>
  );
};
export default ValidatedLocationPanel;
