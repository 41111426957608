import {
  Checkbox, Divider, FormControlLabel, FormGroup
} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditableSection from 'components/EditableSection';
import TitleDisplayStack from 'components/TitleDisplayStack';
import AccountForm, {
  AccountContactNumberField, AccountEmailField, AccountNameField, AccountPreferencesField, useAccountForm
} from 'features/AccountForm';
import { UserDto } from 'providers/api';
import React from 'react';

interface AccountEditableSectionProps {
  user: UserDto
}
const AccountFormEditableSection = ({ user }: AccountEditableSectionProps) => {
  const { form, mutation } = useAccountForm();

  return (
    <EditableSection title="Profile Info" mutation={mutation} formik={form.formik}>
      {(editMode) => (
        <Box py={3}>
          <TitleDisplayStack
            title="Name"
            display={editMode
              ? <AccountNameField />
              : (
                <Typography variant="body2">{`${user.forename} ${user.surname}`}</Typography>
                )}
          />
          <Divider sx={{ my: 3 }} />
          <TitleDisplayStack
            title="Email"
            display={editMode ? <AccountEmailField /> : <Typography variant="body2">{user.email}</Typography>}
          />
          <Divider sx={{ my: 3 }} />
          <TitleDisplayStack
            title="Contact Number"
            display={editMode ? <AccountContactNumberField /> : <Typography variant="body2">{user.contactNumber}</Typography>}
          />
          <Divider sx={{ my: 3 }} />
          <TitleDisplayStack
            title="Preferences"
            display={editMode
              ? <AccountPreferencesField />
              : (
                <FormGroup>
                  <FormControlLabel control={<Checkbox disabled checked={!user.excludeReminderSms} />} label="Text me appointment reminders" />
                  <FormControlLabel control={<Checkbox disabled checked={!user.excludeReminderEmail} />} label="Email me appointment reminders" />
                  <FormControlLabel control={<Checkbox disabled checked={!user.excludeEtaSms} />} label="Text me ETA reminders" />
                  <FormControlLabel control={<Checkbox disabled checked={!user.excludeEtaEmail} />} label="Email me ETA reminders" />
                </FormGroup>
                )}
          />
        </Box>
      )}
    </EditableSection>
  );
};

type AccountInfoProps = {
  user: UserDto
}

const AccountInfoSection = ({ user }: AccountInfoProps) => (
  <AccountForm user={user}>
    <AccountFormEditableSection user={user} />
  </AccountForm>
  );

export default AccountInfoSection;
