/* global google */

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Actions from 'components/Actions';
import { GoogleMap } from 'components/GoogleMap';
import Marker, { MarkerProps } from 'components/GoogleMap/Marker';
import { INITIAL_CENTER_FOR_UK, INITIAL_ZOOM_FOR_UK } from 'config';
import { Outlet } from 'react-router-dom';

interface MapDashboardPageProps {
  title: string;
  markerData: MarkerProps[];
  actions?: [string, JSX.Element][];
  contextData?: any;
  search?: boolean;
  onMapClick?: (latLng: { lat: number; lng: number }) => void;
}

const MapDashboardPage = ({ title, actions, markerData, contextData, search, onMapClick }: MapDashboardPageProps) => {
  const handleMapClick = (e: google.maps.MapMouseEvent) => onMapClick && onMapClick({ lat: e.latLng?.lat() ?? 0, lng: e.latLng?.lng() ?? 0 });

  return (
    <Stack width="100%" height="100%" direction="row" component={Paper}>
      <GoogleMap
        disableDefaultUI
        zoomControl
        onClick={handleMapClick}
        draggableCursor="crosshair"
        zoom={INITIAL_ZOOM_FOR_UK}
        center={INITIAL_CENTER_FOR_UK}
        search={search}
      >
        {
          markerData.map((marker) => <Marker key={(marker as any).key} {...marker} />)
        }
      </GoogleMap>

      <Box width="30%" maxWidth={500} minWidth={350} display="flex" flexDirection="column">
        <Stack p={2} pb={0}>
          <Stack flexDirection="row">
            <Typography flexGrow={1} variant="h6">{title}</Typography>
            <Actions right={actions} />
          </Stack>
          <Divider />
        </Stack>

        <Box p={2} flexGrow={1} flexBasis="auto" overflow="auto">
          <Outlet context={{ contextData }} />
        </Box>
      </Box>
    </Stack>
  );
};

export default MapDashboardPage;
