import Loading from 'components/Loading';
import { useTeamRoleListQuery, useUpdateUserTeamsRolesMutation, useUserQuery } from 'providers/api';
import { useNavigate } from 'react-router-dom';
import EditUserForm from '../EditUserForm';

interface EditUserFeatureProps {
  teamId: string;
  availableRoles: string[];
  userId: string
}
const EditUserFeature = ({ userId, availableRoles, teamId }: EditUserFeatureProps) => {
  const navigate = useNavigate();

  const {
    data: user,
  } = useUserQuery({ teamId, userId });

  const { data: roles } = useTeamRoleListQuery(teamId);

  const updatePermissionsMutation = useUpdateUserTeamsRolesMutation(userId, {
    onSuccess: () => navigate('../'),
  });

  if (!user || !roles) {
    return <Loading />;
  }

  return (
    <EditUserForm teamId={teamId} user={user} roles={roles.filter((role) => availableRoles.includes(role.name))} mutation={updatePermissionsMutation} />
  );
};

export default EditUserFeature;
