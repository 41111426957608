import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import {
  createContext,
  Dispatch,
  SetStateAction
} from 'react';
import { CustomRouteProps } from 'utils';

export interface SubNavigation {
  title: string;
  iconComponent: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  routeProps: CustomRouteProps;
}

export interface IPageContext {
  state: {
    title: string | null;
    parentPath: string | null;
    parentTitle: string | null;
    subNavigation: SubNavigation[];
  },
  actions: {
    setTitle: Dispatch<SetStateAction<string | null>>;
    setParentPath: Dispatch<SetStateAction<string | null>>;
    setParentTitle: Dispatch<SetStateAction<string | null>>;
    setSubNavigation: Dispatch<SetStateAction<SubNavigation[]>>;
  }
}

const PageContext = createContext<IPageContext | null>(null);

export default PageContext;
