import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { HOUR_IN_MILLISECONDS } from 'config';
import { UserDto, useUsersQuery } from 'providers/api';
import React from 'react';

interface ClientUserAutocompleteProps {
  clientId: string;
  selectedUser: UserDto | null;
  unavailableUsers: string[];
  onUserSelect: (user: UserDto | null) => void
}

const ClientUserAutocomplete = ({ clientId, selectedUser, unavailableUsers, onUserSelect }: ClientUserAutocompleteProps) => {
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState<string>();
  const { data: clientUsers, isLoading } = useUsersQuery({ page: 1, pageSize: 20, teamId: clientId, searchTerm }, {
    enabled: open,
    staleTime: HOUR_IN_MILLISECONDS,
  });
  const availableClientUsers = React.useMemo(() => (clientUsers ? clientUsers.items.filter((user) => !unavailableUsers.includes(user.userId)) : []), [clientUsers]);

  return (
    <Autocomplete
      size="small"
      disablePortal
      options={availableClientUsers}
      value={selectedUser}
      onChange={(event, newValue) => onUserSelect(newValue)}
      inputValue={searchTerm}
      onInputChange={(event, newInputValue) => setSearchTerm(newInputValue)}
      loading={isLoading}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionLabel={(obj) => `${obj.forename} ${obj.surname}`}
      noOptionsText="No users available"
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Add Attendee" />}
    />
  );
};

export default ClientUserAutocomplete;
