import { GridComponentProps } from '@mui/x-data-grid';
import DataGridWithLoading, { ResponsiveGridColDef } from 'components/DataGrid';
import { PAGINATION_DEFAULT_PAGE_SIZE, PAGINATION_PAGE_SIZES } from 'config';
import { UserDto } from 'providers/api';
import { ArrivetUser } from 'providers/authorisation/context';
import UsersTableMenu from './UsersTableMenu';

interface UsersTableProps {
  data: UserDto[] | undefined;
  currentUser: ArrivetUser;
  page: number;
  pageSize: number;
  totalItems: number | undefined;
  loading: boolean;
  loadingNew: boolean;
  searchTerm: string | null;
  onPageChange: GridComponentProps['onPageChange'];
  onPageSizeChange: GridComponentProps['onPageSizeChange'];
  onEdit: (userId: string) => void;
  onDelete: (user: UserDto) => void;
}

const UsersTable = ({
  data,
  currentUser,
  loadingNew,
  loading,
  page,
  pageSize = PAGINATION_DEFAULT_PAGE_SIZE,
  totalItems = 0,
  searchTerm,
  onPageChange,
  onPageSizeChange,
  onEdit,
  onDelete,
}: UsersTableProps) => {
  const pageIndex = data ? (page - 1) : 0;
  const rows = data ?? [];

  const columns: ResponsiveGridColDef[] = [
    {
      field: 'forename',
      headerName: 'First Name',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 1,
    },
    {
      field: 'surname',
      headerName: 'Last Name',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 2,
    },
    {
      field: 'teamRoles',
      headerName: 'Roles',
      resizable: false,
      sortable: false,
      filterable: false,
      flex: 2,
      valueFormatter: ({ value }) => (value ? (value as string[]).join(', ') : ''),
    },
    {
      field: '',
      sortable: false,
      filterable: false,
      resizable: false,
      width: 80,
      renderCell: ({ row }) => (<UsersTableMenu user={row as any} currentUser={currentUser} onEdit={onEdit} onDelete={onDelete} />),
    },
  ];

  return (
    <DataGridWithLoading
      loading={loading}
      loadingNew={loadingNew}
      filters={[
        {
          fields: ['email'],
          term: searchTerm,
        },
      ]}
      idProp="userId"
      disableSelectionOnClick
      disableColumnMenu
      autoHeight
      rows={rows}
      columns={columns}
      pagination
      paginationMode="server"
      page={pageIndex}
      pageSize={pageSize}
      rowCount={totalItems}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      rowsPerPageOptions={PAGINATION_PAGE_SIZES}
    />
  );
};

export default UsersTable;
