import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { FormikActions } from 'components/Form';
import TitleBox from 'components/TitleBox';
import { FormikProps } from 'formik';
import React from 'react';
import { UseMutationResult } from 'react-query';

interface EditableSectionProps {
  mutation: UseMutationResult<any, any, any>;
  formik: FormikProps<any>;
  title: string;
  enabled?: boolean;
  small?: boolean;
  onCancel?: () => void;
  onEditing?: () => void;
  children: (isEdit: boolean) => JSX.Element;
}

const EditableSection = ({ title, mutation, formik, enabled = true, small, onCancel, onEditing, children }: EditableSectionProps) => {
  const [editMode, setEditMode] = React.useState(false);

  React.useEffect(() => {
    if (mutation.status === 'success') {
      setEditMode(false);
    }
  }, [mutation.status]);

  const handleCancel = () => {
    setEditMode(false);
    mutation.reset();
    formik.resetForm();
    onCancel && onCancel();
  };

  const handleEditing = () => {
    setEditMode(true);
    onEditing && onEditing();
  };

  const cancelAction: [string, JSX.Element] = ['cancel', (
    <Button variant="contained" color="inherit" onClick={handleCancel} disabled={formik.isSubmitting} size={small ? 'small' : undefined}>
      Cancel
    </Button>
  )];

  return (
    <>
      <TitleBox
        title={title}
        small={small}
        actions={
          !editMode && enabled && (
            <IconButton onClick={() => handleEditing()} size={small ? 'small' : undefined}>
              <EditIcon />
            </IconButton>
          )
        }
      >
        {children(editMode)}
      </TitleBox>
      {
        editMode && (
          <Box mb={2}>
            <FormikActions
              formik={formik}
              mutation={mutation}
              submitText="Save"
              left={[cancelAction]}
              right={['reset', 'submit']}
              small={small}
            />
          </Box>
        )
      }
    </>
  );
};

export default EditableSection;
