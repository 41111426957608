import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { ArrivetRouteObject, RouteConfiguration } from 'modules/interfaces';
import { Navigate } from 'react-router-dom';
import EditCalendarEventPage from './EditCalendarEventPage';
import NewCalendarEventPage from './NewCalendarEventPage';
import StaffAvailabilityPage from './StaffAvailabilityPage/StaffAvailabilityPage';

export const defaultRoute = 'staffavailability';

const routes: (config: RouteConfiguration) => ArrivetRouteObject[] = () => [{
  path: 'staffavailability',
  mainNavigation: {
    title: 'Staff Availability',
    group: '',
    iconComponent: <EventAvailableIcon />,
  },
  children: [
    {
      index: true,
      element: <StaffAvailabilityPage />,
    },
    {
      path: 'new',
      element: <NewCalendarEventPage />,
    },
    {
      path: ':calendarEventId',
      children: [
        {
          index: true,
          element: <Navigate to="details" />,
        },
        {
          path: 'details',
          element: <EditCalendarEventPage />,
        },
      ],
    },
  ],
}];

export default routes;
