import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditableSection from 'components/EditableSection';
import {
  PrimaryContactField,
  useAppointmentForm
} from 'features/ClientAppointmentForm';
import { AppointmentDetailsDto } from 'providers/api';
import { getUserSummary } from 'utils/entities/user';

interface AppointmentPrimaryContactEditableSectionProps {
  appointment: AppointmentDetailsDto;
  editable?: boolean;
}

const AppointmentPrimaryContactEditableSection = ({ appointment, editable = false }: AppointmentPrimaryContactEditableSectionProps) => {
  const { form: { formik }, mutation } = useAppointmentForm();
  return (
    <EditableSection title="Primary Contact" mutation={mutation} formik={formik} enabled={editable}>
      {(editMode) => (
        <Box>
          {
            editMode
              ? <PrimaryContactField />
              : (
                <Typography variant="body2">
                  {appointment.primaryContactDetails
                    ? getUserSummary(appointment.primaryContactDetails)
                    : 'No primary contact'}
                </Typography>
              )
          }
        </Box>
      )}
    </EditableSection>
  );
};

export default AppointmentPrimaryContactEditableSection;
