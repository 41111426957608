import Box from '@mui/material/Box';
import { useValidatedLocationsState, ValidatedLocationList } from 'features/ValidatedLocations';
import { useNavigate } from 'react-router-dom';

const ClientValidatedLocationListPanel = () => {
  const navigate = useNavigate();
  const validatedLocationsState = useValidatedLocationsState();

  const {
    contextData:
      {
        validatedLocations,
        highlightedValidatedLocationId,
        selectedValidatedLocationId,
        confirmedValidatedLocationId,
        setHighlightedValidatedLocationId,
      },
  } = validatedLocationsState;

  const handleValidatedLocationClick = (validatedLocationId: string) => navigate(validatedLocationId);

  return (
    <Box height="calc(100vh - 310px)" display="flex" flexDirection="column">
      <Box flex={1} overflow="auto">
        <ValidatedLocationList
          validatedLocations={validatedLocations}
          onClick={handleValidatedLocationClick}
          highlightedValidatedLocation={highlightedValidatedLocationId}
          selectedValidatedLocation={selectedValidatedLocationId}
          confirmedValidatedLocation={confirmedValidatedLocationId}
          onMouseOver={(validatedLocationId: string) => setHighlightedValidatedLocationId && setHighlightedValidatedLocationId(validatedLocationId)}
          onMouseOut={() => setHighlightedValidatedLocationId && setHighlightedValidatedLocationId(undefined)}
        />
      </Box>
    </Box>
  );
};

export default ClientValidatedLocationListPanel;
