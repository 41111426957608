import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

interface SearchFieldProps {
  onSearch: (search: string | null) => void;
  debounce?: number;
  label?: string;
}

const SearchField = ({ onSearch, debounce = 300, label = 'Search' }: SearchFieldProps) => {
  const [searchText, setSearchText] = React.useState<string>('');

  const debouncedSearch = useDebouncedCallback(
    (value) => {
      onSearch(value === '' ? null : value);
    },
    debounce,
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    debouncedSearch(event.target.value);
  };

  return (
    <TextField
      fullWidth
      label={label}
      variant="filled"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      value={searchText}
      onChange={handleChange}
    />
  );
};

export default SearchField;
