import BusinessIcon from '@mui/icons-material/Business';
import PracticeLocationPage from 'modules/admin/practices/PracticeLocationPage';
import { ArrivetRouteObject, RouteConfiguration } from 'modules/interfaces';
import TabRouterOutlet from 'modules/TabRouterOutlet';
import EditPracticePage from './EditPracticePage';
import NewPracticePage from './NewPracticePage';
import PracticeInvitationsPage from './PracticeInvitationsPage';
import PracticesPage from './PracticesPage';

const tabRoutes = {
  Practices: '',
  'Practice Invites': '/invites',
};
const tabRouteId = 'AdminPractices';

export const defaultRoute = 'practices';

const routes: (config: RouteConfiguration) => ArrivetRouteObject[] = () => [{
  path: 'practices',
  mainNavigation: {
    title: 'Practices',
    group: 'Admin',
    iconComponent: <BusinessIcon />,
  },
  children: [
    {
      tabRouteId,
      element: <TabRouterOutlet routes={tabRoutes} routeId={tabRouteId} />,
      children: [
        {
          index: true,
          element: <PracticesPage />,
        },
        {
          path: 'invites',
          element: <PracticeInvitationsPage />,
        },
      ],
    },
    {
      path: ':practiceId',
      children: [
        {
          index: true,
          element: <EditPracticePage />,
        },
        {
          path: 'practice-location',
          element: <PracticeLocationPage />,
        },

      ],
    },
    {
      path: 'new',
      element: <NewPracticePage />,
    },
  ],
}];

export default routes;
