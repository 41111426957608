import { UsersFeature } from 'features/user';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import useClientPageTitle from '../../../useClientPageTitle';
import { TITLE as parentTitle } from '../ClientsPage';
import { TITLE as pageTitle } from './constants';

const ClientUsersPage = () => {
  const { clientId } = useProtectedParams('clientId');
  const title = useClientPageTitle(clientId, pageTitle);

  return (
    <Page title={title} pageType="large" parentRelativePath="../../.." parentTitle={parentTitle}>
      <UsersFeature teamId={clientId} />
    </Page>
  );
};

export default ClientUsersPage;
