import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { AppointmentForm, useCreateAppointmentMutation } from 'providers/api';
import { useNavigate } from 'react-router-dom';
import { TITLE as parentTitle } from '../CalendarPage';
import NewAppointmentForm from '../NewAppointmentForm';
import { useNewAppointmentLocationState } from './useNewAppointment';

const NewAppointmentPage = () => {
  const { patientId, clientId, date, vetId } = useNewAppointmentLocationState();
  const navigate = useNavigate();
  const { teamId } = useProtectedParams('teamId');
  const createAppointmentMutation = useCreateAppointmentMutation({
    onSuccess: (result) => navigate(`../${result}`),
  });

  const handleValidationSuccess = (values: AppointmentForm) => {
    createAppointmentMutation.mutate({
      practiceId: teamId,
      form: values,
    });
  };

  return (
    <Page pageType="standard" title="New Appointment" parentTitle={parentTitle} parentRelativePath="../">
      <NewAppointmentForm
        onValidationSuccess={handleValidationSuccess}
        initialStartDate={date}
        initialClient={clientId}
        initialPatient={patientId}
        initialVet={vetId}
        mutation={createAppointmentMutation}
      />
    </Page>
  );
};
export default NewAppointmentPage;
