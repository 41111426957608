import Box from '@mui/material/Box';
import { useValidatedLocationsState, ValidatedLocationList } from 'features/ValidatedLocations';
import { useNavigate } from 'react-router-dom';

const ValidatedLocationListPanel = () => {
  const navigate = useNavigate();
  const validatedLocationsState = useValidatedLocationsState();
  const { contextData: { validatedLocations, setHighlightedValidatedLocationId } } = validatedLocationsState;
  const handleValidatedLocationClick = (validatedLocationId: string) => navigate(validatedLocationId);

  return (
    <Box height="calc(100vh - 200px)" overflow="auto">
      <ValidatedLocationList
        onClick={handleValidatedLocationClick}
        validatedLocations={validatedLocations}
        onMouseOver={(validatedLocationId: string) => setHighlightedValidatedLocationId && setHighlightedValidatedLocationId(validatedLocationId)}
        onMouseOut={() => setHighlightedValidatedLocationId && setHighlightedValidatedLocationId(undefined)}
      />
    </Box>
  );
};

export default ValidatedLocationListPanel;
