import { Typography } from '@mui/material';
import EditableSection from 'components/EditableSection';
import TitleDisplayStack from 'components/TitleDisplayStack';
import { HOUR_IN_MILLISECONDS } from 'config';
import useProtectedParams from 'hooks/useProtectedParams';
import { CalendarEventDto, FileResponse, useVetsQuery } from 'providers/api';
import { useDefaultForm } from 'providers/form';
import React from 'react';
import { UseMutationResult } from 'react-query';
import { object, SchemaOf, string } from 'yup';
import VetField from '../NewCalendarEventForm/VetField';

export interface VetSelectFieldFormInputs {
  userId: string
}

const VetSelectFieldSchema: SchemaOf<VetSelectFieldFormInputs> = object().shape({
  userId: string().nullable().required('A vet is required'),
});

interface VetSelectFieldProps {
  mutation: UseMutationResult<FileResponse, unknown, any, unknown>
  calendarEvent: CalendarEventDto
  practiceId: string
  calendarEventId: string
}

const VetSelectField = ({ mutation, calendarEvent, practiceId, calendarEventId }: VetSelectFieldProps) => {
  const { teamId } = useProtectedParams('teamId');

  const { data: vets } = useVetsQuery({ page: 1, pageSize: 1000, teamId }, {
    enabled: true,
    staleTime: HOUR_IN_MILLISECONDS,
  });

  const {
    formik,
  } = useDefaultForm<VetSelectFieldFormInputs>({
    mutation,
    formikConfig: {
      initialValues: {
        userId: calendarEvent.user.entityId ?? '',
      },
      onSubmit: (form, { setSubmitting }) => {
        setSubmitting(true);
        mutation.mutate(
          { practiceId, calendarEventId, form },
          {
            onSuccess: () => {
              setSubmitting(false);
            },
          },
        );
      },
      validationSchema: VetSelectFieldSchema,
    },
  });

  const {
    setFieldValue,
    values,
  } = formik;

  const selectedVet = vets?.items.find((user) => user.userId === values.userId) ?? null;

  const handleVetFieldChange = (userId: string | null) => {
    userId && setFieldValue('userId', userId);
  };

  return (
    <EditableSection title="Vet" mutation={mutation} formik={formik}>
      {(editMode) => (
        <TitleDisplayStack
          title="Vet Name"
          display={(
            editMode
              ? (
                <VetField
                  onChange={handleVetFieldChange}
                  formik={formik}
                  vets={vets}
                />
              )
              : (
                <Typography variant="body2">
                  {`${selectedVet?.forename ?? ''} ${selectedVet?.surname ?? ''}`}
                </Typography>
              )
          )}
        />
      )}
    </EditableSection>
  );
};

export default VetSelectField;
