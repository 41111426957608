import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import * as React from 'react';

interface TagSelectFieldProps {
  currentTags: string[];
  onAdd: (tag: string) => void;
  onRemove: (tag: string) => void;
}

const TagSelectField = ({ currentTags, onAdd, onRemove }: TagSelectFieldProps) => {
  const [value, setValue] = React.useState<string>('');
  const handleAddTag = (tag: string) => {
    onAdd(tag);
    setValue('');
  };

  return (
    <Box>
      <Stack direction="row">
        <TextField
          fullWidth
          id="tags"
          name="tags"
          label="tags"
          value={value}
          size="small"
          onChange={(event) => setValue(event.target.value)}
        />
        <IconButton aria-label="add tag" size="small" onClick={() => handleAddTag(value)} disabled={value === ''}>
          <AddIcon fontSize="inherit" />
        </IconButton>
      </Stack>
      <Stack direction="row" flexWrap="wrap">
        {currentTags.map((tag) => <Box m={0.5}><Chip key={tag} label={tag} variant="outlined" onDelete={() => onRemove(tag)} size="small" /></Box>)}
      </Stack>
    </Box>
  );
};

export default TagSelectField;
