import TextField from '@mui/material/TextField';
import { useAppointmentForm } from './context';

const AppointmentSummaryField = () => {
  const {
    form: { formik, helpers },
  } = useAppointmentForm();

  const { values, handleChange, handleBlur } = formik;

  return (
    <TextField
      fullWidth
      id="appointmentSummary"
      name="appointmentSummary"
      multiline
      rows={4}
      label="Appointment Summary"
      value={values.appointmentSummary ?? ''}
      onChange={handleChange}
      onBlur={handleBlur}
      error={helpers.hasError('appointmentSummary')}
      helperText={helpers.getErrorHelpText('appointmentSummary')}
    />
  );
};

export default AppointmentSummaryField;
