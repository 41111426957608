import Paper from '@mui/material/Paper';
import { HOUR_IN_MILLISECONDS } from 'config';
import AppointmentCalendar from 'features/AppointmentCalendar';
import { mapAppointmentToEvents } from 'features/AppointmentCalendar/eventHelpers';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { useMyAppointmentsQuery } from 'providers/api';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TITLE as sectionTitle } from './constants';

const AppointmentsPage = () => {
  const navigate = useNavigate();
  const { teamId } = useProtectedParams('teamId');
  const title = sectionTitle;

  const { data: appointments } = useMyAppointmentsQuery(
    teamId,
    {
      staleTime: HOUR_IN_MILLISECONDS,
    },
  );
  const appointmentCalendarEvents = (appointments?.items
    && appointments.items.length > 0
    ? mapAppointmentToEvents(appointments.items, true)
    : []
  );

  const handleAppointmentClick = (appointmentId: string) => navigate(appointmentId);

  return (
    <Page pageType="full" title={title}>
      <Paper sx={{ p: 2, mb: 2, height: '100%' }}>
        <AppointmentCalendar
          viewType="timeGridWeek"
          appointmentCalendarEvents={appointmentCalendarEvents}
          readOnly
          eventClick={handleAppointmentClick}
        />
      </Paper>
    </Page>
  );
};

export default AppointmentsPage;
