import { NewPatientFeature } from 'features/patient/PatientFeature';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { useNavigate } from 'react-router-dom';
import { TITLE as parentTitle } from '../ClientPatientsPage';
import { TITLE } from './constants';

const NewPatientPage = () => {
  const navigate = useNavigate();
  const { teamId, clientId } = useProtectedParams('teamId', 'clientId');

  return (
    <Page pageType="standard" title={TITLE} parentRelativePath=".." parentTitle={parentTitle}>
      <NewPatientFeature practiceId={teamId} clientId={clientId} onSuccess={() => navigate('..')} />
    </Page>
  );
};

export default NewPatientPage;
