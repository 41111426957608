import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';

interface TitleBoxProps {
  title: string;
  small?: boolean;
  actions?: React.ReactNode;
  children: React.ReactNode;
}

const TitleBox = ({ title, small, actions, children }: TitleBoxProps) => (
  <Paper sx={{ p: 2, mb: 2 }}>
    <Box height={48} display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant={small ? 'subtitle2' : 'h6'} gutterBottom>{title}</Typography>
      <Box>
        {actions}
      </Box>
    </Box>
    <Box mt={1}>
      {children}
    </Box>
  </Paper>
);

export default TitleBox;
