/* global google */
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Loading from 'components/Loading';
import TitleDisplayStack from 'components/TitleDisplayStack';
import { LONDON_CENTER } from 'config';
import AppointmentStatusChip from 'features/AppointmentStatusChip';
import ShareLocation from 'features/ShareLocation';
import VetStatusChip from 'features/VetStatusChip';
import {
  UpdatePublicLocationMutation, usePublicAppointmentLocationGetQuery, usePublicAppointmentQuery, useUpdatePublicAppointmentLocationMutation, withQueryClientDefaults
} from 'providers/api';
import React from 'react';
import { useParams } from 'react-router-dom';
import { DEFAULT_SHORT_DATE_TIME_CONFIG, displayTemporal } from 'utils';
import Layout from '../Layout';

const SharePublicLocationPage = () => {
  const { appointmentId, accessTokenHash } = useParams();
  const [appointmentLocation, setAppointmentLocation] = React.useState<google.maps.LatLngLiteral>();
  const [currentClientLocation, setCurrentClientLocation] = React.useState<google.maps.LatLngLiteral>();
  const publicLocationMutation = useUpdatePublicAppointmentLocationMutation(appointmentId ?? '');

  const { data: publicAppointment, isLoading: publicAppointmentIsLoading } = usePublicAppointmentQuery(
    {
      appointmentId: appointmentId ?? '',
      accessTokenHash: accessTokenHash ?? '', // test by creating an appointment and checking terminal - hashkey is generated here for testing.
    },
  );

  const { data: publicAppointmentLocation } = usePublicAppointmentLocationGetQuery(
    {
      appointmentId: appointmentId ?? '',
      accessTokenHash: accessTokenHash ?? '',
    },
  );

  // TODO: don't default to London here. Let the share Location feature component handle the default.
  React.useEffect(() => {
    setAppointmentLocation({
      lat: publicAppointmentLocation?.geoPoint.latitude ?? LONDON_CENTER.lat,
      lng: publicAppointmentLocation?.geoPoint.longitude ?? LONDON_CENTER.lng,
    });
  }, [publicAppointmentLocation]);

  const handleSavePublicLocation = () => {
    const payload = {
      cmd: {
        entityKey: 'public-user',
        entityType: accessTokenHash,
        geoPoint: {
          latitude: currentClientLocation?.lat ?? 0,
          longitude: currentClientLocation?.lng ?? 0,
        },
        description: 'Appointment public attendee location',
      },
      accessTokenHash: accessTokenHash ?? '',
    };
    currentClientLocation
      && publicLocationMutation.mutate(payload as UpdatePublicLocationMutation);
  };

  if (
    publicAppointmentIsLoading
    || (!publicAppointmentLocation && publicAppointmentIsLoading)) {
    return <Loading />;
  }

  if (!publicAppointment) {
    return (
      <Layout title="Share Your Location">
        <Alert severity="error"> This link has expired. Please contact the practice. </Alert>
      </Layout>
    );
  }

  return (
    <Layout title="Share Your Location">
      <Box width="100%">
        <Paper sx={{ p: 2, mb: 2 }}>
          <TitleDisplayStack
            title="Appointment status"
            display={(
              <Stack direction="row" spacing={1}>
                <AppointmentStatusChip appointmentStatus={publicAppointment.appointmentStatus} />
                <VetStatusChip vetStatus={publicAppointment.etaStatus} />
              </Stack>
            )}
          />
          <Divider sx={{ my: 1 }} />
          <TitleDisplayStack
            title="Date and times"
            display={
              (
                <Typography variant="body2">
                  {`${displayTemporal(publicAppointment.startTime, DEFAULT_SHORT_DATE_TIME_CONFIG)} -
                        ${displayTemporal(publicAppointment.endTime, DEFAULT_SHORT_DATE_TIME_CONFIG)}`}
                </Typography>
              )
            }
          />
          <Divider sx={{ my: 1 }} />
          <TitleDisplayStack
            title="Patient"
            display={<Typography variant="body2">{publicAppointment.patient.name}</Typography>}
          />
          <Divider sx={{ my: 1 }} />
          <TitleDisplayStack
            title="Vet"
            display={
              <Typography variant="body2">{publicAppointment.vet.name}</Typography>
            }
          />
        </Paper>
        <ShareLocation
          currentClientLocation={currentClientLocation}
          appointmentLocation={appointmentLocation}
          onSaveLocation={handleSavePublicLocation}
          setCurrentClientLocation={setCurrentClientLocation}
        />
      </Box>

    </Layout>
  );
};

export default withQueryClientDefaults(SharePublicLocationPage);
