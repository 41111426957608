import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AddressFormFields, initialData as initialAddressData, validation } from 'components/AddressFormFields';
import { FormikActions, FormStack } from 'components/Form';
import TitleBox from 'components/TitleBox';
import {
  ClientDto,
  ContactDto,
  CreateClientCommand,
  CreateClientMutation,
  UpdateClientCommand,
  UpdateClientForm
} from 'providers/api';
import useDefaultForm from 'providers/form/useDefaultForm';
import { UseMutationResult } from 'react-query';
import {
  bool,
  object,
  SchemaOf,
  string
} from 'yup';

const contactSchema: SchemaOf<ContactDto> = object().shape({
  firstName: string().required('First Name is required'),
  lastName: string().required('Last Name is required'),
  email: string().email('Email is not valid').required('Email is required'),
  contactNumber: string().required('Contact number is not valid'),
});

const clientDetailsSchema: SchemaOf<UpdateClientForm> = object().shape({
  name: string().required('Client name is required'),
});

const clientSchema: SchemaOf<CreateClientCommand> = object().shape({
  client: clientDetailsSchema,
  mainContact: object().shape({
    mainContact: contactSchema.required('A main contact is required'),
  }),
  site: object().shape({
    siteAddress: validation.required('A site address is required'),
  }),
  importedFromExternalSystem: bool(),
});

interface ClientFormProps {
  practiceId: string;
  client?: ClientDto;
  mutation: UseMutationResult<string, unknown, CreateClientMutation, unknown> | UseMutationResult<void, unknown, UpdateClientCommand, unknown>;
}
const ClientForm = ({ client, practiceId, mutation }: ClientFormProps) => {
  const {
    formik,
    helpers,
  } = useDefaultForm<CreateClientCommand | UpdateClientCommand>({
    mutation,
    formikConfig: {
      initialValues: {
        client: {
          name: client?.name ?? '',
        },
        mainContact: {
          mainContact: {
            firstName: client?.mainContact.firstName ?? '',
            lastName: client?.mainContact.lastName ?? '',
            email: client?.mainContact.email ?? '',
            contactNumber: client?.mainContact.contactNumber ?? '',
          },
        },
        site: {
          siteAddress: client?.site ? client.site : initialAddressData,
        },
      },
      onSubmit: (form, { setSubmitting }) => {
        setSubmitting(true);

        const mutationPayload = client
          ? form
          : {
            practiceId,
            client: form,
          };

        // Could be a create or an update. Cast to any at the last possible chance to appease TS
        mutation.mutate(mutationPayload as any, {
          onSettled: () => {
            setSubmitting(false);
          },
        });
      },
      validationSchema: clientSchema,
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleAddAdditionalSiteAddressField = (field: string) => {
    setFieldValue(`site.siteAddress.${field}`, '');
  };

  return (
    <form onSubmit={handleSubmit}>
      <TitleBox title="Client Name">
        <TextField
          fullWidth
          id="client.name"
          name="client.name"
          label="Client Name"
          value={values.client.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={helpers.hasError('client.name')}
          helperText={helpers.getErrorHelpText('client.name')}
        />
      </TitleBox>

      <TitleBox title="Main Contact Details">
        <FormStack>
          <FormStack direction={{ xs: 'column', md: 'row' }}>
            <TextField
              fullWidth
              id="mainContact.mainContact.firstName"
              name="mainContact.mainContact.firstName"
              label="First Name"
              value={values.mainContact.mainContact?.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('mainContact.mainContact.firstName')}
              helperText={helpers.getErrorHelpText('mainContact.mainContact.firstName')}
            />
            <TextField
              fullWidth
              id="mainContact.mainContact.lastName"
              name="mainContact.mainContact.lastName"
              label="Last Name"
              value={values.mainContact.mainContact?.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('mainContact.mainContact.lastName')}
              helperText={helpers.getErrorHelpText('mainContact.mainContact.lastName')}
            />
          </FormStack>
          <TextField
            fullWidth
            id="mainContact.mainContact.contactNumber"
            name="mainContact.mainContact.contactNumber"
            label="Contact Number"
            value={values.mainContact.mainContact?.contactNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('mainContact.mainContact.contactNumber')}
            helperText={helpers.getErrorHelpText('mainContact.mainContact.contactNumber')}
          />
          <TextField
            fullWidth
            id="mainContact.mainContact.email"
            name="mainContact.mainContact.email"
            label="Email"
            value={values.mainContact.mainContact?.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('mainContact.mainContact.email')}
            helperText={helpers.getErrorHelpText('mainContact.mainContact.email')}
          />
        </FormStack>
      </TitleBox>

      <TitleBox title="Address">
        <FormStack>
          <AddressFormFields
            baseKey="site.siteAddress"
            values={values.site.siteAddress!}
            hasError={helpers.hasError}
            getErrorHelpText={helpers.getErrorHelpText}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleAddAdditionalAddressField={handleAddAdditionalSiteAddressField}
          />
        </FormStack>
      </TitleBox>

      <Box mt={2}>
        <FormikActions
          formik={formik}
          mutation={mutation}
          submitText={client ? 'Update Client' : 'Save Client'}
          right={['reset', 'submit']}
        />
      </Box>
    </form>
  );
};

export default ClientForm;
