import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EditableSection from 'components/EditableSection';
import TitleDisplayStack from 'components/TitleDisplayStack';
import AppointmentStatusChip from 'features/AppointmentStatusChip';
import { AppointmentDateTimeField, useAppointmentForm } from 'features/ClientAppointmentForm';
import VetStatusChip from 'features/VetStatusChip';
import { AppointmentDto, AppointmentStatus } from 'providers/api';
import React from 'react';
import { DEFAULT_SHORT_DATE_TIME_CONFIG, displayTemporal } from 'utils';

interface AppointmentDateTimeEditableSectionProps {
  appointment: AppointmentDto;
  editable?: boolean;
}

const AppointmentDateTimeEditableSection = ({ appointment, editable }: AppointmentDateTimeEditableSectionProps) => {
  const { form: { formik }, mutation } = useAppointmentForm();
  const { values, setFieldValue } = formik;

  React.useEffect(() => {
    values.startTime && setFieldValue('startTime', values.startTime);
    values.endTime && setFieldValue('endTime', values.endTime);
  }, []);

  return (
    <EditableSection
      title="Schedule"
      mutation={mutation}
      formik={formik}
      enabled={
        editable
        && appointment.appointmentStatus !== AppointmentStatus.Confirmed
      }
    >
      {(editMode) => (
        <>
          <Box>
            <TitleDisplayStack
              title="Appointment State"
              display={(
                <Stack direction="row" spacing={1}>
                  <AppointmentStatusChip appointmentStatus={appointment.appointmentStatus} />
                  <VetStatusChip vetStatus={appointment.etaStatus} />
                </Stack>
              )}
            />
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box>
            <TitleDisplayStack
              title="Date and times"
              display={
                (
                  editMode
                    ? <AppointmentDateTimeField />
                    : (
                      <Typography variant="body2">
                        {`${displayTemporal(appointment.startTime, DEFAULT_SHORT_DATE_TIME_CONFIG)} -
                        ${displayTemporal(appointment.endTime, DEFAULT_SHORT_DATE_TIME_CONFIG)}`}
                      </Typography>
                    )
                )
              }
            />
          </Box>
        </>
      )}
    </EditableSection>
  );
};

export default AppointmentDateTimeEditableSection;
