import { Duration, ZonedDateTime } from '@js-joda/core';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GoogleMap, Marker } from 'components/GoogleMap';
import { INITIAL_CENTER_FOR_UK, INITIAL_ZOOM_FOR_UK, PRACTICE_ENTITY_TYPE } from 'config';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import {
  AppointmentDto,
  AppointmentStatus,
  useLocationQuery,
  UserDto
} from 'providers/api';
import React from 'react';
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams
} from 'react-router-dom';
import {
  DEFAULT_SHORT_DATE_CONFIG,
  DEFAULT_TIME_CONFIG,
  displayTemporal,
  getDuration,
  getDurationHoursMinsSeconds
} from 'utils';
import useDashboardData from './useDashboardData';
import useVetLocations from './useVetLocations';

export type DashboardState = {
  scheduledVets: Record<string, UserDto>,
  appointments: AppointmentDto[],
  markerStates: Record<string, Boolean> | undefined;
  vetIdsArrayForAppointmentsWithoutLocations: string[];
  setMarkerSelectedState: (id: string, state: boolean) => void;
};

type ContextType = { dashboardState: DashboardState };
export const useDashboardState = () => useOutletContext<ContextType>();

const DashboardPage = () => {
  const { teamId } = useProtectedParams('teamId');
  const { vetId: currentVetId } = useParams();
  const { data: practiceLocation } = useLocationQuery({
    entityId: teamId,
    entityType: PRACTICE_ENTITY_TYPE,
  });
  const navigate = useNavigate();

  const {
    indexes: {
      scheduledVetsIndexedById,
      vetLocationsIndexedById,
      appointmentLocationsIndexedById,
      appointmentsIndexedByVetId,
    },
    arrays: {
      appointments,
      vetIdsArrayForAppointmentsWithoutLocations,
    },
  } = useDashboardData(teamId);

  const realtimeVetLocationsIndexedById = useVetLocations(vetLocationsIndexedById, appointmentsIndexedByVetId);

  const [markerStates, setMarkerStates] = React.useState<Record<string, boolean>>({});

  const setMarkerSelectedState = (targetMarkerId: string, state: boolean) => setMarkerStates(
    (currentMarkerStates) => (currentMarkerStates
      ? {
        ...currentMarkerStates,
        [targetMarkerId]: state,
      }
      : { [targetMarkerId]: state }),
  );

  const handleVetSelect = (vetId: string) => {
    setMarkerSelectedState(vetId, true);
    navigate(`${vetId}/info`);
  };

  const dashboardState: DashboardState = {
    markerStates,
    setMarkerSelectedState,
    scheduledVets: scheduledVetsIndexedById,
    appointments: appointments ?? [],
    vetIdsArrayForAppointmentsWithoutLocations,
  };

  const vetSpecificAppointments = React.useMemo(
    () => (appointments
      ? appointments.filter((appointment) => appointment.vet.entityId === currentVetId)
      : []),
    [currentVetId, appointments],
  );

  return (
    <Page pageType="full" title="Dashboard">
      <Stack width="100%" height="100%" direction="row" component={Paper}>
        <GoogleMap
          disableDefaultUI
          zoomControl
          onClick={() => { }}
          draggableCursor="crosshair"
          zoom={
            practiceLocation
              ? 11
              : INITIAL_ZOOM_FOR_UK
          }
          center={
            practiceLocation
              ? {
                lat: practiceLocation.geoPoint.latitude,
                lng: practiceLocation.geoPoint.longitude,
              }
              : INITIAL_CENTER_FOR_UK
          }
        >
          {
            practiceLocation
            && (
              <Marker
                position={{ lat: practiceLocation.geoPoint.latitude, lng: practiceLocation.geoPoint.longitude }}
                selected
                label="Practice location"
              />
            )
          }
          {/* only show ALL vet markers if a SINGLE vet has not been selected */}
          {!currentVetId && Object.entries(scheduledVetsIndexedById).map(
            ([vetId, vet]) => (
              realtimeVetLocationsIndexedById[vetId] && (
                <Marker
                  key={vetId}
                  position={{
                    lat: realtimeVetLocationsIndexedById[vetId].geoPoint.latitude,
                    lng: realtimeVetLocationsIndexedById[vetId].geoPoint.longitude,
                  }}
                  selected
                  iconType={getDuration(realtimeVetLocationsIndexedById[vetId].dateModified) < (60 * 5) ? 'vet' : 'vetOutdated'}
                  info={`${vet.forename} ${vet.surname} <br />
                      Location last confirmed: <br /> ${Duration.between(realtimeVetLocationsIndexedById[vetId].dateModified, ZonedDateTime.now())
                      .toHours() < 48
                      ? getDurationHoursMinsSeconds(realtimeVetLocationsIndexedById[vetId].dateModified)
                      : 'No recent update'}`}
                  showInfo={markerStates ? markerStates[vet.userId] : false}
                  onClick={() => handleVetSelect(vet.userId)}
                  onInfoWindowClose={() => setMarkerSelectedState(vet.userId, false)}
                  label={{
                    color: 'white',
                    fontWeight: 'bold',
                    text: `${scheduledVetsIndexedById[vetId].forename} ${scheduledVetsIndexedById[vetId].surname}`,
                  }}
                />
              )
            ),
          )}

          {/* only show SINGLE vet if there is one selected */}
          {
            currentVetId && realtimeVetLocationsIndexedById[currentVetId] && scheduledVetsIndexedById[currentVetId] && (
              <Marker
                position={{
                  lat: realtimeVetLocationsIndexedById[currentVetId].geoPoint.latitude,
                  lng: realtimeVetLocationsIndexedById[currentVetId].geoPoint.longitude,
                }}
                selected
                iconType={getDuration(realtimeVetLocationsIndexedById[currentVetId].dateModified) < (60 * 5) ? 'vet' : 'vetOutdated'}
                info={`${scheduledVetsIndexedById[currentVetId].forename} ${scheduledVetsIndexedById[currentVetId].surname} <br />
                Location last confirmed: <br /> ${Duration.between(realtimeVetLocationsIndexedById[currentVetId].dateModified, ZonedDateTime.now())
                    .toHours() > 48
                    ? getDurationHoursMinsSeconds(realtimeVetLocationsIndexedById[currentVetId].dateModified)
                    : 'No recent update'}`}
                showInfo={markerStates ? markerStates[currentVetId] : false}
                onClick={() => handleVetSelect(currentVetId)}
                onInfoWindowClose={() => setMarkerSelectedState(currentVetId, false)}
                label={{
                  color: 'white',
                  fontWeight: 'bold',
                  text: `${scheduledVetsIndexedById[currentVetId].forename} ${scheduledVetsIndexedById[currentVetId].surname}`,
                }}
              />
            )
          }

          {/* only show appointment's marker if the location has been set and a vet has been selected */}
          {vetSpecificAppointments.map(
            (appointment) => (
              appointmentLocationsIndexedById[appointment.entityId] && (
                <Marker
                  key={appointment.entityId}
                  position={{
                    lat: appointmentLocationsIndexedById[appointment.entityId].geoPoint.latitude,
                    lng: appointmentLocationsIndexedById[appointment.entityId].geoPoint.longitude,
                  }}
                  selected
                  iconType="location"
                  info={`
                    ${displayTemporal(appointment.startTime, DEFAULT_TIME_CONFIG)}: ${appointment.appointmentSummary}<br />
                    Status: ${AppointmentStatus[appointment.appointmentStatus]}
                  `}
                  showInfo={markerStates ? markerStates[appointment.entityId] : false}
                  onClick={() => {
                    handleVetSelect(appointment.vet.entityId);
                    setMarkerSelectedState(appointment.entityId, true);
                  }}
                  onInfoWindowClose={() => setMarkerSelectedState(appointment.entityId, false)}
                />
              )
            ),
          )}
        </GoogleMap>

        <Box width="30%" maxWidth={420} minWidth={300} display="flex" flexDirection="column">
          <Stack p={2} pb={0}>
            <Typography variant="h4">{displayTemporal(new Date(), DEFAULT_SHORT_DATE_CONFIG)}</Typography>
            <Divider />
          </Stack>

          <Box p={2} flexGrow={1} flexBasis="auto" overflow="auto">
            <Outlet context={{ dashboardState }} />
          </Box>
        </Box>
      </Stack>
    </Page>
  );
};
export default DashboardPage;
