import DateFnsAdapter from '@date-io/date-fns';
import {
  convert, LocalDate, LocalTime, nativeJs, ZonedDateTime, ZoneId
} from '@js-joda/core';
import {
  Alert, Divider, Stack, Typography, useMediaQuery
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import EditableSection from 'components/EditableSection';
import EnumCheckboxList from 'components/EnumChecklist';
import TitleDisplayStack from 'components/TitleDisplayStack';
import { enGB } from 'date-fns/locale';
import { IsoDayOfWeekAmend, WeekCommencingProps } from 'modules/practice/StaffAvailability/EditCalendarEventForm/constants';
import {
  CalendarEventDto, FileResponse, IsoDayOfWeek, UpdateCalendarEventTimeMutation
} from 'providers/api';
import { useDefaultForm } from 'providers/form';
import React from 'react';
import { UseMutationResult } from 'react-query';
import {
  DEFAULT_DAYMONTH_DATE_CONFIG, DEFAULT_SHORT_DATE_TIME_CONFIG, displayTemporal, enumValues, getSelectedDay, isNilOrEmpty, isValidDate, startAndEndOfWeek, updateTime
} from 'utils';
import {
  array, date, mixed, number, object, SchemaOf
} from 'yup';

interface DatesRepeatFieldInputs {
  startDate: Date | null;
  startTime: Date | null;
  endTime: Date | null;
  repeatDays: IsoDayOfWeek[];
  repeatDurationInWeeks: number;
}

const DatesRepeatFormSchema: SchemaOf<DatesRepeatFieldInputs> = object().shape({
  startDate: date()
    .nullable()
    .required('A start date is required'),
  startTime: date()
    .nullable()
    .required('A start time is required')
    .test({
      name: 'start-time-is-before-end-time?',
      message: 'The start time cannot be after the finish time',
      test: (startTime, context) => {
        if (isNilOrEmpty(startTime)
          || (isNilOrEmpty(context.parent.endTime))) {
          return true;
        }
        const { endTime } = context.parent;
        return (startTime ? startTime < endTime : true);
      },
    }),
  endTime: date()
    .nullable()
    .required('A finish time is required'),
  repeatDays: array().of(mixed().oneOf(enumValues(IsoDayOfWeek))).required('A day is required'),
  repeatDurationInWeeks: number().min(1).required('Please enter a number for how many weeks to repeat this event'),
});

interface DatesRepeatFieldProps {
  calendarEvent: CalendarEventDto
  practiceId: string
  calendarEventId: string
  weekCommencing?: WeekCommencingProps | undefined
  mutation: UseMutationResult<FileResponse, unknown, UpdateCalendarEventTimeMutation, unknown>
}

const DatesRepeatField = ({
  mutation,
  calendarEvent,
  practiceId,
  calendarEventId,
}: DatesRepeatFieldProps) => {
  const {
    formik, helpers,
  } = useDefaultForm<DatesRepeatFieldInputs>({
    mutation,
    formikConfig: {
      initialValues: {
        startDate: convert(calendarEvent.startTime).toDate() ?? null,
        startTime: convert(calendarEvent.startTime).toDate() ?? null,
        endTime: convert(calendarEvent.endTime).toDate() ?? null,
        repeatDays: calendarEvent.repeatDays ?? [],
        repeatDurationInWeeks: calendarEvent.repeatDurationInWeeks ?? 1,
      },
      onSubmit: (form, { setSubmitting }) => {
        setSubmitting(true);
        const startDateBase = LocalDate.from(nativeJs(form.startDate));
        const startTime = LocalTime.from(nativeJs(form.startTime));
        const endTime = LocalTime.from(nativeJs(form.endTime));
        const startTimeZoned = ZonedDateTime.of(startDateBase, startTime, ZoneId.systemDefault());
        const endTimeZoned = ZonedDateTime.of(startDateBase, endTime, ZoneId.systemDefault());

        const payload = {
          start: startTimeZoned,
          end: endTimeZoned,
          repeatDays: form.repeatDays,
          repeatDurationInWeeks: form.repeatDurationInWeeks,
        };
        setSubmitting(true);
        mutation.mutate(
          { practiceId, calendarEventId, form: payload },
          {
            onSuccess: () => {
              setSubmitting(false);
            },
          },
        );
      },
      validationSchema: DatesRepeatFormSchema,
    },
  });

  const {
    values,
    handleBlur,
    setFieldValue,
    setValues,
    handleChange,
  } = formik;

  const updateStartTime = (startTime: Date | null) => {
    setFieldValue(
      'startTime',
      !isNilOrEmpty(startTime) && isValidDate(startTime)
        ? updateTime(values.startDate, startTime)
        : null,
    );
  };

  const updateEndTime = (endTime: Date | null) => {
    setFieldValue(
      'endTime',
      !isNilOrEmpty(endTime) && isValidDate(endTime)
        ? updateTime(values.startDate, endTime)
        : null,
    );
  };

  const selectedDay = getSelectedDay(values.startDate);
  const weekCommencing = startAndEndOfWeek(values.startDate);

  // useEffect manages the checkboxes dependent on startDate change (NOT onClick)
  React.useEffect(() => {
    if (!selectedDay) return;
    if (!values.repeatDays) {
      setFieldValue('repeatDays', [selectedDay]);
      return;
    }
    if (selectedDay && values.repeatDays.includes(selectedDay)) return;
    setFieldValue('repeatDays', [...values.repeatDays, selectedDay]);
  }, [selectedDay]);

  // Manages the checkboxes dependent on click
  const handleRepeatDayClick = (day: any) => {
    if (!values.repeatDays) {
      setFieldValue('repeatDays', [day]);
      return;
    }
    if (values.repeatDays.includes(day)) {
      setFieldValue('repeatDays', values.repeatDays.filter((dayName: any) => dayName !== day));
    } else {
      setFieldValue('repeatDays', [...values.repeatDays, day]);
    }
  };

  // Needed setValues to stop validation bug with Yup. Many setFieldValues resulted in errors saying the startTime
  // is after the endTime when it isn't, which is one of the tests.
  React.useEffect(() => {
    setValues({
      startDate: values.startDate,
      startTime: values.startTime ? updateTime(values.startDate, values.startTime) : null,
      endTime: values.endTime ? updateTime(values.startDate, values.endTime) : null,
      repeatDays: values.repeatDays,
      repeatDurationInWeeks: values.repeatDurationInWeeks,
    });
  }, [values.startDate]);

  const allDaysDisabled = [0, 1, 2, 3, 4, 5, 6, 7];
  const minWidth1200 = useMediaQuery('(min-width:1200px)');

  // useEffect manages the checkboxes dependent on date change
  React.useEffect(() => {
    if (!selectedDay) return;
    if (!values.repeatDays) {
      setFieldValue('repeatDays', [selectedDay]);
      return;
    }
    if (selectedDay && values.repeatDays.includes(selectedDay)) return;
    setFieldValue('repeatDays', [...values.repeatDays, selectedDay]);
  }, [selectedDay]);

  return (
    // TODO: Change date adapter. While there is a JS Joda adapter, it appears to be broken for MUI Time Picker.
    // When Temporal is passed in, there's an infinite loop.
    // https://dev.azure.com/buzzinteractiveteam/Aegis/_workitems/edit/8833/
    <LocalizationProvider adapterLocale={enGB} dateAdapter={DateFnsAdapter}>
      <EditableSection title="Date and Times" mutation={mutation} formik={formik}>
        {(editMode) => (
          <>
            <TitleDisplayStack
              title="Date and Times"
              display={
                (
                  editMode
                    ? (
                      <>
                        <Stack direction="row" spacing={2}>
                          <DatePicker
                            label="Date"
                            value={values.startDate}
                            onChange={(dateItem: any) => setFieldValue('startDate', dateItem)}
                            onClose={() => {
                              formik.setFieldTouched('startDate', true);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="startDate"
                                name="startDate"
                                fullWidth
                                onBlur={handleBlur}
                              />
                            )}
                          />
                          <TimePicker
                            label="Start Time"
                            value={values.startTime}
                            onChange={(dateItem: any) => updateStartTime(dateItem)}
                            onClose={() => formik.setFieldTouched('startTime', true)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="startTime"
                                name="startTime"
                                fullWidth
                                onBlur={handleBlur}
                              />
                            )}
                          />
                          <TimePicker
                            label="Finish Time"
                            value={values.endTime}
                            onChange={(dateItem: any) => updateEndTime(dateItem)}
                            onClose={() => formik.setFieldTouched('endTime', true)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="endTime"
                                name="endTime"
                                fullWidth
                                onBlur={handleBlur}
                              />
                            )}
                          />
                        </Stack>
                        <Stack>
                          {helpers.hasError('startDate') && <Alert sx={{ marginTop: 2 }} severity="error">{helpers.getErrorHelpText('startDate')}</Alert>}
                          {helpers.hasError('startTime') && <Alert sx={{ marginTop: 2 }} severity="error">{helpers.getErrorHelpText('startTime')}</Alert>}
                          {helpers.hasError('endTime') && <Alert sx={{ marginTop: 2 }} severity="error">{helpers.getErrorHelpText('endTime')}</Alert>}
                        </Stack>
                      </>
                    )
                    : (
                      <Typography variant="body2">
                        {`${values.startTime && displayTemporal(values.startTime, DEFAULT_SHORT_DATE_TIME_CONFIG)} -
                    ${values.endTime && displayTemporal(values.endTime, { hour: 'numeric', minute: '2-digit' })}`}
                      </Typography>
                    )
                )
              }
            />
            <Divider sx={{ my: 3 }} />
            <TitleDisplayStack
              title="Days"
              subtitle={
                weekCommencing
                  ? `
                     W/c
                     ${displayTemporal(weekCommencing.monday, DEFAULT_DAYMONTH_DATE_CONFIG)}
                     -
                     ${displayTemporal(weekCommencing.sunday, DEFAULT_DAYMONTH_DATE_CONFIG)}
                    `
                  : undefined
              }
              display={
                editMode
                  ? (
                    <EnumCheckboxList
                      checkboxEnum={IsoDayOfWeekAmend}
                      selection={values.repeatDays ?? []}
                      handleCheckboxClick={handleRepeatDayClick}
                      direction={minWidth1200 ? 'row' : 'column'}
                      disabled={selectedDay ? [selectedDay] : undefined}
                    />
                  )
                  : (
                    <EnumCheckboxList
                      checkboxEnum={IsoDayOfWeekAmend}
                      selection={values.repeatDays ?? []}
                      handleCheckboxClick={handleRepeatDayClick}
                      direction={minWidth1200 ? 'row' : 'column'}
                      disabled={allDaysDisabled}
                    />
                  )
              }
            />
            <Divider sx={{ my: 3 }} />
            <TitleDisplayStack
              title="Weeks"
              display={
                editMode
                  ? (
                    <TextField
                      fullWidth
                      id="repeatDurationInWeeks"
                      name="repeatDurationInWeeks"
                      label="Duration in weeks"
                      value={values.repeatDurationInWeeks}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={helpers.hasError('repeatDurationInWeeks')}
                      helperText={helpers.getErrorHelpText('repeatDurationInWeeks')}
                    />
                  )
                  : (
                    <Typography variant="body2">
                      {values.repeatDurationInWeeks}
                    </Typography>
                  )
              }
            />
          </>
        )}
      </EditableSection>
    </LocalizationProvider>
  );
};

export default DatesRepeatField;
