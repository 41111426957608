import { TeamDto } from 'providers/api';
import { createContext } from 'react';

export interface ITeamContext {
  state: {
    activeTeam?: TeamDto;
    teams?: TeamDto[];
  },
  actions: {
    setActiveTeam: (teamId: string) => void;
    clearTeamsStoredInLocalStorage: () => void;
  }
}

const TeamContext = createContext<ITeamContext | null>(null);

export default TeamContext;
