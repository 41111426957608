import Page from 'modules/Page';
import { useCreatePracticeInviteMutation } from 'providers/api';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TITLE as parentTitle } from '../PracticeInvitationsPage';
import { TITLE as pageTitle } from './constants';
import PracticeInviteForm from './PracticeInviteForm';

const NewPracticePage = () => {
  const navigate = useNavigate();
  const mutation = useCreatePracticeInviteMutation({
    onSuccess: () => {
      navigate('../invites');
    },
  });

  return (
    <Page title={pageTitle} pageType="standard" parentTitle={parentTitle} parentRelativePath="../invites">
      <PracticeInviteForm mutation={mutation} />
    </Page>
  );
};

export default NewPracticePage;
