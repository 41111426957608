import '@js-joda/timezone';
import {
  RawTimeZone,
  rawTimeZones
} from '@vvo/tzdb';
import {
  find,
  propEq
} from 'ramda';
import {
  getSignString,
  padNumber
} from './number';

export const minutesToHours = (minutes: number) => {
  const absMinutes = Math.abs(minutes);
  const hours = Math.floor(absMinutes / 60);
  const remainingMinutes = absMinutes % 60;

  return `${padNumber(hours, 2)}:${padNumber(remainingMinutes, 2)}`;
};

export const countryToFlag = (isoCode: string) => (typeof String.fromCodePoint !== 'undefined' ? (
  isoCode
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
) : isoCode);

export interface TimeZoneWithLabel extends RawTimeZone {
  label: string;
}

export const TIME_ZONES: TimeZoneWithLabel[] = rawTimeZones.map((tz) => ({
  ...tz,
  label: `${countryToFlag(tz.countryCode)} ${getSignString(tz.rawOffsetInMinutes)}${minutesToHours(tz.rawOffsetInMinutes)} ${tz.name}`,
}));

export const getFlagFromCountryName = (name: string): string => countryToFlag(find(propEq('name', name), TIME_ZONES)?.countryCode ?? '');
