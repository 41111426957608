/* global google */

import React from 'react';

const rendererOptions = {
  suppressMarkers: true,
  routeIndex: 0,
};
interface RouteDetails {
  origin: google.maps.LatLngLiteral;
  destination: google.maps.LatLngLiteral;
  waypoints: google.maps.DirectionsWaypoint[];
  optimise?: boolean;
}

export interface RouteRequest {
  details: RouteDetails;
  callback?: (results: google.maps.DirectionsResult | null) => void;
}

const useDirectionRenderer = () => {
  const [map, setMap] = React.useState<google.maps.Map>();
  const [routes, setRoutes] = React.useState<RouteRequest[]>();
  const [directionsService, setDirectionsService] = React.useState<google.maps.DirectionsService>();
  const [displayServiceList, setDisplayServiceList] = React.useState<google.maps.DirectionsRenderer[]>([]);

  const clearDisplayServices = () => {
    displayServiceList.forEach((displayService) => displayService.setMap(null));
    setDisplayServiceList([]);
  };

  const calcRoute = () => {
    if (!directionsService || !routes || !map) return;
    clearDisplayServices();

    routes.forEach((route) => {
      const request: google.maps.DirectionsRequest = {
        origin: route.details.origin,
        destination: route.details.destination,
        waypoints: route.details.waypoints,
        optimizeWaypoints: route.details.optimise, // INFO: Costs more than a standard request
        travelMode: google.maps.TravelMode.DRIVING,
      };

      const directionsDisplay = new google.maps.DirectionsRenderer(rendererOptions);
      directionsDisplay.setMap(map);

      directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          directionsDisplay.setDirections(result);
          setDisplayServiceList((existingDisplayServices) => [...existingDisplayServices, directionsDisplay]);
        }
        route.callback && route.callback(result);
      });
    });
  };

  React.useEffect(() => {
    setDirectionsService(new google.maps.DirectionsService());
  }, []);

  React.useEffect(
    () => {
      if (map && directionsService && routes && routes.length > 0) {
        calcRoute();
        setMap(undefined);
      }
    },
    [directionsService, map, routes],
  );

  const initRoutes = (
    newMap: google.maps.Map,
    newRoutes: RouteRequest[],
  ) => {
    setMap(newMap);
    setRoutes(newRoutes);
  };

  return initRoutes;
};

export default useDirectionRenderer;
