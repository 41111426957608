import { Divider, Stack } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import EditableSection from 'components/EditableSection';
import TitleDisplayStack from 'components/TitleDisplayStack';
import {
  CalendarEventDto, CalendarEventType, FileResponse, UpdateCalendarEventInfoMutation
} from 'providers/api';
import { useDefaultForm } from 'providers/form';
import React from 'react';
import { UseMutationResult } from 'react-query';
import { enumKeys, enumValues, splitCaps } from 'utils';
import {
  mixed, object, SchemaOf, string
} from 'yup';

interface DetailsFormInputs {
  title: string;
  notes: string;
  type: CalendarEventType;
}

const DetailsFieldSchema: SchemaOf<DetailsFormInputs> = object().shape({
  title: string().required('A title is required'),
  notes: string().required('Please leave a note for context'),
  type: mixed().oneOf(enumValues(CalendarEventType)).required('Please select an event type'),
});

interface DetailsFieldProps {
  mutation: UseMutationResult<FileResponse, unknown, UpdateCalendarEventInfoMutation, unknown>
  calendarEvent: CalendarEventDto
  practiceId: string
  calendarEventId: string
}

const DetailsField = ({ mutation, calendarEvent, practiceId, calendarEventId }: DetailsFieldProps) => {
  const {
    formik,
    helpers,
  } = useDefaultForm<DetailsFormInputs>({
    mutation,
    formikConfig: {
      initialValues: {
        title: calendarEvent.title ?? '',
        notes: calendarEvent.notes ?? '',
        type: calendarEvent.type ?? null,
      },
      onSubmit: (form, { setSubmitting }) => {
        setSubmitting(true);
        mutation.mutate(
          { practiceId, calendarEventId, form },
          {
            onSuccess: () => {
              setSubmitting(false);
            },
          },
        );
      },
      validationSchema: DetailsFieldSchema,
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    setFieldValue,
  } = formik;

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('type', event.target.value);
  };

  return (
    <EditableSection title="Details" mutation={mutation} formik={formik}>
      {(editMode) => (
        <Stack spacing={2}>
          <TitleDisplayStack
            title="Title"
            display={(
              editMode
                ? (
                  <TextField
                    fullWidth
                    id="title"
                    name="title"
                    label="Title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={helpers.hasError('title')}
                    helperText={helpers.getErrorHelpText('title')}
                  />

                )
                : (
                  <Typography variant="body2">
                    {values.title}
                  </Typography>
                )
            )}
          />
          <Divider sx={{ my: 3 }} />
          <TitleDisplayStack
            title="Notes"
            display={(
              editMode
                ? (
                  <TextField
                    fullWidth
                    id="notes"
                    name="notes"
                    label="Notes"
                    value={values.notes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={helpers.hasError('notes')}
                    helperText={helpers.getErrorHelpText('notes')}
                    multiline
                    minRows={2}
                  />
                )
                : (
                  <Typography variant="body2">
                    {values.notes}
                  </Typography>
                )
            )}
          />
          <Divider sx={{ my: 3 }} />
          <TitleDisplayStack
            title="Type"
            display={(
              editMode
                ? (
                  <TextField
                    id="Type"
                    select
                    fullWidth
                    label="Type"
                    value={values.type}
                    onChange={handleTypeChange}
                    onBlur={handleBlur}
                    helperText={helpers.getErrorHelpText('type')}
                  >
                    {
                      enumKeys(CalendarEventType).map((option) => (
                        <MenuItem key={option} value={(CalendarEventType as any)[option]}>
                          {splitCaps(option)}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                )
                : (
                  <Typography variant="body2">
                    {splitCaps(CalendarEventType[values.type])}
                  </Typography>
                )
            )}
          />
        </Stack>
      )}
    </EditableSection>
  );
};

export default DetailsField;
