import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import { ArrivetRouteObject, RouteConfiguration } from 'modules/interfaces';
import CreateValidatedLocationPanel from './CreateVaildatedLocationPanel';
import LocationsPage from './LocationsPage';
import ValidatedLocationListPanel from './ValidatedLocationListPanel';
import ValidatedLocationPanel from './ValidatedLocationPanel';

export const defaultRoute = 'locations';

const routes: (config: RouteConfiguration) => ArrivetRouteObject[] = () => [{
  path: 'location',
  mainNavigation: {
    title: 'Locations',
    group: '',
    iconComponent: <EditLocationAltIcon />,
  },
  children: [
    {
      element: <LocationsPage />,
      children: [
        {
          index: true,
          element: <ValidatedLocationListPanel />,
        },
        {
          path: ':locationId',
          element: <ValidatedLocationPanel />,
        },
        {
          path: 'create',
          element: <CreateValidatedLocationPanel />,
        },
      ],
    },
  ],
}];

export default routes;
