import { ArrivetRouteObject, RouteConfiguration } from 'modules/interfaces';
import { Navigate } from 'react-router-dom';
import appointmentsRoutes from './appointment';
import locationRoutes from './locations';
import patientRoutes, { defaultRoute as patientDefaultRoute } from './patient';
import userRoutes from './user';

export const defaultRoute = patientDefaultRoute;

const routes: (config: RouteConfiguration) => ArrivetRouteObject[] = (config) => {
  const patient = patientRoutes(config);
  const user = userRoutes(config);
  const appointments = appointmentsRoutes(config);
  const locations = locationRoutes(config);

  return [
    {
      index: true,
      element: <Navigate to={defaultRoute} />,
    },
    ...appointments,
    ...user,
    ...patient,
    ...locations,
  ];
};

export default routes;
