import DateFnsAdapter from '@date-io/date-fns';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import enGB from 'date-fns/locale/en-GB';
import useProtectedParams from 'hooks/useProtectedParams';
import useValidateVetAvailable from 'hooks/useValidateVetAvailable';
import { VetAvailability } from 'hooks/useValidateVetAvailable/useValidateVetAvailable';
import React from 'react';
import { isNilOrEmpty, isValidDate, updateTime } from 'utils';
import { useAppointmentForm } from './context';

const AppointmentDateTimeField = () => {
  const { form: { formik, helpers } } = useAppointmentForm();
  const { handleBlur, setFieldValue, values, setValues } = formik;
  const { teamId } = useProtectedParams('teamId');
  const { vetAvailability } = useValidateVetAvailable({
    teamId,
    vetId: values.vetId ?? '',
    startTime: values.startTime ?? null,
    endTime: values.endTime ?? null,
  });

  const updateStartTime = (startTime: Date | null) => {
    setFieldValue(
      'startTime',
      !isNilOrEmpty(startTime) && isValidDate(startTime)
        ? updateTime(values.startDate, startTime)
        : null,
    );
  };

  const updateEndTime = (endTime: Date | null) => {
    setFieldValue(
      'endTime',
      !isNilOrEmpty(endTime) && isValidDate(endTime)
        ? updateTime(values.startDate, endTime)
        : null,
    );
  };

  React.useEffect(() => {
    // Needed setValues to stop validation bug with Yup. Many setFieldValues resulted in errors saying the startTime
    // is after the endTime when it isn't, which is one of the tests.
    setValues({
      startDate: values.startDate,
      startTime: values.startTime ? updateTime(values.startDate, values.startTime) : null,
      endTime: values.endTime ? updateTime(values.startDate, values.endTime) : null,
      clientId: values.clientId,
      appointmentType: values.appointmentType,
      appointmentPriority: values.appointmentPriority,
      attendeeIds: values.attendeeIds,
      appointmentSummary: values.appointmentSummary,
      patientId: values.patientId,
      primaryContactId: values.primaryContactId,
      vetId: values.vetId,
      contactDetails: values.contactDetails,
      veterinaryNotes: values.veterinaryNotes,
    });
  }, [values.startDate]);

  return (
    // TODO: Change date adapter. While there is a JS Joda adapter, it appears to be broken for MUI Time Picker.
    // When Temporal is passed in, there's an infinite loop.
    // https://dev.azure.com/buzzinteractiveteam/Aegis/_workitems/edit/8833/
    <LocalizationProvider adapterLocale={enGB} dateAdapter={DateFnsAdapter}>
      <Stack direction="row" spacing={2}>
        <DatePicker
          label="Date"
          disablePast
          value={values.startDate}
          onChange={(dateItem: any) => setFieldValue('startDate', dateItem)}
          onClose={() => formik.setFieldTouched('startDate', true)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              id="startDate"
              name="startDate"
              onBlur={handleBlur}
            />
          )}
        />
        <TimePicker
          label="Start Time"
          value={values.startTime}
          onChange={(dateItem: any) => updateStartTime(dateItem)}
          onClose={() => {
            formik.setFieldTouched('startTime', true);
            // below ensures when times are autofilled when clicking appointment slot that validation error messages can show
            if (values.endTime !== null) formik.setFieldTouched('endTime', true);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id="startTime"
              name="startTime"
              fullWidth
              onBlur={handleBlur}
            />
          )}
        />
        <TimePicker
          label="Finish Time"
          value={values.endTime}
          onChange={(dateItem: any) => updateEndTime(dateItem)}
          onClose={() => {
            formik.setFieldTouched('endTime', true);
            // below ensures when times are autofilled when clicking appointment slot that validation error messages can show
            if (values.startTime !== null) formik.setFieldTouched('startTime', true);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id="endTime"
              name="endTime"
              fullWidth
              onBlur={handleBlur}
            />
          )}
        />
      </Stack>
      <Stack>
        {helpers.hasError('startDate') && <Alert sx={{ marginTop: 2 }} severity="error">{helpers.getErrorHelpText('startDate')}</Alert>}
        {helpers.hasError('startTime') && <Alert sx={{ marginTop: 2 }} severity="error">{helpers.getErrorHelpText('startTime')}</Alert>}
        {helpers.hasError('endTime') && <Alert sx={{ marginTop: 2 }} severity="error">{helpers.getErrorHelpText('endTime')}</Alert>}
        {
          vetAvailability === VetAvailability.UnavailableOnLeaveOrOutsideWorkingHours
          && (
            <Alert severity="error" sx={{ mt: 2 }}>
              The vet you have selected is marked as unavailable for your selected date and time.

              Please only save the appointment if you are certain the vet can make it.
            </Alert>
          )
        }
        {
          vetAvailability === VetAvailability.NoData
          && (
            <Alert severity="info" sx={{ mt: 2 }}>
              The vet you have selected has no availability information for this date.

              You may wish to add this information in the Staff Availability section for more accurate booking.
            </Alert>
          )

        }
      </Stack>
    </LocalizationProvider>
  );
};

export default AppointmentDateTimeField;
