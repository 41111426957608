import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ValidatedLocationDto } from 'providers/api';

const getValidatedBy = (location: ValidatedLocationDto) => {
  if (location.validatingVetUser) return 'Validated by vet';
  if (location.validatingClientUser) return 'Validated by client';
  return 'Not validated';
};

const getSecondaryActionIcon = (
  currentLocationId: string,
  selectedLocationId: string | undefined,
  confirmedLocationId: string | undefined,
  markedForRemoval: boolean,
) => (
  <Stack direction="row" spacing={1}>
    {currentLocationId === confirmedLocationId && <CheckCircleIcon color="success" />}
    {currentLocationId === selectedLocationId && <NotListedLocationIcon />}
    {
      markedForRemoval
      && (
        <Tooltip title="Marked for deletion by client">
          <ErrorOutlinedIcon color="error" />
        </Tooltip>
      )
    }
  </Stack>
);

interface ValidatedLocationListProps {
  validatedLocations: ValidatedLocationDto[];
  highlightedValidatedLocation?: string;
  selectedValidatedLocation?: string;
  confirmedValidatedLocation?: string;
  onClick?: (validatedLocationId: string) => void;
  onMouseOver?: (validatedLocationId: string) => void;
  onMouseOut?: () => void;
}

const ValidatedLocationList = ({
  validatedLocations,
  highlightedValidatedLocation,
  selectedValidatedLocation,
  confirmedValidatedLocation,
  onClick,
  onMouseOver,
  onMouseOut,
}: ValidatedLocationListProps) => (
  <List>
    {validatedLocations.map((validatedLocation) => (
      <>
        <ListItem
          sx={{
            '&.Mui-selected': {
              bgcolor: 'primary.main',
              display: 'block',
            },
          }}
          key={validatedLocation.entityId}
          alignItems="flex-start"
          selected={validatedLocation.entityId === highlightedValidatedLocation}
          onClick={() => onClick && onClick(validatedLocation.entityId)}
          onMouseOut={() => onMouseOut && onMouseOut()}
          onMouseOver={() => onMouseOver && onMouseOver(validatedLocation.entityId)}
          secondaryAction={getSecondaryActionIcon(
            validatedLocation.entityId,
            selectedValidatedLocation,
            confirmedValidatedLocation,
            validatedLocation.markedForRemoval,
          )}
        >
          <ListItemText
            primary={validatedLocation.description}
            secondary={(
              <Stack>
                <Typography component="div" variant="caption">
                  {`tags: ${validatedLocation.tags && validatedLocation.tags.join(', ')}`}
                </Typography>
                <Typography component="div" variant="caption">
                  {getValidatedBy(validatedLocation)}
                </Typography>
              </Stack>

            )}
            secondaryTypographyProps={{ variant: 'caption' }}
          />
        </ListItem>
        <Divider component="li" />
      </>
    ))}
  </List>
);

export default ValidatedLocationList;
