import Button from '@mui/material/Button';
import {
  CalendarEventDto, DeleteCalendarEventMutation, FileResponse, UpdateCalendarEventInfoMutation, UpdateCalendarEventTimeMutation,
  UpdateCalendarEventUserMutation
} from 'providers/api';
import React from 'react';
import { UseMutationResult } from 'react-query';
import DatesRepeatField from './DatesRepeatField';
import DetailsField from './DetailsField';
import VetSelectField from './VetSelectField';

export interface CalendarEventFormProps {
  updateCalendarEventUser: UseMutationResult<FileResponse, unknown, UpdateCalendarEventUserMutation, unknown>
  updateCalendarEventTimeMutation: UseMutationResult<FileResponse, unknown, UpdateCalendarEventTimeMutation, unknown>
  updateCalendarEventInfoMutation: UseMutationResult<FileResponse, unknown, UpdateCalendarEventInfoMutation, unknown>
  practiceId: string
  handleCancelCalendarEvent: (eventToConfirm: DeleteCalendarEventMutation) => void
  calendarEvent: CalendarEventDto
  calendarEventId: string
}

const EditCalendarEventForm = ({
  updateCalendarEventUser,
  updateCalendarEventTimeMutation,
  updateCalendarEventInfoMutation,
  practiceId,
  handleCancelCalendarEvent,
  calendarEvent,
  calendarEventId,
}: CalendarEventFormProps) => (
  <>
    <VetSelectField
      mutation={updateCalendarEventUser}
      calendarEvent={calendarEvent}
      practiceId={practiceId}
      calendarEventId={calendarEventId}
    />
    <DatesRepeatField
      mutation={updateCalendarEventTimeMutation}
      calendarEvent={calendarEvent}
      practiceId={practiceId}
      calendarEventId={calendarEventId}
    />
    <DetailsField
      mutation={updateCalendarEventInfoMutation}
      calendarEvent={calendarEvent}
      practiceId={practiceId}
      calendarEventId={calendarEventId}
    />
    {
      handleCancelCalendarEvent && (
        <Button
          fullWidth
          variant="contained"
          onClick={() => handleCancelCalendarEvent({ practiceId, calendarEventId })}
        >
          Delete Schedule
        </Button>
      )
    }
  </>
);
export default EditCalendarEventForm;
