import Loading from 'components/Loading';
import { HOUR_IN_MILLISECONDS } from 'config';
import AppointmentUpdateForm from 'features/AppointmentUpdateForm';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import {
  useAppointmentQuery,
  useAppointmentVetNotesQuery,
  useCancelAppointmentMutation,
  useClientQuery,
  useConfirmAppointmentMutation,
  useUsersQuery
} from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import { useNavigate } from 'react-router-dom';
import { TITLE as parentTitle } from '../DashboardPage/constants';
import { TITLE } from './constants';

const AppointmentPage = () => {
  const { appointmentId } = useProtectedParams('appointmentId');
  const confirm = useConfirmation();
  const navigate = useNavigate();

  const { data: appointment, isLoading: appointmentIsLoading } = useAppointmentQuery(appointmentId);
  const { data: appointmentVetNotes } = useAppointmentVetNotesQuery(appointmentId);

  const { data: client, isLoading: clientIsLoading } = useClientQuery(appointment?.clientId ?? '', {
    enabled: !!appointment,
    staleTime: HOUR_IN_MILLISECONDS,
  });
  const { data: clientUsersPagedList } = useUsersQuery({ page: 1, pageSize: 1000, teamId: appointment?.clientId ?? '' }, {
    enabled: !!appointment,
    staleTime: HOUR_IN_MILLISECONDS,
  });

  const cancelAppointmentMutation = useCancelAppointmentMutation(appointmentId, {
    onSuccess: () => navigate(''),
  });
  const confirmAppointmentMutation = useConfirmAppointmentMutation(appointmentId, {
    onSuccess: () => navigate(''),
  });

  const handleCancelAppointment = (appointmentToConfirm: string) => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want to cancel this appointment?',
    }).then(() => cancelAppointmentMutation.mutate(appointmentToConfirm));
  };

  const handleConfirmAppointment = (appointmentToConfirm: string) => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want to confirm this appointment?',
    }).then(() => confirmAppointmentMutation.mutate(appointmentToConfirm));
  };

  if (!appointment || appointmentIsLoading || !client || clientIsLoading || !clientUsersPagedList) {
    return <Loading />;
  }
  return (
    <Page pageType="standard" title={TITLE} parentTitle={parentTitle} parentRelativePath="../../..">
      <AppointmentUpdateForm
        appointment={appointment}
        client={client}
        onCancelAppointment={handleCancelAppointment}
        onConfirmAppointment={handleConfirmAppointment}
        vetNotes={appointmentVetNotes}
      />
    </Page>
  );
};

export default AppointmentPage;
