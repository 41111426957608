import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { AppointmentDetailsDto, AppointmentStatus, ClientDto } from 'providers/api';
import AppointmentInfoSections from './AppointmentInfoSections';

interface AppointmentUpdateFormProps {
  appointment: AppointmentDetailsDto;
  client: ClientDto;
  vetNotes?: string;
  onConfirmAppointment: (appointmentId: string) => void;
  onCancelAppointment?: (appointmentId: string) => void;
}

const AppointmentUpdateForm = ({ appointment, client, vetNotes, onConfirmAppointment, onCancelAppointment }: AppointmentUpdateFormProps) => (
  <>
    <AppointmentInfoSections appointment={appointment} client={client} vetNotes={vetNotes} />
    <Stack spacing={2}>
      {
        appointment.appointmentStatus === AppointmentStatus.Unconfirmed
        && (
          <Button
            fullWidth
            variant="contained"
            onClick={() => onConfirmAppointment(appointment.entityId)}
          >
            Confirm Appointment Details
          </Button>
        )
      }
      {onCancelAppointment && <Button fullWidth variant="contained" onClick={() => onCancelAppointment(appointment.entityId)}>Cancel Appointment</Button>}
    </Stack>
  </>
);

export default AppointmentUpdateForm;
