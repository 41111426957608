import { FormikConfig } from 'formik';
import { UpdateUserCommand, UserDto, useUpdateProfile } from 'providers/api';
import { useAuthorisation } from 'providers/authorisation';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { phoneRegExp } from 'utils';
import {
  boolean, object, SchemaOf, string
} from 'yup';
import { AccountFormProvider } from './context';

const AccountSchema: SchemaOf<UpdateUserCommand> = object().shape({
  email: string().email('Please enter a valid email').required('Email is required'),
  forename: string().required('First Name is required'),
  surname: string().required('Surname is required'),
  contactNumber: string().matches(phoneRegExp, 'Phone number is not valid').required('Contact number is required'),
  excludeReminderSms: boolean().notRequired(),
  excludeReminderEmail: boolean().notRequired(),
  excludeEtaSms: boolean().notRequired(),
  excludeEtaEmail: boolean().notRequired(),
});

interface AccountFormProps {
  user: UserDto;
  children: React.ReactNode;
}

const AccountForm = ({ user, children }: AccountFormProps) => {
  const { state: { userTempName }, functions: { setTempName } } = useAuthorisation();
  const navigate = useNavigate();
  const updateProfileMutation = useUpdateProfile(user.userId);

  const formikConfig: FormikConfig<UpdateUserCommand> = {
    initialValues: {
      email: user.email ?? '',
      forename: userTempName?.forename ?? user.forename ?? '',
      surname: userTempName?.surname ?? user.surname ?? '',
      contactNumber: user.contactNumber ?? '',
      excludeReminderSms: user.excludeReminderSms ?? false,
      excludeReminderEmail: user.excludeReminderEmail ?? false,
      excludeEtaSms: user.excludeEtaSms ?? false,
      excludeEtaEmail: user.excludeEtaEmail ?? false,
    },
    onSubmit: (form, { setSubmitting }) => {
      setSubmitting(true);
      setTempName({
        forename: form.forename,
        surname: form.surname,
    });
      updateProfileMutation.mutate(form, {
        onSuccess: () => {
          setSubmitting(false);
          navigate('../');
        },
        });
    },
    validationSchema: AccountSchema,
  };

  return (
    <AccountFormProvider mutation={updateProfileMutation} formikConfig={formikConfig}>
      {children}
    </AccountFormProvider>
  );
};

export default AccountForm;
