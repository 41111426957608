import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useAccountForm } from './context';

const AccountNameField = () => {
  const {
    form: { formik, helpers },
  } = useAccountForm();

  const { values, handleChange, handleBlur } = formik;

  return (
    <Stack direction="row" spacing={2}>
      <TextField
        required
        autoFocus
        fullWidth
        id="forename"
        name="forename"
        label="First Name"
        value={values.forename}
        onChange={handleChange}
        onBlur={handleBlur}
        error={helpers.hasError('forename')}
        helperText={helpers.getErrorHelpText('forename')}
      />
      <TextField
        required
        autoFocus
        fullWidth
        id="surname"
        name="surname"
        label="Surname"
        value={values.surname}
        onChange={handleChange}
        onBlur={handleBlur}
        error={helpers.hasError('surname')}
        helperText={helpers.getErrorHelpText('surname')}
      />
    </Stack>
  );
};

export default AccountNameField;
