import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styled, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { Theme, useTheme } from '@mui/material/styles';
import { TeamDto } from 'providers/api';
import { useTeam } from 'providers/team';
import React from 'react';
import {
  generatePath,
  useMatch,
  useNavigate
} from 'react-router-dom';
import {
  groupByProp,
  isValidCompactGuid,
  lastIndex,
  px
} from 'utils';
import LogoClosed from './logo-closed.svg';
import LogoOpen from './logo-open.svg';
import TeamSelectionListItem from './TeamSelectionListItem';
import { TopLevelMenuItem } from './types';
import {
  DRAWER_WIDTH,
  DRAWER_WIDTH_CLOSED,
  DRAWER_WIDTH_CLOSED_SM
} from './utils';

const menuByGroup = groupByProp<TopLevelMenuItem>('group');
interface MenuItemProps {
  menuItem: TopLevelMenuItem;
}

const MenuItem = ({ menuItem }: MenuItemProps) => {
  const match = useMatch(menuItem.path);
  const navigate = useNavigate();
  const { state: { activeTeam } } = useTeam();

  const handleMenuSelect = () => navigate(
    menuItem.path.includes(':teamId') ? generatePath(menuItem.path, { teamId: activeTeam ? activeTeam.teamId : '' }) : menuItem.path,
  );

  return (
    <ListItem button selected={!!match} sx={{ color: 'background.default' }} onClick={handleMenuSelect}>
      <ListItemIcon sx={{ paddingLeft: [null, px(4)], color: 'inherit' }}>{menuItem.icon}</ListItemIcon>
      <ListItemText primary={menuItem.display} />
    </ListItem>
  );
};

const DrawerHeader = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.main,
    // match app bar height
    ...theme.mixins.toolbar,
  }),
);

const openedMixin = (theme: Theme) => ({
  width: px(DRAWER_WIDTH + 1),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme) => ({
  width: px(DRAWER_WIDTH_CLOSED_SM + 1),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up('sm')]: {
    width: px(DRAWER_WIDTH_CLOSED + 1),
  },
});

const Drawer = styled(MuiDrawer)(
  ({ theme, open, variant }) => ({
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    ...(open && {
      overflowX: 'hidden',
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        overflowX: 'hidden',
        ...openedMixin(theme),
        background: theme.palette.sideMenu.main,
        border: 0,
        boxShadow: theme.shadows[2],
      },
    }),
    ...((!open && variant === 'permanent') && {
      overflowX: 'hidden',
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        overflowX: 'hidden',
        ...closedMixin(theme),
        background: theme.palette.sideMenu.main,
        border: 0,
        boxShadow: theme.shadows[2],
      },
    }),
    '& .logo-container': {
      paddingLeft: 5,
      paddingRight: 5,
      ...(open && {
        transition: theme.transitions.create('background', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
      ...((!open && variant === 'permanent') && {
        transition: theme.transitions.create('background', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }),
    },
  }),
);

interface MenuProps {
  closeMenu: () => void;
  openMenu: () => void;
  open: boolean;
  menu: TopLevelMenuItem[];
}

interface SideDrawerProps extends MenuProps {
  variant: 'permanent' | 'temporary';
  disabled: boolean;
}

const SideDrawer = ({ closeMenu, openMenu, open, menu, variant, disabled }: SideDrawerProps) => {
  const groupedMenu = menuByGroup(menu);
  const menuGroups = Object.keys(groupedMenu);
  const routeMatch = useMatch(':teamId/*');
  const navigate = useNavigate();
  const { state: { activeTeam, teams }, actions: { setActiveTeam } } = useTeam();

  const openAndEnabled = open && !disabled;
  const variantIsPermanent = variant === 'permanent';

  const navigateToSameRoute = (team: TeamDto) => {
    if (routeMatch && routeMatch?.params.teamId && isValidCompactGuid(routeMatch?.params.teamId)) {
      navigate(`../${team.teamId}/${(routeMatch?.params as any)['*']}`);
    }
  };

  const handleTeamChange = (team: TeamDto) => {
    activeTeam?.teamType === team.teamType
      ? navigateToSameRoute(team)
      : navigate(`../${team.teamId}`);

    setActiveTeam(team.teamId);
  };

  return (
    <Drawer anchor="left" variant={variant} open={openAndEnabled} onClose={closeMenu}>
      <DrawerHeader className="logo-container">
        <Box
          component="img"
          sx={{
            flex: 1,
            height: {
              xs: px(DRAWER_WIDTH_CLOSED_SM / 2),
              sm: px(DRAWER_WIDTH_CLOSED / 2),
            },
            ...((!openAndEnabled && variantIsPermanent) && { display: 'none' }),
          }}
          src={LogoOpen}
          alt="Arrivet Logo"
        />
        <Box
          component="img"
          sx={{
            position: 'absolute',
            width: {
              xs: px(DRAWER_WIDTH_CLOSED_SM - 20),
              sm: px(DRAWER_WIDTH_CLOSED - 20),
            },
            ...((openAndEnabled || !variantIsPermanent) && { display: 'none' }),
          }}
          src={LogoClosed}
          alt="Arrivet Logo"
        />

        <IconButton
          color="inherit"
          aria-label="close menu"
          onClick={closeMenu}
          sx={{
            color: 'theme.palette.sideMenu.contrastText',
            ...((!openAndEnabled && variantIsPermanent) && { display: 'none' }),
          }}
          size="large"
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="open menu"
          onClick={openMenu}
          sx={{ ...((openAndEnabled) && { display: 'none' }) }}
          size="large"
        />
      </DrawerHeader>
      <Divider />
      {activeTeam && <TeamSelectionListItem activeTeam={activeTeam} availableTeams={teams || []} onTeamSelect={handleTeamChange} />}
      {
        menuGroups.map((group, index) => {
          const listSubHeader = group !== 'undefined' ? (
            <ListSubheader sx={{ ...((!openAndEnabled && variantIsPermanent) && { visibility: 'hidden' }) }}>{group}</ListSubheader>
          ) : undefined;

          return (
            <div key={group ?? 'no-group'}>
              <List sx={{ '& .MuiListSubheader-root': { backgroundColor: 'primary.dark', color: 'background.default' } }} subheader={listSubHeader}>
                {groupedMenu[group].map((menuItem) => (<MenuItem key={menuItem.path} menuItem={menuItem} />))}
              </List>
              {lastIndex(menuGroups) !== index && (<Divider />)}
            </div>
          );
        })
      }
    </Drawer>
  );
};

const Menu = (props: MenuProps) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <SideDrawer {...props} variant="permanent" disabled={!isLarge} />
      <SideDrawer {...props} variant="temporary" disabled={isLarge} />
    </>
  );
};

export default Menu;
