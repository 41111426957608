/* global google */
import Box from '@mui/material/Box';
import Loading from 'components/Loading';
import { LONDON_CENTER } from 'config';
import ShareLocation from 'features/ShareLocation';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import {
  APPOINTMENT_KEY, useLocationQuery, USER_KEY, useUpdateLocationMutation
} from 'providers/api';
import { useAuthorisation } from 'providers/authorisation';
import React from 'react';
import { TITLE as parentTitle } from '../AppointmentPage/constants';
import { TITLE as sectionTitle } from './constants';

const ShareLocationPage = () => {
  const { appointmentId } = useProtectedParams('appointmentId');
  const [currentClientLocation, setCurrentClientLocation] = React.useState<google.maps.LatLngLiteral>();
  const [appointmentLocation, setAppointmentLocation] = React.useState<google.maps.LatLngLiteral>();
  const { state: { user } } = useAuthorisation();
  const appointmentLocationMutation = useUpdateLocationMutation(appointmentId);

  const { data: appointmentLocationData, isLoading: appointmentLocationDataIsLoading } = useLocationQuery({
    entityId: appointmentId,
    entityType: APPOINTMENT_KEY,
  });

  // TODO: don't default to London here. Let the share Location feature component handle the default.
  React.useEffect(() => {
    setAppointmentLocation({
      lat: appointmentLocationData?.geoPoint.latitude ?? LONDON_CENTER.lat,
      lng: appointmentLocationData?.geoPoint.longitude ?? LONDON_CENTER.lng,
    });
  }, [appointmentLocationData]);

  const handleSaveAppointmentLocation = () => {
    currentClientLocation
      && appointmentLocationMutation.mutate({
        entityKey: user.id,
        entityType: USER_KEY,
        geoPoint: {
          latitude: currentClientLocation.lat,
          longitude: currentClientLocation.lng,
        },
        description: 'Appointment attendee location',
      });
  };

  if (appointmentLocationDataIsLoading) {
    return <Loading />;
  }

  return (
    <Page pageType="standard" title={sectionTitle} parentTitle={parentTitle} parentRelativePath="../details">
      <Box height={500}>
        <ShareLocation
          currentClientLocation={currentClientLocation}
          appointmentLocation={appointmentLocation}
          onSaveLocation={handleSaveAppointmentLocation}
          setCurrentClientLocation={setCurrentClientLocation}
        />
      </Box>
    </Page>
  );
};

export default ShareLocationPage;
