import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PatientsFeature from 'features/patient/PatientsFeature';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { Link, useNavigate } from 'react-router-dom';
import { TITLE as parentTitle } from '../ClientsPage';
import useClientPageTitle from '../useClientPageTitle';
import { TITLE as sectionTitle } from './constants';

const ClientPatientsPage = () => {
  const { clientId } = useProtectedParams('clientId');
  const title = useClientPageTitle(clientId, sectionTitle);
  const navigate = useNavigate();

  const handleNavigateToPatient = (patientId: string) => navigate(patientId);

  return (
    <Page pageType="full" title={title} parentRelativePath="../../.." parentTitle={parentTitle}>
      <Box mb={2} display="flex" justifyContent="end">
        <Button component={Link} to="new" variant="contained" startIcon={<AddIcon />}>New Patient</Button>
      </Box>
      <PatientsFeature onPatientSelect={handleNavigateToPatient} clientId={clientId} />
    </Page>
  );
};

export default ClientPatientsPage;
