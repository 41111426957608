import { UpdateUserDto } from 'providers/api';
import { isNilOrEmpty } from '../logic';

export const getDisplayName = (email: string, firstName?: string, lastName?: string) => {
  if (isNilOrEmpty(firstName) || isNilOrEmpty(lastName)) {
     return email;
  }

  return `${firstName} ${lastName}`;
};

export const getUserSummary = (userDetails: UpdateUserDto) => `
${userDetails.forename && userDetails.forename} ${userDetails.surname && userDetails.surname} - ${userDetails.email}
`;
