import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import EditableSection from 'components/EditableSection';
import { FormStack } from 'components/Form';
import { ValidatedLocationDto } from 'providers/api';
import { UpdateValidatedLocationPositionMutation } from 'providers/api/useValidatedLocations';
import useDefaultForm from 'providers/form/useDefaultForm';
import React from 'react';
import { UseMutationResult } from 'react-query';
import {
  number,
  object,
  SchemaOf,
  string
} from 'yup';
import { useValidatedLocationsState } from './validatedLocation.context';

const validatedLocationSchema: SchemaOf<UpdateValidatedLocationPositionMutation> = object().shape({
  validatedLocationId: string().required('A validated location Id is required'),
  teamId: string().required('A team Id is required'),
  request: object().shape({
    geoPoint: object().shape({
      latitude: number().required('A latitude is required'),
      longitude: number().required('A longitude is required'),
    }),
  }),
});

interface ValidatedLocationPositionFormProps {
  readOnly: boolean;
  location: ValidatedLocationDto;
  mutation: UseMutationResult<string, unknown, UpdateValidatedLocationPositionMutation, unknown>;
}

const ValidatedLocationPositionForm = ({ readOnly, location, mutation }: ValidatedLocationPositionFormProps) => {
  const initialGeoPositionRef = React.useRef(location.geoPoint);
  const initialGeoPosition = initialGeoPositionRef.current;

  const validatedLocationsState = useValidatedLocationsState();
  const { contextData: { selectedGeoPoint, setShowSelectedGeoPoint } } = validatedLocationsState;

  const {
    formik,
    helpers,
  } = useDefaultForm<UpdateValidatedLocationPositionMutation>({
    mutation,
    formikConfig: {
      initialValues: {
        validatedLocationId: location.entityId,
        teamId: location.clientId,
        request: {
          geoPoint: {
            latitude: initialGeoPosition.latitude,
            longitude: initialGeoPosition.longitude,
          },
        },
      },
      onSubmit: (validatedLocationForm, { setSubmitting }) => {
        setSubmitting(true);
        mutation.mutate(validatedLocationForm, {
          onSettled: () => {
            setSubmitting(false);
          },
        });
      },
      validationSchema: validatedLocationSchema,
    },
  });

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  React.useEffect(() => {
    if (selectedGeoPoint) {
      setFieldValue('latitude', selectedGeoPoint.latitude);
      setFieldValue('longitude', selectedGeoPoint.longitude);
    }
  }, [selectedGeoPoint]);

  return (
    <EditableSection
      title={readOnly ? 'Location' : 'Edit location'}
      mutation={mutation as any}
      formik={formik as any}
      small
      enabled={!readOnly}
      onEditing={() => setShowSelectedGeoPoint && setShowSelectedGeoPoint(true)}
      onCancel={() => setShowSelectedGeoPoint && setShowSelectedGeoPoint(false)}
    >
      {(editMode) => (
        <Box>
          {
            editMode
              ? (
                <form onSubmit={handleSubmit}>
                  <FormStack>
                    <Typography variant="caption">Click a point on the map to update the location fields.</Typography>
                    <TextField
                      fullWidth
                      type="number"
                      id="request.geoPoint.latitude"
                      name="request.geoPoint.latitude"
                      label="Latitude"
                      value={values.request.geoPoint.latitude ?? ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={helpers.hasError('request.geoPoint.latitude')}
                      helperText={helpers.getErrorHelpText('request.geoPoint.latitude')}
                      disabled
                    />
                    <TextField
                      fullWidth
                      type="number"
                      id="request.geoPoint.longitude"
                      name="request.geoPoint.longitude"
                      label="Longitude"
                      value={values.request.geoPoint.longitude ?? ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={helpers.hasError('request.geoPoint.longitude')}
                      helperText={helpers.getErrorHelpText('request.geoPoint.longitude')}
                      disabled
                    />
                  </FormStack>
                </form>
              )
              : (
                <Stack spacing={1}>
                  <Typography variant="body2">
                    {`Latitude: ${initialGeoPosition.latitude}`}
                  </Typography>
                  <Typography variant="body2">
                    {`Longitude: ${initialGeoPosition.longitude}`}
                  </Typography>
                </Stack>

              )
          }
        </Box>
      )}
    </EditableSection>
  );
};

export default ValidatedLocationPositionForm;
