import { useValidatedLocationsState } from 'features/ValidatedLocations';
import ValidatedLocationForm from 'features/ValidatedLocations/ValidatedLocationForm';
import useProtectedParams from 'hooks/useProtectedParams';
import { CreateValidatedLocationRequestDto } from 'providers/api';
import { useCreateValidatedLocationMutation } from 'providers/api/useValidatedLocations';
import { useAuthorisation } from 'providers/authorisation';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CreateValidatedLocationPanel = () => {
  const { teamId } = useProtectedParams('teamId');
  const { state: { user } } = useAuthorisation();
  const navigate = useNavigate();
  const { contextData } = useValidatedLocationsState();

  const mutation = useCreateValidatedLocationMutation();

  const handleSubmit = (newValidatedLocation: CreateValidatedLocationRequestDto) => {
    mutation.mutate(
      {
        teamId,
        request: newValidatedLocation,
      },
      {
        onSuccess: () => {
          navigate('..');
        },
      },
    );
  };

  React.useEffect(() => {
    contextData.setShowSelectedGeoPoint && contextData.setShowSelectedGeoPoint(true);
    return () => contextData.setShowSelectedGeoPoint && contextData.setShowSelectedGeoPoint(false);
  }, []);

  return (
    <ValidatedLocationForm
      clientId={teamId}
      clientUserId={user.id}
      mutation={mutation}
      onSubmit={handleSubmit}
      selectedLocation={contextData.selectedGeoPoint}
    />
  );
};
export default CreateValidatedLocationPanel;
