/* eslint-disable react/no-unused-prop-types */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface ConfirmationOptions {
  variant: 'danger' | 'info';
  title?: string;
  description?: string;
  catchOnCancel?: boolean
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onExited: () => void;
}

export const ConfirmationDialog = ({
  open,
  title,
  variant,
  description,
  onSubmit,
  onClose,
  onExited,
}: ConfirmationDialogProps) => (
  <Dialog open={open} TransitionProps={{ onExited }}>
    {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
    {description && (
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
    )}
    <DialogActions>
      {variant === 'danger' && (
        <>
          <Button variant="text" onClick={onClose} autoFocus>
            CANCEL
          </Button>
          <Button color="primary" variant="text" onClick={onSubmit}>
            Yes
          </Button>
        </>
      )}

      {variant === 'info' && (
        <Button color="primary" variant="text" onClick={onSubmit}>
          OK
        </Button>
      )}
    </DialogActions>
  </Dialog>
);
