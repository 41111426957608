import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useMyTeamRolesQuery } from 'providers/api';
import { is } from 'ramda';
import React, { ReactNode, useState } from 'react';
import AuthorisationContext, { ArrivetUser, IAuthorisationContext, TempName } from './context';

const ADMINISTRATOR_ROLE = 'Administrator';

export interface AuthorisationProviderProps {
  children: ReactNode;
}

const formatRoles = (roles: string | string[] | undefined) => {
  if (!roles) return [];
  if (is(String, roles)) return [roles];
  return roles;
};

const AuthorisationProvider = ({ children }: AuthorisationProviderProps) => {
  const { oidcUser } = useReactOidc();
  const [userTempName, setUserTempName] = useState<TempName | undefined>(undefined);

  const { data: teamRoles, isFetching } = useMyTeamRolesQuery(undefined, {
    staleTime: 5 * 60 * 1000,
  });

  const value: IAuthorisationContext = React.useMemo(() => {
    const { name, email, roles: oidcUserRoles, given_name: givenName } = oidcUser.profile;

    // If there is only one roles it comes in as just a sting.
    const roles = formatRoles(oidcUserRoles);

    if (!name) {
      throw new Error('oidcUser does not contain expected prop \'name\'');
    }

    if (!email) {
      throw new Error('oidcUser does not contain expected prop \'email\'');
    }

    const user: ArrivetUser = {
      id: oidcUser.profile.sub,
      username: name,
      name: givenName ?? name,
      email,
      roles: teamRoles ?? {},
    };

    const isAdmin = () => roles.includes(ADMINISTRATOR_ROLE);

    // Holds name until refresh so name shows on form submit in avatar/account area of top nav
    const setTempName = (tempName?: TempName) => setUserTempName(tempName);

    const userHasPermissions = (teamId: string, rolesToCheck: string[]): boolean => (user.roles.teamroles
      ? rolesToCheck.some(
        (roleToCheck) => user.roles.teamroles.some(
          (existingRole: string) => existingRole.includes(teamId) && existingRole.includes(roleToCheck),
        ),
      )
      : false);

    return {
      state: { user, loading: isFetching, userTempName }, // loading: isFetching from useMeQuery
      actions: {
        userHasPermissions,
      },
      functions: {
        isAdmin,
        setTempName,
        validatePermission: () => true,
      },
    };
  }, [oidcUser, teamRoles, isFetching, userTempName]);

  return (
    <AuthorisationContext.Provider value={value}>
      {children}
    </AuthorisationContext.Provider>
  );
};

export default AuthorisationProvider;
