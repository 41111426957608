import React from 'react';

export interface IMenuStateContext {
  state: {
    mainMenuIsOpen: boolean;
    notificationsDrawerIsOpen: boolean;
  },
}

const MenuStateContext = React.createContext<IMenuStateContext | null>(null);

export default MenuStateContext;
