import DateFnsAdapter from '@date-io/date-fns';
import {
  LocalDate, LocalTime, nativeJs, ZonedDateTime, ZoneId
} from '@js-joda/core';
import {
  Alert, Divider, MenuItem, Stack, useMediaQuery
} from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import EnumCheckboxList from 'components/EnumChecklist';
import { FormikActions, FormStack } from 'components/Form';
import Loading from 'components/Loading';
import TitleBox from 'components/TitleBox';
import TitleDisplayStack from 'components/TitleDisplayStack';
import { HOUR_IN_MILLISECONDS } from 'config';
import { enGB } from 'date-fns/locale';
import {
  CalendarEventForm as ICalendarEventForm, CalendarEventType, CreateCalendarEventMutation, IsoDayOfWeek, useVetsQuery
} from 'providers/api';
import { useDefaultForm } from 'providers/form';
import React from 'react';
import { UseMutationResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  DEFAULT_DAYMONTH_DATE_CONFIG, displayTemporal, enumKeys, enumValues, getSelectedDay, isNilOrEmpty, isValidDate, splitCaps, startAndEndOfWeek, updateTime
} from 'utils';
import {
  array, date, mixed, number, object, SchemaOf, string
} from 'yup';
import VetField from './VetField';

export interface CalendarEventFormInputs {
  userId: string
  startDate: Date | null;
  startTime: Date | null;
  endTime: Date | null;
  title: string;
  notes: string;
  type: CalendarEventType | null;
  repeatDays: IsoDayOfWeek[];
  repeatDurationInWeeks?: number;
}

export enum IsoDayOfWeekAmend {
  Mon = 1,
  Tue = 2,
  Wed = 3,
  Thu = 4,
  Fri = 5,
  Sat = 6,
  Sun = 7,
}

export interface CalendarEventFormProps {
  mutation: UseMutationResult<string, unknown, CreateCalendarEventMutation, unknown>;
  initialStartDate?: Date
  initialVet?: string
  teamId: string
}

const CalendarEventFormSchema: SchemaOf<CalendarEventFormInputs> = object().shape({
  userId: string().nullable().required('A vet is required'),
  startDate: date()
    .nullable()
    .required('A start date is required'),
  startTime: date()
    .nullable()
    .required('A start time is required')
    .test(
      'start-time-is-before-end-time?',
      'The start time cannot be after the finish time',
      (startTime, context) => {
        if (isNilOrEmpty(context.parent.startTime)
          || (isNilOrEmpty(context.parent.endTime))) {
          return true;
        }
        const { endTime } = context.parent;
        return (startTime ? startTime < endTime : true);
      },
    ),
  endTime: date()
    .nullable()
    .required('A finish time is required'),
  repeatDays: array().of(mixed().oneOf(enumValues(IsoDayOfWeek))).required('A day is required'),
  repeatDurationInWeeks: number().required('Please enter a number for how many weeks to repeat this event'),
  title: string().required('A title is required'),
  notes: string().required('Please leave a note for context'),
  type: mixed().oneOf(enumValues(CalendarEventType)).required('Please select an event type'),
});

const NewCalendarEventForm = ({ mutation, teamId, initialStartDate, initialVet }: CalendarEventFormProps) => {
  const initialStart = initialStartDate ? new Date(+initialStartDate + (HOUR_IN_MILLISECONDS * 9)) : null;
  const initialEnd = initialStartDate ? new Date(+initialStartDate + (HOUR_IN_MILLISECONDS * 17)) : null;
  const navigate = useNavigate();
  const {
    formik,
    helpers,
  } = useDefaultForm<CalendarEventFormInputs>({
    mutation,
    formikConfig: {
      initialValues: {
        userId: initialVet ?? '',
        startDate: initialStart ?? null,
        startTime: initialStart ?? null,
        endTime: initialEnd ?? null,
        repeatDays: [],
        title: '',
        type: null,
        repeatDurationInWeeks: 1,
        notes: '',
      },
      onSubmit: (form, { setSubmitting }) => {
        setSubmitting(true);
        const { startDate, ...rest } = form;

        const startDateBase = LocalDate.from(nativeJs(startDate));
        const startTime = LocalTime.from(nativeJs(form.startTime));
        const endTime = LocalTime.from(nativeJs(form.endTime));
        const startTimeZoned = ZonedDateTime.of(startDateBase, startTime, ZoneId.systemDefault());
        const endTimeZoned = ZonedDateTime.of(startDateBase, endTime, ZoneId.systemDefault());

        const payload = {
          ...rest,
          startTime: startTimeZoned,
          endTime: endTimeZoned,
        };
        mutation.mutate(
          { practiceId: teamId, userId: form.userId, form: payload as ICalendarEventForm },
          {
            onSettled: () => {
              setSubmitting(false);
              navigate('../');
            },
          },
        );
      },
      validationSchema: CalendarEventFormSchema,
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = formik;

  const { data: vets, isLoading } = useVetsQuery({ page: 1, pageSize: 1000, teamId }, {
    enabled: true,
    staleTime: HOUR_IN_MILLISECONDS,
  });

  const updateStartTime = (startTime: Date | null) => {
    setFieldValue(
      'startTime',
      !isNilOrEmpty(startTime) && isValidDate(startTime)
        ? updateTime(values.startDate, startTime)
        : null,
    );
  };

  const updateEndTime = (endTime: Date | null) => {
    setFieldValue(
      'endTime',
      !isNilOrEmpty(endTime) && isValidDate(endTime)
        ? updateTime(values.startDate, endTime)
        : null,
    );
  };

  const selectedDay = getSelectedDay(values.startDate);
  const weekCommencing = startAndEndOfWeek(values.startDate);

  // useEffect manages the checkboxes dependent on startDate change (NOT onClick)
  React.useEffect(() => {
    if (!selectedDay) return;
    if (!values.repeatDays) {
      setFieldValue('repeatDays', [selectedDay]);
      return;
    }
    if (selectedDay && values.repeatDays.includes(selectedDay)) return;
    setFieldValue('repeatDays', [...values.repeatDays, selectedDay]);
  }, [selectedDay]);

  // Manages the checkboxes dependent on click
  const handleRepeatDayClick = (day: any) => {
    if (!values.repeatDays) {
      setFieldValue('repeatDays', [day]);
      return;
    }
    if (values.repeatDays.includes(day)) {
      setFieldValue('repeatDays', values.repeatDays.filter((dayName: any) => dayName !== day));
    } else {
      setFieldValue('repeatDays', [...values.repeatDays, day]);
    }
  };

  // Needed setValues to stop validation bug with Yup. Many setFieldValues resulted in errors saying the startTime
  // is after the endTime when it isn't, which is one of the tests.
  React.useEffect(() => {
    setValues({
      startDate: values.startDate,
      startTime: values.startTime ? updateTime(values.startDate, values.startTime) : null,
      endTime: values.endTime ? updateTime(values.startDate, values.endTime) : null,
      repeatDays: values.repeatDays,
      repeatDurationInWeeks: values.repeatDurationInWeeks,
      title: values.title,
      notes: values.notes,
      type: values.type,
      userId: values.userId,
    });
  }, [values.startDate]);

  // useEffect manages the checkboxes dependent on date change
  React.useEffect(() => {
    if (!selectedDay) return;
    if (!values.repeatDays) {
      setFieldValue('repeatDays', [selectedDay]);
      return;
    }
    if (selectedDay && values.repeatDays.includes(selectedDay)) return;
    setFieldValue('repeatDays', [...values.repeatDays, selectedDay]);
  }, [selectedDay]);

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('type', event.target.value);
  };

  const handleVetFieldChange = (userId: string | null) => {
    userId && setFieldValue('userId', userId);
  };

  const minWidth1200 = useMediaQuery('(min-width:1200px)');

  if (!vets || isLoading) {
    return <Loading />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <TitleBox title="Schedule Time">
        <FormStack>
          <TitleDisplayStack
            title="Vet"
            display={
              (
                <VetField
                  vets={vets}
                  onChange={handleVetFieldChange}
                  formik={formik}
                />
              )
            }
          />
          {/* TODO: Change date adapter. While there is a JS Joda adapter, it appears to be broken for MUI Time Picker.
              When Temporal is passed in, there's an infinite loop.
              https://dev.azure.com/buzzinteractiveteam/Aegis/_workitems/edit/8833/
           */}
          <LocalizationProvider adapterLocale={enGB} dateAdapter={DateFnsAdapter}>
            <TitleDisplayStack
              title="Date and Times"
              display={
                (
                  <>
                    <Stack direction="row" spacing={2}>
                      <DatePicker
                        label="Date"
                        value={values.startDate}
                        onChange={(dateItem: any) => setFieldValue('startDate', dateItem)}
                        onClose={() => formik.setFieldTouched('startDate', true)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="startDate"
                            name="startDate"
                            fullWidth
                            onBlur={handleBlur}
                          />
                        )}
                      />
                      <TimePicker
                        label="Start Time"
                        value={values.startTime}
                        onChange={(dateItem: any) => updateStartTime(dateItem)}
                        onClose={() => formik.setFieldTouched('startTime', true)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="startTime"
                            name="startTime"
                            fullWidth
                            onBlur={handleBlur}
                          />
                        )}
                      />
                      <TimePicker
                        label="Finish Time"
                        value={values.endTime}
                        onChange={(dateItem: any) => updateEndTime(dateItem)}
                        onClose={() => formik.setFieldTouched('endTime', true)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="endTime"
                            name="endTime"
                            fullWidth
                            onBlur={handleBlur}
                          />
                        )}
                      />
                    </Stack>
                    <Stack>
                      {helpers.hasError('startDate') && <Alert sx={{ marginTop: 2 }} severity="error">{helpers.getErrorHelpText('startDate')}</Alert>}
                      {helpers.hasError('startTime') && <Alert sx={{ marginTop: 2 }} severity="error">{helpers.getErrorHelpText('startTime')}</Alert>}
                      {helpers.hasError('endTime') && <Alert sx={{ marginTop: 2 }} severity="error">{helpers.getErrorHelpText('endTime')}</Alert>}
                    </Stack>
                  </>
                )
              }
            />
          </LocalizationProvider>
          <Divider sx={{ my: 3 }} />
          <TitleDisplayStack
            title="Days"
            subtitle={
              weekCommencing
                ? `W/c
              ${displayTemporal(weekCommencing.monday, DEFAULT_DAYMONTH_DATE_CONFIG)}
              -
              ${displayTemporal(weekCommencing.sunday, DEFAULT_DAYMONTH_DATE_CONFIG)}
              `
                : undefined
            }
            display={
              (
                <EnumCheckboxList
                  checkboxEnum={IsoDayOfWeekAmend}
                  selection={values.repeatDays ?? []}
                  handleCheckboxClick={handleRepeatDayClick}
                  direction={minWidth1200 ? 'row' : 'column'}
                  disabled={selectedDay ? [selectedDay] : undefined}
                />
              )
            }
          />
          <>
            <Divider sx={{ my: 3 }} />
            <TitleDisplayStack
              title="Weeks"
              display={
                (
                  <TextField
                    fullWidth
                    id="repeatDurationInWeeks"
                    name="repeatDurationInWeeks"
                    label="Duration in weeks"
                    value={values.repeatDurationInWeeks}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={helpers.hasError('repeatDurationInWeeks')}
                    helperText={helpers.getErrorHelpText('repeatDurationInWeeks')}
                  />
                )
              }
            />
          </>
          <Divider sx={{ my: 3 }} />
          <TitleDisplayStack
            title="Type"
            display={
              (
                <TextField
                  id="Type"
                  select
                  fullWidth
                  label="Type"
                  value={values.type}
                  onChange={handleTypeChange}
                  onBlur={handleBlur}
                  helperText={helpers.getErrorHelpText('type')}
                >
                  {
                    enumKeys(CalendarEventType).map((option) => (
                      <MenuItem key={option} value={(CalendarEventType as any)[option]}>
                        {splitCaps(option)}
                      </MenuItem>
                    ))
                  }
                </TextField>
              )
            }
          />
          <Divider sx={{ my: 3 }} />
          <TitleDisplayStack
            title="Context"
            display={
              (
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    id="title"
                    name="title"
                    label="Title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={helpers.hasError('title')}
                    helperText={helpers.getErrorHelpText('title')}
                  />
                  <TextField
                    fullWidth
                    id="notes"
                    name="notes"
                    label="Notes"
                    value={values.notes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={helpers.hasError('notes')}
                    helperText={helpers.getErrorHelpText('notes')}
                    multiline
                    minRows={2}
                  />
                </Stack>
              )
            }
          />
        </FormStack>
      </TitleBox>
      <Box mt={2}>
        <FormikActions
          formik={formik}
          mutation={mutation}
          submitText="Create"
          right={['reset', 'submit']}
        />
      </Box>
    </form>
  );
};
export default NewCalendarEventForm;
