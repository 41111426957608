import { FormikProps } from 'formik';
import { isNilOrEmpty } from './logic';

export const handleNumberChange = (field: string, setFieldValue: FormikProps<any>['setFieldValue']) => (event: React.ChangeEvent<HTMLInputElement>) => {
  const parsedNumber = isNilOrEmpty(event.target.value) ? NaN : Number(event.target.value);
  const value = Number.isNaN(parsedNumber) ? undefined : parsedNumber;
  setFieldValue(field, value);
};

export const handleIntChange = (field: string, setFieldValue: FormikProps<any>['setFieldValue']) => (event: React.ChangeEvent<HTMLInputElement>) => {
  const parsedNumber = isNilOrEmpty(event.target.value) ? NaN : parseInt(event.target.value, 10);
  const value = Number.isNaN(parsedNumber) ? undefined : parsedNumber;
  setFieldValue(field, value);
};

export const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
