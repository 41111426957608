import Loading from 'components/Loading';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import {
  DeleteCalendarEventMutation,
  useCalendarEventQuery,
  useDeleteCalendarEvent,
  useUpdateCalendarEventInfo,
  useUpdateCalendarEventTime,
  useUpdateCalendarEventUser
} from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import { useNavigate } from 'react-router-dom';
import EditCalendarEventForm from '../EditCalendarEventForm/EditCalendarEventForm';
import { TITLE as parentTitle } from '../StaffAvailabilityPage';
import { TITLE } from './constants';

const EditCalendarEventPage = () => {
  const { teamId, calendarEventId } = useProtectedParams('teamId', 'calendarEventId');
  const confirm = useConfirmation();
  const navigate = useNavigate();

  const { data: calendarEvent, isLoading: eventIsLoading } = useCalendarEventQuery({ practiceId: teamId, calendarEventId });
  const updateCalendarEventTime = useUpdateCalendarEventTime(teamId);
  const updateCalendarEventUser = useUpdateCalendarEventUser(teamId);
  const updateCalendarEventInfo = useUpdateCalendarEventInfo(teamId);
  const deleteCalendarEvent = useDeleteCalendarEvent();

  const handleCancelCalendarEvent = (eventToConfirm: DeleteCalendarEventMutation) => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want to DELETE this entire schedule?',
    }).then(() => deleteCalendarEvent.mutate(eventToConfirm))
      .then(() => navigate('../../'));
  };

  if (!calendarEvent || eventIsLoading) {
    return <Loading />;
  }

  return (
    <Page
      pageType="standard"
      title={TITLE}
      parentRelativePath="../../"
      parentTitle={parentTitle}
    >
      <EditCalendarEventForm
        updateCalendarEventTimeMutation={updateCalendarEventTime}
        updateCalendarEventInfoMutation={updateCalendarEventInfo}
        updateCalendarEventUser={updateCalendarEventUser}
        practiceId={teamId}
        handleCancelCalendarEvent={handleCancelCalendarEvent}
        calendarEvent={calendarEvent}
        calendarEventId={calendarEventId}
      />
    </Page>
  );
};
export default EditCalendarEventPage;
