import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { ArrivetRouteObject, RouteConfiguration } from 'modules/interfaces';
import { AppointmentTemporaryLocationPanel, AppointmentValidatedLocationListPanel } from 'modules/practice/calendar/AppointmentLocationPage';
import TabRouterOutlet from 'modules/TabRouterOutlet';
import { Navigate } from 'react-router-dom';
import AppointmentLocationPage from './AppointmentLocationPage';
import AppointmentPage from './AppointmentPage';
import AppointmentsPage from './AppointmentsPage';
import ShareLocationPage from './ShareLocationPage';

const tabRoutes = {
  Appointment: '/details',
  Location: '/location',
};
const tabRouteId = 'ClientAppointment';

export const defaultRoute = 'appointments';

const routes: (config: RouteConfiguration) => ArrivetRouteObject[] = () => [{
  path: 'appointments',
  mainNavigation: {
    title: 'Appointments',
    group: '',
    iconComponent: <CalendarTodayIcon />,
  },
  children: [
    {
      index: true,
      element: <AppointmentsPage />,
    },
    {
      path: ':appointmentId',
      children: [
        {
          tabRouteId,
          element: <TabRouterOutlet routes={tabRoutes} routeId={tabRouteId} />,
          children: [
            {
              index: true,
              element: <Navigate to="details" />,
            },
            {
              path: 'details',
              element: <AppointmentPage />,
            },
            {
              path: 'location',
              element: <AppointmentLocationPage />,
              children: [
                {
                  index: true,
                  element: <AppointmentValidatedLocationListPanel />,
                },
                {
                  path: 'temporary',
                  element: <AppointmentTemporaryLocationPanel />,
                },
              ],
            },
          ],
        },
        {
          path: 'share',
          element: <ShareLocationPage />,
        },
      ],
    },
  ],
}];

export default routes;
