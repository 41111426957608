import EmailIcon from '@mui/icons-material/Email';
import { ArrivetRouteObject, RouteConfiguration } from 'modules/interfaces';
import TabRouterOutlet from 'modules/TabRouterOutlet';
import { Navigate } from 'react-router-dom';
import ClientLocationsPage from './ClientLocationsPage';
import ClientLocationPanel from './ClientLocationsPage/ClientLocationPanel';
import ClientValidatedLocationListPanel from './ClientLocationsPage/ClientValidatedLocationListPanel';
import ClientPatientsPage from './ClientPatientsPage';
import ClientRouterOutlet from './ClientRouterOutlet';
import ClientsPage from './ClientsPage/ClientsPage';
import ClientUserInvitationsPage from './ClientUserInvitationsPage';
import ClientUsersPage from './ClientUsersPage';
import EditClientPage from './EditClientPage';
import EditClientUserPage from './EditClientUserPage';
import EditPatientPage from './EditPatientPage';
import NewClientPage from './NewClientPage';
import NewClientUserPage from './NewClientUserPage';
import NewPatientPage from './NewPatientPage';
import PatientPage from './PatientPage';

export const defaultRoute = 'clients';

const tabRoutes = {
  Patients: '/patients',
  Locations: '/locations',
  Users: '/users',
  Invitations: '/invites',
};

const tabRouteId = 'PracticeClientTab';

const routes: (config: RouteConfiguration) => ArrivetRouteObject[] = () => [{
  path: 'clients',
  mainNavigation: {
    title: 'Clients',
    group: '',
    iconComponent: <EmailIcon />,
  },
  children: [
    {
      index: true,
      element: <ClientsPage />,
    },
    {
      path: 'new',
      element: <NewClientPage />,
    },
    {
      path: ':clientId',
      element: <ClientRouterOutlet />,
      children: [
        {
          index: true,
          element: <Navigate to="patients" />,
        },
        {
          path: 'patients/new',
          element: <NewPatientPage />,
        },
        {
          path: 'patients/:patientId',
          element: <PatientPage />,
        },
        {
          path: 'patients/:patientId/edit',
          element: <EditPatientPage />,
        },
        {
          path: 'users/new',
          element: <NewClientUserPage />,
        },
        {
          path: 'users/edit/:userId',
          element: <EditClientUserPage />,
        },
        {
          tabRouteId: 'PracticeClientTab',
          element: <TabRouterOutlet routes={tabRoutes} routeId={tabRouteId} />,
          children: [
            {
              path: 'patients',
              element: <ClientPatientsPage />,
            },
            {
              path: 'locations',
              element: <ClientLocationsPage />,
              children: [
                {
                  index: true,
                  element: <ClientValidatedLocationListPanel />,
                },
                {
                  path: ':locationId',
                  element: <ClientLocationPanel />,
                },
              ],
            },
            {
              path: 'users',
              element: <ClientUsersPage />,
            },
            {
              path: 'invites',
              element: <ClientUserInvitationsPage />,
            },
          ],
        },
        {
          path: 'edit',
          element: <EditClientPage />,
        },
        {
          path: 'invite',
          element: <NewClientUserPage />,
        },
      ],
    },
  ],
}];

export default routes;
