import { useSetAppointmentsAsUnconfirmedMutation } from 'providers/api';
import { useConfirmation } from 'providers/confirm';

const usePublishAppointments = (teamId: string) => {
  const confirm = useConfirmation();
  const setAppointmentAsUnconfirmedMutation = useSetAppointmentsAsUnconfirmedMutation();

  // Publish is called 'unconfirm' server side
  const publishAppointments = (appointmentsToPublish: string[]) => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want to publish all draft appointments for this period? This will enable email and SMS notifications to attendees.',
    }).then(() => setAppointmentAsUnconfirmedMutation.mutate({
      teamId,
      command: {
        appointmentIds: appointmentsToPublish,
      },
    }));
  };

  return { publishAppointments };
};
export default usePublishAppointments;
