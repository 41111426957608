import { useParams } from 'react-router-dom';

const useProtectedParams = (...paramsKeys: string[]) => {
  type ProtectedParams = { [K in typeof paramsKeys[number]]: string }

  const params = useParams();

  const entries = paramsKeys.map((key) => {
    const value = params[key];

    if (!value) {
      throw new Error(`"${key}" could not be found in params`);
    }

    return [key, value] as [string, string];
  });

  const protectedParams = Object.fromEntries(new Map(entries));

  return protectedParams as ProtectedParams;
};

export default useProtectedParams;
