import { MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AppointmentPriority } from 'providers/api';
import { enumValues, splitCaps } from 'utils';
import { useAppointmentForm } from './context';

const AppointmentPriorityField = () => {
  const {
    form: { formik, helpers },
  } = useAppointmentForm();

  const { values, handleChange, handleBlur } = formik;

  return (
    <TextField
      fullWidth
      id="appointmentPriority"
      name="appointmentPriority"
      label="Appointment Priority"
      value={values.appointmentPriority ?? ''}
      onChange={handleChange}
      onBlur={handleBlur}
      error={helpers.hasError('appointmentPriority')}
      helperText={helpers.getErrorHelpText('appointmentPriority')}
      select
    >
      <MenuItem value="">
        None
      </MenuItem>
      {enumValues(AppointmentPriority).map((option) => (
        <MenuItem key={option} value={option}>
          {splitCaps(AppointmentPriority[option as any])}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default AppointmentPriorityField;
