/* global google */
import { EventResizeDoneArg } from '@fullcalendar/interaction';
import { EventDropArg } from '@fullcalendar/react';
import {
  ChronoUnit,
  Duration,
  Instant, nativeJs,
  ZonedDateTime
} from '@js-joda/core';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { GoogleMap, Marker } from 'components/GoogleMap';
import { RouteRequest } from 'components/GoogleMap/useDirectionRenderer';
import {
  ALPHABET,
  HOUR_IN_MILLISECONDS,
  INITIAL_CENTER_FOR_UK,
  INITIAL_ZOOM_FOR_UK,
  PRACTICE_ENTITY_TYPE
} from 'config';
import AppointmentCalendar from 'features/AppointmentCalendar';
import { mapAppointmentToEvents } from 'features/AppointmentCalendar/eventHelpers';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import {
  AppointmentDto, AppointmentTimeUpdate,
  APPOINTMENT_KEY,
  LocationDto,
  useAppointmentsQuery,
  useLocationQuery,
  useLocationsQuery, useUpdateMultipleAppointmentTimesMutation, useUserQuery
} from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import { equals, indexBy, isEmpty } from 'ramda';
import React from 'react';
import { DEFAULT_SHORT_DATE_CONFIG, displayTemporal } from 'utils';
import { TITLE as parentTitle } from '../CalendarPage';
import { TITLE } from './constants';
import SchedulerCalendarMenu from './SchedulerPanelMenu';

const getAppointmentDurationInSeconds = (appointment: AppointmentDto) => Duration.between(appointment.startTime, appointment.endTime).seconds();
const sumArrayByRange = (start: number, end: number, array: number[]) => array.slice(start, end + 1).reduce((a, b) => a + b, 0);

const locationToWaypoint = (location: LocationDto): google.maps.DirectionsWaypoint => ({
  location: new google.maps.LatLng({ lat: location.geoPoint.latitude, lng: location.geoPoint.longitude }),
});

const DURATION_OFFSET = 1.01;

const validateAndCreateDate = (dayParam: any, monthParam: any, yearParam: any) => {
  const day = Number.parseInt(dayParam, 10);
  const month = Number.parseInt(monthParam, 10);
  const year = Number.parseInt(yearParam, 10);

  const tests = [
    [Number.isNaN(day), Number.isNaN(month), Number.isNaN(year)].some((val) => val === false),
    year < 10000,
    day < 32,
    month < 12,
  ];

  if (tests.some((test) => test === false)) return null;

  return new Date(Number(year), Number(month), Number(day));
};

const SchedulerPage = () => {
  const { teamId, vetId, day, month, year } = useProtectedParams('teamId', 'vetId', 'day', 'month', 'year');
  const startDate = validateAndCreateDate(day, month, year);
  const confirm = useConfirmation();

  const updateMultipleAppointmentTimesMutation = useUpdateMultipleAppointmentTimesMutation(teamId);
  const [origin, setOrigin] = React.useState<google.maps.LatLng | null>(null);
  const originalAppointmentsList = React.useRef<AppointmentDto[]>();
  const [appointmentsList, setAppointmentsList] = React.useState<AppointmentDto[]>();
  const [routes, setRoutes] = React.useState<RouteRequest[]>();
  const [optimiseTrigger, setOptimiseTrigger] = React.useState(false);
  const [isOptimisedRoute, setIsOptimisedRoute] = React.useState(false);
  const [anyMissingLocations, setAnyMissingLocations] = React.useState(false);

  const { data: vet } = useUserQuery({ teamId, userId: vetId });

  const { data: practiceLocation } = useLocationQuery({
    entityId: teamId,
    entityType: PRACTICE_ENTITY_TYPE,
  });

  const {
    data: appointments,
  } = useAppointmentsQuery(
    {
      practiceId: teamId,
      // Min date added to avoid error. In reality the query won't be enabled if there is no date.
      startDateUtc: startDate ? Instant.from(nativeJs(startDate)) : Instant.MIN,
      endDateUtc: startDate ? Instant.from(nativeJs(startDate)).plus(1, ChronoUnit.DAYS) : Instant.MIN,
      vetId,
    },
    {
      enabled: !appointmentsList && startDate !== undefined,
      onSuccess: (results) => setAppointmentsList(
        results.sort((appointmentA: AppointmentDto, appointmentB: AppointmentDto) => appointmentA.startTime.toEpochSecond() - appointmentB.startTime.toEpochSecond()),
      ),
    },
  );

  const appointmentCalendarEvents = appointmentsList && appointmentsList.length > 0
    ? mapAppointmentToEvents(appointmentsList ?? [], false)
    : [];

  if (appointmentsList && !originalAppointmentsList.current) originalAppointmentsList.current = appointmentsList;

  const { data: appointmentLocations } = useLocationsQuery(
    { entityType: APPOINTMENT_KEY, entityIds: appointments ? appointments?.map((appointment) => appointment.entityId) : [] },
    { staleTime: HOUR_IN_MILLISECONDS, enabled: appointments && appointments.length > 0 },
  );

  const appointmentLocationsIndexedById: Record<string, LocationDto> = React.useMemo(
    () => (appointmentLocations && appointmentLocations.length > 0
      ? indexBy((a) => a.entityId.split('|')[0], appointmentLocations)
      : {}),
    [appointmentLocations],
  );

  const handleAppointmentEdit = (event: EventDropArg | EventResizeDoneArg) => {
    if (!appointmentsList) return;
    setIsOptimisedRoute(false);

    const newAppointmentList = appointmentsList.map(
      (appointment) => (
        appointment.entityId === event.event.id
          ? {
            ...appointment,
            startTime: ZonedDateTime.from(nativeJs(event.event.start)),
            endTime: ZonedDateTime.from(nativeJs(event.event.end)),
          }
          : appointment
      ),
    )
      .sort(
        (appointmentA, appointmentB) => (appointmentA.startTime as ZonedDateTime).toEpochSecond() - (appointmentB.startTime as ZonedDateTime).toEpochSecond(),
      );
    newAppointmentList && setAppointmentsList(newAppointmentList);
  };

  const handleSaveSchedule = () => {
    confirm({
      variant: 'danger',
      description: 'This will permanently alter appointments related to this schedule. Do you want to continue?',
    }).then(() => {
      const appointmentUpdates: AppointmentTimeUpdate[] | undefined = appointmentsList?.map((appointment) => ({
        appointmentId: appointment.entityId,
        startTime: appointment.startTime,
        endTime: appointment.endTime,
      }));
      appointmentUpdates && updateMultipleAppointmentTimesMutation.mutate({ appointments: appointmentUpdates });
    });
  };

  const handleOptimiseSchedule = () => {
    confirm({
      variant: 'danger',
      description: 'This will alter the current schedule. Do you want to continue?',
    }).then(() => {
      setOptimiseTrigger((currentState) => !currentState);
    });
  };

  const applyOptimisationResults = (results: google.maps.DirectionsResult | null) => {
    if (!results || !appointmentsList) return;
    const { waypoint_order: waypointOrder, legs } = results.routes[0];

    setAppointmentsList((currentAppointments) => {
      if (!currentAppointments) return undefined;

      // appointments ordered correctly but the start and end times are incorrect
      const orderedAppointments = waypointOrder.map((waypoint) => currentAppointments[waypoint]);

      const timePassedPerLeg = legs.map((leg, index) => {
        const legDuration = legs[index + 1] ? (legs[index + 1].duration!.value * DURATION_OFFSET) : 0;
        const appointmentDurationWithPadding = orderedAppointments[index] ? getAppointmentDurationInSeconds(orderedAppointments[index]) : 0;
        return legDuration + appointmentDurationWithPadding;
      });

      setIsOptimisedRoute(true);

      // update the times based on appointment duration, travel duration and pad using duration offset
      return orderedAppointments.map((appointment, appointmentIndex) => {
        const timePassedSinceStart = sumArrayByRange(0, appointmentIndex - 1, timePassedPerLeg);
        const appointmentDuration = getAppointmentDurationInSeconds(appointment);

        // start time is always the intended start from the first appointment not ordered by waypoints
        return {
          ...appointment,
          startTime: appointmentsList[0].startTime.plusSeconds(timePassedSinceStart),
          endTime: appointmentsList[0].startTime.plusSeconds(timePassedSinceStart + appointmentDuration),
        };
      }).sort(
        (appointmentA, appointmentB) => (appointmentA.startTime as ZonedDateTime).toEpochSecond() - (appointmentB.startTime as ZonedDateTime).toEpochSecond(),
      );
    });
  };

  const generateRoutes = (optimise = false) => {
    if (isEmpty(appointmentLocationsIndexedById) || !appointmentsList || appointmentsList.length === 0 || anyMissingLocations || !origin) return;
    setRoutes(
      [{
        details: {
          origin: origin.toJSON(),
          destination: origin.toJSON(),
          waypoints: appointmentsList.map((appointment) => locationToWaypoint(appointmentLocationsIndexedById[appointment.entityId])),
          optimise,
        },
        callback: optimise ? applyOptimisationResults : undefined,
      }],
    );
  };

  const setOriginPoint = (latLng: google.maps.LatLng | null) => {
    setIsOptimisedRoute(false);
    setOrigin(latLng);
  };

  React.useEffect(
    () => generateRoutes(),
    [appointmentsList, appointmentLocationsIndexedById, origin],
  );

  React.useEffect(
    () => generateRoutes(true),
    [optimiseTrigger],
  );

  React.useEffect(
    () => {
      setAnyMissingLocations(
        appointmentsList && !isEmpty(appointmentLocationsIndexedById)
          ? appointmentsList.some((appointment) => appointmentLocationsIndexedById[appointment.entityId] === undefined)
          : true,
      );
    },
    [appointmentsList, appointmentLocationsIndexedById],
  );

  const isReadOnly = appointmentsList
    ? appointmentsList.some(
      (appointment) => appointment.appointmentStatus > 10,
    )
    : true;

  if (!startDate) {
    return (
      <Page pageType="standard" title={TITLE} parentRelativePath=".." parentTitle={parentTitle}>
        <Alert severity="error">
          {`The ${TITLE} requires a valid date. Return to the calendar and select a date.`}
        </Alert>
      </Page>
    );
  }

  if (appointmentLocations && appointmentLocations.length > 0 && anyMissingLocations) {
    return (
      <Page pageType="standard" title={TITLE} parentRelativePath=".." parentTitle={parentTitle}>
        <Alert severity="error">
          {`There are appointments scheduled on this day that do not have an assigned location.
          Every appointment must have a location before the ${TITLE} will function properly.`}
        </Alert>
      </Page>
    );
  }

  return (
    <Page pageType="full" title={TITLE} parentRelativePath=".." parentTitle={parentTitle}>
      <Stack width="100%" height="100%" direction="row" component={Paper}>
        <GoogleMap
          disableDefaultUI
          zoomControl
          onClick={(e) => setOriginPoint(e.latLng)}
          draggableCursor="crosshair"
          routes={routes}
          zoom={
            practiceLocation
              ? 11
              : INITIAL_ZOOM_FOR_UK
          }
          center={
            practiceLocation
              ? {
                lat: practiceLocation.geoPoint.latitude,
                lng: practiceLocation.geoPoint.longitude,
              }
              : INITIAL_CENTER_FOR_UK
          }
        >
          {
            practiceLocation
            && (
              <Marker
                position={{ lat: practiceLocation.geoPoint.latitude, lng: practiceLocation.geoPoint.longitude }}
                selected
                label="Practice location"
              />
            )
          }
          {
            origin && (
              <Marker
                position={origin}
                selected
                iconType="vetOutdated"
                label={{
                  color: 'black',
                  fontWeight: 'bold',
                  text: 'ORIGIN',
                }}
              />
            )
          }
          {
            origin && appointmentsList && appointmentsList.map(
              (appointment, index) => (
                appointmentLocationsIndexedById[appointment.entityId] && (
                  <Marker
                    key={appointment.entityId}
                    position={{
                      lat: appointmentLocationsIndexedById[appointment.entityId].geoPoint.latitude,
                      lng: appointmentLocationsIndexedById[appointment.entityId].geoPoint.longitude,
                    }}
                    selected
                    iconType="schedulerLocation"
                    label={{
                      color: 'white',
                      fontWeight: 'bold',
                      text: ALPHABET[index],
                    }}
                  />
                )
              ),
            )
          }
        </GoogleMap>

        <Box width="30%" maxWidth={420} minWidth={300} display="flex" flexDirection="column">
          <Stack p={2} pb={0}>
            <Typography variant="h4">{displayTemporal(startDate, DEFAULT_SHORT_DATE_CONFIG)}</Typography>
            <Box width="100%" pt={1}>
              <Divider />
            </Box>
            <Box flexGrow={1} flexBasis="auto" overflow="auto" mb={1}>
              <Stack direction="row" display="flex">
                <Box flexGrow={1}>
                  <Typography variant="h6">{`${vet?.forename} ${vet?.surname}`}</Typography>
                  <Stack spacing={2} direction="row">
                    {isOptimisedRoute && <Chip label="Optimised" color="success" variant="outlined" size="small" />}
                    {!equals(originalAppointmentsList.current, appointmentsList) && <Chip label="Modified" color="success" variant="outlined" size="small" />}
                    {isReadOnly && (
                      <Tooltip title="One or more appointments in this schedule are not editable">
                        <Chip label="Read only" color="error" variant="outlined" size="small" />
                      </Tooltip>
                    )}
                  </Stack>
                </Box>
                {origin && (
                  <SchedulerCalendarMenu
                    disabled={isReadOnly}
                    onSave={handleSaveSchedule}
                    onOptimise={handleOptimiseSchedule}
                    onReset={() => originalAppointmentsList.current && setAppointmentsList(originalAppointmentsList.current)}
                  />
                )}
              </Stack>
            </Box>
          </Stack>
          {!origin && (
            <Box p={1}>
              <Alert severity="info">
                {`Click a starting point (origin) on the map to begin using the ${TITLE}`}
              </Alert>
            </Box>
          )}
          {origin && (
            <AppointmentCalendar
              appointmentCalendarEvents={
                appointmentCalendarEvents.map(
                  (calendarEvent, index) => (
                    {
                      ...calendarEvent,
                      title: `${ALPHABET[index]}: ${calendarEvent.title}`,
                    }
                  ),
                )
              }
              dateClick={() => { }}
              viewType="timeGridDay"
              controls={false}
              readOnly={isReadOnly}
              onAppointmentEdit={(event) => handleAppointmentEdit(event)}
              initialDate={startDate}
            />
          )}
        </Box>
      </Stack>
    </Page>
  );
};
export default SchedulerPage;
