import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface TitleDisplayStackProps {
  title: string;
  subtitle?: string
  display: React.ReactNode;
}

const TitleDisplayStack = ({ title, display, subtitle }: TitleDisplayStackProps) => (
  <Stack direction={{ xs: 'column', sm: 'row' }}>
    <Stack direction="column" sx={{ width: 200 }}>
      <Typography variant="subtitle2">{title}</Typography>
      <Typography variant="caption">{subtitle}</Typography>

    </Stack>
    <Box flex={1}>{display}</Box>
  </Stack>
);

export default TitleDisplayStack;
