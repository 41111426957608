import { DateTimeFormatter, LocalDateTime } from '@js-joda/core';
import '@js-joda/timezone';

// Override toJSON function as format expected is slightly different to what js-joda produces
LocalDateTime.prototype.toJSON = function toJSON() {
  const formatter = DateTimeFormatter
    .ofPattern('HH:mm:ss.nnnnnnnnn');

  return `${this.toLocalDate().toString()}T${this.toLocalTime().format(formatter)}`;
};

// eslint-disable-next-line import/prefer-default-export
export const makeLocalDateTime = (dateTimeString: string): LocalDateTime => LocalDateTime.parse(dateTimeString);
