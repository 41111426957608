import { useContext } from 'react';
import SignalRContext, { ISignalRContext } from './signalR.context';

export default function useSignalR(): ISignalRContext {
  const context = useContext(SignalRContext);
  if (context === null) {
    throw new Error('No Signal R context available');
  }
  return context;
}
