import { PRACTICE_ROLES } from 'config';
import { NewUserFeature } from 'features/user';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { TITLE as parentTitle } from '../UserInvitationsPage';
import { TITLE as pageTitle } from './constants';

const NewUserPage = () => {
  const { teamId } = useProtectedParams('teamId');

  return (
    <Page title={pageTitle} pageType="standard" parentTitle={parentTitle} parentRelativePath="../invites">
      <NewUserFeature teamId={teamId} availableRoles={PRACTICE_ROLES} />
    </Page>
  );
};

export default NewUserPage;
