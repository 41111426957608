/* global google */
import { WebStorageStateStore } from 'oidc-client';

export const LOCAL_STORAGE_PREFIX = 'ARRIVET';
export const OIDC_CONFIGURATION = {
  client_id: 'Aegis',
  redirect_uri: `${window.location.origin}/app/authentication/callback`,
  response_type: 'code',
  post_logout_redirect_uri: `${window.location.origin}/Login`,
  scope: 'openid email profile roles offline_access',
  authority: window.location.origin,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  // This might be redundant as we are using a refresh token instead of the slient callback
  silent_redirect_uri: `${window.location.origin}/app/authentication/silent_callback`,
  automaticSilentRenew: true,
  loadUserInfo: true,
};
export const FULL_CALENDARY_KEY = '0023742070-fcs-1653469217';
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const LONDON_CENTER: google.maps.LatLngLiteral = { lat: 51.5072, lng: 0.1276 };
export const INITIAL_ZOOM_FOR_UK = 6;
export const INITIAL_CENTER_FOR_UK = { lat: 50.736129, lng: -1.988229 };
export const PAGINATION_PAGE_SIZES = [10, 25, 50];
export const PAGINATION_DEFAULT_PAGE_SIZE = PAGINATION_PAGE_SIZES[0];
export const AUTOCOMPLETE_PAGE_SIZE = PAGINATION_PAGE_SIZES[1];
export const BAD_REQUEST_CODE = 400;
export const UNAUTHORISED_ERROR_CODE = 401;
export const FORBIDDEN_ERROR_CODE = 403;
export const NOT_FOUND_ERROR_CODE = 404;
export const NOT_ACCEPTABLE_ERROR_CODE = 406;
export const TIMEOUT_ERROR_CODE = 408;
export const TEMPORARY_ACCESS_TOKEN_EXPIRED_CODE = 410;
export const EXPECTED_ERROR_CODES = [
  BAD_REQUEST_CODE,
  UNAUTHORISED_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
  NOT_ACCEPTABLE_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
  TEMPORARY_ACCESS_TOKEN_EXPIRED_CODE,
];
export const SECOND_IN_MILLISECONDS = 1000;
export const MINUTE_IN_MILLISECONDS = SECOND_IN_MILLISECONDS * 60;
export const HOUR_IN_MILLISECONDS = MINUTE_IN_MILLISECONDS * 60;
export const DAY_IN_MILLISECONDS = HOUR_IN_MILLISECONDS * 24;
export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const AUTH_TO_CREATE_APPOINTMENTS = ['Practice Administrator', 'Receptionist'];
export const CLIENT_ROLES = ['Client', 'Patient Handler'];
export const PRACTICE_ROLES = ['Practice Administrator', 'Receptionist', 'Vet'];
export const PRACTICE_ENTITY_TYPE = 'practice';
