import { PRACTICE_ROLES } from 'config';
import { EditUserFeature } from 'features/user';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { TITLE as parentTitle } from '../UsersPage';
import { TITLE as pageTitle } from './constants';

const EditUserPage = () => {
  const { teamId, userId } = useProtectedParams('teamId', 'userId');

  return (
    <Page title={pageTitle} pageType="standard" parentTitle={parentTitle} parentRelativePath="..">
      <EditUserFeature teamId={teamId} userId={userId} availableRoles={PRACTICE_ROLES} />
    </Page>
  );
};

export default EditUserPage;
