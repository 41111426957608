import { useContext } from 'react';
import { ConfirmationOptions } from './ConfirmDialog';
import ConfirmationContext from './context';

const useConfirmation = (): (options: ConfirmationOptions) => Promise<void> => {
  const context = useContext(ConfirmationContext);
  if (context === null) {
    throw new Error('No confirmation context available');
  }
  return context;
};

export default useConfirmation;
