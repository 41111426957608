import {
  AppointmentPriority, AppointmentType,
  ContactDetailsForm,
  CreateAppointmentMutation
} from 'providers/api';
import { createForm } from 'providers/form';

export interface AppointmentValues {
  clientId: string;
  startDate: Date | null;
  startTime: Date | null;
  endTime: Date | null;
  appointmentType: AppointmentType | null;
  appointmentPriority: AppointmentPriority | null;
  vetId: string;
  patientId: string;
  attendeeIds: string[];
  appointmentSummary: string;
  veterinaryNotes?: string;
  contactDetails?: ContactDetailsForm;
  primaryContactId: string;
}

export const {
  FormProvider: AppointmentFormProvider,
  useForm: useAppointmentForm,
} = createForm<CreateAppointmentMutation, AppointmentValues>();
