import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { useCreateCalendarEventMutation } from 'providers/api';
import NewCalendarEventForm from '../NewCalendarEventForm/NewCalendarEventForm';
import { TITLE as parentTitle } from '../StaffAvailabilityPage';
import { TITLE } from './constants';
import { useNewTimeBlockLocationState } from './useNewTimeBlock';

const NewCalendarEventPage = () => {
  // TODO: consider storing simple state in the url via query string props for improved UX
  const { date, vetId } = useNewTimeBlockLocationState();
  const { teamId } = useProtectedParams('teamId');

  const createCalendarEventMutation = useCreateCalendarEventMutation();

  return (
    <Page
      pageType="standard"
      title={TITLE}
      parentRelativePath=".."
      parentTitle={parentTitle}
    >
      <NewCalendarEventForm
        mutation={createCalendarEventMutation}
        initialStartDate={date}
        initialVet={vetId}
        teamId={teamId}
      />
    </Page>
  );
};
export default NewCalendarEventPage;
