import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Actions from 'components/Actions';
import { FormikProps } from 'formik';
import { UseMutationResult } from 'react-query';

interface FormikSubmitButtonProps {
  formik: FormikProps<any>;
  text?: string;
  small?: boolean;
  disabledOverride?: boolean;
  allowPristineSubmit?: boolean
}

const FormikSubmitButton = ({
  formik: { isSubmitting, isValid, dirty, handleSubmit },
  small,
  text = 'Submit',
  disabledOverride,
  allowPristineSubmit = false,
}: FormikSubmitButtonProps) => (
  <LoadingButton
    variant="contained"
    color="primary"
    onClick={() => handleSubmit()}
    disabled={disabledOverride || !isValid || (allowPristineSubmit ? false : !dirty)}
    loading={isSubmitting}
    size={small ? 'small' : undefined}
  >
    {text}
  </LoadingButton>
);

interface FormikResetButtonProps {
  mutation: UseMutationResult<any, any, any, any>,
  formik: FormikProps<any>,
  small?: boolean;
  text?: string,
}

const FormikResetButton = ({ mutation, formik: { resetForm, isSubmitting, dirty }, small, text = 'Reset' }: FormikResetButtonProps) => {
  const handleResetForm = () => {
    resetForm();
    mutation.reset();
  };

  return (
    <Button variant="contained" color="secondary" onClick={handleResetForm} disabled={!dirty || isSubmitting} size={small ? 'small' : undefined}>
      {text}
    </Button>
  );
};

const makeActionFactory = (
  mutation: UseMutationResult<any, any, any, any>,
  formik: FormikProps<any>,
  submitText: string,
  resetText: string,
  submitDisableOverride?: boolean,
  small?: boolean,
) => (type: 'submit' | 'reset' | [string, JSX.Element]) => {
  if (type === 'submit') {
    return ['submit', <FormikSubmitButton formik={formik} text={submitText} disabledOverride={submitDisableOverride} small={small} />] as [string, JSX.Element];
  }

  if (type === 'reset') {
    return ['reset', <FormikResetButton mutation={mutation} formik={formik} text={resetText} small={small} />] as [string, JSX.Element];
  }

  return type;
};

interface FormikActionsProps {
  mutation: UseMutationResult<any, any, any, any>;
  formik: FormikProps<any>;
  left?: ('submit' | 'reset' | [string, JSX.Element])[];
  center?: ('submit' | 'reset' | [string, JSX.Element])[];
  right?: ('submit' | 'reset' | [string, JSX.Element])[];
  small?: boolean;
  submitText?: string;
  resetText?: string;
  submitDisableOverride?: boolean;
}

const FormikActions = ({
  mutation,
  formik,
  left = [],
  right = [],
  center = [],
  small,
  submitText = 'Submit',
  resetText = 'Reset',
  submitDisableOverride,
}: FormikActionsProps) => {
  const getAction = makeActionFactory(mutation, formik, submitText, resetText, submitDisableOverride, small);

  const leftActions = left.map(getAction);
  const centerActions = center.map(getAction);
  const rightActions = right.map(getAction);

  return <Actions left={leftActions} center={centerActions} right={rightActions} />;
};

export default FormikActions;
