import React, { ReactNode } from 'react';
import PageContext, {
  IPageContext,
  SubNavigation
} from './context';

export interface PageProviderProps {
  children: ReactNode;
}

const PageProvider = ({ children }: PageProviderProps) => {
  const [title, setTitle] = React.useState<string | null>(null);
  const [parentPath, setParentPath] = React.useState<string | null>(null);
  const [parentTitle, setParentTitle] = React.useState<string | null>(null);
  const [subNavigation, setSubNavigation] = React.useState<SubNavigation[]>([]);

  const value: IPageContext = React.useMemo(() => ({
    state: { title, parentPath, parentTitle, subNavigation },
    actions: { setTitle, setParentPath, setParentTitle, setSubNavigation },
  }), [title, parentPath, parentTitle, subNavigation]);

  return (
    <PageContext.Provider value={value}>
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;
