import PracticeLocationFeature from 'features/PracticeLocation/PracticeLocationFeature';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import React from 'react';
import { TITLE as parentTitle } from '../SettingsPage/constants';
import { TITLE } from './constants';

const PracticeLocationPage = () => {
  const { teamId } = useProtectedParams('teamId');

  return (
    <Page pageType="standard" title={TITLE} parentRelativePath="../" parentTitle={parentTitle}>
      <PracticeLocationFeature practiceId={teamId} />
    </Page>
  );
};

export default PracticeLocationPage;
