/* eslint-disable react/no-unused-prop-types */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface OptionDialogInterface {
  title? : string
  description?: string
  optionAButtonText?: string
  onOptionA?: () => void
  optionBButtonText: string
  onOptionB: () => void
  handleClose: () => void
}

const OptionDialog = ({
  title,
  description,
  optionAButtonText,
  onOptionA,
  optionBButtonText,
  onOptionB,
  handleClose,
}: OptionDialogInterface) => (
  <Dialog open>
    {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle> }
    {description && (
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
        )}
    <DialogActions sx={{ pb: 2, pr: 2 }}>
      <>
        <Button variant="text" onClick={handleClose}>Cancel</Button>
        {onOptionA
          && (
          <Button color="secondary" variant="contained" onClick={onOptionA}>
            {optionAButtonText}
          </Button>
              )}
        <Button color="primary" variant="contained" onClick={onOptionB} autoFocus>
          {optionBButtonText}
        </Button>
      </>
    </DialogActions>
  </Dialog>
);

export default OptionDialog;
