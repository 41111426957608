import { InvitationDto, InviteUserToTeamsCommand, PagedListOfInvitationDto } from './api';
import {
  ListKey,
  makeCreateMutation,
  makeDeleteMutation,
  makeListQuery,
  PageParams
} from './useApi';

const USER_INVITES_KEY = 'user-invitations' as ListKey;

export interface UserInvitationsQueryParams extends PageParams {
  teamId?: string | null,
  searchTerm?: string | null;
}

export const useUserInvitationsQuery = makeListQuery<UserInvitationsQueryParams, PagedListOfInvitationDto>(
  USER_INVITES_KEY,
  (api, params) => () => api.userInvitations.listInvitations(params.page, params.pageSize, params.teamId, params.searchTerm),
);

export const useCreateUserInviteMutation = makeCreateMutation<void, InviteUserToTeamsCommand>(
  USER_INVITES_KEY,
  (api) => (mutation) => api.userInvitations.create(mutation),
  (mutation) => `Invite successfully sent to ${mutation.email}`,
);

export const useRevokeUserInvitationMutation = makeDeleteMutation<void, InvitationDto>(
  null,
  USER_INVITES_KEY,
  (api) => (invite) => api.userInvitations.revokeInvitation(invite.invitationId),
  (invite) => `Invite for ${invite.email} successfully revoked`,
  (mutation) => mutation.invitationId,
);
