import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/system/Box';
import Loading from 'components/Loading';
import { HOUR_IN_MILLISECONDS } from 'config';
import AppointmentInfoSections from 'features/AppointmentUpdateForm/AppointmentInfoSections';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import usePublishAppointments from 'modules/practice/calendar/usePublishAppointments';
import {
  AppointmentStatus,
  useAppointmentQuery,
  useAppointmentVetNotesQuery,
  useCancelAppointmentMutation,
  useClientQuery,
  useConfirmAppointmentMutation,
  useSetAppointmentsAsDraftMutation
} from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import { useNavigate } from 'react-router-dom';
import { TITLE as parentTitle } from '../CalendarPage/constants';
import { TITLE } from './constants';

const AppointmentPage = () => {
  const { teamId, appointmentId } = useProtectedParams('teamId', 'appointmentId');
  const confirm = useConfirmation();
  const navigate = useNavigate();
  const { publishAppointments } = usePublishAppointments(teamId);
  const { data: appointment, isLoading: appointmentIsLoading } = useAppointmentQuery(appointmentId);
  const { data: client, isLoading: clientIsLoading } = useClientQuery(appointment?.clientId ?? '', {
    enabled: !!appointment,
    staleTime: HOUR_IN_MILLISECONDS,
  });
  const setAppointmentAsDraftMutation = useSetAppointmentsAsDraftMutation();
  const { data: appointmentVetNotes } = useAppointmentVetNotesQuery(appointmentId);
  const cancelAppointmentMutation = useCancelAppointmentMutation(appointmentId, {
    onSuccess: () => navigate('../../..'),
  });
  const confirmAppointmentMutation = useConfirmAppointmentMutation(appointmentId, {
    onSuccess: () => navigate('../../..'),
  });

  const markAppointmentAsDraft = (appointmentsToDraft: string[]) => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want to move this appointment to draft?',
    }).then(() => setAppointmentAsDraftMutation.mutate({
      teamId,
      command: {
        appointmentIds: appointmentsToDraft,
      },
    }));
  };

  const handleConfirmAppointment = (appointmentToConfirm: string) => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want to confirm this appointment?',
    }).then(() => confirmAppointmentMutation.mutate(appointmentToConfirm));
  };

  const handleCancelAppointment = (appointmentToConfirm: string) => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want to cancel this appointment?',
    }).then(() => cancelAppointmentMutation.mutate(appointmentToConfirm));
  };

  if (!appointment || appointmentIsLoading || !client || clientIsLoading) {
    return <Loading />;
  }
  return (
    <Page pageType="standard" title={TITLE} parentTitle={parentTitle} parentRelativePath="../../..">
      {client && <AppointmentInfoSections appointment={appointment} client={client} vetNotes={appointmentVetNotes} />}
      <Stack spacing={2}>
        {
          appointment.appointmentStatus === AppointmentStatus.Draft
          && (
            <>
              <Button
                fullWidth
                variant="contained"
                onClick={() => publishAppointments([appointmentId])}
              >
                Publish Appointment
              </Button>
              <Tooltip title="You must publish the appointment before you can confirm the details">
                <Box>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => handleConfirmAppointment(appointmentId)}
                    disabled
                  >
                    Confirm Appointment Details
                  </Button>
                </Box>
              </Tooltip>
            </>
          )
        }
        {
          appointment.appointmentStatus === AppointmentStatus.Unconfirmed
          && (
            <>
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleConfirmAppointment(appointmentId)}
              >
                Confirm Appointment Details
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => markAppointmentAsDraft([appointmentId])}
              >
                Move Appointment To Draft
              </Button>
            </>
          )
        }
        <Button
          fullWidth
          variant="contained"
          color="error"
          onClick={() => handleCancelAppointment(appointmentId)}
        >
          {appointment.appointmentStatus === AppointmentStatus.Draft
            ? 'Delete Appointment'
            : 'Cancel Appointment'}
        </Button>
      </Stack>
    </Page>
  );
};

export default AppointmentPage;
