import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { AddressFormFields, initialData as addressFormInitialData } from 'components/AddressFormFields';
import { FormStack } from 'components/Form';
import { FormikErrors } from 'formik';
import { UpdatePracticeCommand } from 'providers/api';
import { dissocPath } from 'ramda';
import { usePracticeForm } from './context';

const SiteAddressFields = () => {
  const {
    form: { formik, helpers },
  } = usePracticeForm();

  const { values, handleChange, handleBlur, setFieldValue, setErrors, errors } = formik;

  const handleAddSiteAddress = () => {
    setFieldValue('site.siteAddress', addressFormInitialData);
  };

  const handleRemoveSiteAddress = () => {
    setFieldValue('site.siteAddress', undefined);
    const updatedErrors = dissocPath<FormikErrors<UpdatePracticeCommand>>(['site', 'siteAddress'], errors);
    setErrors(updatedErrors);
  };

  const handleAddAdditionalSiteAddressField = (field: string) => {
    setFieldValue(`site.siteAddress.${field}`, '');
  };

  return !values.site.siteAddress
    ? (
      <Box my={2}>
        <Button variant="contained" color="secondary" onClick={handleAddSiteAddress} startIcon={<AddIcon />}>Address</Button>
      </Box>
    )
    : (
      <FormStack>
        <AddressFormFields
          baseKey="site.siteAddress"
          values={values.site.siteAddress}
          hasError={helpers.hasError}
          getErrorHelpText={helpers.getErrorHelpText}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleAddAdditionalAddressField={handleAddAdditionalSiteAddressField}
        />
        <Box my={2}>
          <Button variant="contained" color="inherit" onClick={handleRemoveSiteAddress}>Remove Address</Button>
        </Box>
      </FormStack>
    );
};

export default SiteAddressFields;
