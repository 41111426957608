/* eslint-disable class-methods-use-this */
import {
  DateTimeFormatter, Instant, LocalDate, LocalTime
} from '@js-joda/core';
import '@js-joda/timezone';

Instant.prototype.toJSON = function toJSON() {
  const formatter = DateTimeFormatter
    .ofPattern('HH:mm:ss.nnnnnnnnn');

  const localDate = LocalDate.ofInstant(this);
  const localTime = LocalTime.ofInstant(this);

  return `${localDate.toString()}T${localTime.format(formatter)}Z`;
};

// eslint-disable-next-line no-useless-escape, max-len
const OFFSET_DATE_TIME_REG_EX = /^[0-9]{4}-((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01])|(0[469]|11)-(0[1-9]|[12][0-9]|30)|(02)-(0[1-9]|[12][0-9]))T(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])(\.[0-9]{1,9})?Z$/;
// eslint-disable-next-line no-useless-escape
const SINGLE_OFFSET_REG_EX = /[\+-][0-9]{2}$/;

// eslint-disable-next-line import/prefer-default-export
export const makeInstant = (dateTimeString: string): Instant => {
  const matches = dateTimeString.match(OFFSET_DATE_TIME_REG_EX);

  const offsetDateTime = matches![0];

  const offset = offsetDateTime.match(SINGLE_OFFSET_REG_EX);

  const jsJodaFriendly = offset?.length ? offsetDateTime.replace(SINGLE_OFFSET_REG_EX, `${offset[0]}:00`) : offsetDateTime;

  return Instant.parse(`${jsJodaFriendly}`);
};
