/* global google */

import React from 'react';
import vetOutdatedMapIcon from './pin-outdated.svg';
import vetMapIcon from './pin.svg';
import locationMapIcon from './selected-map-icon.png';

export type MarkerIconTypes = 'vet' | 'vetOutdated' | 'location' | 'schedulerLocation';
export interface MarkerProps extends google.maps.MarkerOptions {
  selected?: boolean;
  hover?: boolean;
  iconType?: MarkerIconTypes;
  info?: string;
  showInfo?: boolean;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  onInfoWindowClose?: () => void;
}

const Marker = ({ selected, hover, iconType = 'location', info, showInfo, onClick, onMouseOver, onMouseOut, onInfoWindowClose, ...options }: MarkerProps) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  // INFO: this record is instantiated inside the component because initialising google objects outside causes an error.
  // google is not defined outside the scope of the component. However, types can be access for some reason. This might
  // be due to the async loading of the google api library. This is something to be aware of if maps is behaving
  // unpredictably in the future.
  const ICONS: Record<MarkerIconTypes, google.maps.Icon> = {
    vet: {
      url: vetMapIcon,
      scaledSize: new google.maps.Size(50, 62),
      origin: new google.maps.Point(0, 0),
      labelOrigin: new google.maps.Point(25, 67),
    },
    vetOutdated: {
      url: vetOutdatedMapIcon,
      scaledSize: new google.maps.Size(50, 62),
      origin: new google.maps.Point(0, 0),
      labelOrigin: new google.maps.Point(25, 67),
      anchor: new google.maps.Point(25, 62),
    },
    location: {
      url: locationMapIcon,
      scaledSize: new google.maps.Size(50, 62),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(14.5, 62),
      labelOrigin: new google.maps.Point(25, 67),
    },
    schedulerLocation: {
      url: locationMapIcon,
      scaledSize: new google.maps.Size(50, 62),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(14.5, 62),
      labelOrigin: new google.maps.Point(15, 30),
    },
  };
  const [infoWindow, setInfoWindow] = React.useState<google.maps.InfoWindow>();

  const selectedIcon = ICONS[iconType];

  React.useEffect(() => {
    if (!marker) {
      const newMarker = new google.maps.Marker();
      setMarker(newMarker);
      if (info) {
        const newWindow = new google.maps.InfoWindow({ content: info });
        newWindow.addListener('closeclick', () => onInfoWindowClose && onInfoWindowClose());

        if (showInfo) {
          newWindow.open({
            anchor: newMarker,
            map: newMarker.getMap(),
            shouldFocus: false,
          });
        }

        setInfoWindow(newWindow);
      }
    }

    if (marker) {
      ['mouseover', 'mouseout', 'click'].forEach((eventName) => google.maps.event.clearListeners(marker, eventName));

      marker.addListener('mouseover', () => onMouseOver && onMouseOver());
      marker.addListener('mouseout', () => onMouseOut && onMouseOut());

      onClick && marker.addListener('click', () => onClick());
    }

    return () => {
      // remove marker from map on unmount
      if (marker) {
        ['mouseover', 'mouseout', 'click'].forEach((eventName) => google.maps.event.clearListeners(marker, eventName));
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker && infoWindow && showInfo) {
      const map = marker.getMap();
      const markerPosition = marker.getPosition();
      infoWindow.open({
        anchor: marker,
        map,
        shouldFocus: false,
      });
      markerPosition && (map as google.maps.Map)?.panTo(markerPosition);
    }

    if (marker && infoWindow && !showInfo) {
      infoWindow.close();
    }
  }, [showInfo]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions({ ...options, icon: (selected || hover) ? selectedIcon : undefined });
    }
  }, [marker, options, selected, hover]);

  return null;
};

export default Marker;
