import React from 'react';

export type ITopContentContext = {
  container: HTMLDivElement | null;
  containerHeight: number;
}

const TopContentContext = React.createContext<ITopContentContext | null>(null);

export default TopContentContext;
