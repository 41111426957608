import { DateClickArg, EventResizeDoneArg } from '@fullcalendar/interaction';
import FullCalendar, {
  EventApi, EventClickArg, EventDropArg, EventSourceInput
} from '@fullcalendar/react';
import {
  DayOfWeek,
  Duration,
  Instant, nativeJs, TemporalAdjusters, ZonedDateTime
} from '@js-joda/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FULL_CALENDARY_KEY, HOUR_IN_MILLISECONDS } from 'config';
import { mapTimeBlockToEvents } from 'features/AppointmentCalendar/eventHelpers';
import { interactionPlugin, resourceTimelinePlugin, scrollGridPlugin } from 'features/AppointmentCalendar/importsPlugins';
import useProtectedParams from 'hooks/useProtectedParams';
import useMenuState from 'modules/Layout/MenuStateProvider';
import Page from 'modules/Page';
import { useNewTimeBlockNavigate } from 'modules/practice/StaffAvailability/NewCalendarEventPage/useNewTimeBlock';
import {
  UpdateTimeBlockCommand, useCalendarEventsQuery, useDeleteCalendarEvent, useDeleteTimeBlock, UserDto, useUpdateTimeBlockMutation, useUsersQuery
} from 'providers/api';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TITLE } from './constants';
import OptionDialog from './OptionDialog';

interface EditEventInfoInterface {
  eventInfo: EventDropArg | EventResizeDoneArg
  payloadTimeBlock: UpdateTimeBlockCommand
}

interface DeleteEventInfoInterface {
  calendarEventId: string
  timeBlockId: string
}

const StaffAvailabilityPage = () => {
  const navigate = useNavigate();
  const newTimeBlockNavigate = useNewTimeBlockNavigate();
  const { state: { mainMenuIsOpen, notificationsDrawerIsOpen } } = useMenuState();
  const { teamId } = useProtectedParams('teamId');
  const calendarRef = React.createRef<FullCalendar>();
  const deleteTimeBlock = useDeleteTimeBlock();
  const deleteCalendarEvent = useDeleteCalendarEvent();
  const updateTimeBlockMutation = useUpdateTimeBlockMutation();
  const [calendarConstraints, setCalendarConstraints] = React.useState({
    startDate: ZonedDateTime.now().with(TemporalAdjusters.previousOrSame(DayOfWeek.MONDAY)),
    endDate: ZonedDateTime.now().with(TemporalAdjusters.nextOrSame(DayOfWeek.SUNDAY)),
  });
  const { data: users } = useUsersQuery({ page: 1, pageSize: 100, teamId });
  const vets = users ? users.items.filter((user: UserDto) => user.teamRoles.includes('Vet')) : [];
  const [isMonthView, setIsMonthView] = React.useState(false);
  const [isDayView, setIsDayView] = React.useState(false);
  const [editEventInfo, setEditEventInfo] = React.useState<EditEventInfoInterface | null>(null);
  const [deleteEventInfo, setDeleteEventInfo] = React.useState<DeleteEventInfoInterface | null>(null);

  const {
    data: calendarEvents,
  } = useCalendarEventsQuery(
    {
      practiceId: teamId,
      startDate: calendarConstraints.startDate,
      endDate: calendarConstraints.endDate,
    },
    {
      staleTime: HOUR_IN_MILLISECONDS,
    },
  );

  const timeBlockEvents: EventSourceInput = calendarEvents
    ? mapTimeBlockToEvents(calendarEvents, false, isDayView, 'resourceTimeline')
    : [];

  const handleConfirmEditEvent = (eventInfo: EventDropArg | EventResizeDoneArg) => {
    const payloadTimeBlock = {
      start: ZonedDateTime.from(nativeJs(eventInfo.event.start)),
      end: ZonedDateTime.from(nativeJs(eventInfo.event.end)),
      timeBlockId: eventInfo.event.id,
    };
    setEditEventInfo({ eventInfo, payloadTimeBlock });
  };

  const handleDeleteClick = (eventInfo: EventApi) => {
    setDeleteEventInfo({ calendarEventId: eventInfo.groupId, timeBlockId: eventInfo.id });
  };
  const handleEventClick = (targetEventId: string) => navigate(targetEventId);
  const handleDateClick = (date: Date, vetId: string) => newTimeBlockNavigate('new', { date, vetId });

  const handleCalendarConstraintsChange = (startDate: Date, endDate: Date) => {
    const constraintDuration = Duration.between(Instant.from(nativeJs(startDate)), Instant.from(nativeJs(endDate)));
    setIsMonthView(constraintDuration.toDays() > 7);
    setIsDayView(constraintDuration.toDays() === 1);

    setCalendarConstraints({
      startDate: ZonedDateTime.from(nativeJs(startDate)),
      endDate: ZonedDateTime.from(nativeJs(endDate)),
    });
  };

  React.useEffect(() => {
    setTimeout(() => calendarRef.current && calendarRef.current.getApi().updateSize(), 300);
  }, [mainMenuIsOpen, notificationsDrawerIsOpen]);

  const renderEventContent = (eventInfo: EventClickArg) => (
    isMonthView
      ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
          <IconButton size="small" aria-label="edit" onClick={() => handleEventClick(eventInfo.event.groupId)}>
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" aria-label="delete" onClick={() => handleDeleteClick(eventInfo.event)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      )
      : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
          <Typography variant="body1">
            {eventInfo.event.title}
          </Typography>
          <Box>
            <IconButton aria-label="edit" onClick={() => handleEventClick(eventInfo.event.groupId)}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => handleDeleteClick(eventInfo.event)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      )
  );

  return (
    <Page pageType="full" title={TITLE} parentRelativePath="..">
      <Paper sx={{ p: 2, mb: 2, height: '100%' }}>
        <FullCalendar
          schedulerLicenseKey={FULL_CALENDARY_KEY}
          ref={calendarRef}
          plugins={[interactionPlugin, scrollGridPlugin, resourceTimelinePlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayView,weekView,monthView',
          }}
          views={{
            dayView: {
              type: 'resourceTimeline',
              duration: { days: 1 },
              buttonText: 'Day',
            },
            weekView: {
              type: 'resourceTimeline',
              duration: { weeks: 1 },
              buttonText: 'Week',
            },
            monthView: {
              type: 'resourceTimeline',
              duration: { months: 1 },
              buttonText: 'Month',
            },
          }}
          initialView="resourceTimelineMonth"
          weekends
          expandRows
          events={timeBlockEvents}
          height="100%"
          dayMinWidth={200}
          duration={{ days: 1 }}
          nowIndicator
          dateClick={(event: DateClickArg) => handleDateClick(event.date, event.resource?.id ?? '')}
          resources={
            vets
              ? vets.map((vet) => ({
                id: vet.userId,
                ...(vet.forename === undefined && vet.surname === undefined
                  ? { title: vet.email }
                  : { title: `${vet.forename ?? ''} ${vet.surname ?? ''}` }
                ),
              }))
              : undefined
          }
          resourceAreaHeaderContent="Vets"
          datesSet={(dateConstraints) => handleCalendarConstraintsChange(dateConstraints.start, dateConstraints.end)}
          eventContent={renderEventContent}
          eventDrop={handleConfirmEditEvent}
          eventResize={handleConfirmEditEvent}
        />
      </Paper>
      {editEventInfo && teamId
        && (
          <OptionDialog
            description={
              `Please confirm that you would like to update the event for this day.

            If you would like to edit the entire series, please click the pencil on the timeline view.`
            }
            optionBButtonText="Confirm"
            onOptionB={() => updateTimeBlockMutation.mutate(
              { practiceId: teamId, calendarEventId: editEventInfo.eventInfo.event.groupId, form: editEventInfo.payloadTimeBlock },
              {
                onSuccess: () => {
                  setEditEventInfo(null);
                },
              },
            )}
            handleClose={() => {
              setEditEventInfo(null);
            }}
          />
        )}
      {deleteEventInfo && teamId
        && (
          <OptionDialog
            description="Would you like to DELETE this event only or all events in the series?"
            optionAButtonText="Delete all in series"
            onOptionA={() => deleteCalendarEvent.mutate(
              { practiceId: teamId, calendarEventId: deleteEventInfo.calendarEventId },
              {
                onSuccess: () => {
                  setDeleteEventInfo(null);
                },
              },
            )}
            optionBButtonText="Delete this event only"
            onOptionB={() => deleteTimeBlock.mutate(
              { practiceId: teamId, calendarEventId: deleteEventInfo.calendarEventId, timeBlockId: deleteEventInfo.timeBlockId },
              {
                onSuccess: () => {
                  setDeleteEventInfo(null);
                },
              },
            )}
            handleClose={() => setDeleteEventInfo(null)}
          />
        )}
    </Page>
  );
};
export default StaffAvailabilityPage;
