import CalendarIcon from '@mui/icons-material/CalendarToday';
import { ArrivetRouteObject, RouteConfiguration } from 'modules/interfaces';
import TabRouterOutlet from 'modules/TabRouterOutlet';
import { Navigate } from 'react-router-dom';
import AppointmentLocationPage, { AppointmentTemporaryLocationPanel, AppointmentValidatedLocationListPanel } from './AppointmentLocationPage';
import AppointmentPage from './AppointmentPage';
import CalendarPage from './CalendarPage';
import NewAppointmentPage from './NewAppointmentPage';
import SchedulerPage from './SchedulerPage';

const tabRoutes = {
  Appointment: '/details',
  Location: '/location',
};
const tabRouteId = 'PracticeCalendar';

export const defaultRoute = 'calendar';

const routes: (config: RouteConfiguration) => ArrivetRouteObject[] = () => ([{
  path: 'calendar',
  mainNavigation: {
    title: 'Calendar',
    group: '',
    iconComponent: <CalendarIcon />,
  },
  children: [
    {
      index: true,
      element: <CalendarPage />,
    },
    {
      path: 'new',
      element: <NewAppointmentPage />,
    },
    {
      path: 'scheduler/:vetId/:day/:month/:year',
      element: <SchedulerPage />,
    },
    {
      path: ':appointmentId',
      children: [
        {
          tabRouteId,
          element: <TabRouterOutlet routes={tabRoutes} routeId={tabRouteId} />,
          children: [
            {
              index: true,
              element: <Navigate to="details" />,
            },
            {
              path: 'details',
              element: <AppointmentPage />,
            },
            {
              path: 'location',
              element: <AppointmentLocationPage />,
              children: [
                {
                  index: true,
                  element: <AppointmentValidatedLocationListPanel />,
                },
                {
                  path: 'temporary',
                  element: <AppointmentTemporaryLocationPanel />,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}]);

export default routes;
