/* global google */

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { MarkerIconTypes, MarkerProps } from 'components/GoogleMap/Marker';
import MapDashboardPage from 'features/MapDashboard';
import { ValidatedLocationsState } from 'features/ValidatedLocations/validatedLocation.context';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { useValidatedLocationsQuery } from 'providers/api/useValidatedLocations';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TITLE as sectionTitle } from './constants';

const selectLocation = (
  validatedLocationId: string,
  selectedLocationId: string | undefined,
  highlightedLocationId: string | undefined,
): MarkerIconTypes => {
  if (validatedLocationId === selectedLocationId) return 'location';
  if (validatedLocationId === highlightedLocationId) return 'vetOutdated';
  return 'vet';
};

const ClientLocationsPage = () => {
  const navigate = useNavigate();
  const { teamId: clientId } = useProtectedParams('teamId');
  const { locationId } = useParams();

  const [highlightedLocationId, setHighlightedLocationId] = React.useState<string>();
  const [selectedGeoPoint, setSelectedGeoPoint] = React.useState<google.maps.LatLngLiteral>();
  const [showSelectedGeoPoint, setShowSelectedGeoPoint] = React.useState(false);
  const { data: validatedLocations } = useValidatedLocationsQuery({ teamId: clientId, clientId });

  const validatedLocationMarkerData: MarkerProps[] = React.useMemo(
    () => (validatedLocations
      ? validatedLocations.map((validatedLocation) => (
        {
          key: validatedLocation.entityId,
          position: {
            lat: validatedLocation.geoPoint.latitude,
            lng: validatedLocation.geoPoint.longitude,
          },
          selected: true,
          iconType: selectLocation(validatedLocation.entityId, locationId, highlightedLocationId),
          info: validatedLocation.description,
          onClick: () => navigate(validatedLocation.entityId),
          onMouseOver: () => setHighlightedLocationId(validatedLocation.entityId),
          onMouseOut: () => setHighlightedLocationId(undefined),
        }
      ))
      : []
    ),
    [locationId, validatedLocations, highlightedLocationId],
  );

  const handleLocationSelect = (latLng: google.maps.LatLngLiteral) => setSelectedGeoPoint(latLng);

  const locationPanelActions: [string, JSX.Element][] = [
    ['Close', <IconButton onClick={() => navigate('..')}><CloseIcon /></IconButton>],
  ];

  const locationsPanelActions: [string, JSX.Element][] = [
    ['Add', <IconButton onClick={() => navigate('create')}><AddIcon /></IconButton>],
  ];

  const validatedLocationState: ValidatedLocationsState = {
    highlightedValidatedLocationId: highlightedLocationId,
    validatedLocations: validatedLocations ?? [],
    selectedGeoPoint: selectedGeoPoint
      ? { latitude: selectedGeoPoint.lat, longitude: selectedGeoPoint.lng }
      : undefined,
    setHighlightedValidatedLocationId: setHighlightedLocationId,
    setShowSelectedGeoPoint,
  };

  const tempMarkerData = (latLng: google.maps.LatLngLiteral | undefined, showSelectedLocation: boolean): MarkerProps[] => (
    latLng && showSelectedLocation
      ? [{
        position: latLng,
        selected: true,
        iconType: 'vetOutdated',
      }]
      : []
  );

  const markerData = [...validatedLocationMarkerData, ...tempMarkerData(selectedGeoPoint, showSelectedGeoPoint)];

  return (
    <Page pageType="full" title={sectionTitle}>
      <MapDashboardPage
        markerData={markerData}
        title={locationId ? 'Edit Location' : 'Validated Locations'}
        actions={locationId ? locationPanelActions : locationsPanelActions}
        contextData={validatedLocationState}
        onMapClick={handleLocationSelect}
        search
      />
    </Page>
  );
};

export default ClientLocationsPage;
