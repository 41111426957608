import { ArrivetRouteObject, RouteConfiguration } from 'modules/interfaces';
import PageNotFoundPage from './PageNotFoundPage';
import PracticeDisabledPage from './PracticeDisabledPage';
import VetAccessPage from './VetAccessPage';

export const defaultRoute = '*';

const routes: (config: RouteConfiguration) => Record<string, ArrivetRouteObject> = () => ({
  404: {
    path: '*',
    element: <PageNotFoundPage />,
  },
  disabled: {
    index: true,
    element: <PracticeDisabledPage />,
  },
  vetAccess: {
    index: true,
    element: <VetAccessPage />,
  },
});

export default routes;
