import { useReactOidc } from '@axa-fr/react-oidc-context';
import axios from 'axios';
import React, { ReactNode } from 'react';
import {
  AppointmentsService, ArrivalTimesService, CalendarEventsService, ClientsService,
  IntegrationsService,
  LocationsService,
  MeService,
  PatientsService,
  PracticeInvitationsService,
  PracticesService,
  TeamRolesService,
  TeamsService,
  UserInvitationsService,
  UsersService,
  ValidatedLocationsService
} from './api';
import ApiContext, { IApiContext } from './context';

export interface ApiProviderProps {
  children: ReactNode;
}

const ApiProvider = ({ children }: ApiProviderProps) => {
  const { oidcUser } = useReactOidc();

  const value: IApiContext = React.useMemo(() => {
    const instance = axios.create({
      headers: { Authorization: `Bearer ${oidcUser?.access_token}` },
      transformResponse: (res) => res,
    });

    const patients = new PatientsService(window.location.origin, instance);
    const practiceInvitations = new PracticeInvitationsService(window.location.origin, instance);
    const userInvitations = new UserInvitationsService(window.location.origin, instance);
    const practices = new PracticesService(window.location.origin, instance);
    const teams = new TeamsService(window.location.origin, instance);
    const clients = new ClientsService(window.location.origin, instance);
    const users = new UsersService(window.location.origin, instance);
    const me = new MeService(window.location.origin, instance);
    const appointments = new AppointmentsService(window.location.origin, instance);
    const locations = new LocationsService(window.location.origin, instance);
    const arrivalTimes = new ArrivalTimesService(window.location.origin, instance);
    const teamRoles = new TeamRolesService(window.location.origin, instance);
    const integrations = new IntegrationsService(window.location.origin, instance);
    const calendarEvents = new CalendarEventsService(window.location.origin, instance);
    const validatedLocations = new ValidatedLocationsService(window.location.origin, instance);

    return ({
      patients,
      practiceInvitations,
      userInvitations,
      practices,
      teams,
      clients,
      users,
      me,
      appointments,
      locations,
      arrivalTimes,
      teamRoles,
      integrations,
      calendarEvents,
      validatedLocations,
    });
  }, [oidcUser?.access_token]);

  return (
    <ApiContext.Provider value={value}>
      {children}
    </ApiContext.Provider>
  );
};

export default ApiProvider;
