/* global google */

import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import { createCustomEqual } from 'fast-equals';
import React from 'react';

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    if (
      isLatLngLiteral(a)
      || a instanceof google.maps.LatLng
      || isLatLngLiteral(b)
      || b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  },
);

const useDeepCompareMemoize = (value: any) => {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

const useDeepCompareEffectForMap = (
  callback: React.EffectCallback,
  dependencies: any[],
) => React.useEffect(callback, dependencies.map(useDeepCompareMemoize));

export default useDeepCompareEffectForMap;
