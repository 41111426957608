import {
  ChronoUnit, Duration, Instant, ZonedDateTime, ZoneId
} from '@js-joda/core';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { HOUR_IN_MILLISECONDS, SECOND_IN_MILLISECONDS } from 'config';
import AppointmentCalendar from 'features/AppointmentCalendar';
import { mapAppointmentToEvents, mapTimeBlockToEvents } from 'features/AppointmentCalendar/eventHelpers';
import { EventInputWithType } from 'features/AppointmentCalendar/interfaces';
import useProtectedParams from 'hooks/useProtectedParams';
import { useAppointmentsQuery, useCalendarEventsQuery } from 'providers/api';
import { useTeam } from 'providers/team';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getDurationHoursMinsSeconds } from 'utils';
import { useDashboardState } from './DashboardPage';
import IncrementalDisplay from './IncrementalDisplay';
import useDashboardData from './useDashboardData';
import useVetLocations from './useVetLocations';

const SelectedVetPanel = () => {
  const { state: { activeTeam } } = useTeam();
  const { vetId, teamId } = useProtectedParams('vetId', 'teamId');
  const { dashboardState } = useDashboardState();
  const navigate = useNavigate();
  const viewType = 'timeGridDay';

  const instantNowRef = React.useRef(Instant.now());

  const {
    data: appointments,
  } = useAppointmentsQuery(
    {
      practiceId: activeTeam?.teamId,
      startDateUtc: instantNowRef.current,
      endDateUtc: instantNowRef.current.plus(1, ChronoUnit.DAYS),
      vetId,
    },
  );

  const {
    data: calendarEvents,
  } = useCalendarEventsQuery(
    {
      practiceId: teamId,
      startDate: instantNowRef.current.atZone(ZoneId.systemDefault()),
      endDate: instantNowRef.current.atZone(ZoneId.systemDefault()).plusDays(1),
      userId: vetId,
    },
    {
      staleTime: HOUR_IN_MILLISECONDS,
    },
  );

  const {
    indexes: {
      vetLocationsIndexedById,
      appointmentsIndexedByVetId,
    },
  } = useDashboardData(teamId);

  const realtimeVetLocationsIndexedById = useVetLocations(vetLocationsIndexedById, appointmentsIndexedByVetId);

  const vet = dashboardState ? (dashboardState.scheduledVets)[vetId] : null;
  const handleCloseVetSelect = () => navigate('..');
  const handleAppointmentClick = (appointmentId: string) => {
    navigate(`../../appointment/${appointmentId}`);
  };

  React.useEffect(() => {
    if (dashboardState && dashboardState.markerStates && vet && dashboardState.markerStates[vet.userId] !== true) dashboardState?.setMarkerSelectedState(vet.userId, true);
  }, [vet]);

  if (!vet) return <Alert severity="info">Vet info not found</Alert>;

  const appointmentCalendarEvents = (
    appointments
      && appointments.length > 0
      ? mapAppointmentToEvents(appointments, false)
      : []
  );

  const timeBlockEvents: EventInputWithType[] = calendarEvents
    ? mapTimeBlockToEvents(calendarEvents, true, true, viewType)
    : [];

  return (
    <>
      <Stack direction="row">
        <IconButton onClick={() => handleCloseVetSelect()}>
          <CloseIcon />
        </IconButton>
        <Box>
          <Typography variant="h5" sx={{ flexGrow: 1, textAlign: 'center' }}>
            {`${vet.forename} ${vet.surname}`}
          </Typography>
          {
            realtimeVetLocationsIndexedById[vetId]
            && (
              <>
                <Typography variant="caption">
                  Location last confirmed:
                </Typography>
                <br />
                <Typography variant="caption">
                  <IncrementalDisplay
                    callback={() => (Duration.between(realtimeVetLocationsIndexedById[vetId].dateModified, ZonedDateTime.now())
                      .toHours() < 48
                      ? getDurationHoursMinsSeconds(realtimeVetLocationsIndexedById[vetId].dateModified)
                      : 'No recent update')}
                    delay={SECOND_IN_MILLISECONDS}
                  />
                </Typography>
              </>
            )
          }
        </Box>
      </Stack>
      <AppointmentCalendar
        appointmentCalendarEvents={appointmentCalendarEvents}
        timeBlockEvents={timeBlockEvents}
        eventClick={(targetAppointment) => handleAppointmentClick(targetAppointment)}
        dateClick={() => { }}
        viewType={viewType}
        controls={false}
      />
    </>
  );
};

export default SelectedVetPanel;
