import ClientAppointmentForm from 'features/ClientAppointmentForm';
import useProtectedParams from 'hooks/useProtectedParams';
import {
  AppointmentDetailsDto, AppointmentForm,
  ClientDto,
  EntitySummaryDto, useUpdateAppointmentAddAttendeeMutation,
  useUpdateAppointmentDatetimeMutation,
  useUpdateAppointmentDetailsMutation,
  useUpdateAppointmentPrimaryContactMutation,
  useUpdateAppointmentRemoveAttendeeMutation
} from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import { useTeam } from 'providers/team';
import AppointmentPrimaryContactEditableSection from './AppointmenmtPrimaryContactEditableSection';
import AppointmentAttendeesEditableSection from './AppointmentAttendeesEditableSection';
import AppointmentClientAndPetSection from './AppointmentClientAndPetSection';
import AppointmentDateTimeEditableSection from './AppointmentDateTimeEditableSection';
import AppointmentDetailsEditableSection from './AppointmentDetailsEditableSection';
import AppointmentVeterinaryNotesSection from './AppointmentVeterinaryNotesSection';

interface AppointmentInfoSectionsProps {
  appointment: AppointmentDetailsDto;
  client: ClientDto;
  vetNotes?: string;
}

const AppointmentInfoSections = ({ appointment, client, vetNotes }: AppointmentInfoSectionsProps) => {
  const { teamId, appointmentId } = useProtectedParams('teamId', 'appointmentId');
  const { state: { activeTeam } } = useTeam();
  // TODO: consider write permissions when user authorisation is implemented
  const isPractice = activeTeam?.teamType === 'Practice';
  const confirm = useConfirmation();

  const updateAppointmentDatetimeMutation = useUpdateAppointmentDatetimeMutation(appointmentId);
  const handleUpdateAppointmentDatetimeSuccess = (
    newValues: AppointmentForm,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => updateAppointmentDatetimeMutation.mutate(
    {
      startTime: newValues.startTime,
      endTime: newValues.endTime,
    },
    { onSettled: () => setSubmitting(false) },
  );

  const updateAppointmentPrimaryContactMutation = useUpdateAppointmentPrimaryContactMutation(appointmentId);
  const handleUpdateAppointmentPrimaryContactSuccess = (
    newValues: AppointmentForm,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => newValues.primaryContactId && updateAppointmentPrimaryContactMutation.mutate(
    newValues.primaryContactId,
    { onSettled: () => setSubmitting(false) },
  );

  const updateAppointmentDetailsMutation = useUpdateAppointmentDetailsMutation(appointmentId);
  const handleUpdateAppointmentDetailsSuccess = (
    newValues: AppointmentForm,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => updateAppointmentDetailsMutation.mutate(
    {
      practiceId: teamId,
      form: newValues,
    },
    { onSettled: () => setSubmitting(false) },
  );

  const updateAppointmentRemoveAttendeeMutation = useUpdateAppointmentRemoveAttendeeMutation(appointmentId);
  const handleRemoveAttendee = (attendee: EntitySummaryDto) => {
    confirm({
      variant: 'danger',
      description: `Are you sure you want to remove ${attendee.name} from the attendees list?`,
    }).then(() => updateAppointmentRemoveAttendeeMutation.mutate(attendee.entityId));
  };

  const updateAppointmentAddAttendeeMutation = useUpdateAppointmentAddAttendeeMutation(appointmentId);
  const handleAddAttendee = (attendeeId: string) => updateAppointmentAddAttendeeMutation.mutate(attendeeId);

  return (
    <>
      <ClientAppointmentForm mutation={updateAppointmentDatetimeMutation} appointment={appointment} onValidationSuccess={handleUpdateAppointmentDatetimeSuccess}>
        <AppointmentDateTimeEditableSection appointment={appointment} editable={isPractice} />
      </ClientAppointmentForm>

      <AppointmentClientAndPetSection appointment={appointment} client={client} />

      <ClientAppointmentForm mutation={updateAppointmentPrimaryContactMutation} appointment={appointment} onValidationSuccess={handleUpdateAppointmentPrimaryContactSuccess}>
        <AppointmentPrimaryContactEditableSection appointment={appointment} editable />
      </ClientAppointmentForm>

      <AppointmentAttendeesEditableSection appointment={appointment} onRemoveAttendee={handleRemoveAttendee} onAddAttendee={handleAddAttendee} />

      <ClientAppointmentForm mutation={updateAppointmentDetailsMutation} appointment={appointment} onValidationSuccess={handleUpdateAppointmentDetailsSuccess}>
        <AppointmentDetailsEditableSection appointment={appointment} editable={isPractice} />
      </ClientAppointmentForm>

      {isPractice && <AppointmentVeterinaryNotesSection vetNotes={vetNotes} />}
    </>
  );
};

export default AppointmentInfoSections;
