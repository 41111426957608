import {
  BAD_REQUEST_CODE,
  EXPECTED_ERROR_CODES,
  FORBIDDEN_ERROR_CODE,
  SECOND_IN_MILLISECONDS,
  TEMPORARY_ACCESS_TOKEN_EXPIRED_CODE
} from 'config';
import { useSnackbar } from 'notistack';
import React from 'react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ApiException } from './api';
import ErrorSnackMessage from './ErrorSnackMessage';

const GENERIC_ERROR_TITLE = 'Unexpected Error';
const GENERIC_ERROR_DETAILS = 'The application has encountered an unexpected error. Contact the administrator for further support.';
const TEMPORARY_ACCESS_TOKEN_EXPIRED_TITLE = 'Link Expired';
const TEMPORARY_ACCESS_TOKEN_EXPIRED_DETAILS = 'Please contact the practice for any questions you have relating to this appointment.';
// Error string format '[2].volume:[ "Input string '6500.04' is not a valid integer. Path '[2].volume', line 1, position 350." ]'
const processErrors = (errors: { [id: string]: string[] }) => Object.values(errors)
  .map((messages) => messages.map((errorMsg) => errorMsg.split('Path')[0]).join(', '))
  .slice(0, 10);

// eslint-disable-next-line import/prefer-default-export
export const withQueryClientDefaults = (WrappedComponent: React.ComponentType) => () => {
  const { enqueueSnackbar } = useSnackbar();

  const triggerErrorFeedback = (error: any) => {
    let errorTitle = GENERIC_ERROR_TITLE;
    let errorDetails = [GENERIC_ERROR_DETAILS];

    const isExpectedError = error instanceof ApiException && EXPECTED_ERROR_CODES.includes(error.status);

    if (isExpectedError && error.result) {
      if (error.result.title) errorTitle = error.result.title;
      switch (error.status) {
        case BAD_REQUEST_CODE:
          if (error.result?.errors) errorDetails = processErrors(error.result.errors.results);
          break;
        // INFO: The server returns error info in header. Could be improved server side before processing here.
        case FORBIDDEN_ERROR_CODE:
          errorTitle = 'Insufficient Access';
          errorDetails = ['You do not have permissions to perform the requested action.'];
          break;
        default:
          if (error.result && error.result.detail) errorDetails = [error.result.detail];
      }
    }

    if (error.status === TEMPORARY_ACCESS_TOKEN_EXPIRED_CODE) {
      errorTitle = TEMPORARY_ACCESS_TOKEN_EXPIRED_TITLE;
      errorDetails = [TEMPORARY_ACCESS_TOKEN_EXPIRED_DETAILS];
    }

    enqueueSnackbar(errorDetails, {
      variant: 'error',
      persist: !isExpectedError,
      autoHideDuration: SECOND_IN_MILLISECONDS * 30,
      preventDuplicate: true,
      key: `CODE:${error.status}`,
      // eslint-disable-next-line react/no-unstable-nested-components
      content: (key) => (
        <ErrorSnackMessage key={key} details={errorDetails} title={errorTitle} id={key} />
      ),
    });
  };

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: triggerErrorFeedback,
    }),
    mutationCache: new MutationCache({
      onError: triggerErrorFeedback,
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <WrappedComponent />
    </QueryClientProvider>
  );
};

// This was used to set query client defaults that have since been refactored.
// This might still be useful to decided to keep
