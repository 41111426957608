import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import { AppointmentEtaStatus } from 'providers/api';
import { splitCaps } from 'utils';

interface VetStatusChipProps {
  vetStatus?: AppointmentEtaStatus;
}

const VetStatusChip = ({ vetStatus }: VetStatusChipProps) => {
  const theme = useTheme();
  const STATUS_COLOR_MAP: Record<AppointmentEtaStatus, string> = {
   [AppointmentEtaStatus.OnTime]: theme.palette.primary.main,
   [AppointmentEtaStatus.AheadOfTime]: theme.palette.grey[500],
   [AppointmentEtaStatus.BehindTime]: theme.palette.error.dark,
  };

  return (
    <Chip
      size="small"
      label={vetStatus !== undefined ? splitCaps(AppointmentEtaStatus[vetStatus]) : 'No active ETA'}
      sx={{
        backgroundColor: vetStatus !== undefined ? STATUS_COLOR_MAP[vetStatus] : theme.palette.grey[800],
        color: theme.palette.sideMenu.contrastText,
      }}
    />
  );
};

export default VetStatusChip;
