import { useLocalStorage } from '@rehooks/local-storage';
import Loading from 'components/Loading';
import { HOUR_IN_MILLISECONDS, LOCAL_STORAGE_PREFIX } from 'config';
import { TeamDto, useMyTeamsQuery } from 'providers/api';
import { useAuthorisation } from 'providers/authorisation';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import TeamContext, { ITeamContext } from './context';

export type TeamType = 'Practice' | 'Client';

export interface TeamProviderProps {
  children: ReactNode;
}

const TeamProvider = ({ children }: TeamProviderProps) => {
  const [activeTeamId, setActiveTeamId] = useLocalStorage<string | null>(`${LOCAL_STORAGE_PREFIX}-ACTIVE_TEAM`, null);
  const [teams, setTeams] = React.useState<TeamDto[]>([]);
  const authorisation = useAuthorisation();
  const navigate = useNavigate();

  const hasTeams = (teams && teams.length > 0);

  // load teams. If no active team use the first in the list
  useMyTeamsQuery(null, {
    staleTime: HOUR_IN_MILLISECONDS,
    onSuccess: (loadedTeams: TeamDto[]) => {
      setTeams(loadedTeams);
      if (loadedTeams.length > 0 && !activeTeamId) {
        setActiveTeamId(loadedTeams[0].teamId);
      }
      // If the user is not assigned to the team stored in local storage then overwrite the local storage with a new default team
      if (loadedTeams.length > 0 && activeTeamId && !teams.some((team) => team.teamId === activeTeamId)) setActiveTeamId(loadedTeams[0].teamId);

      const urlTeamId = window.location.pathname.split('/')[2];

      const urlTeamExists = loadedTeams.some((loadedTeam) => loadedTeam.teamId === urlTeamId);

      if (urlTeamExists) {
        setActiveTeamId(urlTeamId);
      } else {
        navigate('/');
      }
    },
  });

  const activeTeam = React.useMemo(() => {
    if (hasTeams && activeTeamId) {
      const summary = teams.find((team: TeamDto) => team.teamId === activeTeamId);
      if (!summary) throw new Error('Unable to locate current team');
      return summary;
    }
    return null;
  }, [teams, activeTeamId]);

  const setActiveTeam = (teamId: string) => {
    if (hasTeams) {
      const requestedTeamIdExists = teams.some((team) => team.teamId === teamId);
      requestedTeamIdExists ? setActiveTeamId(teamId) : setActiveTeamId(null);
    }
  };

  const clearTeamsStoredInLocalStorage = () => setActiveTeamId(null);

  const value = React.useMemo(() => ({
    state: { activeTeam, teams: teams ?? [] },
    actions: { setActiveTeam, clearTeamsStoredInLocalStorage },
  }), [activeTeam]);

  // Non admins must have a team loaded
  if (!activeTeam && !authorisation.functions.isAdmin()) {
    return <Loading />;
  }

  return (
    <TeamContext.Provider value={value as ITeamContext}>
      {children}
    </TeamContext.Provider>
  );
};

export default TeamProvider;
