import HandymanIcon from '@mui/icons-material/Handyman';
import { ArrivetRouteObject, RouteConfiguration } from 'modules/interfaces';
import AddToWhitelistPage from './AddToWhitelistPage';

export const defaultRoute = 'addtowhitelist';

const routes: (config: RouteConfiguration) => ArrivetRouteObject[] = () => [{
  path: 'tools',
  mainNavigation: {
    title: 'Tools',
    group: 'Admin',
    iconComponent: <HandymanIcon />,
  },
  children: [
    {
      index: true,
      element: <AddToWhitelistPage />,
    },
  ],
}];

export default routes;
