import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormikActions, FormStack } from 'components/Form';
import TitleBox from 'components/TitleBox';
import {
  InviteUserToTeamsCommand,
  RoleDto,
  TeamRolesAssignmentForm
} from 'providers/api';
import useDefaultForm from 'providers/form/useDefaultForm';
import { UseMutationResult } from 'react-query';
import {
  array,
  object,
  SchemaOf,
  string
} from 'yup';
import UserPermissionsField from '../UserPermissionsField';

const teamRolesAssignmentFormSchema: SchemaOf<TeamRolesAssignmentForm> = object().shape({
  teamId: string().required(),
  roles: array().of(string().required()).required(),
});

const userInviteSchema: SchemaOf<InviteUserToTeamsCommand> = object().shape({
  email: string().email('Email is invalid').required('Email is required'),
  teamRoles: array().of(teamRolesAssignmentFormSchema).required('Required'),
});

interface UserFormProps {
  roles: RoleDto[];
  teamId: string;
  mutation: UseMutationResult<void, unknown, InviteUserToTeamsCommand, unknown>;
}
const UserForm = ({ roles, teamId, mutation }: UserFormProps) => {
  const {
    formik,
    helpers,
  } = useDefaultForm<InviteUserToTeamsCommand>({
    mutation,
    formikConfig: {
      initialValues: {
        email: '',
        teamRoles: [],
      },
      onSubmit: (form, { setSubmitting }) => {
        setSubmitting(true);
        mutation.mutate(form, {
          onSettled: () => {
            setSubmitting(false);
          },
        });
      },
      validationSchema: userInviteSchema,
    },
  });

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  const handlePermissionSelect = (role: string) => {
    const teamExistsInTeamRoles = values.teamRoles.some((permission) => permission.teamId === teamId);

    // add a new team
    if (!teamExistsInTeamRoles) {
      setFieldValue('teamRoles', [...values.teamRoles, { teamId, roles: [role] }]);
      return;
    }

    // update an existing team
    const newTeamRoles = values.teamRoles.map(
      (existingTeamRoles) => (existingTeamRoles.teamId === teamId
        ? { teamId: existingTeamRoles.teamId, roles: [...existingTeamRoles.roles, role] }
        : existingTeamRoles),
    );
    setFieldValue('teamRoles', newTeamRoles);
  };

  const handlePermissionDeselect = (role: string) => {
    const newRoles = values.teamRoles.map((teamRole) => (
      teamRole.teamId === teamId
        ? { teamId: teamRole.teamId, roles: teamRole.roles.filter((existingRole) => existingRole !== role) }
        : teamRole
    ));
    setFieldValue('teamRoles', newRoles);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TitleBox title="Invite Details">
        <FormStack>
          <TextField
            required
            autoFocus
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('email')}
            helperText={helpers.getErrorHelpText('email')}
          />

          <UserPermissionsField
            roles={roles}
            selectedRoles={values.teamRoles.find((teamRole) => teamRole.teamId === teamId)?.roles ?? []}
            disabled={false}
            onSelect={handlePermissionSelect}
            onDeselect={handlePermissionDeselect}
          />
        </FormStack>
      </TitleBox>

      <Box mt={2}>
        <FormikActions
          formik={formik}
          mutation={mutation}
          submitText="Invite"
          right={['reset', 'submit']}
        />
      </Box>
    </form>
  );
};

export default UserForm;
