import {
  groupBy,
  isEmpty,
  prop,
  symmetricDifference
} from 'ramda';

export const groupByProp = <T extends {}>(propName: string) => groupBy<T>(prop(propName));

export const lastIndex = (arr: any[]) => arr.length - 1;

export const equalValues = (listA: string[] | number[], listB: string[] | number[]) => isEmpty(symmetricDifference<string | number>(listA, listB));
