import { ArrivetRouteObject } from 'modules/interfaces';
import { useContext } from 'react';
import AppRoutesContext from './appRoutes.context';

export default function useAppRoutes(): ArrivetRouteObject {
  const context = useContext(AppRoutesContext);
  if (context === null) {
    throw new Error('No AppRoutes context available');
  }
  return context;
}
