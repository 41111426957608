import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Loading from 'components/Loading';
import { useInfinitePatientsQuery } from 'providers/api';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import PatientsList from './PatientsList';

interface PatientsFeatureProps {
  clientId: string;
  onPatientSelect: (patientId: string) => void;
}

const PatientsFeature = ({ clientId, onPatientSelect }: PatientsFeatureProps) => {
  const {
    status,
    data: patients,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfinitePatientsQuery({ clientId });

  const [sentryRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage: !!hasNextPage,
    onLoadMore: fetchNextPage,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 100px 0px',
  });

  if (isLoading || !patients) return <Loading />;

  return (
    <>

      <PatientsList
        patients={patients.pages.flatMap((p) => p.items) ?? []}
        onNavigateToPatient={onPatientSelect}
      />
      <div ref={sentryRef}>
        {!!hasNextPage && status === 'success' && (
          <Box mt={6} display="flex" justifyContent="center">
            <LoadingButton variant="contained" color="primary" onClick={() => fetchNextPage()} loading={isFetchingNextPage}>
              Load More
            </LoadingButton>
          </Box>
        )}
      </div>
    </>
  );
};

export default PatientsFeature;
