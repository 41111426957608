import { useLocation, useNavigate } from 'react-router-dom';

type NewTimeBlockInputs = {
  clientId?: string;
  patientId?: string;
  date?: Date;
  vetId?: string;
}

const useNewTimeBlockNavigate = () => {
  const navigate = useNavigate();
  return (path: string, inputs: NewTimeBlockInputs) => navigate(path, {
    state: {
      clientId: inputs.clientId, patientId: inputs.patientId, date: inputs.date, vetId: inputs.vetId,
    },
  });
};

const useNewTimeBlockLocationState = (): NewTimeBlockInputs => {
  const { state } = useLocation();
  const { patientId, clientId, date, vetId } = state as NewTimeBlockInputs ?? { patientId: undefined, clientId: undefined, date: undefined, vetId: undefined };
  return { patientId, clientId, date, vetId };
};

export {
  useNewTimeBlockLocationState,
  useNewTimeBlockNavigate,
};
