import Loading from 'components/Loading';
import { FormikConfig } from 'formik';
import { UpdatePatientForm, useEditPatientMutation, usePatientQuery } from 'providers/api';
import PatientForm from './PatientForm';

interface EditPatientFeatureProps {
  patientId: string;
  onSuccess: () => void;
}

const EditPatientFeature = ({ patientId, onSuccess }: EditPatientFeatureProps) => {
  const { data: patient } = usePatientQuery(patientId);
  const editPatientMutation = useEditPatientMutation(patientId, {
    onSuccess: () => {
      onSuccess();
    },
  });

  if (!patient) {
    return <Loading />;
  }

  const onSubmit: FormikConfig<UpdatePatientForm>['onSubmit'] = (patientForm, { setSubmitting }) => {
    editPatientMutation.mutate(patientForm, {
      onSettled: () => {
        setSubmitting(false);
      },
    });
  };

  return (
    <PatientForm onSubmit={onSubmit} mutation={editPatientMutation} patient={patient} />
  );
};

export default EditPatientFeature;
