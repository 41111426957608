import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { useTeam } from 'providers/team';
import React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => (
  <Page pageType="standard" title="Error">
    <Alert severity="error">
      <Typography variant="subtitle2">Something went wrong:</Typography>
      <pre>{error.message}</pre>
      <Button onClick={resetErrorBoundary}>Retry</Button>
    </Alert>
  </Page>
);

const TeamRouterOutlet = () => {
  const { actions: { setActiveTeam }, state: { activeTeam } } = useTeam();
  const { teamId } = useProtectedParams('teamId');

  React.useEffect(() => {
    if (activeTeam?.teamId !== teamId) setActiveTeam(teamId);
  }, [teamId]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
    >
      <Outlet />
    </ErrorBoundary>
  );
};

export default TeamRouterOutlet;
