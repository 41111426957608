import SwitchIcon from '@mui/icons-material/WifiProtectedSetup';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { TeamDto } from 'providers/api';
import React from 'react';
import { initials, px } from 'utils';

interface TeamSelectionListItemProps {
  activeTeam: TeamDto;
  availableTeams: TeamDto[];
  onTeamSelect: (team: TeamDto) => void;
}

const TeamSelectionListItem = ({ activeTeam, availableTeams, onTeamSelect }: TeamSelectionListItemProps) => {
  const [expanded, setExpanded] = React.useState(false);

  const toggleExpanded = () => {
    setExpanded((oldExpanded) => !oldExpanded);
  };

  const expandable = availableTeams.length > 1;

  const handleTeamSelect = (team: TeamDto) => {
    onTeamSelect(team);
    setExpanded(false);
  };

  return (
    <Accordion
      square
      disableGutters
      sx={{
        backgroundColor: 'sideMenu.main',
        color: 'background.default',
      }}
      expanded={expandable ? expanded : false}
      onChange={toggleExpanded}
    >
      <AccordionSummary
        expandIcon={expandable && <SwitchIcon sx={{ color: 'background.default' }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ justifyContent: 'start', overflow: 'hidden' }}
      >
        <Box display="flex">
          <Avatar sx={{ ml: px(-4), mr: 2, flexShrink: 0 }}>{initials(activeTeam.name)}</Avatar>
          <Typography sx={{ alignSelf: 'center' }} variant="button">{activeTeam.name}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingX: 0 }}>
        <List>
          {
            availableTeams.map((team) => (
              <ListItem
                key={team.teamId}
                button
                sx={{ pl: 1.5, color: 'background.default' }}
                disabled={team.teamId === activeTeam.teamId}
                onClick={() => handleTeamSelect(team)}
              >
                <ListItemAvatar sx={{ mr: 2 }}>
                  <Avatar src={team.image?.path}>{initials(team.name)}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={team.name} />
              </ListItem>
            ))
          }
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default TeamSelectionListItem;
