import TextField from '@mui/material/TextField';
import { useAccountForm } from './context';

const AccountEmailField = () => {
  const {
    form: { formik, helpers },
  } = useAccountForm();

  const { values, handleChange, handleBlur } = formik;

  return (
    <TextField
      required
      autoFocus
      fullWidth
      id="email"
      name="email"
      label="Email"
      value={values.email}
      onChange={handleChange}
      onBlur={handleBlur}
      error={helpers.hasError('email')}
      helperText={helpers.getErrorHelpText('email')}
    />
  );
};

export default AccountEmailField;
