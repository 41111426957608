import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EditableSection from 'components/EditableSection';
import TitleDisplayStack from 'components/TitleDisplayStack';
import {
  AppointmentPriorityField,
  AppointmentSummaryField,
  AppointmentTypeField, useAppointmentForm,
  VetField
} from 'features/ClientAppointmentForm';
import {
  AppointmentDetailsDto, AppointmentPriority,
  AppointmentType
} from 'providers/api';
import { splitCaps } from 'utils';
import { getUserSummary } from 'utils/entities/user';

interface AppointmentDetailsEditableSectionProps {
  appointment: AppointmentDetailsDto;
  editable?: boolean;
}

const AppointmentDetailsEditableSection = ({ appointment, editable = false }: AppointmentDetailsEditableSectionProps) => {
  const { form: { formik }, mutation } = useAppointmentForm();
  return (
    <EditableSection title="Appointment Info" mutation={mutation} formik={formik} enabled={editable}>
      {(editMode) => (
        <Box>
          <TitleDisplayStack
            title="Vet"
            display={
              editMode
                ? <VetField />
                : <Typography variant="body2">{ appointment.vetUserDetails ? getUserSummary(appointment.vetUserDetails) : '-'}</Typography>
            }
          />
          <Divider sx={{ my: 3 }} />
          <TitleDisplayStack
            title="Appointment Details"
            display={
              editMode
                ? (
                  <Stack spacing={2}>
                    <AppointmentTypeField />
                    <AppointmentPriorityField />
                  </Stack>
                )
                : (
                  <Typography variant="body2">
                    {appointment && `${splitCaps(AppointmentType[appointment.appointmentType])}, ${splitCaps(AppointmentPriority[appointment.appointmentPriority])}`}
                  </Typography>
                )
            }
          />
          <Divider sx={{ my: 3 }} />
          <TitleDisplayStack
            title="Summary"
            display={
              editMode
                ? <AppointmentSummaryField />
                : <Typography variant="body2">{appointment.appointmentSummary}</Typography>
            }
          />
        </Box>
      )}
    </EditableSection>
  );
};

export default AppointmentDetailsEditableSection;
