import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import { CALENDAR_EVENT_STATUS_KEY } from 'features/AppointmentCalendar';
import { AppointmentStatus } from 'providers/api';
import { splitCaps } from 'utils';

interface AppointmentStatusChipProps {
  appointmentStatus: AppointmentStatus;
}

const AppointmentStatusChip = ({ appointmentStatus }: AppointmentStatusChipProps) => {
  const theme = useTheme();

  return (
    <Chip
      size="small"
      label={splitCaps(AppointmentStatus[appointmentStatus])}
      sx={{
        backgroundColor: CALENDAR_EVENT_STATUS_KEY[appointmentStatus],
        color: theme.palette.sideMenu.contrastText,
      }}
    />
  );
};

export default AppointmentStatusChip;
