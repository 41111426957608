import { UsersFeature } from 'features/user';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { TITLE as pageTitle } from './constants';

const UsersPage = () => {
  const { teamId } = useProtectedParams('teamId');

  return (
    <Page title={pageTitle} pageType="large">
      <UsersFeature teamId={teamId} />
    </Page>
  );
};

export default UsersPage;
