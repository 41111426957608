import { createContext } from 'react';

export interface ArrivetUser {
  id: string,
  username: string,
  name: string,
  email: string,
  roles: Record<string, any>,
}

export interface TempName {
  forename: string,
  surname: string
}

export interface IAuthorisationContext {
  state: {
    user: ArrivetUser;
    loading: boolean;
    userTempName?: TempName;
  },
  functions: {
    isAdmin: () => boolean;
    setTempName: (tempName: TempName) => void
  }
  actions: {
    userHasPermissions: (teamId: string, rolesToCheck: string[]) => boolean;
  }
}

const AuthorisationContextContext = createContext<IAuthorisationContext | null>(null);

export default AuthorisationContextContext;
