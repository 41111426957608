import useModuleRoutes from 'modules/routes';
import { AppRoutesProvider } from 'providers/appRoutes';
import { useRoutes } from 'react-router-dom';

const App = () => {
  const appRoutes = useModuleRoutes();
  const routedElement = useRoutes([appRoutes]);

  return <AppRoutesProvider routes={appRoutes}>{routedElement}</AppRoutesProvider>;
};

export default App;
