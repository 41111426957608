import {
  convert,
  Duration,
  Instant,
  LocalDate,
  LocalDateTime,
  ZonedDateTime
} from '@js-joda/core';
import '@js-joda/timezone';
import { isNil, path } from 'ramda';

export { makeInstant } from './instant';
export { makeDate } from './js-date';
export { makeLocalDate } from './local-date';
export { makeLocalDateTime } from './local-date-time';
export { makeZonedDateTime } from './zoned-date-time';

export const isTemporal = (testTemporal: any) => testTemporal instanceof ZonedDateTime
  || testTemporal instanceof LocalDateTime
  || testTemporal instanceof LocalDate
  || testTemporal instanceof Date;

export const DEFAULT_TIME_CONFIG: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
};

export const DEFAULT_SHORT_DATE_CONFIG: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

export const DEFAULT_MONTHVIEW_CALENDAR_CONFIG: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
};

export const DEFAULT_DAYMONTH_DATE_CONFIG: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
};

export const DEFAULT_LONG_DATE_TIME_CONFIG: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
};

export const DEFAULT_SHORT_DATE_TIME_CONFIG: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
};
export const DEFAULT_TIMEZONE_CONFIG: Intl.DateTimeFormatOptions = {
  timeZone: 'short',
};

export const displayTemporal = (temporal: ZonedDateTime | LocalDate | LocalDateTime | Date, options?: Intl.DateTimeFormatOptions) => {
  const jsDate = temporal instanceof Date ? temporal : convert(temporal).toDate();

  return jsDate.toLocaleString('en-GB', options);
};

interface DateSortOptions {
  dateProp?: string | string[];
  asc: boolean;
}

export const dateSort = ({ dateProp, asc = true }: DateSortOptions) => {
  let propPath: string[] | undefined;

  if (dateProp) {
    propPath = Array.isArray(dateProp) ? dateProp : [dateProp];
  }

  return (a: any, b: any) => {
    const aDate = propPath ? path(propPath, a) : a as ZonedDateTime | LocalDate | LocalDateTime | Date;
    const bDate = propPath ? path(propPath, b) : b as ZonedDateTime | LocalDate | LocalDateTime | Date;

    if (typeof aDate !== typeof bDate) {
      throw new Error('You cannot sort difference date types');
    }

    if (isNil(aDate) || isNil(bDate)) {
      return 0;
    }

    const left = asc ? aDate : bDate;
    const right = asc ? bDate : aDate;

    if (left instanceof ZonedDateTime && right instanceof ZonedDateTime) {
      return left.compareTo(right);
    }

    if (left instanceof LocalDate && right instanceof LocalDate) {
      return left.compareTo(right);
    }

    if (left instanceof LocalDateTime && right instanceof LocalDateTime) {
      return left.compareTo(right);
    }

    if (left instanceof Instant && right instanceof Instant) {
      return left.compareTo(right);
    }

    if (left instanceof Date && right instanceof Date) {
      return left.getTime() - right.getTime();
    }

    throw new Error('Unsupported date type');
  };
};

export const getDurationHoursMinsSeconds = (lastDateTime: ZonedDateTime): string => {
  const duration = Duration.between(lastDateTime, ZonedDateTime.now());
  return `${duration.toHours()} hours, ${duration.toMinutes() % 60} mins, and ${duration.seconds() % 60} seconds.`;
};

export const getDuration = (lastDateTime: ZonedDateTime): number => Duration.between(lastDateTime, ZonedDateTime.now()).seconds();

// Returns false if it isn't a number, verifying that it's a date
// NaN is never equal to NaN
// eslint-disable-next-line no-self-compare
export const isValidDate = (testDate: Date | null) => {
  // eslint-disable-next-line no-constant-condition
  if (testDate === null) return false;
  // eslint-disable-next-line no-self-compare
  return testDate.getTime() === testDate.getTime();
};

export const updateTime = (
  baseDate: Date | null,
  dateWithTimeUpdate: Date | null,
) => {
  if (!baseDate && !dateWithTimeUpdate) return null;
  if (!baseDate) return dateWithTimeUpdate;
  if (!dateWithTimeUpdate) return baseDate;

  const newDate = new Date(baseDate);
  newDate.setHours(dateWithTimeUpdate.getHours() ?? 0);
  newDate.setMinutes(dateWithTimeUpdate.getMinutes() ?? 0);
  return newDate;
};

export const getSelectedDay = (dateInput: Date | null) => {
  if (!dateInput || !isValidDate(dateInput)) {
    return null;
  }

  return dateInput.getDay() === 0
    ? 7
    : dateInput.getDay();
};

export const startAndEndOfWeek = (dateToCompare: Date | null) => {
  if (!dateToCompare || !isValidDate(dateToCompare)) {
    return null;
  }

  const monday = new Date(dateToCompare);
  const getCalendarDay = dateToCompare.getDay();
  const ISODay = getCalendarDay === 0
    ? 7
    : getCalendarDay;
  monday.setDate(monday.getDate() - ISODay + 1);

  const sunday = new Date(dateToCompare);
  sunday.setDate(sunday.getDate() - ISODay + 7);
  return { monday, sunday };
};
