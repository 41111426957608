import GroupIcon from '@mui/icons-material/Group';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { RoleDto } from 'providers/api';
import React from 'react';
import {
  PracticeAdminIcon,
  ReceptionistIcon,
  VetIcon
} from './svgs';

const permissionIcons: { [name: string]: JSX.Element, } = {};
permissionIcons.Vet = <VetIcon />;
permissionIcons['Practice Administrator'] = <PracticeAdminIcon />;
permissionIcons.Receptionist = <ReceptionistIcon />;

interface UserPermissionsProps {
  roles: RoleDto[];
  selectedRoles: string[];
  disabled?: boolean;
  onSelect: (roleName: string) => void;
  onDeselect: (roleName: string) => void;
}

const UserPermissionsField = ({ disabled, roles, selectedRoles, onSelect, onDeselect }: UserPermissionsProps) => {
  const handleRoleChange = (role: string, checked: boolean) => {
    if (checked) {
      onSelect(role);
    } else {
      onDeselect(role);
    }
  };

  const handleCheckboxChange = (role: string) => (event: any, checked: boolean) => {
    handleRoleChange(role, checked);
  };

  const handleToggleRole = (role: string) => () => {
    const currentIndex = selectedRoles.indexOf(role);
    handleRoleChange(role, currentIndex === -1);
  };

  return (
    <List>
      {roles.map((role) => (
        <ListItem
          key={role.name}
          button
          disableRipple={disabled}
          disableTouchRipple={disabled}
          onClick={handleToggleRole(role.name)}
        >
          <ListItemAvatar>
            {role.name in permissionIcons ? permissionIcons[role.name] : <GroupIcon />}
          </ListItemAvatar>
          <Box mr={5}>
            <ListItemText id={role.name} primary={role.name} />
          </Box>
          <ListItemSecondaryAction>
            <Checkbox
              disabled={disabled}
              checked={selectedRoles.includes(role.name)}
              color="primary"
              onChange={handleCheckboxChange(role.name)}
            />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default UserPermissionsField;
