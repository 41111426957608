import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SearchField from 'components/SearchField';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'config';
import { UserDto, useUpdateUserTeamsRolesMutation, useUsersQuery } from 'providers/api';
import { useAuthorisation } from 'providers/authorisation';
import { useConfirmation } from 'providers/confirm';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import UsersTable from './UsersTable';

interface UsersFeatureProps {
  teamId: string;
}

const UsersFeature = ({ teamId }: UsersFeatureProps) => {
  const { user: currentUser } = useAuthorisation().state;

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGINATION_DEFAULT_PAGE_SIZE);
  const [searchTerm, setSearchTerm] = React.useState<string | null>(null);
  const [userToDelete, setUserToDelete] = React.useState<UserDto>();
  const navigate = useNavigate();
  const confirm = useConfirmation();

  const {
    data,
    isFetching,
    isFetched,
    isPreviousData,
  } = useUsersQuery({ page, pageSize, teamId, searchTerm });

  const updatePermissionsMutation = useUpdateUserTeamsRolesMutation(userToDelete?.userId ?? '');

  const handlePageChange = (newPage: number) => setPage(newPage + 1);

  const handlePageSizeChange = (newPageSize: number) => {
    setPage(1);
    setPageSize(newPageSize);
  };

  const handleSearch = (text: string | null) => {
    setPage(1);
    setSearchTerm(text);
  };

  const handleEdit = (userId: string) => navigate(`edit/${userId}`);

  const handleRemoveFromPractice = (user: UserDto) => {
    confirm({
      variant: 'danger',
      description: `Are you sure you want to remove ${user.forename} ${user.surname} from the practice?`,
    }).then(() => {
      setUserToDelete(user);
    });
  };

  React.useEffect(() => {
    if (userToDelete) {
      updatePermissionsMutation.mutate({ teamRoles: [{ teamId, roles: [] }] }, {
        onSuccess: () => setUserToDelete(undefined),
      });
    }
  }, [userToDelete]);

  return (
    <>
      <Box>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Filters</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SearchField label="Search by Email" onSearch={handleSearch} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Paper sx={{ mt: 3 }}>
        <UsersTable
          data={data?.items}
          currentUser={currentUser}
          page={page}
          pageSize={pageSize}
          totalItems={data?.pager.totalItems}
          loading={isFetching}
          loadingNew={isFetching && (!isFetched || isPreviousData)}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          searchTerm={searchTerm}
          onEdit={handleEdit}
          onDelete={handleRemoveFromPractice}
        />
      </Paper>
    </>
  );
};

export default UsersFeature;
