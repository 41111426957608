import {
  PagedListOfPracticeDto,
  PracticeDto,
  UpdatePracticeCommand
} from './api';
import {
  GetKey,
  ListKey,
  makeDeleteMutation,
  makeGetQuery,
  makeListQuery,
  makeUpdateMutation,
  PageParams
} from './useApi';

const USER_PRACTICES_KEY = 'user-practices' as ListKey;
const PRACTICES_KEY = 'practices' as ListKey;
const PRACTICE_KEY = 'practice' as GetKey;
export interface PracticesQueryParams extends PageParams {
  searchTerm?: string | null;
}

export const usePracticesQuery = makeListQuery<PracticesQueryParams, PagedListOfPracticeDto>(
  PRACTICES_KEY,
  (api, params) => () => api.practices.list(params.page, params.pageSize, params.searchTerm),
);

export const usePracticeQuery = makeGetQuery<PracticeDto, string>(
  PRACTICE_KEY,
  (api, practiceId) => () => api.practices.get(practiceId),
);

export const useDisablePracticeMutation = makeUpdateMutation<void, string>(
  PRACTICE_KEY,
  PRACTICES_KEY,
  (api) => (practiceId) => api.practices.disable(practiceId),
  () => 'The Practice has been disabled',
  [USER_PRACTICES_KEY],
);

export const useReinstatePracticeMutation = makeUpdateMutation<void, string>(
  PRACTICE_KEY,
  PRACTICES_KEY,
  (api) => (practiceId) => api.practices.reinstate(practiceId),
  () => 'The Practice has been Reinstated',
  [USER_PRACTICES_KEY],
);

export const useDeletePracticeMutation = makeDeleteMutation<void, PracticeDto>(
  null,
  PRACTICES_KEY,
  (api) => (practice) => api.practices.delete(practice.entityId),
  (practice) => `${practice.practiceName} has been successfully deleted`,
  (mutation) => mutation.entityId,
  [USER_PRACTICES_KEY],
);

export const useUpdatePracticeMutation = makeUpdateMutation<void, UpdatePracticeCommand>(
  PRACTICE_KEY,
  PRACTICES_KEY,
  (api, practiceId) => (command) => api.practices.update(practiceId, command),
  (mutation) => `${mutation.practice.practiceName} successfully updated.`,
  [USER_PRACTICES_KEY],
);
