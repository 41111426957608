import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MailIcon from '@mui/icons-material/Mail';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ResponsiveNavButton from 'components/ResponsiveNavButton';
import SearchField from 'components/SearchField';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'config';
import Page from 'modules/Page';
import {
  InvitationDto,
  usePracticeInvitationsQuery,
  useRevokePracticeInvitationMutation
} from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import React from 'react';
import { TITLE as pageTitle } from './constants';
import PracticeInvitationsTable from './PracticeInvitationsTable';

const PracticeInvitationsPage = () => {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGINATION_DEFAULT_PAGE_SIZE);
  const [searchTerm, setSearchTerm] = React.useState<string | null>(null);
  const confirm = useConfirmation();
  const mutation = useRevokePracticeInvitationMutation();

  const {
    data,
    isFetching,
    isFetched,
    isPreviousData,
  } = usePracticeInvitationsQuery({ page, pageSize, searchTerm });

  const handlePageChange = (newPage: number) => {
    setPage(newPage + 1);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPage(1);
    setPageSize(newPageSize);
  };

  const handleSearch = (text: string | null) => {
    setPage(1);
    setSearchTerm(text);
  };

  const handleRevoke = (invite: InvitationDto) => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want to revoke this invite?',
    }).then(() => mutation.mutate(invite));
  };

  return (
    <Page title={pageTitle} pageType="large">
      <Stack direction="row" spacing={2} alignItems="flex-start">
        <Box flexGrow={1}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SearchField label="Search by Email" onSearch={handleSearch} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        <ResponsiveNavButton path="../new" label="Invite Practice" Icon={<MailIcon />} />
      </Stack>
      <Paper sx={{ mt: 3 }}>
        <PracticeInvitationsTable
          data={data?.items}
          page={page}
          pageSize={pageSize}
          totalItems={data?.pager.totalItems}
          loading={isFetching}
          loadingNew={isFetching && (!isFetched || isPreviousData)}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onRevoke={handleRevoke}
          searchTerm={searchTerm}
        />
      </Paper>
    </Page>
  );
};

export default PracticeInvitationsPage;
