import { CLIENT_ROLES } from 'config';
import { NewUserFeature } from 'features/user';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import useClientPageTitle from '../../../useClientPageTitle';
import { TITLE as parentTitle } from '../ClientUserInvitationsPage';
import { TITLE as pageTitle } from './constants';

const NewClientUserPage = () => {
  const { clientId } = useProtectedParams('clientId');
  const title = useClientPageTitle(clientId, pageTitle);

  return (
    <Page title={title} pageType="standard" parentTitle={parentTitle} parentRelativePath="../invites">
      <NewUserFeature teamId={clientId} availableRoles={CLIENT_ROLES} />
    </Page>
  );
};

export default NewClientUserPage;
