import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import MailIcon from '@mui/icons-material/Mail';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';

interface SchedulerCalendarMenuProps {
  disabled: boolean;
  onSave: () => void;
  onOptimise: () => void;
  onReset: () => void;
}

const SchedulerCalendarMenu = ({ disabled, onSave, onOptimise, onReset }: SchedulerCalendarMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSave = () => {
    onSave();
    setAnchorEl(null);
  };

  const handleOptimise = () => {
    onOptimise();
    setAnchorEl(null);
  };

  const handleReset = () => {
    onReset();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" aria-controls="scheduler calandar action menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="scheduler-calendar-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {disabled && (
          <Tooltip title="One or more appointments in this schedule are not editable">
            <Chip label="Read only" color="error" variant="outlined" size="small" />
          </Tooltip>
        )}
        <MenuItem onClick={handleSave} disabled={disabled}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Save</Typography>
        </MenuItem>
        <MenuItem onClick={handleOptimise} disabled={disabled}>
          <ListItemIcon>
            <MailIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Optimise</Typography>
        </MenuItem>
        <MenuItem onClick={handleReset} disabled={disabled}>
          <ListItemIcon>
            <DeleteForeverIcon color="error" fontSize="small" />
          </ListItemIcon>
          <Typography color="error" variant="inherit">Reset</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SchedulerCalendarMenu;
