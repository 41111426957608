import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SearchField from 'components/SearchField';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'config';
import Page from 'modules/Page';
import { PracticeDto } from 'providers/api';
import {
  useDeletePracticeMutation,
  useDisablePracticeMutation,
  usePracticesQuery, useReinstatePracticeMutation
} from 'providers/api/usePractices';
import { useConfirmation } from 'providers/confirm';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TITLE } from './constants';
import PracticesTable from './PracticesTable';

const PracticesPage = () => {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGINATION_DEFAULT_PAGE_SIZE);
  const [searchTerm, setSearchTerm] = React.useState<string | null>(null);
  const confirm = useConfirmation();
  const navigate = useNavigate();

  const practiceDisableMutation = useDisablePracticeMutation('');
  const practiceReinstateMutation = useReinstatePracticeMutation('');
  const practiceDeleteMutation = useDeletePracticeMutation();

  const {
    data,
    isFetching,
    isFetched,
    isPreviousData,
  } = usePracticesQuery({ page, pageSize, searchTerm });

  const handlePageChange = (newPage: number) => setPage(newPage + 1);

  const handlePageSizeChange = (newPageSize: number) => {
    setPage(1);
    setPageSize(newPageSize);
  };

  const handleSearch = (text: string | null) => {
    setPage(1);
    setSearchTerm(text);
  };

  const handleEdit = (practiceId: string) => {
    navigate(practiceId);
  };

  const handleSetPracticeLocation = (practiceId: string) => {
    navigate(`${practiceId}/practice-location`);
  };

  const handleDisable = (practice: PracticeDto) => {
    confirm({
      variant: 'danger',
      description: `Are you sure you want disable ${practice.practiceName}?`,
    }).then(() => practiceDisableMutation.mutate(practice.entityId));
  };

  const handleReinstate = (practice: PracticeDto) => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want reinstate this practice?',
    }).then(() => practiceReinstateMutation.mutate(practice.entityId));
  };

  const handleDelete = (practice: PracticeDto) => {
    confirm({
      variant: 'danger',
      description: `Are you sure you want to delete ${practice.practiceName}?`,
    }).then(() => practiceDeleteMutation.mutate(practice));
  };

  return (
    <Page title={TITLE} pageType="large">
      <Box>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Filters</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SearchField label="Search by Practice Name" onSearch={handleSearch} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Paper sx={{ mt: 3 }}>
        <PracticesTable
          data={data?.items}
          page={page}
          pageSize={pageSize}
          totalItems={data?.pager.totalItems}
          loading={isFetching}
          loadingNew={isFetching && (!isFetched || isPreviousData)}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          searchTerm={searchTerm}
          onEdit={handleEdit}
          onSetPracticeLocation={handleSetPracticeLocation}
          onDisable={handleDisable}
          onReinstate={handleReinstate}
          onDelete={handleDelete}
        />
      </Paper>
    </Page>
  );
};

export default PracticesPage;
