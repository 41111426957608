export interface WeekCommencingProps {
  monday: Date;
  sunday: Date;
}

export enum IsoDayOfWeekAmend {
  Mon = 1,
  Tue = 2,
  Wed = 3,
  Thu = 4,
  Fri = 5,
  Sat = 6,
  Sun = 7,
}
