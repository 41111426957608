import { ArrivetRouteObject, RouteConfiguration } from 'modules/interfaces';
import { Navigate } from 'react-router-dom';
import calendarRoutes from './calendar';
import clientRoutes from './client';
import dashboardRoutes, { defaultRoute as dashboardDefaultRoute } from './dashboard';
import settingsRoutes from './settings';
import staffAvailabilityRoutes from './StaffAvailability';
import userRoutes from './user';

export const defaultRoute = dashboardDefaultRoute;

const routes: (config: RouteConfiguration) => ArrivetRouteObject[] = (config) => {
  const calendar = calendarRoutes(config);
  const settings = settingsRoutes(config);
  const user = userRoutes(config);
  const dashboard = dashboardRoutes(config);
  const client = clientRoutes(config);
  const staffAvailability = staffAvailabilityRoutes(config);

  return [
    {
      index: true,
      element: <Navigate to={defaultRoute} />,
    },
    ...dashboard,
    ...calendar,
    ...staffAvailability,
    ...client,
    ...user,
    ...settings,
  ];
};

export default routes;
