import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SnackbarContent, useSnackbar } from 'notistack';
import { take } from 'ramda';
import { forwardRef, useCallback } from 'react';

const SnackMessage = forwardRef<HTMLDivElement, { id: string | number, details?: string[], title: string }>((props, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  const limitedDetails = props.details ? take(5, props.details) : undefined;

  return (
    <Box component={SnackbarContent} ref={ref} sx={{ maxWidth: ['100%', '500px'] }}>
      <Alert variant="filled" severity="error" sx={{ width: '100%' }} onClose={handleDismiss}>
        {limitedDetails && <AlertTitle>{props.title}</AlertTitle>}
        {limitedDetails ? limitedDetails.map((detail) => <Typography key={detail} variant="body1">{detail}</Typography>) : props.title}
        {(limitedDetails?.length ?? 0) > 1 && (
          <Typography display="block" variant="caption">
            {`${limitedDetails?.length}/${props.details?.length}`}
          </Typography>
        )}
      </Alert>
    </Box>
  );
});

export default SnackMessage;
