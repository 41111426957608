import { createContext } from 'react';
import {
  AppointmentsService,
  ArrivalTimesService,
  CalendarEventsService,
  ClientsService,
  IntegrationsService,
  LocationsService,
  MeService,
  PatientsService,
  PracticeInvitationsService,
  PracticesService,
  TeamRolesService,
  TeamsService,
  UserInvitationsService,
  UsersService,
  ValidatedLocationsService
} from './api';

export interface IApiContext {
  patients: PatientsService,
  practiceInvitations: PracticeInvitationsService,
  userInvitations: UserInvitationsService,
  practices: PracticesService,
  teams: TeamsService,
  clients: ClientsService,
  users: UsersService,
  me: MeService,
  appointments: AppointmentsService,
  locations: LocationsService,
  arrivalTimes: ArrivalTimesService,
  teamRoles: TeamRolesService
  integrations: IntegrationsService,
  calendarEvents: CalendarEventsService,
  validatedLocations: ValidatedLocationsService,
}

const ApiContext = createContext<IApiContext | null>(null);

export default ApiContext;
