import Portal from '@mui/material/Portal';
import { ReactNode } from 'react';
import useTopContent from './useTopContent';

interface TopContentProps {
  children: ReactNode;
}

const TopContent = ({ children }: TopContentProps) => {
  const { container } = useTopContent();

  return (
    <Portal container={container}>
      {children}
    </Portal>
  );
};

export default TopContent;
