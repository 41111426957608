import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { PracticeDto } from 'providers/api';
import React from 'react';

interface PracticesTableMenuProps {
  practice: PracticeDto;
  onEdit: (practiceId: string) => void;
  onSetPracticeLocation: (practiceId: string) => void;
  onDisable: (practice: PracticeDto) => void;
  onReinstate: (practice: PracticeDto) => void;
  onDelete: (practice: PracticeDto) => void;
}

const PracticesTableMenu = ({ practice, onEdit, onSetPracticeLocation, onDisable, onReinstate, onDelete }: PracticesTableMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit(practice.entityId);
    setAnchorEl(null);
  };

  const handleSetPracticeLocation = () => {
    onSetPracticeLocation(practice.entityId);
    setAnchorEl(null);
  };

  const handleDisable = () => {
    onDisable(practice);
    setAnchorEl(null);
  };

  const handleReinstate = () => {
    onReinstate(practice);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete(practice);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" aria-controls={`client-actions-menu-${practice.entityId}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`client-actions-menu-${practice.entityId}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Edit</Typography>
        </MenuItem>
        <MenuItem onClick={handleSetPracticeLocation}>
          <ListItemIcon>
            <AddLocationAltIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Set Practice Location</Typography>
        </MenuItem>
        <MenuItem onClick={handleDisable}>
          <ListItemIcon>
            <DesktopAccessDisabledIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Disable</Typography>
        </MenuItem>
        <MenuItem onClick={handleReinstate}>
          <ListItemIcon>
            <DesktopWindowsIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Reinstate</Typography>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteForeverIcon color="error" fontSize="small" />
          </ListItemIcon>
          <Typography color="error" variant="inherit">Delete</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default PracticesTableMenu;
