import { useContext } from 'react';
import PageContext, { IPageContext } from './context';

export default function usePage(): IPageContext {
  const context = useContext(PageContext);
  if (context === null) {
    throw new Error('No page context available');
  }
  return context;
}
