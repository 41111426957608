import { FormikConfig } from 'formik';
import React from 'react';
import { UseMutationResult } from 'react-query';
import { IFormContext } from './context';
import useDefaultForm from './useDefaultForm';

export interface PracticeProviderProps<TForm, TValues> {
  mutation: UseMutationResult<any, any, TForm, any>;
  formikConfig: FormikConfig<TValues>;
  children: React.ReactNode;
  pathToServerEntityModel?: string;
}

export const createFormProvider = <TForm, TValues>(Context: React.Context<IFormContext<TForm, TValues> | null>) => ({
  children,
  mutation,
  formikConfig,
  pathToServerEntityModel,
}: PracticeProviderProps<TForm, TValues>) => {
  const form = useDefaultForm({ mutation, formikConfig, pathToServerEntityModel });

  const value: IFormContext<TForm, TValues> = React.useMemo(() => ({
    form,
    mutation,
  }), [form, mutation]);

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};
