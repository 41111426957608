import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FormikActions, FormStack } from 'components/Form';
import { GeoPointDto, LocationDto, UpdateLocationCommand } from 'providers/api';
import useDefaultForm from 'providers/form/useDefaultForm';
import React from 'react';
import { UseMutationResult } from 'react-query';
import {
  number, object,
  SchemaOf,
  string
} from 'yup';

const locationSchema: SchemaOf<UpdateLocationCommand> = object().shape({
  entityKey: string().required('The location must be assigned to an entity'),
  entityType: string().required('The location must be assigned a type'),
  description: string().required('A location description is required'),
  validatedLocationId: string().notRequired(),
  geoPoint: object().shape({
    latitude: number().required(),
    longitude: number().required(),
  }).required(),
});

interface InitialLocation extends Omit<UpdateLocationCommand, 'geoPoint'> {
  geoPoint: {
    latitude: number | undefined;
    longitude: number | undefined;
  }
}
interface LocationFormProps {
  entityKey: string; // entityId to attach the location to
  entityType: string; // a string key used to identify type of location. Use the ReactQuery key where possible
  geoPoint?: GeoPointDto;
  mutation: UseMutationResult<string, unknown, UpdateLocationCommand, unknown>;
  location?: LocationDto;
  onCancel?: () => void;
}

const LocationForm = ({ entityKey, entityType, geoPoint, mutation, location, onCancel }: LocationFormProps) => {
  const {
    formik,
    helpers,
  } = useDefaultForm<InitialLocation>({
    mutation,
    formikConfig: {
      initialValues: {
        entityKey,
        entityType,
        description: location?.description ?? '',
        geoPoint: {
          latitude: geoPoint?.latitude,
          longitude: geoPoint?.longitude,
        },
      },
      onSubmit: (locationForm, { setSubmitting }) => {
        setSubmitting(true);
        mutation.mutate(locationForm as UpdateLocationCommand, {
          onSettled: () => {
            setSubmitting(false);
          },
        });
      },
      validationSchema: locationSchema,
    },
  });

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  React.useEffect(() => {
    if (geoPoint) {
      setFieldValue('geoPoint.latitude', geoPoint.latitude);
      setFieldValue('geoPoint.longitude', geoPoint.longitude);
    }
  }, [geoPoint]);

  React.useEffect(() => {
    if (location) {
      setFieldValue('geoPoint.latitude', location.geoPoint.latitude);
      setFieldValue('geoPoint.longitude', location.geoPoint.longitude);
      setFieldValue('description', location.description);
    }
  }, [location]);

  const cancelButton = [
    'cancel',
    <Button
      color="error"
      variant="outlined"
      onClick={() => onCancel && onCancel()}
    >
      Cancel
    </Button>,
  ] as [string, JSX.Element];

  return (
    <form onSubmit={handleSubmit}>
      <FormStack>
        <TextField
          fullWidth
          id="description"
          name="description"
          label="Location Description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          error={helpers.hasError('description')}
          helperText={helpers.getErrorHelpText('description')}
        />
        <Typography variant="caption">Select a point on the map to update the location</Typography>
        <TextField
          fullWidth
          type="number"
          id="geoPoint.latitude"
          name="geoPoint.latitude"
          label="Latitude"
          value={values.geoPoint.latitude ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={helpers.hasError('geoPoint.latitude')}
          helperText={helpers.getErrorHelpText('geoPoint.latitude')}
          disabled
        />
        <TextField
          fullWidth
          type="number"
          id="geoPoint.longitude"
          name="geoPoint.longitude"
          label="Longitude"
          value={values.geoPoint.longitude ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={helpers.hasError('geoPoint.longitude')}
          helperText={helpers.getErrorHelpText('geoPoint.longitude')}
          disabled
        />
      </FormStack>

      <Box mt={2}>
        <FormikActions
          formik={formik}
          mutation={mutation}
          submitText="Save"
          right={['reset', 'submit']}
          left={[
            ...(onCancel ? [cancelButton] : []),
          ]}
        />
      </Box>
    </form>
  );
};

export default LocationForm;
