import PeopleIcon from '@mui/icons-material/People';
import { ArrivetRouteObject, RouteConfiguration } from 'modules/interfaces';
import TabRouterOutlet from 'modules/TabRouterOutlet';
import EditUserPage from './EditUserPage';
import NewUserPage from './NewUserPage';
import UserInvitationsPage from './UserInvitationsPage';
import UsersPage from './UsersPage';

export const defaultRoute = 'users';

const tabRoutes = {
  Users: '',
  'User Invites': '/invites',
};

const routes: (config: RouteConfiguration) => ArrivetRouteObject[] = () => [{
  path: 'users',
  mainNavigation: {
    title: 'Users',
    group: '',
    iconComponent: <PeopleIcon />,
  },
  children: [
    {
      tabRouteId: 'PracticeUsers',
      element: <TabRouterOutlet routes={tabRoutes} routeId="PracticeUsers" />,
      children: [
        {
          index: true,
          element: <UsersPage />,
        },
        {
          path: 'invites',
          element: <UserInvitationsPage />,
        },
      ],
    },
    {
      path: 'new',
      element: <NewUserPage />,
    },
    {
      path: 'edit/:userId',
      element: <EditUserPage />,
    },
  ],
}];

export default routes;
