import { createContext } from 'react';

export type MessageType = 'signal' | 'Location Updated' | 'Vet ETA updated';
export type MessageTypeCallbackList = Record<string, (args: any[]) => void>
export type MessageTypeCallbackRecord = Record<MessageType, MessageTypeCallbackList>

export interface ISignalRContext {
  actions: {
    addCallback: (messageType: MessageType, callback: (args: any) => void) => string;
    removeCallback: (messageType: MessageType, callbackId: string) => void;
    isConnected: () => boolean;
  },
}

const SignalRContext = createContext<ISignalRContext | null>(null);

export default SignalRContext;
