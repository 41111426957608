import {
  InvitationDto,
  InvitePracticeCommand,
  PagedListOfInvitationDto
} from './api';
import {
  ListKey,
  makeCreateMutation,
  makeDeleteMutation, makeListQuery, PageParams
} from './useApi';

const PRACTICE_INVITES_KEY = 'practice-invitations' as ListKey;

export interface PracticeInvitationsQueryParams extends PageParams {
  searchTerm?: string | null;
}

export const usePracticeInvitationsQuery = makeListQuery<PracticeInvitationsQueryParams, PagedListOfInvitationDto>(
  PRACTICE_INVITES_KEY,
  (api, params) => () => api.practiceInvitations.listInvitations(params.page, params.pageSize, params.searchTerm),
);

export const useCreatePracticeInviteMutation = makeCreateMutation<void, InvitePracticeCommand>(
  PRACTICE_INVITES_KEY,
  (api) => (mutation) => api.practiceInvitations.create(mutation),
  (mutation) => `Invite successfully sent to ${mutation.email}`,
);

export const useRevokePracticeInvitationMutation = makeDeleteMutation<void, InvitationDto>(
  null,
  PRACTICE_INVITES_KEY,
  (api) => (invite) => api.practiceInvitations.revokeInvitation(invite.invitationId),
  (invite) => `Invite for ${invite.email} successfully revoked`,
  (mutation) => mutation.invitationId,
);
