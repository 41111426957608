import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React from 'react';

interface ActionsProps {
  left?: [string, JSX.Element][];
  center?: [string, JSX.Element][];
  right?: [string, JSX.Element][];
}

const getItems = (elms: [string, JSX.Element][]) => elms.map(([key, Component]) => (<div key={key}>{Component}</div>));

const Actions = ({ left, center, right }: ActionsProps) => (
  <Box display="flex" alignItems="center" justifyContent="space-between">
    {left ? <Stack direction="row" spacing={2}>{getItems(left)}</Stack> : <div />}
    {center ? <Stack direction="row" spacing={2}>{getItems(center)}</Stack> : <div />}
    {right ? <Stack direction="row" spacing={2}>{getItems(right)}</Stack> : <div />}
  </Box>
);

export default Actions;
