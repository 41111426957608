import Box from '@mui/material/Box';
import { usePage } from 'providers/page';
import React from 'react';
import { useResolvedPath } from 'react-router-dom';

type PageType = 'standard' | 'large' | 'full' | 'fullscreen';
interface PageTypeInfo {
  maxWidth?: number | string;
  height?: number | string;
  p?: number;
}

// TODO: Testing an approach - expecting this to grow over time
const pageTypes: Record<PageType, PageTypeInfo> = {
  standard: {
    maxWidth: 960,
    p: 2,
  },
  large: {
    maxWidth: 1200,
    p: 2,
  },
  full: {
    p: 2,
    maxWidth: '100%',
    height: '100%',
  },
  fullscreen: {
    maxWidth: '100%',
  },
};

interface PageProps {
  title: string;
  parentTitle?: string;
  parentRelativePath?: string;
  children: React.ReactNode;
  pageType?: PageType;
}

const Page = ({ title, parentTitle, parentRelativePath: parentPath, children, pageType = 'standard' }: PageProps) => {
  const { actions } = usePage();

  // global back button is scoped from the top route and requires a resolved path.
  const absolutePath = parentPath ? useResolvedPath(parentPath).pathname : null;

  React.useEffect(() => {
    actions.setParentPath(absolutePath);
    actions.setParentTitle(parentTitle ?? null);
    title !== undefined && actions.setTitle(title);
  }, [actions, parentPath, title, parentTitle]);

  return (
    <Box sx={{ ...pageTypes[pageType], minHeight: '100%', height: '100%' }}>
      {children}
    </Box>
  );
};

export default Page;
