import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormikActions, FormStack } from 'components/Form';
import TitleBox from 'components/TitleBox';
import Page from 'modules/Page';
import { IntegrationForm, useAddPracticeToWhitelistMutation } from 'providers/api';
import { useDefaultForm } from 'providers/form';
import {
  number, object,
  SchemaOf,
  string
} from 'yup';

const integrationFormSchema: SchemaOf<IntegrationForm> = object().shape({
  practiceId: string().required('You must provide a practice Id'),
  externalSystemId: string().required('You must provide an external system Id'),
  externalSystemName: number().required('You must provide and external system name'),
});

const AddToWhitelistPage = () => {
  const addPracticeToWhitelistMutation = useAddPracticeToWhitelistMutation();

  const {
    formik,
    helpers,
  } = useDefaultForm<IntegrationForm>({
    mutation: addPracticeToWhitelistMutation,
    formikConfig: {
      initialValues: {
        practiceId: '',
        externalSystemName: 1,
        externalSystemId: '',
      },
      onSubmit: (form, { setSubmitting }) => {
        setSubmitting(true);
        addPracticeToWhitelistMutation.mutate(form as any, {
          onSettled: () => {
            setSubmitting(false);
          },
        });
      },
      validationSchema: integrationFormSchema,
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  return (
    <Page title="Add to Whitelist">
      <form onSubmit={handleSubmit}>
        <TitleBox title="Add">
          <FormStack>
            <TextField
              fullWidth
              id="practiceId"
              name="practiceId"
              label="Practice Id"
              value={values.practiceId}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('practiceId')}
              helperText={helpers.getErrorHelpText('practiceId')}
            />
            <TextField
              fullWidth
              id="externalSystemName"
              name="externalSystemName"
              label="External System Name"
              value={values.externalSystemName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('externalSystemName')}
              helperText={helpers.getErrorHelpText('externalSystemName')}
            />
            <TextField
              fullWidth
              id="externalSystemId"
              name="externalSystemId"
              label="External System Id"
              value={values.externalSystemId}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('externalSystemId')}
              helperText={helpers.getErrorHelpText('externalSystemId')}
            />
          </FormStack>
        </TitleBox>

        <Box mt={2}>
          <FormikActions
            formik={formik}
            mutation={addPracticeToWhitelistMutation}
            submitText="Add"
            right={['reset', 'submit']}
          />
        </Box>
      </form>
    </Page>
  );
};

export default AddToWhitelistPage;
