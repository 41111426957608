import TextField from '@mui/material/TextField';
import { usePracticeForm } from './context';

const PracticeCompanyNameField = () => {
  const {
    form: { formik, helpers },
  } = usePracticeForm();

  const { values, handleChange, handleBlur } = formik;

  return (
    <TextField
      fullWidth
      id="practice.registeredCompanyName"
      name="practice.registeredCompanyName"
      label="Company Name"
      value={values.practice.registeredCompanyName ?? ''}
      onChange={handleChange}
      onBlur={handleBlur}
      error={helpers.hasError('practice.registeredCompanyName')}
      helperText={helpers.getErrorHelpText('practice.registeredCompanyName')}
    />
  );
};

export default PracticeCompanyNameField;
