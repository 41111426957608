import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FormikContextType } from 'formik';
import { PagedListOfUserDto, UserDto } from 'providers/api';
import React from 'react';

interface VetFieldProps {
  onChange: (user: string | null) => void
  formik: FormikContextType<any>
  vets: PagedListOfUserDto | undefined
}

const VetField = ({ onChange, formik, vets }: VetFieldProps) => {
  const selectedVet = vets?.items.find((user) => user.userId === formik.values.userId) ?? null;

  return (
    <Autocomplete
      fullWidth
      options={vets?.items || []}
      getOptionLabel={(option: UserDto) => (
        option.forename === undefined && option.surname === undefined
          ? `${option.email}`
          : `${option.forename ?? ''} ${option.surname ?? ''}`
      )}
      value={selectedVet}
      onChange={(event, newUser) => {
        onChange(newUser?.userId ?? null);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          id="userId"
          name="userId"
          label="Vet"
          variant="outlined"
        />
      )}
    />
  );
};

export default VetField;
