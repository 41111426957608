import TextField from '@mui/material/TextField';
import { usePracticeForm } from './context';

const PracticeCompanyVatField = () => {
  const {
    form: { formik, helpers },
  } = usePracticeForm();

  const { values, handleChange, handleBlur } = formik;

  return (
    <TextField
      fullWidth
      id="practice.companyVatNumber"
      name="practice.companyVatNumber"
      label="VAT Number"
      value={values.practice.companyVatNumber ?? ''}
      onChange={handleChange}
      onBlur={handleBlur}
      error={helpers.hasError('practice.companyVatNumber')}
      helperText={helpers.getErrorHelpText('practice.companyVatNumber')}
    />
  );
};

export default PracticeCompanyVatField;
