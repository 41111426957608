import Typography, { TypographyProps } from '@mui/material/Typography';
import { AddressDto } from 'providers/api';
import { notNilOrEmpty } from 'utils';

interface AddressProps {
  type: 'inline' | 'traditional';
  address: AddressDto;
  typographyVariant?: TypographyProps['variant'];
}
type AddressParts = string[]

interface AddressTypeProps {
  addressParts: AddressParts;
  typographyVariant: TypographyProps['variant'];
}

const InlineAddress = ({ addressParts, typographyVariant }: AddressTypeProps) => (
  <Typography component="address" variant={typographyVariant}>{addressParts.join(', ')}</Typography>
);

const TraditionalAddress = ({ addressParts, typographyVariant }: AddressTypeProps) => {
  const [first, ...parts] = addressParts;
  return (
    <Typography component="address" variant={typographyVariant}>
      {first}
      {
        parts.map((part) => (
          <>
            <br />
            {part}
          </>
        ))
      }
    </Typography>
  );
};

const Address = ({ type, address, typographyVariant = 'body1' }: AddressProps) => {
  const AddressType = type === 'inline' ? InlineAddress : TraditionalAddress;
  const { addressLine1, addressLine2, city, region, postCode, country } = address;
  const addressParts = [addressLine1, addressLine2, city, region, postCode.toLocaleUpperCase(), country]
    .filter(notNilOrEmpty) as string[];

  return <AddressType addressParts={addressParts} typographyVariant={typographyVariant} />;
};

export default Address;
