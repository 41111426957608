import { convert } from '@js-joda/core';
import { CALENDAR_EVENT_STATUS_KEY } from 'features/AppointmentCalendar/index';
import { EventInputWithType } from 'features/AppointmentCalendar/interfaces';
import {
  AppointmentDto,
  AppointmentStatus,
  CalendarEventDto,
  CalendarEventType,
  TimeBlockDto
} from 'providers/api';

// Makes working hours (0) appear clear in appointment calendar and blocks out in grey non-working hours.
const alterDisplayForAppointmentCalendar = (viewType: string, calendarEventType: number) => {
  if (viewType !== 'resourceTimeline' && calendarEventType === 0) {
    return {
      display: 'inverse-background',
      backgroundColor: '#d3d3d3',
      borderColor: '#d3d3d3',
      title: '',
    };
  } return {
    display: 'block',
  };
};

export const mapAppointmentToEvents = (appointments: AppointmentDto[], readOnly: boolean) => {
  const now = new Date();
  const then = new Date(new Date().setMonth(now.getMonth() + 1));

  return appointments.map((appointment): EventInputWithType => (
    {
      id: appointment.entityId,
      title: `${appointment.patient.name} - ${appointment.appointmentSummary}`,
      start: convert(appointment.startTime).toDate(),
      end: convert(appointment.endTime).toDate(),
      backgroundColor: CALENDAR_EVENT_STATUS_KEY[appointment.appointmentStatus],
      textColor: '#000',
      borderColor: CALENDAR_EVENT_STATUS_KEY[appointment.appointmentStatus],
      display: 'block',
      resourceId: appointment.vet.entityId,
      extendedProps: {
        type: 'appointment',
        status: appointment.appointmentStatus,
      },
      editable: !readOnly
        && (
          appointment.appointmentStatus === AppointmentStatus.Unconfirmed
          || appointment.appointmentStatus === AppointmentStatus.Draft
        ),
      resourceEditable: !readOnly
        && (
          appointment.appointmentStatus === AppointmentStatus.Unconfirmed
          || appointment.appointmentStatus === AppointmentStatus.Draft
        ),
      constraint: {
        start: now.toISOString().split('.')[0],
        end: then.toISOString().split('.')[0],
      },
    }
  ));
};

export const mapTimeBlockToEvents = (
  calendarEvents: CalendarEventDto[],
  readOnly: boolean,
  isDayView: boolean,
  viewType: 'resourceTimeline' | 'timeGridWeek' | 'resourceTimeGrid' | 'timeGridDay',
): EventInputWithType[] => {
  const TIMEBLOCK_EVENT_STATUS_KEY: Record<CalendarEventType, string> = {
    [CalendarEventType.WorkingHours]: '#44A184',
    [CalendarEventType.Training]: '#A799B7',
    [CalendarEventType.Maternity]: '#EEC6CA',
    [CalendarEventType.Paternity]: '#F7DAD9',
    [CalendarEventType.Sabbatical]: '#ffe7b2',
    [CalendarEventType.CompassionateLeave]: '#e3edf9',
    [CalendarEventType.SickLeave]: '#c7e2d9',
    [CalendarEventType.Miscellaneous]: '#d6ccc8',
    [CalendarEventType.Holiday]: '#a1d7db',
  };

  const now = new Date();
  const then = new Date(new Date().setMonth(now.getMonth() + 1));

  return calendarEvents.reduce(
    (events: EventInputWithType[], currentCalendarEvent: CalendarEventDto) => [
      ...events,
      ...currentCalendarEvent.timeBlocks.map(
        (timeBlock: TimeBlockDto): EventInputWithType => (
          {
            id: timeBlock.entityId,
            groupId: currentCalendarEvent.entityId, // needed to display full white background when available on calendar.
            title: currentCalendarEvent.title,
            start: convert(timeBlock.start).toDate(),
            end: convert(timeBlock.end).toDate(),
            textColor: '#000',
            resourceId: currentCalendarEvent.user.entityId,
            editable: !readOnly,
            ...(!isDayView && { durationEditable: false }),
            resourceEditable: false,
            eventResourceEditable: false,
            constraint: {
              start: now.toISOString().split('.')[0],
              end: then.toISOString().split('.')[0],
            },
            backgroundColor: TIMEBLOCK_EVENT_STATUS_KEY[currentCalendarEvent.type],
            borderColor: TIMEBLOCK_EVENT_STATUS_KEY[currentCalendarEvent.type],
            extendedProps: { type: 'availability' },
            ...(alterDisplayForAppointmentCalendar(viewType, currentCalendarEvent.type)),
          }
        ),
      ),
    ],
    [],
  );
};
