import { ChronoUnit, Instant } from '@js-joda/core';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocalStorage } from '@rehooks/local-storage';
import { AUTH_TO_CREATE_APPOINTMENTS, LOCAL_STORAGE_PREFIX, MINUTE_IN_MILLISECONDS } from 'config';
import { useAppointmentsQuery, useArchiveMyPreviousMessagesMutation, useMyMessageListQuery } from 'providers/api';
import { useAuthorisation } from 'providers/authorisation';
import { useConfirmation } from 'providers/confirm';
import useSignalR from 'providers/signalR/signalR.use';
import { useTeam } from 'providers/team';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { px } from 'utils';
import Menu from './Menu';
import { MenuStateProvider } from './MenuStateProvider';
import Notifications, { NotificationsDrawerContext } from './Notifications';
import TopBar from './TopBar';
import { TopContentProvider } from './TopContentProvider';
import { TopLevelMenuItem } from './types';

export const CONTENT_MOBILE_OFFSET = 56;
export const CONTENT_OFFSET = 64;

type LayoutProps = {
  menu: TopLevelMenuItem[];
};

const Layout = ({ menu }: LayoutProps) => {
  const { functions: { isAdmin }, actions: { userHasPermissions } } = useAuthorisation();
  const theme = useTheme();
  const signalR = useSignalR();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const [savedMenuOpen, setSavedMenuOpen] = useLocalStorage(`${LOCAL_STORAGE_PREFIX}-MENU`, true);
  const [open, setOpen] = React.useState(false);
  const [notificationsDrawerState, setNotificationsDrawerState] = React.useState<NotificationsDrawerContext>();
  const { state: { activeTeam } } = useTeam();
  const confirm = useConfirmation();

  const [loadingMessages, setLoadingMessages] = React.useState(true);

  const startDateUtcRef = React.useRef(Instant.now());
  const endDateUtcRef = React.useRef(Instant.now().plus(5, ChronoUnit.DAYS));

  // TODO: consider lazy loading based on the notification drawer state
  const {
    data: urgentUnconfirmedAppointments,
  } = useAppointmentsQuery({
    practiceId: activeTeam?.teamType === 'Practice' ? activeTeam?.teamId : undefined,
    clientId: activeTeam?.teamType === 'Client' ? activeTeam.teamId : undefined,
    appointmentStatus: 0,
    startDateUtc: startDateUtcRef.current,
    endDateUtc: endDateUtcRef.current,
  }, {
    staleTime: MINUTE_IN_MILLISECONDS * 10,
    enabled: !isAdmin() && userHasPermissions(activeTeam?.teamId ?? '', AUTH_TO_CREATE_APPOINTMENTS),
  });

  const { data: messageList } = useMyMessageListQuery(
    { page: 1, pageSize: 100, teamId: activeTeam?.teamId ?? '' },
    {
      enabled: loadingMessages && !isAdmin() && userHasPermissions(activeTeam?.teamId ?? '', AUTH_TO_CREATE_APPOINTMENTS),
      onSuccess: () => setLoadingMessages(false),
    },
  );

  const archiveMessages = useArchiveMyPreviousMessagesMutation(activeTeam?.teamId ?? '');

  const handleArchiveMessages = () => confirm({
    variant: 'danger',
    description: 'Are you sure you want to archive all messages?',
  }).then(() => {
    archiveMessages.mutate(activeTeam?.teamId ?? '', {
      onSuccess: () => setLoadingMessages(true),
    });
  });

  React.useEffect(() => {
    !isLarge && setOpen(false);
  }, [isLarge]);

  const handleMenuOpen = () => {
    setOpen(true);
    isLarge && setSavedMenuOpen(true);
  };

  const handleMenuClosed = () => {
    setOpen(false);
    isLarge && setSavedMenuOpen(false);
  };

  const handleNotificationsClosed = () => setNotificationsDrawerState(undefined);

  const menuOpen = isLarge ? savedMenuOpen : open;

  React.useEffect(() => {
    const callbackId = signalR.actions.addCallback('signal', () => setLoadingMessages(true));
    return () => signalR.actions.removeCallback('signal', callbackId);
  }, []);

  return (
    <Box display="flex">
      <TopBar
        mainMenuIsOpen={menuOpen}
        notificationsDrawerIsOpen={!!notificationsDrawerState}
        eventTotal={messageList ? messageList?.pager.totalItems : 0}
        errorTotal={urgentUnconfirmedAppointments?.length ?? 0}
        onOpenNotifications={(context: NotificationsDrawerContext) => setNotificationsDrawerState(context)}
      />
      <TopContentProvider mainMenuIsOpen={menuOpen} notificationsDrawerIsOpen={!!notificationsDrawerState}>
        {(topContentHeight) => (
          <>
            <Menu menu={menu} closeMenu={handleMenuClosed} openMenu={handleMenuOpen} open={menuOpen} />
            <Box
              component="main"
              sx={{
                position: 'relative',
                flexGrow: 1,
                minHeight: {
                  xs: `calc(100vh - ${px(CONTENT_MOBILE_OFFSET + topContentHeight)})`,
                  sm: `calc(100vh - ${px(CONTENT_OFFSET + topContentHeight)})`,
                },
                mt: {
                  xs: px(CONTENT_MOBILE_OFFSET + topContentHeight),
                  sm: px(CONTENT_OFFSET + topContentHeight),
                },
              }}
            >
              <MenuStateProvider mainMenuIsOpen={menuOpen} notificationsDrawerIsOpen={!!notificationsDrawerState}>
                <Outlet />
              </MenuStateProvider>
            </Box>
            <Notifications
              state={notificationsDrawerState}
              errors={urgentUnconfirmedAppointments ?? []}
              events={messageList?.items ?? []}
              onArchiveAllMessage={handleArchiveMessages}
              closeMenu={handleNotificationsClosed}
            />
          </>
        )}
      </TopContentProvider>
    </Box>
  );
};

export default Layout;
