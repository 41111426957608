import {
  CreatePatientCommand,
  FileParameter,
  PagedListOfPatientDto,
  PatientDto,
  UpdatePatientForm
} from './api';
import {
  GetKey,
  ListKey,
  makeCreateMutation,
  makeDeleteMutation,
  makeGetQuery,
  makeInfiniteListQuery,
  makeListQuery,
  makeUpdateMutation,
  PageParams
} from './useApi';

const PATIENTS_KEY = 'patients' as ListKey;
const PATIENT_KEY = 'patient' as GetKey;

export interface PatientsQueryParams extends PageParams {
  searchTerm?: string | null;
  practiceId?: string;
  clientId?: string;
}

export const usePatientsQuery = makeListQuery<PatientsQueryParams, PagedListOfPatientDto>(
  PATIENTS_KEY,
  (api, params) => () => api.patients.list(params.page, params.pageSize, params.searchTerm, params.practiceId, params.clientId),
);

export interface InfinitePatientsParams {
  searchTerm?: string | null;
  practiceId?: string;
  clientId?: string;
}

export const useInfinitePatientsQuery = makeInfiniteListQuery<InfinitePatientsParams, PatientDto>(
  PATIENTS_KEY,
  (api, params) => ({
    pageParam = { page: 1, pageSize: 8 },
  }) => api.patients.list(pageParam.page, pageParam.pageSize, params?.searchTerm, params?.practiceId, params?.clientId),
);

export const usePatientQuery = makeGetQuery<PatientDto, string>(
  PATIENT_KEY,
  (api, patientId) => () => api.patients.get(patientId),
);

export interface CreatePatientMutation {
  practiceId: string;
  command: CreatePatientCommand;
}

export const useCreatePatientMutation = makeCreateMutation<string, CreatePatientMutation>(
  PATIENTS_KEY,
  (api) => (mutation) => api.patients.create(mutation.practiceId, mutation.command),
  () => 'A new patient has successfully been added to to the client',
);

export const useEditPatientMutation = makeUpdateMutation<void, UpdatePatientForm>(
  PATIENT_KEY,
  PATIENTS_KEY,
  (api, patientId) => (patient) => api.patients.update(patientId, patient),
  () => 'Patient successfully updated',
);

export const useUpdatePatientImageMutation = makeUpdateMutation<void, FileParameter>(
  PATIENT_KEY,
  PATIENTS_KEY,
  (api, patientId) => (file) => api.patients.updatePatientImage(patientId, file),
  () => 'Image successfully updated',
);

export const useDeletePatientMutation = makeDeleteMutation<void, PatientDto>(
  PATIENT_KEY,
  PATIENTS_KEY,
  (api) => (patient) => api.patients.delete(patient.entityId),
  (patient) => `Patient ${patient.name} has been successfully deleted`,
  (patient) => patient.entityId,
);
