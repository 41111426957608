import { UserInvitationsFeature } from 'features/user';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { TITLE as pageTitle } from './constants';

const UserInvitationsPage = () => {
  const { teamId } = useProtectedParams('teamId');

  return (
    <Page title={pageTitle} pageType="large">
      <UserInvitationsFeature teamId={teamId} newInvitePath="../new" />
    </Page>
  );
};

export default UserInvitationsPage;
