/* global google */
import Box from '@mui/material/Box';
import { PRACTICE_ENTITY_TYPE } from 'config';
import PracticeLocationMap from 'features/PracticeLocation/PracticeLocationMap';
import { useLocationQuery, useUpdatePracticeLocationMutation } from 'providers/api';
import React from 'react';

type PracticeLocationProps = {
  practiceId: string
}

const PracticeLocationFeature = ({ practiceId }: PracticeLocationProps) => {
  const locationMutation = useUpdatePracticeLocationMutation();
  const { data: practiceLocationData } = useLocationQuery({
    entityId: practiceId,
    entityType: PRACTICE_ENTITY_TYPE,
  });
  const [practiceLocation, setPracticeLocation] = React.useState<google.maps.LatLngLiteral | undefined>();

  React.useEffect(() => {
    practiceLocationData
      && setPracticeLocation(
        {
          lat: practiceLocationData.geoPoint.latitude,
          lng: practiceLocationData.geoPoint.longitude,
        },
      );
  }, [practiceLocationData]);

  const handleSaveLocation = () => {
    practiceLocation
      && locationMutation.mutate({
        entityKey: practiceId,
        entityType: PRACTICE_ENTITY_TYPE,
        geoPoint: {
          latitude: practiceLocation.lat,
          longitude: practiceLocation.lng,
        },
        description: 'Practice location',
      });
  };
  return (
    <Box>
      <PracticeLocationMap
        practiceLocation={practiceLocation}
        onSaveLocation={handleSaveLocation}
        setPracticeLocation={setPracticeLocation}
      />
    </Box>
  );
};

export default PracticeLocationFeature;
