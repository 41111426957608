import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import EditableSection from 'components/EditableSection';
import { ValidatedLocationDto } from 'providers/api';
import { UpdateValidatedLocationTagsMutation } from 'providers/api/useValidatedLocations';
import useDefaultForm from 'providers/form/useDefaultForm';
import { UseMutationResult } from 'react-query';
import {
  array,
  object,
  SchemaOf,
  string
} from 'yup';
import TagSelectField from './TagSelectField';

const updateValidatedLocationTagsMutationSchema: SchemaOf<UpdateValidatedLocationTagsMutation> = object().shape({
  teamId: string().required('A team Id is required'),
  request: object().shape({
    tags: array().of(string()),
  }).required(),
});

interface ValidatedLocationTagsFormProps {
  validatedLocation: ValidatedLocationDto;
  readOnly?: boolean;
  mutation: UseMutationResult<string, unknown, UpdateValidatedLocationTagsMutation, unknown>;
}

const ValidatedLocationTagsForm = ({ validatedLocation, readOnly, mutation }: ValidatedLocationTagsFormProps) => {
  const {
    formik,
  } = useDefaultForm<UpdateValidatedLocationTagsMutation>({
    mutation,
    formikConfig: {
      initialValues: {
        teamId: validatedLocation.clientId,
        request: {
          tags: validatedLocation.tags ?? [],
        },
      },
      onSubmit: (form, { setSubmitting }) => {
        setSubmitting(true);

        mutation.mutate(form, {
          onSettled: () => {
            setSubmitting(false);
          },
        });
      },
      validationSchema: updateValidatedLocationTagsMutationSchema,
    },
  });

  const {
    values,
    touched,
    handleSubmit,
    setFieldValue,
    setTouched,
  } = formik;

  const handleAddTag = (tag: string) => {
    const newTags = [...values.request.tags, tag];
    setFieldValue('request.tags', newTags);
  };
  const handleRemoveTag = (tag: string) => {
    setTouched({
      ...touched,
      request: {
        tags: true,
      },
    });
    setFieldValue('request.tags', values.request.tags.filter((existingTag: string) => tag !== existingTag));
  };

  return (
    <EditableSection
      title={readOnly ? 'Details' : 'Edit details'}
      mutation={mutation as any}
      formik={formik as any}
      small
      enabled={!readOnly}
    >
      {(editMode) => (
        <Box>
          {
            editMode
              ? (
                <form onSubmit={handleSubmit}>
                  <TagSelectField currentTags={values.request.tags ?? []} onAdd={handleAddTag} onRemove={handleRemoveTag} />
                </form>
              )
              : (
                <Box>
                  {validatedLocation.tags && (
                    <Stack direction="row" pt={2} flexWrap="wrap">
                      {validatedLocation.tags.map((tag) => <Box m={0.5}><Chip key={tag} label={tag} size="small" /></Box>)}
                    </Stack>
                  )}
                </Box>
              )
          }
        </Box>
      )}
    </EditableSection>
  );
};

export default ValidatedLocationTagsForm;
