import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ValidatedLocationDto } from 'providers/api';
import { useTeam } from 'providers/team';
import { TeamType } from 'providers/team/provider';

interface SelectedValidatedLocationBoxProps {
  selectedValidatedLocation?: ValidatedLocationDto;
  validatedBy: string;
  dirty: boolean;
  onCancel: () => void;
  onConfirmExistingValidatedLocation: () => void;
  onDeleteValidatedLocation: () => void;
}

const SelectedValidatedLocationBox = ({
  selectedValidatedLocation,
  validatedBy,
  dirty,
  onCancel,
  onConfirmExistingValidatedLocation,
  onDeleteValidatedLocation,
}: SelectedValidatedLocationBoxProps) => {
  const { state: { activeTeam } } = useTeam();

  return (
    <>
      <Typography variant="subtitle2">Selected Location: </Typography>
      {
        selectedValidatedLocation
          ? (
            <Card sx={{ height: '90%' }}>
              <CardContent>
                <Typography variant="body2">
                  {selectedValidatedLocation.description}
                </Typography>
                <Stack direction="row" pt={2} flexWrap="wrap">
                  {selectedValidatedLocation.tags && selectedValidatedLocation.tags.map((tag) => <Box m={0.5}><Chip key={tag} label={tag} size="small" /></Box>)}
                </Stack>
                <Typography sx={{ mb: 1 }} variant="caption" color="text.secondary">
                  {validatedBy}
                </Typography>
              </CardContent>
              <CardActions sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                {
                  selectedValidatedLocation.markedForRemoval
                    && activeTeam?.teamType as TeamType === 'Practice'
                    ? (
                      <Button
                        size="small"
                        variant="outlined"
                        disabled={!dirty}
                        color="error"
                        onClick={() => onDeleteValidatedLocation()}
                      >
                        Delete
                      </Button>
                    )
                    : (
                      <Button
                        size="small"
                        variant="outlined"
                        disabled={!dirty}
                        onClick={() => onConfirmExistingValidatedLocation()}
                      >
                        Use Location
                      </Button>
                    )
                }
                <Button
                  size="small"
                  variant="text"
                  sx={{ mr: 1 }}
                  disabled={!dirty}
                  onClick={() => onCancel()}
                >
                  Reset
                </Button>
              </CardActions>
            </Card>
          )
          : (
            <Box sx={{ p: 2, border: '1px dashed grey' }}>
              <Alert severity="info">No validated location selected</Alert>
            </Box>
          )
      }
    </>
  );
};

export default SelectedValidatedLocationBox;
