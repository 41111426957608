import { ReactNode } from 'react';
import RouteContext from './appRoutes.context';

export interface AppRoutesProviderProps {
  children: ReactNode;
  routes: any;
}

const AppRoutesProvider = ({ children, routes }: AppRoutesProviderProps) => (
  <RouteContext.Provider value={routes}>
    {children}
  </RouteContext.Provider>
);

export default AppRoutesProvider;
