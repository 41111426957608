import {
  CreateValidatedLocationRequestDto,
  UpdateValidatedLocationDescriptionRequestDto,
  UpdateValidatedLocationPositionRequestDto,
  UpdateValidatedLocationTagsRequestDto,
  ValidatedLocationDto
} from './api';
import {
  GetKey,
  ListKey,
  makeCreateMutation,
  makeDeleteMutation,
  makeGetQuery,
  makeListQuery,
  makeUpdateMutation
} from './useApi';

const VALIDATED_LOCATIONS_KEY = 'validated-locations' as ListKey;
const VALIDATED_LOCATION_KEY = 'validated-location' as GetKey;

export interface ValidatedLocationsQueryParams {
  clientId: string;
  teamId: string;
}

export const useValidatedLocationsQuery = makeListQuery<ValidatedLocationsQueryParams, ValidatedLocationDto[]>(
  VALIDATED_LOCATIONS_KEY,
  (api, params) => () => api.validatedLocations.list(params.clientId, params.teamId),
);

interface ValidatedLocationQueryParams {
  entityId: string;
  clientId: string;
}

export const useValidatedLocationQuery = makeGetQuery<ValidatedLocationDto, ValidatedLocationQueryParams>(
  VALIDATED_LOCATION_KEY,
  (api, params) => () => api.validatedLocations.get(params.clientId, params.entityId),
);

interface ValidateLocationMutation {
  teamId: string;
  userId: string;
}

export const useClientValidateLocationMutation = makeUpdateMutation<string, ValidateLocationMutation>(
  VALIDATED_LOCATION_KEY,
  VALIDATED_LOCATIONS_KEY,
  (api, validatedLocationId) => (mutation) => api.validatedLocations.setAsValidatedByClient(validatedLocationId, mutation.userId, mutation.teamId),
  () => 'Location validated successfully updated',
);

export const useVetValidateLocationMutation = makeUpdateMutation<string, ValidateLocationMutation>(
  VALIDATED_LOCATION_KEY,
  VALIDATED_LOCATIONS_KEY,
  (api, validatedLocationId) => (mutation) => api.validatedLocations.setAsValidatedByPractice(validatedLocationId, mutation.userId, mutation.teamId),
  () => 'Location validated successfully updated',
);

export interface UpdateValidatedLocationDescriptionMutation {
  validatedLocationId: string;
  teamId: string;
  request: UpdateValidatedLocationDescriptionRequestDto;
}

export const useUpdateValidatedLocationDescriptionMutation = makeUpdateMutation<string, UpdateValidatedLocationDescriptionMutation>(
  VALIDATED_LOCATION_KEY,
  VALIDATED_LOCATIONS_KEY,
  (api, validatedLocationId) => (mutation) => api.validatedLocations.updateDescription(validatedLocationId, mutation.teamId, mutation.request),
  () => 'Location description updated successfully updated',
);

export interface UpdateValidatedLocationPositionMutation {
  validatedLocationId: string;
  teamId: string;
  request: UpdateValidatedLocationPositionRequestDto;
}

export const useUpdateValidatedLocationPositionMutation = makeUpdateMutation<string, UpdateValidatedLocationPositionMutation>(
  VALIDATED_LOCATION_KEY,
  VALIDATED_LOCATIONS_KEY,
  (api, validatedLocationId) => (mutation) => api.validatedLocations.updatePosition(validatedLocationId, mutation.teamId, mutation.request),
  () => 'Location position updated successfully',
);

export interface CreateValidatedLocationMutation {
  teamId: string;
  request: CreateValidatedLocationRequestDto;
}

export const useCreateValidatedLocationMutation = makeCreateMutation<string, CreateValidatedLocationMutation>(
  VALIDATED_LOCATIONS_KEY,
  (api) => (mutation) => api.validatedLocations.create(mutation.teamId, mutation.request),
  () => 'Validated location created successfully updated',
);

export const useMarkForRemovalValidatedLocationMutation = makeUpdateMutation<void, string>(
  VALIDATED_LOCATION_KEY,
  VALIDATED_LOCATIONS_KEY,
  (api, validatedLocationId) => (teamId) => api.validatedLocations.markForRemoval(teamId, validatedLocationId),
  () => 'Validated location successfully flagged for deletion',
);

interface DeleteValidatedLocationMutation {
  practiceId: string,
  validatedLocationId: string;
}

export const useDeleteValidatedLocationMutation = makeDeleteMutation<void, DeleteValidatedLocationMutation>(
  VALIDATED_LOCATION_KEY,
  VALIDATED_LOCATIONS_KEY,
  (api) => (mutation) => api.validatedLocations.delete(mutation.practiceId, mutation.validatedLocationId),
  () => 'The validated location has been deleted successfully',
  (mutation) => mutation.validatedLocationId,
);

export interface UpdateValidatedLocationTagsMutation {
  teamId: string,
  request: UpdateValidatedLocationTagsRequestDto;
}

export const useUpdateValidatedLocationTagsMutation = makeUpdateMutation<string, UpdateValidatedLocationTagsMutation>(
  VALIDATED_LOCATION_KEY,
  VALIDATED_LOCATIONS_KEY,
  (api, validatedLocationId) => (mutation) => api.validatedLocations.updateTags(mutation.teamId, validatedLocationId, mutation.request),
  () => 'Location tags updated successfully',
);
