import { SvgIcon } from '@mui/material';
import { ReactComponent as PracticeAdminSVGIcon } from './practice-admin.svg';
import { ReactComponent as ReceptionistSVGIcon } from './receptionist.svg';
import { ReactComponent as VetSVGIcon } from './vet.svg';

const PracticeAdminIcon = () => <SvgIcon fontSize="large" component={PracticeAdminSVGIcon} viewBox="0 0 512.001 512.001" />;
const ReceptionistIcon = () => <SvgIcon fontSize="large" component={ReceptionistSVGIcon} viewBox="0 0 512 512" />;
const VetIcon = () => <SvgIcon fontSize="large" component={VetSVGIcon} viewBox="0 0 511.997 511.997" />;

export {
  PracticeAdminIcon,
  ReceptionistIcon,
  VetIcon,
};
