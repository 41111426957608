import { useLocation, useNavigate } from 'react-router-dom';

type NewAppointmentInputs = {
  clientId?: string;
  patientId?: string;
  date?: Date;
  vetId?: string;
}

const useNewAppointmentNavigate = () => {
  const navigate = useNavigate();
  return (path: string, inputs: NewAppointmentInputs) => navigate(path, {
    state: {
      clientId: inputs.clientId, patientId: inputs.patientId, date: inputs.date, vetId: inputs.vetId,
    },
  });
};

const useNewAppointmentLocationState = (): NewAppointmentInputs => {
  const { state } = useLocation();
  const { patientId, clientId, date, vetId } = state as NewAppointmentInputs ?? { patientId: undefined, clientId: undefined, date: undefined, vetId: undefined };
  return { patientId, clientId, date, vetId };
};

export {
  useNewAppointmentLocationState,
  useNewAppointmentNavigate,
};
