import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useValidatedLocationsState, ValidatedLocationList } from 'features/ValidatedLocations';
import useProtectedParams from 'hooks/useProtectedParams';
import {
  APPOINTMENT_KEY,
  UpdateLocationCommand,
  useUpdateLocationMutation,
  ValidatedLocationDto
} from 'providers/api';
import { useDeleteValidatedLocationMutation } from 'providers/api/useValidatedLocations';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SelectedValidatedLocationBox from './SelectedValidatedLocationBox';

const getValidatedBy = (location: ValidatedLocationDto) => {
  if (location.validatingVetUser) return 'Validated by vet';
  if (location.validatingClientUser) return 'Validated by client';
  return 'Not validated';
};

const getSelectedValidatedLocation = (
  selectedValidatedLocationId: string | undefined,
  confirmedValidatedLocationId: string | undefined,
  validatedLocations: ValidatedLocationDto[],
) => {
  if (selectedValidatedLocationId) {
    return validatedLocations.find((validatedLocation) => validatedLocation.entityId === selectedValidatedLocationId);
  }
  if (confirmedValidatedLocationId) {
    return validatedLocations.find((validatedLocation) => validatedLocation.entityId === confirmedValidatedLocationId);
  }
  return undefined;
};

const AppointmentValidatedLocationListPanel = () => {
  const navigate = useNavigate();
  const { teamId, appointmentId } = useProtectedParams('teamId', 'appointmentId');
  const validatedLocationsState = useValidatedLocationsState();
  const updateLocationMutation = useUpdateLocationMutation(appointmentId);
  const [currentConfirmedValidatedLocationId, setCurrentConfirmedValidatedLocationId] = React.useState<string>();
  const deleteValidatedLocationMutation = useDeleteValidatedLocationMutation();

  const {
    contextData:
    {
      validatedLocations,
      highlightedValidatedLocationId,
      selectedValidatedLocationId,
      confirmedValidatedLocationId,
      setHighlightedValidatedLocationId,
      setSelectedValidatedLocationId,
    },
  } = validatedLocationsState;

  const selectedValidatedLocation = getSelectedValidatedLocation(selectedValidatedLocationId, currentConfirmedValidatedLocationId, validatedLocations);

  const handleConfirmExistingValidatedLocation = () => {
    selectedValidatedLocation && updateLocationMutation.mutate({
      entityKey: appointmentId,
      entityType: APPOINTMENT_KEY,
      description: selectedValidatedLocation.description,
      geoPoint: selectedValidatedLocation.geoPoint,
      validatedLocationId: selectedValidatedLocation.entityId,
    } as UpdateLocationCommand);
  };

  const handleDeleteValidatedLocation = () => {
    selectedValidatedLocation && deleteValidatedLocationMutation.mutate({
      practiceId: teamId,
      validatedLocationId: selectedValidatedLocation.entityId,
    });
  };

  const handleCancel = () => {
    setSelectedValidatedLocationId && setSelectedValidatedLocationId(undefined);
    setCurrentConfirmedValidatedLocationId(undefined);
  };

  // Set the initial confirmed
  React.useEffect(() => {
    if (confirmedValidatedLocationId && !currentConfirmedValidatedLocationId) setCurrentConfirmedValidatedLocationId(confirmedValidatedLocationId);
  }, [confirmedValidatedLocationId]);

  return (
    <Box height="calc(100vh - 250px)" display="flex" flexDirection="column">
      <Box>
        <Button
          size="small"
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
          onClick={() => navigate('temporary')}
        >
          Select temporary location
        </Button>
      </Box>
      <Box pb={2} flex={0}>
        <SelectedValidatedLocationBox
          selectedValidatedLocation={selectedValidatedLocation}
          validatedBy={selectedValidatedLocation ? getValidatedBy(selectedValidatedLocation) : ''}
          dirty={selectedValidatedLocation?.entityId !== confirmedValidatedLocationId}
          onCancel={handleCancel}
          onConfirmExistingValidatedLocation={handleConfirmExistingValidatedLocation}
          onDeleteValidatedLocation={handleDeleteValidatedLocation}
        />
      </Box>
      <Typography variant="subtitle2">Select from validated locations</Typography>
      <Box flex={1} overflow="auto">
        <ValidatedLocationList
          validatedLocations={validatedLocations}
          highlightedValidatedLocation={highlightedValidatedLocationId}
          selectedValidatedLocation={selectedValidatedLocationId}
          confirmedValidatedLocation={confirmedValidatedLocationId}
          onMouseOver={(validatedLocationId: string) => setHighlightedValidatedLocationId && setHighlightedValidatedLocationId(validatedLocationId)}
          onMouseOut={() => setHighlightedValidatedLocationId && setHighlightedValidatedLocationId(undefined)}
          onClick={(validatedLocationId: string) => setSelectedValidatedLocationId && setSelectedValidatedLocationId(validatedLocationId)}
        />
      </Box>
    </Box>

  );
};

export default AppointmentValidatedLocationListPanel;
