import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import { IconButton } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import Address from 'components/Address';
import { HOUR_IN_MILLISECONDS } from 'config';
import useProtectedParams from 'hooks/useProtectedParams';
import { TopContent } from 'modules/Layout';
import { useClientQuery } from 'providers/api';
import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useOnWindowScroll } from 'rooks';

const ClientRouterOutlet = () => {
  const { clientId } = useProtectedParams('clientId');
  const { data: client } = useClientQuery(clientId, { staleTime: HOUR_IN_MILLISECONDS });

  const [expanded, setExpanded] = React.useState(false);
  useOnWindowScroll(() => setExpanded(false));

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <>
      <TopContent>
        <Box display="flex" alignItems="start" justifyContent="space-between" m={1}>
          <Accordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" sx={{ mr: 3, flexShrink: 0 }}>{client?.name}</Typography>
              {client && <Box alignSelf="center"><Address type="inline" address={client.site} typographyVariant="caption" /></Box>}
            </AccordionSummary>
            <AccordionDetails>
              {client && (
                <List
                  sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                  subheader={(
                    <ListSubheader disableSticky component="div" id="nested-list-subheader">
                      Contact Details
                    </ListSubheader>
                  )}
                >
                  <ListItem>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary={`${client.mainContact.firstName} ${client.mainContact.lastName}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <PhoneIcon />
                    </ListItemIcon>
                    <ListItemText primary={client.mainContact.contactNumber} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText primary={client.mainContact.email} />
                  </ListItem>
                </List>
              )}
            </AccordionDetails>
          </Accordion>
          <IconButton
            sx={{ mt: '4px', ml: 2, color: (theme) => theme.palette.common.white }}
            size="large"
            component={Link}
            to="edit"
          >
            <EditIcon />
          </IconButton>
        </Box>
        <Divider />
      </TopContent>
      <Outlet />
    </>
  );
};

export default ClientRouterOutlet;
