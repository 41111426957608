import { IntegrationForm } from './api';
import {
  ListKey,
  makeCreateMutation
} from './useApi';

const INTEGRATIONS_KEY = 'integrations' as ListKey;

// eslint-disable-next-line import/prefer-default-export
export const useAddPracticeToWhitelistMutation = makeCreateMutation<void, IntegrationForm>(
  INTEGRATIONS_KEY,
  (api) => (mutation) => api.integrations.addPracticeIdToWhiteList(mutation),
  () => 'Practice added to whitelist',
);
