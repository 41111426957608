import { PagedListOfUserDto, UpdateUserTeamRolesCommand, UserDto } from './api';
import {
  GetKey,
  ListKey,
  makeGetQuery,
  makeListQuery,
  makeUpdateMutation,
  PageParams
} from './useApi';

const USERS_KEY = 'users' as ListKey;
export const USER_KEY = 'user' as GetKey;
const VET_KEY = 'vet' as ListKey;
export interface UsersQueryParams extends PageParams {
  teamId: string | null,
  searchTerm?: string | null;
}

export const useUsersQuery = makeListQuery<UsersQueryParams, PagedListOfUserDto>(
  USERS_KEY,
  (api, params) => () => api.users.listUsers(params.page, params.pageSize, params.teamId, params.searchTerm),
);

export const useUpdateUserTeamsRolesMutation = makeUpdateMutation<void, UpdateUserTeamRolesCommand>(
  USER_KEY,
  USERS_KEY,
  (api, userId) => (command) => api.users.updateUserTeamsRoles(userId, command),
  () => 'User roles successfully updated',
  [USERS_KEY],
);

export interface UserGetParams {
  teamId: string;
  userId: string;
}

export const useUserQuery = makeGetQuery<UserDto, UserGetParams>(
  USER_KEY,
  (api, params) => () => api.users.get(params.userId, params.teamId),
);

export const useVetsQuery = makeListQuery<UsersQueryParams, PagedListOfUserDto>(
  VET_KEY,
  (api, params) => () => api.users.listVetsForPractice(params.teamId, params.page, params.pageSize),
);

export interface ListOfTeamUsersGetParams {
  userIds: string[];
  teamId: string;
}

export const useListOfTeamUsersQuery = makeListQuery<ListOfTeamUsersGetParams, UserDto[]>(
  USERS_KEY,
  (api, params) => async () => {
    const results = await Promise.all(params.userIds.map((userId) => api.users.get(userId, params.teamId)));
    return results;
  },
);
