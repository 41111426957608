import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { useAccountForm } from './context';

const AccountNameField = () => {
  const {
    form: { formik },
  } = useAccountForm();

  const { values, setFieldValue } = formik;

  return (
    <FormGroup>
      <FormControlLabel
        control={(
          <Checkbox
            checked={!values.excludeReminderSms}
          />
        )}
        name="excludeReminderSms"
        onClick={() => setFieldValue('excludeReminderSms', !values.excludeReminderSms)}
        label="Text me appointment reminders"
      />
      <FormControlLabel
        control={(
          <Checkbox
            checked={!values.excludeReminderEmail}
          />
        )}
        name="excludeReminderSms"
        onClick={() => setFieldValue('excludeReminderEmail', !values.excludeReminderEmail)}
        label="Email me appointment reminders"
      />
      <FormControlLabel
        control={(
          <Checkbox
            checked={!values.excludeEtaSms}
          />
        )}
        name="excludeEtaSms"
        onClick={() => setFieldValue('excludeEtaSms', !values.excludeEtaSms)}
        label="Text me ETA reminders"

      />
      <FormControlLabel
        control={(
          <Checkbox
            checked={!values.excludeEtaEmail}
          />
        )}
        name="excludeEtaEmail"
        onClick={() => setFieldValue('excludeEtaEmail', !values.excludeEtaEmail)}
        label="Email me ETA reminders"
      />
    </FormGroup>
  );
};

export default AccountNameField;
