import Loading from 'components/Loading';
import PatientInfoPanel from 'features/patient/PatientInfoPanel';
import usePatientData from 'features/Patients/usePatientData';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { useNewAppointmentNavigate } from 'modules/practice/calendar/NewAppointmentPage/useNewAppointment';
import { useUpdatePatientImageMutation } from 'providers/api';
import { useNavigate } from 'react-router-dom';
import { TITLE as parentTitle } from '../ClientPatientsPage';
import { TITLE } from './constants';

const PatientPage = () => {
  const { clientId, patientId } = useProtectedParams('clientId', 'patientId');
  const { patient, handleDelete } = usePatientData(patientId);

  const newAppointmentNavigate = useNewAppointmentNavigate();
  const imageMutation = useUpdatePatientImageMutation(patientId);
  const navigate = useNavigate();

  const handleImageUpload = (file: File) => {
    imageMutation.mutate({ fileName: file.name, data: file });
  };

  const handleCreateAppointment = () => newAppointmentNavigate('../../../calendar/new', { clientId, patientId });

  if (!patient) {
    return <Loading />;
  }

  return (
    <Page pageType="standard" title={TITLE} parentRelativePath="../" parentTitle={parentTitle}>
      <PatientInfoPanel
        patient={patient}
        uploading={imageMutation.isLoading}
        onUpload={handleImageUpload}
        onCreateAppointment={handleCreateAppointment}
        onEdit={() => navigate(`../patients/${patientId}/edit`)}
        handleDelete={handleDelete}
      />
    </Page>
  );
};

export default PatientPage;
