import { Stack, StackProps } from '@mui/material';
import React from 'react';

interface FormStackProps {
  direction?: StackProps['direction'],
  children: React.ReactNode,
}

const FormStack = ({ direction = 'column', children }: FormStackProps) => (
  <Stack direction={direction} spacing={2}>
    {children}
  </Stack>
);

export default FormStack;
