import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    sideMenu: Palette['primary'];
  }
  interface PaletteOptions {
    sideMenu: PaletteOptions['primary'];
  }
}

const defaultTheme = createTheme();

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  palette: {
    background: {
      default: '#DEDBD3',
    },
    primary: {
      main: '#44A184',
    },
    secondary: {
      main: '#EBAA95',
    },
    sideMenu: {
      main: '#2A4B4E',
      contrastText: '#fff',
    },
    error: {
      main: '#d32f2f',
      dark: '#F50057',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 0,
          paddingRight: 0,
          [defaultTheme.breakpoints.up('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          padding: '11px 22px', // Matches height of icon button
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
});

export default theme;
