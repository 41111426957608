import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { FormStack } from 'components/Form';
import { FormikErrors } from 'formik';
import { UpdatePracticeCommand } from 'providers/api';
import { dissocPath } from 'ramda';
import { usePracticeForm } from './context';

const PracticeContactFields = () => {
  const {
    form: { formik, helpers },
  } = usePracticeForm();

  const { values, handleChange, handleBlur, setFieldValue, setErrors, errors } = formik;

  const handleAddPrimaryContact = () => {
    setFieldValue('contact.practiceContact', {
      firstName: '',
      lastName: '',
      contactNumber: '',
      email: '',
    });
  };

  const handleRemovePrimaryContact = () => {
    setFieldValue('contact.practiceContact', undefined);
    const updatedErrors = dissocPath<FormikErrors<UpdatePracticeCommand>>(['site', 'siteAddress'], errors);
    setErrors(updatedErrors);
  };

  return !values.contact.practiceContact
    ? (
      <Box my={2}>
        <Button variant="contained" color="secondary" onClick={handleAddPrimaryContact} startIcon={<AddIcon />}>Primary Contact</Button>
      </Box>
    )
    : (
      <>
        <FormStack>
          <FormStack direction={{ xs: 'column', md: 'row' }}>
            <TextField
              fullWidth
              id="contact.practiceContact.firstName"
              name="contact.practiceContact.firstName"
              label="First Name"
              value={values.contact.practiceContact.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('contact.practiceContact.firstName')}
              helperText={helpers.getErrorHelpText('contact.practiceContact.firstName')}
            />
            <TextField
              fullWidth
              id="contact.practiceContact.lastName"
              name="contact.practiceContact.lastName"
              label="Last Name"
              value={values.contact.practiceContact.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('contact.practiceContact.lastName')}
              helperText={helpers.getErrorHelpText('contact.practiceContact.lastName')}
            />
          </FormStack>
          <TextField
            fullWidth
            id="contact.practiceContact.contactNumber"
            name="contact.practiceContact.contactNumber"
            label="Contact Number"
            value={values.contact.practiceContact.contactNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('contact.practiceContact.contactNumber')}
            helperText={helpers.getErrorHelpText('contact.practiceContact.contactNumber')}
          />
          <TextField
            fullWidth
            id="contact.practiceContact.email"
            name="contact.practiceContact.email"
            label="Email"
            value={values.contact.practiceContact.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={helpers.hasError('contact.practiceContact.email')}
            helperText={helpers.getErrorHelpText('contact.practiceContact.email')}
          />
        </FormStack>
        <Box my={2}>
          <Button variant="contained" color="inherit" onClick={handleRemovePrimaryContact}>Remove Primary Contact</Button>
        </Box>
      </>
    );
};

export default PracticeContactFields;
