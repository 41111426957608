import {
  convert,
  DateTimeFormatter, ZonedDateTime
} from '@js-joda/core';
import '@js-joda/timezone';

export const getTimeZoneAbbreviation = (zonedDateTime: ZonedDateTime) => {
  const timeZoneFormatter = Intl.DateTimeFormat('default', {
    timeZoneName: 'short',
    timeZone: zonedDateTime.zone().id(),
  });

  return timeZoneFormatter.formatToParts(convert(zonedDateTime).toDate())
    .find((part) => part.type === 'timeZoneName')?.value || '';
};

ZonedDateTime.prototype.toJSON = function toJSON() {
  const formatter = DateTimeFormatter
    .ofPattern('HH:mm:ss.nnnnnnnnn');

  return `${this.toLocalDate().toString()}T${this.toLocalTime().format(formatter)}${this.offset().toString()} ${this.zone()}`;
};

// eslint-disable-next-line no-useless-escape, max-len
const OFFSET_DATE_TIME_REG_EX = /([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?/;
// eslint-disable-next-line no-useless-escape
const SINGLE_OFFSET_REG_EX = /[\+-][0-9]{2}$/;

export const makeZonedDateTime = (dateTimeString: string): ZonedDateTime => {
  const matches = dateTimeString.match(OFFSET_DATE_TIME_REG_EX);

  const timezone = dateTimeString.replace(OFFSET_DATE_TIME_REG_EX, '').trim();

  const offsetDateTime = matches![0];

  const offset = offsetDateTime.match(SINGLE_OFFSET_REG_EX);

  const jsJodaFriendly = offset?.length ? offsetDateTime.replace(SINGLE_OFFSET_REG_EX, `${offset[0]}:00`) : offsetDateTime;

  return ZonedDateTime.parse(`${jsJodaFriendly}[${timezone}]`);
};
