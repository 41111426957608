import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Address from 'components/Address';
import TitleBox from 'components/TitleBox';
import TitleDisplayStack from 'components/TitleDisplayStack';
import {
  AppointmentDto,
  ClientDto
} from 'providers/api';
import React from 'react';

interface AppointmentClientAndPetSectionProps {
  appointment: AppointmentDto;
  client: ClientDto;
}

const AppointmentClientAndPetSection = ({ appointment, client }: AppointmentClientAndPetSectionProps) => (
  <TitleBox title="Client and Patient">
    <TitleDisplayStack
      title="Client"
      display={(
        <>
          <Typography display="inline">
            {`${client.name}`}
          </Typography>
          <Address address={client.site} type="inline" typographyVariant="body2" />
        </>
      )}
    />
    <Divider sx={{ my: 3 }} />
    <TitleDisplayStack
      title="Patient"
      display={<Typography variant="body2">{appointment.patient.name}</Typography>}
    />
  </TitleBox>
);

export default AppointmentClientAndPetSection;
