import { FormikContextType } from 'formik';
import { createContext } from 'react';
import { UseMutationResult } from 'react-query';

export interface Form<T = any> {
  formik: FormikContextType<T>;
  helpers: {
    hasError: (stringPath: string) => boolean;
    getErrorHelpText: (stringPath: string) => string;
  }
}

export interface IFormContext<TForm = unknown, TValues = unknown> {
  form: Form<TValues>,
  mutation: UseMutationResult<any, any, TForm, any>;
}

export const createFormContext = <TForm = unknown, TValues = unknown>() => createContext<IFormContext<TForm, TValues> | null>(null);
