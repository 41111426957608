import {
  convert, LocalDate, LocalTime, nativeJs, ZonedDateTime, ZoneId
} from '@js-joda/core';
import { HOUR_IN_MILLISECONDS } from 'config';
import { FormikConfig } from 'formik';
import {
  AppointmentDto,
  AppointmentForm,
  ContactDetailsForm
} from 'providers/api';
import { UseMutationResult } from 'react-query';
import { isNilOrEmpty } from 'utils';
import {
  array, date, number, object, SchemaOf, string
} from 'yup';
import { AppointmentFormProvider, AppointmentValues } from './context';

const contactDetailsFormSchema: SchemaOf<ContactDetailsForm> = object().shape({
  firstName: string().required('A first name is required'),
  lastName: string().required('A last name is required'),
  email: string().required('An email address is required'),
  contactNumber: string().required('A contact number is required'),
});

const AppointmentSchema = object().shape({
  clientId: string().required('A client must be selected'),
  startDate: date()
    .nullable()
    .required('A start date is required'),
  startTime: date()
    .nullable()
    .required('A start time is required')
    .test(
      'appointment-start-time-is-in-future?',
      'The appointment start time must be in the future',
      (startTime) => (startTime ? startTime > new Date() : true),
    )
    .test(
      'start-time-is-before-end-time?',
      'The start time cannot be after the finish time',
      (startTime, context) => {
        if (isNilOrEmpty(context.parent.startTime)
          || (isNilOrEmpty(context.parent.endTime))) {
          return true;
        } const { endTime } = context.parent;
        return (startTime ? startTime < endTime : true);
      },
    ),
  endTime: date()
    .nullable()
    .required('A finish time is required'),
  appointmentType: number().required('An appointment type is required'),
  appointmentPriority: number().required('An appointment priority is required'),
  vetId: string().required('A vet must be selected'),
  patientId: string().required('A patient must be selected'),
  attendeeIds: array().of(string().required()).required(),
  appointmentSummary: string().required('An appointment summary is required'),
  veterinaryNotes: string(),
  contactDetails: contactDetailsFormSchema.notRequired().default(undefined),
  primaryContactId: string().when('contactDetails', {
    is: undefined,
    then: (schema) => schema.required('If no primary contacted is selected then you are required to include contact details'),
    otherwise: (schema) => schema.optional(),
  }),
});

interface ClientAppointmentFormProps {
  // INFO: mutation is provided as prop as different mutations are used on all or part of the same data structure
  mutation: UseMutationResult<any, any, any, any>;
  appointment?: AppointmentDto;
  initialStartDate?: Date;
  initialClient?: string;
  initialPatient?: string;
  initialVet?: string;
  children: React.ReactNode | React.ReactNode[];
  onValidationSuccess: (values: AppointmentForm, setSubmitting: (isSubmitting: boolean) => void) => void;
}

const ClientAppointmentForm = ({
  mutation,
  appointment,
  children,
  initialStartDate,
  initialClient,
  initialPatient,
  initialVet,
  onValidationSuccess,
}: ClientAppointmentFormProps) => {
  const initialStart = initialStartDate;
  const initialEnd = initialStart ? new Date(+initialStart + HOUR_IN_MILLISECONDS) : null;

  const formikConfig: FormikConfig<AppointmentValues> = {
    initialValues: {
      clientId: appointment?.clientId ?? (initialClient ?? ''),
      startDate: appointment?.startTime ? convert(appointment.startTime).toDate() : initialStartDate ?? null,
      startTime: appointment?.startTime ? convert(appointment.startTime).toDate() : initialStartDate ?? null,
      endTime: appointment?.endTime ? convert(appointment.endTime).toDate() : initialEnd,
      appointmentType: appointment?.appointmentType ?? null,
      appointmentPriority: appointment?.appointmentPriority ?? null,
      vetId: appointment?.vet.entityId ?? (initialVet ?? ''),
      patientId: appointment?.patient.entityId ?? (initialPatient ?? ''),
      attendeeIds: appointment?.attendees ? appointment.attendees.map((attendee) => attendee.entityId) : [],
      appointmentSummary: appointment?.appointmentSummary ?? '',
      veterinaryNotes: '',
      primaryContactId: appointment?.primaryContact ? appointment?.primaryContact.entityId : '',
      contactDetails: appointment?.contactDetails ?? undefined,
    },
    onSubmit: (appointmentValues, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const validatedAppointmentForm = AppointmentSchema.validateSync(appointmentValues);

        const startDateBase = LocalDate.from(nativeJs(appointmentValues.startDate));
        const startTime = LocalTime.from(nativeJs(appointmentValues.startTime));
        const endTime = LocalTime.from(nativeJs(appointmentValues.endTime));
        const startTimeZoned = ZonedDateTime.of(startDateBase, startTime, ZoneId.systemDefault());
        const endTimeZoned = ZonedDateTime.of(startDateBase, endTime, ZoneId.systemDefault());

        const appointmentForm: AppointmentForm = {
          ...validatedAppointmentForm,
          startTime: startTimeZoned,
          endTime: endTimeZoned,
        };

        onValidationSuccess(appointmentForm, setSubmitting);
      } catch (e) {
        // noop
      }
    },
    validationSchema: AppointmentSchema,
  };

  return (
    <AppointmentFormProvider mutation={mutation} formikConfig={formikConfig}>
      {children}
    </AppointmentFormProvider>
  );
};

export default ClientAppointmentForm;
