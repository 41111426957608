import { createFormContext } from './context';
import { createFormProvider } from './provider';
import { createUseForm } from './use';

export const createForm = <TForm = unknown, TValues = unknown>() => {
  const FormContext = createFormContext<TForm, TValues>();
  const FormProvider = createFormProvider(FormContext);
  const useForm = createUseForm(FormContext);

  return {
    FormProvider,
    useForm,
  };
};

export default createForm;
