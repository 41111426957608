import { EtaDto } from './api';
import {
  GetKey,
  ListKey,
  makeGetQuery,
  makeListQuery
} from './useApi';

const ARRIVAL_TIME_KEY = 'arrival-time' as GetKey;
const ARRIVAL_TIMES_KEY = 'arrival-times' as ListKey;

// eslint-disable-next-line import/prefer-default-export
export const useArrivalTimeQuery = makeGetQuery<EtaDto | undefined, string>(
  ARRIVAL_TIME_KEY,
  (api, appointmentId) => async () => {
    const location = await api.arrivalTimes.get(appointmentId)
      .then((result) => result)
      .catch(() => undefined);
    return location;
  },
);

export const useArrivalTimesQuery = makeListQuery<string[], EtaDto[]>(
  ARRIVAL_TIMES_KEY,
  (api, appointmentIds) => async () => {
    const promiseQueue = Promise.allSettled(appointmentIds.map((appointmentId) => api.arrivalTimes.get(appointmentId)));
    const promiseResults = await promiseQueue;
    return promiseResults.filter((result) => result.status === 'fulfilled').map((result) => (result as PromiseFulfilledResult<EtaDto>).value);
  },
);
