import AppointmentLocationPage from './AppointmentLocationPage';
import AppointmentTemporaryLocationPanel from './AppointmentTemporaryLocationPanel';
import AppointmentValidatedLocationListPanel from './AppointmentValidatedLocationListPanel';

export {
  AppointmentValidatedLocationListPanel,
  AppointmentTemporaryLocationPanel,
};

export default AppointmentLocationPage;
