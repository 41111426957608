import {
  ClientDto,
  CreateClientCommand,
  PagedListOfClientDto,
  UpdateClientCommand
} from './api';
import {
  GetKey,
  ListKey,
  makeCreateMutation,
  makeDeleteMutation,
  makeGetQuery,
  makeListQuery,
  makeUpdateMutation,
  PageParams
} from './useApi';

const CLIENTS_KEY = 'clients' as ListKey;
const CLIENT_KEY = 'client' as GetKey;

export interface ClientsQueryParams extends PageParams {
  teamId?: string | null,
  clientName?: string | null;
  clientPostCode?: string | null;
}

export const useClientsQuery = makeListQuery<ClientsQueryParams, PagedListOfClientDto>(
  CLIENTS_KEY,
  (api, params) => () => api.clients.list(params.page, params.pageSize, params.clientName, params.clientPostCode, params.teamId),
);

export const useClientQuery = makeGetQuery<ClientDto, string>(
  CLIENT_KEY,
  (api, clientId) => () => api.clients.get(clientId),
);

export interface CreateClientMutation {
  practiceId: string;
  client: CreateClientCommand;
}

export const useCreateClientMutation = makeCreateMutation<string, CreateClientMutation>(
  CLIENTS_KEY,
  (api) => (mutation) => api.clients.create(mutation.practiceId, mutation.client),
  (mutation) => `${mutation.client.client.name} has been successfully created`,
);

export const useUpdateClientMutation = makeUpdateMutation<void, UpdateClientCommand>(
  CLIENT_KEY,
  CLIENTS_KEY,
  (api, clientId) => (mutation) => api.clients.update(clientId, mutation),
  (mutation) => `The client ${mutation.client.name} has been updated`,
);

export const useDeleteClientMutation = makeDeleteMutation<void, ClientDto>(
  CLIENT_KEY,
  CLIENTS_KEY,
  (api) => (client) => api.clients.delete(client.entityId),
  (client) => `Client ${client.name} has been successfully deleted`,
  (client) => client.entityId,
);
