import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { FormikActions } from 'components/Form';
import TitleBox from 'components/TitleBox';
import {
  RoleDto,
  TeamRolesAssignmentForm,
  UpdateUserTeamRolesCommand,
  UserDto
} from 'providers/api';
import useDefaultForm from 'providers/form/useDefaultForm';
import { UseMutationResult } from 'react-query';
import {
  array,
  object,
  SchemaOf,
  string
} from 'yup';
import UserPermissionsField from '../UserPermissionsField';

const teamRolesAssignmentFormSchema: SchemaOf<TeamRolesAssignmentForm> = object().shape({
  teamId: string().required(),
  roles: array()
    .min(1, 'You must include at least one role.') // any less and the user will be removed from the practice
    .of(string().required()),
});

const updateUserTeamRolesSchema: SchemaOf<UpdateUserTeamRolesCommand> = object().shape({
  teamRoles: array().of(teamRolesAssignmentFormSchema),
});

interface EditUserFormProps {
  roles: RoleDto[];
  teamId: string;
  user?: UserDto;
  mutation: UseMutationResult<void, unknown, UpdateUserTeamRolesCommand, unknown>;
}
const EditUserForm = ({ roles, teamId, user, mutation }: EditUserFormProps) => {
  const {
    formik,
  } = useDefaultForm<UpdateUserTeamRolesCommand>({
    mutation,
    formikConfig: {
      initialValues: {
        teamRoles: [{
          teamId,
          roles: user?.teamRoles ?? [],
        }],
      },
      onSubmit: (form, { setSubmitting }) => {
        setSubmitting(true);
        mutation.mutate(form, {
          onSettled: () => {
            setSubmitting(false);
          },
        });
      },
      validationSchema: updateUserTeamRolesSchema,
    },
  });

  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
  } = formik;

  const handlePermissionSelect = (role: string) => {
    const teamExistsInTeamRoles = values.teamRoles.some((permission) => permission.teamId === teamId);

    // add a new team
    if (!teamExistsInTeamRoles) {
      setFieldValue('teamRoles', [...values.teamRoles, { teamId, roles: [role] }]);
      return;
    }

    // update an existing team
    const newTeamRoles = values.teamRoles.map(
      (existingTeamRoles) => (existingTeamRoles.teamId === teamId
        ? { teamId: existingTeamRoles.teamId, roles: [...existingTeamRoles.roles, role] }
        : existingTeamRoles),
    );
    setFieldValue('teamRoles', newTeamRoles);
  };

  const handlePermissionDeselect = (role: string) => {
    const newRoles = values.teamRoles.map((teamRole) => (
      teamRole.teamId === teamId
        ? { teamId: teamRole.teamId, roles: teamRole.roles.filter((existingRole) => existingRole !== role) }
        : teamRole
    ));
    setFieldValue('teamRoles', newRoles, true);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TitleBox title={`Update roles for ${user?.email}`}>
        {errors.teamRoles && <Alert severity="error">{(errors.teamRoles[0] as any).roles}</Alert>}

        <UserPermissionsField
          roles={roles}
          selectedRoles={values.teamRoles.find((teamRole) => teamRole.teamId === teamId)?.roles ?? []}
          disabled={false}
          onSelect={handlePermissionSelect}
          onDeselect={handlePermissionDeselect}
        />
      </TitleBox>

      <Box mt={2}>
        <FormikActions
          formik={formik}
          mutation={mutation}
          submitText="Update"
          right={['reset', 'submit']}
        />
      </Box>
    </form>
  );
};

export default EditUserForm;
