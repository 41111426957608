import AccountSettings from 'modules/account/settings/AccountSettingsPage';
import { ArrivetRouteObject, RouteConfiguration } from 'modules/interfaces';

const routes: (config: RouteConfiguration) => ArrivetRouteObject[] = () => [
    {
      index: true,
      element: <AccountSettings />,
      path: 'account',
    },

  ];

export default routes;
