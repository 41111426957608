import { AppointmentDto, LocationDto } from 'providers/api';
import useApi from 'providers/api/useApi';
import { useSignalR } from 'providers/signalR';
import React from 'react';

const useVetLocations = (
  vetLocationsIndexedById: Record<string, LocationDto>,
  appointmentsIndexedByVetId: Record<string, AppointmentDto>,
) => {
  const signalR = useSignalR();
  const api = useApi();
  const [realtimeVetLocationsIndexedById, setRealtimeVetLocationsIndexedById] = React.useState(vetLocationsIndexedById);

  const updateVetLocation = (userId: string, location: LocationDto) => {
    setRealtimeVetLocationsIndexedById((existingRealtimeVetLocationsIndexedById) => ({
      ...existingRealtimeVetLocationsIndexedById,
      [userId]: location,
    }));
  };

  // INFO: Stale closure - ref required for passing the appointmentsIndexedByVetId to the callback
  // SEE: https://stackoverflow.com/questions/62806541/how-to-solve-the-react-hook-closure-issue
  const latestValue = React.useRef<Record<string, AppointmentDto>>({});
  latestValue.current = appointmentsIndexedByVetId;

  React.useEffect(() => {
    const locationUpdatedCallbackId = signalR.actions.addCallback('Location Updated', (payload) => {
      const userId = payload.split('|')[0];
      const targetAppointment = latestValue.current[userId].entityId;
      api.locations.getUserLocation(targetAppointment, userId)
        .then((result) => updateVetLocation(userId, result));
    });

    return () => signalR.actions.removeCallback('Location Updated', locationUpdatedCallbackId);
  }, []);

  React.useEffect(() => {
    setRealtimeVetLocationsIndexedById(vetLocationsIndexedById);
  }, [vetLocationsIndexedById]);

  return realtimeVetLocationsIndexedById;
};

export default useVetLocations;
