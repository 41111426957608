import SettingsIcon from '@mui/icons-material/Settings';
import { ArrivetRouteObject, RouteConfiguration } from 'modules/interfaces';
import PracticeLocationPage from './PracticeLocationPage';
import SettingsPage from './SettingsPage/SettingsPage';

export const defaultRoute = 'settings';

const routes: (config: RouteConfiguration) => ArrivetRouteObject[] = () => ([{
  path: 'settings',
  mainNavigation: {
    title: 'Settings',
    group: '',
    iconComponent: <SettingsIcon />,
  },
  children: [
    {
      index: true,
      element: <SettingsPage />,
    },
    {
      path: 'practice-location',
      element: <PracticeLocationPage />,
    },
  ],
}]);

export default routes;
