import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ResponsiveNavButton from 'components/ResponsiveNavButton';
import SearchField from 'components/SearchField';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'config';
import useProtectedParams from 'hooks/useProtectedParams';
import Page from 'modules/Page';
import { ClientDto, useClientsQuery, useDeleteClientMutation } from 'providers/api';
import { useConfirmation } from 'providers/confirm';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ClientsTable from './ClientsTable';
import { TITLE } from './constants';

const ClientsPage = () => {
  const { teamId } = useProtectedParams('teamId');
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGINATION_DEFAULT_PAGE_SIZE);
  const [clientNameSearchTerm, setClientNameSearchTerm] = React.useState<string | null>(null);
  const [clientPostCodeSearchTerm, setClientPostCodeSearchTerm] = React.useState<string | null>(null);

  const confirm = useConfirmation();

  const deleteClientMutation = useDeleteClientMutation();

  const {
    data,
    isFetching,
    isFetched,
    isPreviousData,
  } = useClientsQuery({
    page,
    pageSize,
    clientName: clientNameSearchTerm,
    clientPostCode: clientPostCodeSearchTerm,
    teamId,
  });

  const handlePageChange = (newPage: number) => setPage(newPage + 1);

  const handlePageSizeChange = (newPageSize: number) => {
    setPage(1);
    setPageSize(newPageSize);
  };

  const handleClientNameSearch = (text: string | null) => {
    setPage(1);
    setClientNameSearchTerm(text);
  };

  const handleClientPostCodeSearch = (text: string | null) => {
    setPage(1);
    setClientPostCodeSearchTerm(text);
  };

  const handleEdit = (clientId: string) => navigate(`${clientId}/edit`);
  const handleInvite = (clientId: string) => navigate(`${clientId}/invite`);

  const handleDelete = (client: ClientDto) => {
    confirm({
      variant: 'danger',
      description: 'Are you sure you want to remove this client?',
    }).then(() => deleteClientMutation.mutate(client));
  };

  const handleSelect = (clientId: string) => navigate(clientId);

  return (
    <Page title={TITLE} pageType="large">
      <Stack direction="row" spacing={2} alignItems="flex-start">
        <Box flexGrow={1}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2} direction="row" width="100%">
                <SearchField label="Search by Client Name" onSearch={handleClientNameSearch} />
                <SearchField label="Search by Postcode" onSearch={handleClientPostCodeSearch} />
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
        <ResponsiveNavButton path="../new" label="Add Client" Icon={<AddIcon />} />
      </Stack>
      <Paper sx={{ mt: 3 }}>
        <ClientsTable
          data={data?.items}
          page={page}
          pageSize={pageSize}
          totalItems={data?.pager.totalItems}
          loading={isFetching}
          loadingNew={isFetching && (!isFetched || isPreviousData)}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          clientNameSearchTerm={clientNameSearchTerm}
          clientPostCodeSearchTerm={clientPostCodeSearchTerm}
          onEdit={handleEdit}
          onInvite={handleInvite}
          onDelete={handleDelete}
          onSelect={handleSelect}
        />
      </Paper>
    </Page>
  );
};

export default ClientsPage;
